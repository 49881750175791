(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.o.b3 === region.i.b3)
	{
		return 'on line ' + region.o.b3;
	}
	return 'on lines ' + region.o.b3 + ' through ' + region.i.b3;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

var _Json_decodeInt = { $: 2 };
var _Json_decodeBool = { $: 3 };
var _Json_decodeFloat = { $: 4 };
var _Json_decodeValue = { $: 5 };
var _Json_decodeString = { $: 6 };

function _Json_decodeList(decoder) { return { $: 7, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 8, b: decoder }; }

function _Json_decodeNull(value) { return { $: 9, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 10,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 11,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 12,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 13,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 14,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 15,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 3:
			return (typeof value === 'boolean')
				? $elm$core$Result$Ok(value)
				: _Json_expecting('a BOOL', value);

		case 2:
			if (typeof value !== 'number') {
				return _Json_expecting('an INT', value);
			}

			if (-2147483647 < value && value < 2147483647 && (value | 0) === value) {
				return $elm$core$Result$Ok(value);
			}

			if (isFinite(value) && !(value % 1)) {
				return $elm$core$Result$Ok(value);
			}

			return _Json_expecting('an INT', value);

		case 4:
			return (typeof value === 'number')
				? $elm$core$Result$Ok(value)
				: _Json_expecting('a FLOAT', value);

		case 6:
			return (typeof value === 'string')
				? $elm$core$Result$Ok(value)
				: (value instanceof String)
					? $elm$core$Result$Ok(value + '')
					: _Json_expecting('a STRING', value);

		case 9:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 5:
			return $elm$core$Result$Ok(_Json_wrap(value));

		case 7:
			if (!Array.isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 8:
			if (!Array.isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 10:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 11:
			var index = decoder.e;
			if (!Array.isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 12:
			if (typeof value !== 'object' || value === null || Array.isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 13:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 14:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 15:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 3:
		case 2:
		case 4:
		case 6:
		case 5:
			return true;

		case 9:
			return x.c === y.c;

		case 7:
		case 8:
		case 12:
			return _Json_equality(x.b, y.b);

		case 10:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 11:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 13:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 14:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 15:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.iT,
		impl.ks,
		impl.jU,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		i2: func(record.i2),
		jT: record.jT,
		jn: record.jn
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.i2;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.jT;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.jn) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.iT,
		impl.ks,
		impl.jU,
		function(sendToApp, initialModel) {
			var view = impl.kv;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.iT,
		impl.ks,
		impl.jU,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.eL && impl.eL(sendToApp)
			var view = impl.kv;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.hY);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.j5) && (_VirtualDom_doc.title = title = doc.j5);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.jc;
	var onUrlRequest = impl.jd;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		eL: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.g_ === next.g_
							&& curr.f7 === next.f7
							&& curr.gU.a === next.gU.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		iT: function(flags)
		{
			return A3(impl.iT, flags, _Browser_getUrl(), key);
		},
		kv: impl.kv,
		ks: impl.ks,
		jU: impl.jU
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { iN: 'hidden', h9: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { iN: 'mozHidden', h9: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { iN: 'msHidden', h9: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { iN: 'webkitHidden', h9: 'webkitvisibilitychange' }
		: { iN: 'hidden', h9: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		g9: _Browser_getScene(),
		hy: {
			hD: _Browser_window.pageXOffset,
			hF: _Browser_window.pageYOffset,
			hC: _Browser_doc.documentElement.clientWidth,
			f6: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		hC: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		f6: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			g9: {
				hC: node.scrollWidth,
				f6: node.scrollHeight
			},
			hy: {
				hD: node.scrollLeft,
				hF: node.scrollTop,
				hC: node.clientWidth,
				f6: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			g9: _Browser_getScene(),
			hy: {
				hD: x,
				hF: y,
				hC: _Browser_doc.documentElement.clientWidth,
				f6: _Browser_doc.documentElement.clientHeight
			},
			ix: {
				hD: x + rect.left,
				hF: y + rect.top,
				hC: rect.width,
				f6: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


// SEND REQUEST

var _Http_toTask = F2(function(request, maybeProgress)
{
	return _Scheduler_binding(function(callback)
	{
		var xhr = new XMLHttpRequest();

		_Http_configureProgress(xhr, maybeProgress);

		xhr.addEventListener('error', function() {
			callback(_Scheduler_fail($elm$http$Http$NetworkError));
		});
		xhr.addEventListener('timeout', function() {
			callback(_Scheduler_fail($elm$http$Http$Timeout));
		});
		xhr.addEventListener('load', function() {
			callback(_Http_handleResponse(xhr, request.fU.a));
		});

		try
		{
			xhr.open(request.i3, request.ff, true);
		}
		catch (e)
		{
			return callback(_Scheduler_fail($elm$http$Http$BadUrl(request.ff)));
		}

		_Http_configureRequest(xhr, request);

		var body = request.hY;
		xhr.send($elm$http$Http$Internal$isStringBody(body)
			? (xhr.setRequestHeader('Content-Type', body.a), body.b)
			: body.a
		);

		return function() { xhr.abort(); };
	});
});

function _Http_configureProgress(xhr, maybeProgress)
{
	if (!$elm$core$Maybe$isJust(maybeProgress))
	{
		return;
	}

	xhr.addEventListener('progress', function(event) {
		if (!event.lengthComputable)
		{
			return;
		}
		_Scheduler_rawSpawn(maybeProgress.a({
			h2: event.loaded,
			h3: event.total
		}));
	});
}

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.f5; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}

	xhr.responseType = request.fU.b;
	xhr.withCredentials = request.kB;

	$elm$core$Maybe$isJust(request.hn) && (xhr.timeout = request.hn.a);
}


// RESPONSES

function _Http_handleResponse(xhr, responseToResult)
{
	var response = _Http_toResponse(xhr);

	if (xhr.status < 200 || 300 <= xhr.status)
	{
		response.body = xhr.responseText;
		return _Scheduler_fail($elm$http$Http$BadStatus(response));
	}

	var result = responseToResult(response);

	if ($elm$core$Result$isOk(result))
	{
		return _Scheduler_succeed(result.a);
	}
	else
	{
		response.body = xhr.responseText;
		return _Scheduler_fail(A2($elm$http$Http$BadPayload, result.a, response));
	}
}

function _Http_toResponse(xhr)
{
	return {
		ff: xhr.responseURL,
		hg: { ib: xhr.status, i2: xhr.statusText },
		f5: _Http_parseHeaders(xhr.getAllResponseHeaders()),
		hY: xhr.response
	};
}

function _Http_parseHeaders(rawHeaders)
{
	var headers = $elm$core$Dict$empty;

	if (!rawHeaders)
	{
		return headers;
	}

	var headerPairs = rawHeaders.split('\u000d\u000a');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf('\u003a\u0020');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}

	return headers;
}


// EXPECTORS

function _Http_expectStringResponse(responseToResult)
{
	return {
		$: 0,
		b: 'text',
		a: responseToResult
	};
}

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		b: expect.b,
		a: function(response) {
			var convertedResponse = expect.a(response);
			return A2($elm$core$Result$map, func, convertedResponse);
		}
	};
});


// BODY

function _Http_multipart(parts)
{


	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}

	return $elm$http$Http$Internal$FormDataBody(formData);
}



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.i5) { flags += 'm'; }
	if (options.h7) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.q) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.s),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.s);
		} else {
			var treeLen = builder.q * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.u) : builder.u;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.q);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.s) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.s);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{u: nodeList, q: (len / $elm$core$Array$branchFactor) | 0, s: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {f0: fragment, f7: host, eA: path, gU: port_, g_: protocol, g$: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$element = _Browser_element;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $author$project$LandingSite$Page$Home = {$: 0};
var $author$project$LandingSite$Main$SetTimeZone = function (a) {
	return {$: 2, a: a};
};
var $author$project$LandingSite$Main$Tick = function (a) {
	return {$: 1, a: a};
};
var $author$project$Eth$Config$BasicConfig = F5(
	function (contracts, cTokensRaw, tokens, interestModels, blocks) {
		return {cy: blocks, fB: cTokensRaw, fJ: contracts, iW: interestModels, hp: tokens};
	});
var $author$project$CompoundComponents$Eth$Ethereum$Contract = $elm$core$Basics$identity;
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $elm$core$String$toLower = _String_toLower;
var $author$project$CompoundComponents$Eth$Decoders$decodeContractAddress = A2(
	$elm$json$Json$Decode$andThen,
	A2($elm$core$Basics$composeL, $elm$json$Json$Decode$succeed, $elm$core$Basics$identity),
	A2($elm$json$Json$Decode$map, $elm$core$String$toLower, $elm$json$Json$Decode$string));
var $author$project$Eth$Config$InterestRateModelConfig = F2(
	function (contract, address) {
		return {hO: address, il: contract};
	});
var $author$project$Eth$Config$decodeInterestRateModel = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Eth$Config$InterestRateModelConfig,
	A2($elm$json$Json$Decode$field, 'contract', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'address', $author$project$CompoundComponents$Eth$Decoders$decodeContractAddress));
var $author$project$Eth$Config$RawCTokenConfig = F5(
	function (name, symbol, decimals, address, underlying) {
		return {hO: address, aA: decimals, i6: name, j0: symbol, a6: underlying};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$json$Json$Decode$map5 = _Json_map5;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $author$project$Eth$Config$decodeRawCToken = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Eth$Config$RawCTokenConfig,
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'symbol', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'decimals', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'address', $author$project$CompoundComponents$Eth$Decoders$decodeContractAddress),
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'underlying', $author$project$CompoundComponents$Eth$Decoders$decodeContractAddress)));
var $author$project$Eth$Config$TokenConfig = F6(
	function (name, symbol, decimals, address, supported, reader) {
		return {hO: address, aA: decimals, i6: name, g2: reader, hk: supported, j0: symbol};
	});
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$map6 = _Json_map6;
var $author$project$Eth$Config$decodeToken = A7(
	$elm$json$Json$Decode$map6,
	$author$project$Eth$Config$TokenConfig,
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'symbol', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'decimals', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'address', $author$project$CompoundComponents$Eth$Decoders$decodeContractAddress),
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'supported', $elm$json$Json$Decode$bool)),
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'reader', $author$project$CompoundComponents$Eth$Decoders$decodeContractAddress)));
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $author$project$Eth$Config$basicConfigDecoder = $elm$json$Json$Decode$dict(
	$elm$json$Json$Decode$maybe(
		A6(
			$elm$json$Json$Decode$map5,
			$author$project$Eth$Config$BasicConfig,
			A2(
				$elm$json$Json$Decode$field,
				'Contracts',
				$elm$json$Json$Decode$dict($author$project$CompoundComponents$Eth$Decoders$decodeContractAddress)),
			A2(
				$elm$json$Json$Decode$field,
				'cTokens',
				$elm$json$Json$Decode$dict($author$project$Eth$Config$decodeRawCToken)),
			A2(
				$elm$json$Json$Decode$field,
				'Tokens',
				$elm$json$Json$Decode$dict($author$project$Eth$Config$decodeToken)),
			A2(
				$elm$json$Json$Decode$field,
				'InterestRateModel',
				$elm$json$Json$Decode$dict($author$project$Eth$Config$decodeInterestRateModel)),
			A2(
				$elm$json$Json$Decode$field,
				'Blocks',
				$elm$json$Json$Decode$dict($elm$json$Json$Decode$int)))));
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$Governance$Home$Loading = {$: 2};
var $author$project$Governance$Home$emptyState = {cM: _List_Nil, aW: $author$project$Governance$Home$Loading, b_: $author$project$Governance$Home$Loading, cp: $author$project$Governance$Home$Loading};
var $author$project$Governance$ProposalDetail$Loading = {$: 2};
var $author$project$Governance$ProposalDetail$emptyState = {ba: $author$project$Governance$ProposalDetail$Loading, cM: _List_Nil, bi: $author$project$Governance$ProposalDetail$Loading, ab: $author$project$Governance$ProposalDetail$Loading, c2: $author$project$Governance$ProposalDetail$Loading, ch: $elm$core$Maybe$Nothing};
var $author$project$Governance$ProposalOverview$Loading = {$: 2};
var $author$project$Governance$ProposalOverview$emptyState = {bd: 1, cM: _List_Nil, by: $elm$core$Dict$empty, bI: $author$project$Governance$ProposalOverview$Loading};
var $author$project$LandingSite$CommonViews$emptyState = {cY: false};
var $author$project$Strings$Translations$En = 0;
var $author$project$CompoundComponents$Utils$CompoundHtmlAttributes$External = 2;
var $elm$html$Html$a = _VirtualDom_node('a');
var $author$project$Strings$Translations$comp_faq_1 = function (lang) {
	switch (lang) {
		case 0:
			return 'What is COMP, and why is it being distributed?';
		case 1:
			return '¿Qué es COMP y por qué se distribuye?';
		case 2:
			return 'COMP是什么，为什么要分发释放这个通证？';
		case 3:
			return 'Qu\'est-ce que COMP et pourquoi est-il distribué?';
		default:
			return 'COMP란 무엇이며 왜 분배되고 있나요?';
	}
};
var $author$project$Strings$Translations$comp_faq_1_description_link_1 = function (lang) {
	switch (lang) {
		case 0:
			return 'delegates';
		case 1:
			return 'delegados';
		case 2:
			return '代表';
		case 3:
			return 'délégués';
		default:
			return '투표 대리인';
	}
};
var $author$project$Strings$Translations$comp_faq_1_description_link_2 = function (lang) {
	switch (lang) {
		case 0:
			return 'propose';
		case 1:
			return 'proponer';
		case 2:
			return '提出';
		case 3:
			return 'proposer';
		default:
			return '제안';
	}
};
var $author$project$Strings$Translations$comp_faq_1_description_link_3 = function (lang) {
	switch (lang) {
		case 0:
			return 'vote';
		case 1:
			return 'votar';
		case 2:
			return '投票';
		case 3:
			return 'voter';
		default:
			return '투표';
	}
};
var $author$project$Strings$Translations$comp_faq_1_description_part_1 = function (lang) {
	switch (lang) {
		case 0:
			return 'Compound (COMP) is an ERC-20 asset that empowers community governance of the Compound protocol; COMP token-holders and their ';
		case 1:
			return 'Compuesto (COMP) es un activo ERC-20 que potencia la gobernanza comunitaria del protocolo Compuesto; Titulares de tokens COMP y sus';
		case 2:
			return '复合（COMP）是ERC-20资产，可增强对复合协议的社区治理； COMP代币持有者及其';
		case 3:
			return 'Compound (COMP) est un atout ERC-20 qui renforce la gouvernance communautaire du protocole Compound; Les détenteurs de jetons COMP et leurs';
		default:
			return 'Compound (COMP)는 ERC-20 자산이며 Compound 프로토콜에 대한 커뮤니티 거버넌스 도구입니다. COMP 토큰 보유자와 ';
	}
};
var $author$project$Strings$Translations$comp_faq_1_description_part_2 = function (lang) {
	switch (lang) {
		case 0:
			return ' debate, ';
		case 1:
			return 'debate,';
		case 2:
			return '辩论，';
		case 3:
			return 'débat,';
		default:
			return ' 토론,';
	}
};
var $author$project$Strings$Translations$comp_faq_1_description_part_3 = function (lang) {
	switch (lang) {
		case 0:
			return ', and ';
		case 1:
			return 'y';
		case 2:
			return '和';
		case 3:
			return ', et';
		default:
			return ',';
	}
};
var $author$project$Strings$Translations$comp_faq_1_description_part_4 = function (lang) {
	switch (lang) {
		case 0:
			return ' on all changes to the protocol.';
		case 1:
			return 'en todos los cambios al protocolo.';
		case 2:
			return '对协议的所有更改。';
		case 3:
			return 'sur toutes les modifications apportées au protocole.';
		default:
			return ' 등 프로토콜 변경과 관련된 모든 곳에서 사용됩니다.';
	}
};
var $author$project$Strings$Translations$comp_faq_1_description_part_5 = function (lang) {
	switch (lang) {
		case 0:
			return 'By placing COMP directly into the hands of users and applications, an increasingly large ecosystem will be able to upgrade the protocol, and will be incentivized to collectively steward the protocol into the future with good governance.';
		case 1:
			return 'Al colocar COMP directamente en manos de los usuarios y las aplicaciones, un ecosistema cada vez más grande podrá actualizar el protocolo y se le incentivará para administrar colectivamente el protocolo hacia el futuro con un buen gobierno.';
		case 2:
			return '通过直接将COMP交到用户和应用程序手中，一个越来越大的生态系统将能够升级该协议，并且将受到激励，通过良好的管理集体将协议推向未来。';
		case 3:
			return 'En plaçant COMP directement entre les mains des utilisateurs et des applications, un écosystème de plus en plus vaste sera en mesure de mettre à niveau le protocole et sera incité à gérer collectivement le protocole à l\'avenir avec une bonne gouvernance.';
		default:
			return 'COMP를 Compound 이용자와 애플리케이션에게 직접 제공하면, 프로토콜 업그레이드에 참여하는 생태계 참가자는 더욱 많아질 것이며, 좋은 거버넌스를 가진 프로토콜로 발전시키는데 동기 부여가 될 것으로 기대하고 있습니다.';
	}
};
var $author$project$Strings$Translations$comp_faq_2 = function (lang) {
	switch (lang) {
		case 0:
			return 'How does the distribution work?';
		case 1:
			return '¿Cómo funciona la distribución?';
		case 2:
			return 'COMP通证是如何释放给用户的？';
		case 3:
			return 'Comment fonctionne la distribution?';
		default:
			return '분배는 어떤 방식으로 진행되나요?';
	}
};
var $author$project$Strings$Translations$comp_faq_2_description_part_1 = F2(
	function (lang, compAmount) {
		switch (lang) {
			case 0:
				return 'Each day, approximately ' + (compAmount + ' COMP will be distributed to users of the protocol; the distribution is allocated to each market (ETH, USDC, DAI…), and is set through the governance process by COMP token-holders.');
			case 1:
				return 'Cada día, aproximadamente ' + (compAmount + ' COMP se distribuirá a los usuarios del protocolo; la distribución se asigna a cada mercado (ETH, USDC, DAI…) y se establece a través del proceso de gobernanza por los titulares de tokens COMP.');
			case 2:
				return '每天，大约' + (compAmount + ' COMP将分发给该协议的用户；分配分配给每个市场（ETH，USDC，DAI…），并由COMP代币持有者通过治理过程进行设置。');
			case 3:
				return 'Chaque jour, environ ' + (compAmount + ' COMP sera distribué aux utilisateurs du protocole; la distribution est allouée à chaque marché (ETH, USDC, DAI…), et est définie par le processus de gouvernance par les détenteurs de jetons COMP.');
			default:
				return '매일 약 ' + (compAmount + ' COMP가 프로토콜 사용자에게 배포됩니다. 분배는 각 시장 (ETH, USDC, DAI…)에 할당되고 COMP 토큰 보유자의 거버넌스 프로세스를 통해 설정됩니다.');
		}
	});
var $author$project$Strings$Translations$comp_faq_2_description_part_2 = function (lang) {
	switch (lang) {
		case 0:
			return 'The table above describes how each deployment allocates the COMP Distribution to users.';
		case 1:
			return 'Dentro de cada mercado, la mitad de la distribución la obtienen los proveedores y la otra mitad los prestatarios.';
		case 2:
			return '在每个市场中，一半的分配是由供应商赚取的，另一半是由借款人赚取的。';
		case 3:
			return 'Au sein de chaque marché, la moitié de la distribution est réalisée par les fournisseurs et l\'autre moitié par les emprunteurs.';
		default:
			return '각 시장 내에서 분배의 절반은 공급 업체가, 나머지 절반은 차용자가 얻습니다.';
	}
};
var $author$project$Strings$Translations$comp_faq_3 = function (lang) {
	switch (lang) {
		case 0:
			return 'As a user, what do I need to do to receive COMP?';
		case 1:
			return 'Como usuario, ¿qué debo hacer para recibir COMP?';
		case 2:
			return '作为用户，我如何得到COMP?';
		case 3:
			return 'En tant qu\'utilisateur, que dois-je faire pour recevoir COMP?';
		default:
			return '이용자가 COMP를 받으려면 어떻게 해야하나요?';
	}
};
var $author$project$Strings$Translations$comp_faq_3_description_part_1 = function (lang) {
	switch (lang) {
		case 0:
			return 'When you use Compound to supply or borrow assets, you\'ll automatically begin accruing COMP; in the Market Distribution table above, you can see how much COMP is distributed by market and activity.';
		case 1:
			return 'Cuando usa Compuesto para suministrar o tomar prestados activos, automáticamente comenzará a acumular COMP; En la tabla de Distribución de mercado anterior, puede ver cuánto COMP se distribuye por mercado y actividad.';
		case 2:
			return '当您使用化合物来提供或借入资产时，您将自动开始累积COMP。在上方的市场分布表中，您可以查看按市场和活动分配的COMP数量。';
		case 3:
			return 'Lorsque vous utilisez Compound pour fournir ou emprunter des actifs, vous commencez automatiquement à accumuler des COMP; dans le tableau de répartition du marché ci-dessus, vous pouvez voir combien de COMP est distribué par marché et par activité.';
		default:
			return 'Compound를 사용하여 자산을 공급하거나 차용하면 자동으로 COMP가 발생하기 시작합니다. 위의 Market Distribution 표에서 시장 및 활동별로 COMP가 얼마나 분배되는지 확인할 수 있습니다.';
	}
};
var $author$project$Strings$Translations$comp_faq_3_description_part_2 = function (lang) {
	switch (lang) {
		case 0:
			return 'You can track and claim your COMP Distribution in the ';
		case 1:
			return 'todos los COMP no distribuidos';
		case 2:
			return '所有未分配的COMP。';
		case 3:
			return 'tous non distribués COMP.';
		default:
			return '을 할 수 있습니다.';
	}
};
var $author$project$Strings$Translations$comp_faq_3_description_part_2_link = function (lang) {
	switch (lang) {
		case 0:
			return 'Dashboard';
		case 1:
			return 'Tablero';
		case 2:
			return '仪表板';
		case 3:
			return 'Tableau de bord';
		default:
			return '계기반';
	}
};
var $author$project$Strings$Translations$comp_faq_3_description_part_3 = function (lang) {
	switch (lang) {
		case 0:
			return '.';
		case 1:
			return '.';
		case 2:
			return '。';
		case 3:
			return '.';
		default:
			return '.';
	}
};
var $author$project$Strings$Translations$comp_faq_4 = function (lang) {
	switch (lang) {
		case 0:
			return 'I\'m building an application; how should I design for the distribution?';
		case 1:
			return 'Estoy construyendo una aplicación; ¿Cómo debo diseñar para la distribución?';
		case 2:
			return '我在开发一个Application，该如何实现给我的用户分配COMP token?';
		case 3:
			return 'Je crée une application; comment dois-je concevoir pour la distribution?';
		default:
			return '애플리케이션을 작성 중입니다. COMP 분배를 받으려면 어떻게 설계해야 하나요?';
	}
};
var $author$project$Strings$Translations$comp_faq_4_description = function (lang) {
	switch (lang) {
		case 0:
			return 'COMP is only claimable by addresses that directly interact with the protocol (msg.sender), and that are capable of calling the claim function.';
		case 1:
			return 'COMP está diseñado para distribuirse solo a la dirección que ha acumulado el COMP. Las billeteras de sus usuarios deben poder enviar COMP desde la misma dirección que está interactuando con Compound. Esto se puede hacer de muchas maneras, pero es importante resolverlo; de lo contrario, COMP no se reclamará, será inaccesible y se perderá para siempre. Si tiene preguntas, únase a nuestro Discord y pregúntenos.';
		case 2:
			return '系统设计是这样，COMP只能分配给计息钱包地址。要获得计息的Comp通证，用户必须能够控制那个特定的与Compound协议对接的钱包。虽然实现方法很多，但这很重要请务必重视，否则COMP通证将永远丢失，无法领取，无法使用。如有问题，请在Discord上和我们联系。';
		case 3:
			return 'COMP est conçu pour être distribué uniquement à l\'adresse qui a accumulé le COMP. Les portefeuilles de vos utilisateurs doivent pouvoir envoyer COMP à partir de la même adresse qui interagit avec Compound. Cela peut être fait d\'une multitude de façons, mais il est important de le résoudre, sinon le COMP sera non réclamé, inaccessible et définitivement perdu. Si vous avez des questions, rejoignez notre Discord et demandez-nous.';
		default:
			return 'COMP는 COMP가 누적되는 주소에만 COMP가 분배되는 구조로 설계되었습니다. 사용자의 지갑은 COMP를 전송할 수 있어야 하며, Compound와 상호 작용하는 지갑 주소와 사용자의 지갑 주소는 같아야 합니다. 이 작업은 다양한 방법으로 목표를 달성할 수 있습니다. 위의 조건에 부합하지 않으면, COMP에 대한 클레임 요청을 할 수 없으며, 접근할 수도 없고, 영원히 잃게 됩니다. 이와 관련해 궁금한 점이 있다면 Compound Discord에 문의해주세요.';
	}
};
var $author$project$Strings$Translations$comp_faq_4_description_part_2 = function (lang) {
	switch (lang) {
		case 0:
			return 'Developers building smart contract wrappers should design for this interaction, otherwise COMP will be unclaimed & inaccessible. If you have questions, join the Compound ';
		case 1:
			return 'Los desarrolladores que creen envoltorios de contratos inteligentes deben diseñar para esta interacción, de lo contrario, COMP no será reclamado e inaccesible. Si tiene preguntas, únase al Compuesto';
		case 2:
			return '开发智能合约包装程序的开发人员应为此交互设计，否则COMP将无人认领且无法访问。如有疑问，请加入大院';
		case 3:
			return 'Les développeurs qui créent des enveloppes de contrat intelligentes doivent concevoir cette interaction, sinon COMP sera non réclamé et inaccessible. Si vous avez des questions, rejoignez le Compound';
		default:
			return '스마트 계약 래퍼를 구축하는 개발자는 이러한 상호 작용을 위해 설계해야합니다. 그렇지 않으면 COMP에 대한 소유권이 주장되지 않고 액세스 할 수 없게됩니다. 질문이 있으시면 컴파운드에 가입하십시오';
	}
};
var $author$project$Strings$Translations$comp_faq_4_description_part_3 = function (lang) {
	switch (lang) {
		case 0:
			return ' and ask the community.';
		case 1:
			return 'y pregunte a la comunidad.';
		case 2:
			return '询问社区。';
		case 3:
			return 'et demandez à la communauté.';
		default:
			return '커뮤니티에 물어보세요.';
	}
};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href = F2(
	function (linkType, linkString) {
		var relAttributes = function () {
			switch (linkType) {
				case 1:
					return _List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'rel', 'internal')
						]);
				case 2:
					return _List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'rel', 'external')
						]);
				default:
					return _List_Nil;
			}
		}();
		return _Utils_ap(
			relAttributes,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$attribute, 'href', linkString)
				]));
	});
var $elm$html$Html$p = _VirtualDom_node('p');
var $author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target = function (targetName) {
	return A2($elm$html$Html$Attributes$attribute, 'target', targetName);
};
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$LandingSite$Comp$accordianData = F2(
	function (userLanguage, dailyDistribution) {
		return _List_fromArray(
			[
				_Utils_Tuple2(
				$author$project$Strings$Translations$comp_faq_1(userLanguage),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Strings$Translations$comp_faq_1_description_part_1(userLanguage)),
								A2(
								$elm$html$Html$a,
								A2(
									$elm$core$List$cons,
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank'),
									A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://www.comp.xyz/t/governance-guide-how-to-delegate/365')),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Strings$Translations$comp_faq_1_description_link_1(userLanguage))
									])),
								$elm$html$Html$text(
								$author$project$Strings$Translations$comp_faq_1_description_part_2(userLanguage)),
								A2(
								$elm$html$Html$a,
								A2(
									$elm$core$List$cons,
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank'),
									A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://www.comp.xyz/t/governance-guide-how-to-propose/367')),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Strings$Translations$comp_faq_1_description_link_2(userLanguage))
									])),
								$elm$html$Html$text(
								$author$project$Strings$Translations$comp_faq_1_description_part_3(userLanguage)),
								A2(
								$elm$html$Html$a,
								A2(
									$elm$core$List$cons,
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank'),
									A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://www.comp.xyz/t/governance-guide-how-to-vote/366')),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Strings$Translations$comp_faq_1_description_link_3(userLanguage))
									])),
								$elm$html$Html$text(
								$author$project$Strings$Translations$comp_faq_1_description_part_4(userLanguage))
							])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Strings$Translations$comp_faq_1_description_part_5(userLanguage))
							]))
					])),
				_Utils_Tuple2(
				$author$project$Strings$Translations$comp_faq_2(userLanguage),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								A2($author$project$Strings$Translations$comp_faq_2_description_part_1, userLanguage, dailyDistribution))
							])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Strings$Translations$comp_faq_2_description_part_2(userLanguage))
							]))
					])),
				_Utils_Tuple2(
				$author$project$Strings$Translations$comp_faq_3(userLanguage),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Strings$Translations$comp_faq_3_description_part_1(userLanguage))
							])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Strings$Translations$comp_faq_3_description_part_2(userLanguage)),
								A2(
								$elm$html$Html$a,
								A2(
									$elm$core$List$cons,
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank'),
									A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://app.compound.finance/')),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Strings$Translations$comp_faq_3_description_part_2_link(userLanguage))
									])),
								$elm$html$Html$text(
								$author$project$Strings$Translations$comp_faq_3_description_part_3(userLanguage))
							]))
					])),
				_Utils_Tuple2(
				$author$project$Strings$Translations$comp_faq_4(userLanguage),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Strings$Translations$comp_faq_4_description(userLanguage))
							])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Strings$Translations$comp_faq_4_description_part_2(userLanguage)),
								A2(
								$elm$html$Html$a,
								A2(
									$elm$core$List$cons,
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank'),
									A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://compound.finance/discord')),
								_List_fromArray(
									[
										$elm$html$Html$text('Discord')
									])),
								$elm$html$Html$text(
								$author$project$Strings$Translations$comp_faq_4_description_part_3(userLanguage))
							]))
					]))
			]);
	});
var $elm$core$Array$repeat = F2(
	function (n, e) {
		return A2(
			$elm$core$Array$initialize,
			n,
			function (_v0) {
				return e;
			});
	});
var $author$project$LandingSite$Comp$emptyState = {
	cM: _List_Nil,
	aU: A2(
		$elm$core$Array$repeat,
		$elm$core$List$length(
			A2($author$project$LandingSite$Comp$accordianData, 0, '—')),
		false),
	b0: $elm$core$Dict$empty,
	b6: $elm$core$Maybe$Nothing,
	cV: $elm$core$Maybe$Nothing
};
var $author$project$LandingSite$Home$Institutions = 3;
var $author$project$Bootstrap$Carousel$Paused = 0;
var $author$project$Bootstrap$Carousel$Active = 1;
var $author$project$Bootstrap$Carousel$defaultStateOptions = {
	ag: 1,
	bn: $elm$core$Maybe$Just(5000),
	bZ: true,
	eB: true,
	eT: 0,
	a9: true
};
var $author$project$Bootstrap$Carousel$IgnoreHover = 2;
var $author$project$Bootstrap$Carousel$NotAnimating = {$: 2};
var $author$project$Bootstrap$Carousel$NotHovered = 1;
var $author$project$Bootstrap$Carousel$State = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Bootstrap$Carousel$initialStateWithOptions = function (options) {
	return A2(
		$author$project$Bootstrap$Carousel$State,
		$author$project$Bootstrap$Carousel$NotAnimating,
		{
			aS: options.eT,
			ag: options.ag,
			bY: options.eB ? 1 : 2,
			bn: A2($elm$core$Maybe$withDefault, 0, options.bn),
			bZ: options.bZ,
			a3: 2,
			a9: options.a9
		});
};
var $author$project$LandingSite$Home$emptyState = {
	cM: _List_Nil,
	aI: $author$project$Bootstrap$Carousel$initialStateWithOptions(
		_Utils_update(
			$author$project$Bootstrap$Carousel$defaultStateOptions,
			{
				ag: 0,
				bn: $elm$core$Maybe$Just(0)
			})),
	c6: 3
};
var $author$project$Strings$Translations$Es = 1;
var $author$project$Strings$Translations$Fr = 3;
var $author$project$Strings$Translations$Ko = 4;
var $author$project$Strings$Translations$Zh = 2;
var $author$project$Strings$Translations$getLnFromCode = function (code) {
	switch (code) {
		case 'en':
			return 0;
		case 'es':
			return 1;
		case 'zh':
			return 2;
		case 'fr':
			return 3;
		case 'ko':
			return 4;
		default:
			return 0;
	}
};
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$here = _Time_here(0);
var $author$project$CompoundComponents$Eth$Network$Development = 10;
var $author$project$CompoundComponents$Eth$Network$Goerli = 5;
var $author$project$CompoundComponents$Eth$Network$Kovan = 7;
var $author$project$CompoundComponents$Eth$Network$MainNet = 1;
var $author$project$CompoundComponents$Eth$Network$Rinkeby = 4;
var $author$project$CompoundComponents$Eth$Network$Ropsten = 3;
var $author$project$CompoundComponents$Eth$Network$networkFromName = function (name) {
	var _v0 = $elm$core$String$toLower(name);
	switch (_v0) {
		case 'mainnet':
			return 1;
		case 'ropsten':
			return 3;
		case 'goerli':
			return 5;
		case 'kovan':
			return 7;
		case 'rinkeby':
			return 4;
		case 'development':
			return 10;
		default:
			return 1;
	}
};
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $author$project$CompoundComponents$DisplayCurrency$USD = 1;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $author$project$Preferences$askStoredPreferencesPort = _Platform_outgoingPort(
	'askStoredPreferencesPort',
	function ($) {
		return $elm$json$Json$Encode$object(_List_Nil);
	});
var $author$project$Preferences$askStoredPreferences = $author$project$Preferences$askStoredPreferencesPort(
	{});
var $author$project$Preferences$preferencesInit = function (initialLanguage) {
	return _Utils_Tuple2(
		{bc: true, bf: 1, bE: true, as: initialLanguage},
		$author$project$Preferences$askStoredPreferences);
};
var $author$project$LandingSite$Main$DelayScroll = function (a) {
	return {$: 13, a: a};
};
var $author$project$LandingSite$Page$GovernanceHome = {$: 2};
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $author$project$LandingSite$Page$getHrefUrl = function (page) {
	switch (page.$) {
		case 0:
			return '/';
		case 1:
			return '/governance/comp';
		case 2:
			return '/governance';
		case 3:
			var proposalId = page.a;
			return '/governance/proposals/' + $elm$core$String$fromInt(proposalId);
		default:
			return '/governance/proposals';
	}
};
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {aV: frag, a_: params, aN: unvisited, bK: value, a7: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.aN;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.bK);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.bK);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.eA),
					$elm$url$Url$Parser$prepareQuery(url.g$),
					url.f0,
					$elm$core$Basics$identity)));
	});
var $author$project$LandingSite$Page$Comp = {$: 1};
var $author$project$LandingSite$Page$GovernanceProposalDetail = function (a) {
	return {$: 3, a: a};
};
var $author$project$LandingSite$Page$GovernanceProposalOverview = {$: 4};
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.a7;
			var unvisited = _v0.aN;
			var params = _v0.a_;
			var frag = _v0.aV;
			var value = _v0.bK;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$int = A2($elm$url$Url$Parser$custom, 'NUMBER', $elm$core$String$toInt);
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.a7;
		var unvisited = _v0.aN;
		var params = _v0.a_;
		var frag = _v0.aV;
		var value = _v0.bK;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.a7;
			var unvisited = _v1.aN;
			var params = _v1.a_;
			var frag = _v1.aV;
			var value = _v1.bK;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.a7;
		var unvisited = _v0.aN;
		var params = _v0.a_;
		var frag = _v0.aV;
		var value = _v0.bK;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$LandingSite$Page$parser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, $author$project$LandingSite$Page$Home, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$LandingSite$Page$Comp,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('governance'),
				$elm$url$Url$Parser$s('comp'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$LandingSite$Page$GovernanceHome,
			$elm$url$Url$Parser$s('governance')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$LandingSite$Page$GovernanceProposalDetail,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('governance'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('proposals'),
					$elm$url$Url$Parser$int))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$LandingSite$Page$GovernanceProposalOverview,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('governance'),
				$elm$url$Url$Parser$s('proposals')))
		]));
var $author$project$LandingSite$Page$getLandingPage = function (location) {
	var maybeDesiredPage = A2($elm$url$Url$Parser$parse, $author$project$LandingSite$Page$parser, location);
	return A2($elm$core$Maybe$withDefault, $author$project$LandingSite$Page$Home, maybeDesiredPage);
};
var $author$project$LandingSite$Main$GovernanceHomeMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$LandingSite$Main$GovernanceProposalDetailMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$LandingSite$Main$GovernanceProposalOverviewMsg = function (a) {
	return {$: 12, a: a};
};
var $author$project$LandingSite$Main$LandingCompMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Governance$ProposalDetail$AgainstVoteReceiptsResponse = function (a) {
	return {$: 3, a: a};
};
var $author$project$Governance$ProposalDetail$ForVoteReceiptsResponse = function (a) {
	return {$: 2, a: a};
};
var $author$project$Governance$ProposalDetail$ProposalsResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Governance$ProposalDetail$desiredVoteReceiptsSize = 100;
var $elm$http$Http$Internal$EmptyBody = {$: 0};
var $elm$http$Http$emptyBody = $elm$http$Http$Internal$EmptyBody;
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$BadPayload = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Internal$FormDataBody = function (a) {
	return {$: 2, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$http$Http$Internal$isStringBody = function (body) {
	if (body.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$http$Http$expectStringResponse = _Http_expectStringResponse;
var $elm$http$Http$expectJson = function (decoder) {
	return $elm$http$Http$expectStringResponse(
		function (response) {
			var _v0 = A2($elm$json$Json$Decode$decodeString, decoder, response.hY);
			if (_v0.$ === 1) {
				var decodeError = _v0.a;
				return $elm$core$Result$Err(
					$elm$json$Json$Decode$errorToString(decodeError));
			} else {
				var value = _v0.a;
				return $elm$core$Result$Ok(value);
			}
		});
};
var $elm$http$Http$Internal$Request = $elm$core$Basics$identity;
var $elm$http$Http$request = $elm$core$Basics$identity;
var $elm$http$Http$get = F2(
	function (url, decoder) {
		return $elm$http$Http$request(
			{
				hY: $elm$http$Http$emptyBody,
				fU: $elm$http$Http$expectJson(decoder),
				f5: _List_Nil,
				i3: 'GET',
				hn: $elm$core$Maybe$Nothing,
				ff: url,
				kB: false
			});
	});
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$CompoundApi$Governance$ProposalVoteReceiptService$Models$ProposalVoteReceiptResponse = F2(
	function (pagination_summary, proposal_vote_receipts) {
		return {gP: pagination_summary, jr: proposal_vote_receipts};
	});
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$CompoundApi$Common$Models$PaginationSummary = F4(
	function (page_number, page_size, total_entries, total_pages) {
		return {jj: page_number, jk: page_size, kf: total_entries, kg: total_pages};
	});
var $elm$json$Json$Decode$map4 = _Json_map4;
var $author$project$CompoundApi$Common$Decoders$paginationSummaryDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$CompoundApi$Common$Models$PaginationSummary,
	A2($elm$json$Json$Decode$field, 'page_number', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'page_size', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'total_entries', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'total_pages', $elm$json$Json$Decode$int));
var $author$project$CompoundApi$Governance$ProposalVoteReceiptService$Models$createProposalVoteReceipt = F5(
	function (id, displayAccount, support, votes, proposal) {
		return {gY: proposal, eD: id, eX: support, hz: displayAccount, kx: votes};
	});
var $author$project$CompoundApi$Governance$Common$Models$createDisplayAccount = F3(
	function (address, display_name, image_url) {
		return {hO: address, iv: display_name, iP: image_url};
	});
var $elm$json$Json$Decode$map3 = _Json_map3;
var $author$project$CompoundApi$Governance$Common$Decoders$displayAccountDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$CompoundApi$Governance$Common$Models$createDisplayAccount,
	A2($elm$json$Json$Decode$field, 'address', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'display_name',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
	A2(
		$elm$json$Json$Decode$field,
		'image_url',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)));
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $author$project$CompoundApi$Governance$ProposalService$Models$createProposal = F7(
	function (id, title, description, states, for_votes, against_votes, abstain_votes) {
		return {fj: abstain_votes, fp: against_votes, iu: description, f_: for_votes, gb: id, hf: states, j5: title};
	});
var $author$project$CompoundApi$Governance$ProposalService$Models$ProposalState = F5(
	function (state, start_time, end_time, transaction_hash, cross_chain_network) {
		return {$9: cross_chain_network, iB: end_time, jP: start_time, jQ: state, kk: transaction_hash};
	});
var $author$project$CompoundComponents$Eth$Network$Arbitrum = 14;
var $author$project$CompoundComponents$Eth$Network$ArbitrumGoerli = 15;
var $author$project$CompoundComponents$Eth$Network$Base = 18;
var $author$project$CompoundComponents$Eth$Network$BaseGoerli = 19;
var $author$project$CompoundComponents$Eth$Network$Mumbai = 13;
var $author$project$CompoundComponents$Eth$Network$Optimism = 16;
var $author$project$CompoundComponents$Eth$Network$OptimismGoerli = 17;
var $author$project$CompoundComponents$Eth$Network$Polygon = 12;
var $author$project$CompoundApi$Governance$ProposalService$Decoders$crossChainNetworkEnumDecoder = function () {
	var crossChainNetworkStringDecoder = function (networkString) {
		switch (networkString) {
			case 'polygon-mainnet':
				return $elm$json$Json$Decode$succeed(12);
			case 'polygon-mumbai':
				return $elm$json$Json$Decode$succeed(13);
			case 'arbitrum-mainnet':
				return $elm$json$Json$Decode$succeed(14);
			case 'arbitrum-goerli':
				return $elm$json$Json$Decode$succeed(15);
			case 'optimism-mainnet':
				return $elm$json$Json$Decode$succeed(16);
			case 'optimism-goerli':
				return $elm$json$Json$Decode$succeed(17);
			case 'base-mainnet':
				return $elm$json$Json$Decode$succeed(18);
			case 'base-goerli':
				return $elm$json$Json$Decode$succeed(19);
			default:
				return $elm$json$Json$Decode$fail('Unrecognized cross chain network: ' + networkString);
		}
	};
	return A2($elm$json$Json$Decode$andThen, crossChainNetworkStringDecoder, $elm$json$Json$Decode$string);
}();
var $author$project$CompoundApi$Governance$ProposalService$Models$Active = 1;
var $author$project$CompoundApi$Governance$ProposalService$Models$Canceled = 6;
var $author$project$CompoundApi$Governance$ProposalService$Models$Executed = 5;
var $author$project$CompoundApi$Governance$ProposalService$Models$Expired = 7;
var $author$project$CompoundApi$Governance$ProposalService$Models$Failed = 3;
var $author$project$CompoundApi$Governance$ProposalService$Models$Pending = 0;
var $author$project$CompoundApi$Governance$ProposalService$Models$Queued = 4;
var $author$project$CompoundApi$Governance$ProposalService$Models$Succeeded = 2;
var $author$project$CompoundApi$Governance$ProposalService$Decoders$proposalStateEnumDecoder = function () {
	var proposalStateStringDecoder = function (stateString) {
		switch (stateString) {
			case 'pending':
				return $elm$json$Json$Decode$succeed(0);
			case 'active':
				return $elm$json$Json$Decode$succeed(1);
			case 'succeeded':
				return $elm$json$Json$Decode$succeed(2);
			case 'defeated':
				return $elm$json$Json$Decode$succeed(3);
			case 'failed':
				return $elm$json$Json$Decode$succeed(3);
			case 'queued':
				return $elm$json$Json$Decode$succeed(4);
			case 'executed':
				return $elm$json$Json$Decode$succeed(5);
			case 'canceled':
				return $elm$json$Json$Decode$succeed(6);
			case 'expired':
				return $elm$json$Json$Decode$succeed(7);
			default:
				return $elm$json$Json$Decode$fail('Unrecognized proposal state: ' + stateString);
		}
	};
	return A2($elm$json$Json$Decode$andThen, proposalStateStringDecoder, $elm$json$Json$Decode$string);
}();
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$CompoundApi$Governance$ProposalService$Decoders$proposalStateDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'cross_chain_network',
	$elm$json$Json$Decode$nullable($author$project$CompoundApi$Governance$ProposalService$Decoders$crossChainNetworkEnumDecoder),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'transaction_hash',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'end_time',
			$elm$json$Json$Decode$nullable(
				A2(
					$elm$json$Json$Decode$map,
					$elm$time$Time$millisToPosix,
					A2(
						$elm$json$Json$Decode$map,
						$elm$core$Basics$mul(1000),
						$elm$json$Json$Decode$int))),
			$elm$core$Maybe$Nothing,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'start_time',
				A2(
					$elm$json$Json$Decode$map,
					$elm$time$Time$millisToPosix,
					A2(
						$elm$json$Json$Decode$map,
						$elm$core$Basics$mul(1000),
						$elm$json$Json$Decode$int)),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'state',
					$author$project$CompoundApi$Governance$ProposalService$Decoders$proposalStateEnumDecoder,
					$elm$json$Json$Decode$succeed($author$project$CompoundApi$Governance$ProposalService$Models$ProposalState))))));
var $author$project$CompoundComponents$Eth$Decoders$forceMaybe = F2(
	function (err, maybe) {
		if (!maybe.$) {
			var val = maybe.a;
			return $elm$json$Json$Decode$succeed(val);
		} else {
			return $elm$json$Json$Decode$fail(err);
		}
	});
var $torreyatcitty$the_best_decimal$Decimal$Decimal = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $cmditch$elm_bigint$BigInt$Negative = 1;
var $cmditch$elm_bigint$BigInt$Positive = 0;
var $cmditch$elm_bigint$BigInt$Magnitude = $elm$core$Basics$identity;
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$combine = A2(
	$elm$core$List$foldr,
	$elm$core$Maybe$map2($elm$core$List$cons),
	$elm$core$Maybe$Just(_List_Nil));
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm_community$list_extra$List$Extra$dropWhile = F2(
	function (predicate, list) {
		dropWhile:
		while (true) {
			if (!list.b) {
				return _List_Nil;
			} else {
				var x = list.a;
				var xs = list.b;
				if (predicate(x)) {
					var $temp$predicate = predicate,
						$temp$list = xs;
					predicate = $temp$predicate;
					list = $temp$list;
					continue dropWhile;
				} else {
					return list;
				}
			}
		}
	});
var $cmditch$elm_bigint$BigInt$emptyZero = function (_v0) {
	var xs = _v0;
	var _v1 = A2(
		$elm_community$list_extra$List$Extra$dropWhile,
		$elm$core$Basics$eq(0),
		xs);
	if (!_v1.b) {
		return _List_Nil;
	} else {
		return xs;
	}
};
var $elm$core$String$fromList = _String_fromList;
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm_community$list_extra$List$Extra$greedyGroupsOfWithStep = F3(
	function (size, step, xs) {
		var xs_ = A2($elm$core$List$drop, step, xs);
		var okayXs = $elm$core$List$length(xs) > 0;
		var okayArgs = (size > 0) && (step > 0);
		return (okayArgs && okayXs) ? A2(
			$elm$core$List$cons,
			A2($elm$core$List$take, size, xs),
			A3($elm_community$list_extra$List$Extra$greedyGroupsOfWithStep, size, step, xs_)) : _List_Nil;
	});
var $elm_community$list_extra$List$Extra$greedyGroupsOf = F2(
	function (size, xs) {
		return A3($elm_community$list_extra$List$Extra$greedyGroupsOfWithStep, size, size, xs);
	});
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $cmditch$elm_bigint$Constants$maxDigitMagnitude = 7;
var $cmditch$elm_bigint$BigInt$fromString_ = function (x) {
	return A2(
		$elm$core$Maybe$map,
		A2($elm$core$Basics$composeL, $cmditch$elm_bigint$BigInt$emptyZero, $elm$core$Basics$identity),
		$elm_community$maybe_extra$Maybe$Extra$combine(
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$reverse,
					A2($elm$core$Basics$composeR, $elm$core$String$fromList, $elm$core$String$toInt)),
				A2(
					$elm_community$list_extra$List$Extra$greedyGroupsOf,
					$cmditch$elm_bigint$Constants$maxDigitMagnitude,
					$elm$core$List$reverse(x)))));
};
var $cmditch$elm_bigint$BigInt$Neg = function (a) {
	return {$: 1, a: a};
};
var $cmditch$elm_bigint$BigInt$Pos = function (a) {
	return {$: 0, a: a};
};
var $cmditch$elm_bigint$BigInt$Zer = {$: 2};
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $cmditch$elm_bigint$BigInt$mkBigInt = F2(
	function (s, mag) {
		var digits = mag;
		if ($elm$core$List$isEmpty(digits)) {
			return $cmditch$elm_bigint$BigInt$Zer;
		} else {
			switch (s) {
				case 2:
					return $cmditch$elm_bigint$BigInt$Zer;
				case 0:
					return $cmditch$elm_bigint$BigInt$Pos(mag);
				default:
					return $cmditch$elm_bigint$BigInt$Neg(mag);
			}
		}
	});
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $cmditch$elm_bigint$BigInt$fromString = function (x) {
	var _v0 = $elm$core$String$toList(
		$elm$core$String$toLower(x));
	if (!_v0.b) {
		return $elm$core$Maybe$Nothing;
	} else {
		switch (_v0.a) {
			case '-':
				if (!_v0.b.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					var xs = _v0.b;
					return A2(
						$elm$core$Maybe$map,
						$cmditch$elm_bigint$BigInt$mkBigInt(1),
						$cmditch$elm_bigint$BigInt$fromString_(xs));
				}
			case '+':
				if (!_v0.b.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					var xs = _v0.b;
					return A2(
						$elm$core$Maybe$map,
						$cmditch$elm_bigint$BigInt$mkBigInt(0),
						$cmditch$elm_bigint$BigInt$fromString_(xs));
				}
			default:
				var xs = _v0;
				return A2(
					$elm$core$Maybe$map,
					$cmditch$elm_bigint$BigInt$mkBigInt(0),
					$cmditch$elm_bigint$BigInt$fromString_(xs));
		}
	}
};
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $torreyatcitty$the_best_decimal$Decimal$fromString = function (s) {
	var stringToDecimal = function (targetString) {
		var stringIntToDecimal = F2(
			function (s_, e) {
				var _v8 = $cmditch$elm_bigint$BigInt$fromString(s_);
				if (_v8.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var a = _v8.a;
					return $elm$core$Maybe$Just(
						A2($torreyatcitty$the_best_decimal$Decimal$Decimal, a, e));
				}
			});
		var _v6 = A2($elm$core$String$split, '.', targetString);
		_v6$2:
		while (true) {
			if (_v6.b) {
				if (_v6.b.b) {
					if (!_v6.b.b.b) {
						var a = _v6.a;
						var _v7 = _v6.b;
						var b = _v7.a;
						return A2(
							stringIntToDecimal,
							_Utils_ap(a, b),
							-$elm$core$String$length(b));
					} else {
						break _v6$2;
					}
				} else {
					var a = _v6.a;
					return A2(stringIntToDecimal, a, 0);
				}
			} else {
				break _v6$2;
			}
		}
		return $elm$core$Maybe$Nothing;
	};
	var makeMantissa = function (targetString) {
		var _v4 = A2($elm$core$String$split, '.', targetString);
		_v4$2:
		while (true) {
			if (_v4.b) {
				if (!_v4.b.b) {
					var s1 = _v4.a;
					return stringToDecimal(s1);
				} else {
					if (!_v4.b.b.b) {
						var s1 = _v4.a;
						var _v5 = _v4.b;
						var s2 = _v5.a;
						return stringToDecimal(
							A2(
								$elm$core$String$join,
								'',
								_List_fromArray(
									[s1, s2])));
					} else {
						break _v4$2;
					}
				}
			} else {
				break _v4$2;
			}
		}
		return $elm$core$Maybe$Nothing;
	};
	var splitMantissaExponent = function (targetString) {
		var _v2 = A2(
			$elm$core$String$split,
			'e',
			$elm$core$String$toLower(targetString));
		_v2$2:
		while (true) {
			if (_v2.b) {
				if (!_v2.b.b) {
					var s1 = _v2.a;
					return _Utils_Tuple2(
						stringToDecimal(s1),
						$elm$core$Maybe$Just(0));
				} else {
					if (!_v2.b.b.b) {
						var s1 = _v2.a;
						var _v3 = _v2.b;
						var s2 = _v3.a;
						return _Utils_Tuple2(
							stringToDecimal(s1),
							$elm$core$String$toInt(s2));
					} else {
						break _v2$2;
					}
				}
			} else {
				break _v2$2;
			}
		}
		return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
	};
	var _v0 = splitMantissaExponent(s);
	if ((!_v0.a.$) && (!_v0.b.$)) {
		var _v1 = _v0.a.a;
		var m = _v1.a;
		var a = _v1.b;
		var e = _v0.b.a;
		return $elm$core$Maybe$Just(
			A2($torreyatcitty$the_best_decimal$Decimal$Decimal, m, e + a));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$CompoundComponents$Eth$Decoders$stringDecimal = A2(
	$elm$json$Json$Decode$andThen,
	$author$project$CompoundComponents$Eth$Decoders$forceMaybe('invalid float'),
	A2($elm$json$Json$Decode$map, $torreyatcitty$the_best_decimal$Decimal$fromString, $elm$json$Json$Decode$string));
var $cmditch$elm_bigint$BigInt$BigIntNotNormalised = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $cmditch$elm_bigint$BigInt$MagnitudeNotNormalised = $elm$core$Basics$identity;
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $elm_community$list_extra$List$Extra$last = function (items) {
	last:
	while (true) {
		if (!items.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!items.b.b) {
				var x = items.a;
				return $elm$core$Maybe$Just(x);
			} else {
				var rest = items.b;
				var $temp$items = rest;
				items = $temp$items;
				continue last;
			}
		}
	}
};
var $cmditch$elm_bigint$BigInt$isNegativeMagnitude = function (digits) {
	var _v0 = $elm_community$list_extra$List$Extra$last(digits);
	if (_v0.$ === 1) {
		return false;
	} else {
		var x = _v0.a;
		return x < 0;
	}
};
var $cmditch$elm_bigint$BigInt$mkBigIntNotNormalised = F2(
	function (s, digits) {
		return A2($cmditch$elm_bigint$BigInt$BigIntNotNormalised, s, digits);
	});
var $elm_community$list_extra$List$Extra$dropWhileRight = function (p) {
	return A2(
		$elm$core$List$foldr,
		F2(
			function (x, xs) {
				return (p(x) && $elm$core$List$isEmpty(xs)) ? _List_Nil : A2($elm$core$List$cons, x, xs);
			}),
		_List_Nil);
};
var $cmditch$elm_bigint$BigInt$dropZeroes = $elm_community$list_extra$List$Extra$dropWhileRight(
	$elm$core$Basics$eq(0));
var $elm$core$Basics$pow = _Basics_pow;
var $cmditch$elm_bigint$Constants$maxDigitValue = (-1) + A2($elm$core$Basics$pow, 10, $cmditch$elm_bigint$Constants$maxDigitMagnitude);
var $cmditch$elm_bigint$BigInt$baseDigit = $cmditch$elm_bigint$Constants$maxDigitValue + 1;
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $cmditch$elm_bigint$BigInt$normaliseDigit = function (x) {
	return (x < 0) ? A2(
		$elm$core$Tuple$mapFirst,
		$elm$core$Basics$add(-1),
		$cmditch$elm_bigint$BigInt$normaliseDigit(x + $cmditch$elm_bigint$BigInt$baseDigit)) : _Utils_Tuple2((x / $cmditch$elm_bigint$BigInt$baseDigit) | 0, x % $cmditch$elm_bigint$BigInt$baseDigit);
};
var $cmditch$elm_bigint$BigInt$normaliseDigitList = F2(
	function (carry, xs) {
		normaliseDigitList:
		while (true) {
			if (!xs.b) {
				if (_Utils_cmp(carry, $cmditch$elm_bigint$BigInt$baseDigit) > 0) {
					var $temp$carry = 0,
						$temp$xs = _List_fromArray(
						[carry]);
					carry = $temp$carry;
					xs = $temp$xs;
					continue normaliseDigitList;
				} else {
					return _List_fromArray(
						[carry]);
				}
			} else {
				var x = xs.a;
				var xs_ = xs.b;
				var _v1 = $cmditch$elm_bigint$BigInt$normaliseDigit(x + carry);
				var newCarry = _v1.a;
				var x_ = _v1.b;
				return A2(
					$elm$core$List$cons,
					x_,
					A2($cmditch$elm_bigint$BigInt$normaliseDigitList, newCarry, xs_));
			}
		}
	});
var $cmditch$elm_bigint$BigInt$normaliseMagnitude = function (_v0) {
	var xs = _v0;
	return $cmditch$elm_bigint$BigInt$dropZeroes(
		A2($cmditch$elm_bigint$BigInt$normaliseDigitList, 0, xs));
};
var $cmditch$elm_bigint$BigInt$reverseMagnitude = $elm$core$List$map($elm$core$Basics$negate);
var $cmditch$elm_bigint$BigInt$Zero = 2;
var $cmditch$elm_bigint$BigInt$signNegate = function (sign_) {
	switch (sign_) {
		case 0:
			return 1;
		case 1:
			return 0;
		default:
			return 2;
	}
};
var $cmditch$elm_bigint$BigInt$normalise = function (_v0) {
	normalise:
	while (true) {
		var s = _v0.a;
		var digits = _v0.b;
		var _v1 = $cmditch$elm_bigint$BigInt$normaliseMagnitude(digits);
		var normalisedMag = _v1;
		if ($cmditch$elm_bigint$BigInt$isNegativeMagnitude(normalisedMag)) {
			var $temp$_v0 = A2(
				$cmditch$elm_bigint$BigInt$mkBigIntNotNormalised,
				$cmditch$elm_bigint$BigInt$signNegate(s),
				$cmditch$elm_bigint$BigInt$reverseMagnitude(normalisedMag));
			_v0 = $temp$_v0;
			continue normalise;
		} else {
			return A2($cmditch$elm_bigint$BigInt$mkBigInt, s, normalisedMag);
		}
	}
};
var $cmditch$elm_bigint$BigInt$signFromInt = function (x) {
	var _v0 = A2($elm$core$Basics$compare, x, 0);
	switch (_v0) {
		case 0:
			return 1;
		case 2:
			return 0;
		default:
			return 2;
	}
};
var $cmditch$elm_bigint$BigInt$fromInt = function (x) {
	return $cmditch$elm_bigint$BigInt$normalise(
		A2(
			$cmditch$elm_bigint$BigInt$BigIntNotNormalised,
			$cmditch$elm_bigint$BigInt$signFromInt(x),
			_List_fromArray(
				[
					$elm$core$Basics$abs(x)
				])));
};
var $torreyatcitty$the_best_decimal$Decimal$fromIntWithExponent = F2(
	function (n, e) {
		return A2(
			$torreyatcitty$the_best_decimal$Decimal$Decimal,
			$cmditch$elm_bigint$BigInt$fromInt(n),
			e);
	});
var $torreyatcitty$the_best_decimal$Decimal$fromInt = function (n) {
	return A2($torreyatcitty$the_best_decimal$Decimal$fromIntWithExponent, n, 0);
};
var $torreyatcitty$the_best_decimal$Decimal$zero = $torreyatcitty$the_best_decimal$Decimal$fromInt(0);
var $author$project$CompoundApi$Governance$ProposalService$Decoders$proposalDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'abstain_votes',
	$author$project$CompoundComponents$Eth$Decoders$stringDecimal,
	$torreyatcitty$the_best_decimal$Decimal$zero,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'against_votes',
		$author$project$CompoundComponents$Eth$Decoders$stringDecimal,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'for_votes',
			$author$project$CompoundComponents$Eth$Decoders$stringDecimal,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'states',
				$elm$json$Json$Decode$list($author$project$CompoundApi$Governance$ProposalService$Decoders$proposalStateDecoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'description',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'title',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'id',
							$elm$json$Json$Decode$int,
							$elm$json$Json$Decode$succeed($author$project$CompoundApi$Governance$ProposalService$Models$createProposal))))))));
var $author$project$CompoundApi$Governance$ProposalVoteReceiptService$Decoders$proposalVoteReceiptDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'proposal',
	$elm$json$Json$Decode$nullable($author$project$CompoundApi$Governance$ProposalService$Decoders$proposalDecoder),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'votes',
		$author$project$CompoundComponents$Eth$Decoders$stringDecimal,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'support',
			$elm$json$Json$Decode$bool,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'voter',
				$author$project$CompoundApi$Governance$Common$Decoders$displayAccountDecoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'proposal_id',
					$elm$json$Json$Decode$int,
					$elm$json$Json$Decode$succeed($author$project$CompoundApi$Governance$ProposalVoteReceiptService$Models$createProposalVoteReceipt))))));
var $author$project$CompoundApi$Governance$ProposalVoteReceiptService$Decoders$proposalVoteReceiptResponseDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$CompoundApi$Governance$ProposalVoteReceiptService$Models$ProposalVoteReceiptResponse,
	A2($elm$json$Json$Decode$field, 'pagination_summary', $author$project$CompoundApi$Common$Decoders$paginationSummaryDecoder),
	A2(
		$elm$json$Json$Decode$field,
		'proposal_vote_receipts',
		$elm$json$Json$Decode$list($author$project$CompoundApi$Governance$ProposalVoteReceiptService$Decoders$proposalVoteReceiptDecoder)));
var $author$project$CompoundApi$Governance$ProposalService$Encoders$encodeProposalStateEnum = function (stateEnum) {
	switch (stateEnum) {
		case 0:
			return 'pending';
		case 1:
			return 'active';
		case 2:
			return 'succeeded';
		case 3:
			return 'defeated';
		case 4:
			return 'queued';
		case 5:
			return 'executed';
		case 6:
			return 'canceled';
		default:
			return 'expired';
	}
};
var $author$project$CompoundComponents$Eth$Network$networkName = function (network) {
	switch (network) {
		case 0:
			return 'Olympic';
		case 1:
			return 'Mainnet';
		case 2:
			return 'Morden';
		case 3:
			return 'Ropsten';
		case 4:
			return 'Rinkeby';
		case 5:
			return 'Goerli';
		case 6:
			return 'Ubiq';
		case 7:
			return 'Kovan';
		case 8:
			return 'Sokol';
		case 9:
			return 'Core';
		case 12:
			return 'Polygon';
		case 13:
			return 'Mumbai';
		case 14:
			return 'Arbitrum';
		case 15:
			return 'Arbitrum Goerli';
		case 16:
			return 'Optimism';
		case 17:
			return 'Optimism Goerli';
		case 18:
			return 'Base';
		case 19:
			return 'Base Goerli';
		case 10:
			return 'Development';
		default:
			return 'unknown';
	}
};
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$relative = F2(
	function (pathSegments, parameters) {
		return _Utils_ap(
			A2($elm$core$String$join, '/', pathSegments),
			$elm$url$Url$Builder$toQuery(parameters));
	});
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $author$project$CompoundApi$Governance$ProposalService$Urls$proposalsRequestUrl = F3(
	function (apiBaseUrlMap, network, proposalRequest) {
		var requiredQueryParams = _List_fromArray(
			[
				A2(
				$elm$url$Url$Builder$string,
				'page_size',
				$elm$core$String$fromInt(proposalRequest.jk)),
				A2(
				$elm$url$Url$Builder$string,
				'page_number',
				$elm$core$String$fromInt(proposalRequest.jj))
			]);
		var intermediateQueryParams = _Utils_ap(
			requiredQueryParams,
			_Utils_ap(
				function () {
					var _v1 = proposalRequest.jq;
					if (!_v1.b) {
						return _List_Nil;
					} else {
						var proposalIdsList = _v1;
						var proposalsQueryArg = A2(
							$elm$core$String$join,
							',',
							A2($elm$core$List$map, $elm$core$String$fromInt, proposalIdsList));
						return _List_fromArray(
							[
								A2($elm$url$Url$Builder$string, 'proposal_ids', proposalsQueryArg)
							]);
					}
				}(),
				function () {
					var _v2 = proposalRequest.kC;
					if (!_v2.$) {
						if (_v2.a) {
							return _List_fromArray(
								[
									A2($elm$url$Url$Builder$string, 'with_detail', 'true')
								]);
						} else {
							return _List_fromArray(
								[
									A2($elm$url$Url$Builder$string, 'with_detail', 'false')
								]);
						}
					} else {
						return _List_Nil;
					}
				}()));
		var finalQueryParams = function () {
			var _v0 = proposalRequest.jQ;
			if (!_v0.$) {
				var actualStateFilter = _v0.a;
				return _Utils_ap(
					intermediateQueryParams,
					_List_fromArray(
						[
							A2(
							$elm$url$Url$Builder$string,
							'state',
							$author$project$CompoundApi$Governance$ProposalService$Encoders$encodeProposalStateEnum(actualStateFilter))
						]));
			} else {
				return intermediateQueryParams;
			}
		}();
		return A2(
			$elm$core$Maybe$map,
			function (apiBaseUrl) {
				return _Utils_ap(
					apiBaseUrl,
					A2(
						$elm$url$Url$Builder$relative,
						_List_fromArray(
							[
								'governance',
								$elm$core$String$toLower(
								$author$project$CompoundComponents$Eth$Network$networkName(network)),
								'all',
								'proposals'
							]),
						finalQueryParams));
			},
			A2($elm$core$Dict$get, 'v3_api', apiBaseUrlMap));
	});
var $author$project$CompoundApi$Governance$ProposalService$Models$ProposalsWithDetailResponse = F2(
	function (pagination_summary, proposals) {
		return {gP: pagination_summary, gZ: proposals};
	});
var $author$project$CompoundApi$Governance$ProposalService$Models$createProposalWithDetail = F9(
	function (id, title, description, proposer, actions, states, for_votes, against_votes, abstain_votes) {
		return {fj: abstain_votes, hL: actions, fp: against_votes, iu: description, f_: for_votes, gb: id, jv: proposer, hf: states, j5: title};
	});
var $author$project$CompoundApi$Governance$Common$Models$ProposalAction = F6(
	function (title, subtitles, target, value, signature, data) {
		return {iq: data, jK: signature, jV: subtitles, j1: target, j5: title, bK: value};
	});
var $author$project$CompoundApi$Governance$Common$Decoders$proposalActionDecoder = A7(
	$elm$json$Json$Decode$map6,
	$author$project$CompoundApi$Governance$Common$Models$ProposalAction,
	A2($elm$json$Json$Decode$field, 'title', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'subtitles',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string)),
	A2($elm$json$Json$Decode$field, 'target', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'value', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
	A2($elm$json$Json$Decode$field, 'signature', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'data', $elm$json$Json$Decode$string));
var $author$project$CompoundApi$Governance$ProposalService$Decoders$proposalWithDetailDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'abstain_votes',
	$author$project$CompoundComponents$Eth$Decoders$stringDecimal,
	$torreyatcitty$the_best_decimal$Decimal$zero,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'against_votes',
		$author$project$CompoundComponents$Eth$Decoders$stringDecimal,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'for_votes',
			$author$project$CompoundComponents$Eth$Decoders$stringDecimal,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'states',
				$elm$json$Json$Decode$list($author$project$CompoundApi$Governance$ProposalService$Decoders$proposalStateDecoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'actions',
					$elm$json$Json$Decode$list($author$project$CompoundApi$Governance$Common$Decoders$proposalActionDecoder),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'proposer',
						$author$project$CompoundApi$Governance$Common$Decoders$displayAccountDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'description',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'title',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'id',
									$elm$json$Json$Decode$int,
									$elm$json$Json$Decode$succeed($author$project$CompoundApi$Governance$ProposalService$Models$createProposalWithDetail))))))))));
var $author$project$CompoundApi$Governance$ProposalService$Decoders$proposalsWithDetailResponseDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$CompoundApi$Governance$ProposalService$Models$ProposalsWithDetailResponse,
	A2($elm$json$Json$Decode$field, 'pagination_summary', $author$project$CompoundApi$Common$Decoders$paginationSummaryDecoder),
	A2(
		$elm$json$Json$Decode$field,
		'proposals',
		$elm$json$Json$Decode$list($author$project$CompoundApi$Governance$ProposalService$Decoders$proposalWithDetailDecoder)));
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$http$Http$toTask = function (_v0) {
	var request_ = _v0;
	return A2(_Http_toTask, request_, $elm$core$Maybe$Nothing);
};
var $elm$http$Http$send = F2(
	function (resultToMessage, request_) {
		return A2(
			$elm$core$Task$attempt,
			resultToMessage,
			$elm$http$Http$toTask(request_));
	});
var $author$project$CompoundApi$Governance$ProposalVoteReceiptService$Urls$voteReceiptsRequestUrl = F3(
	function (apiBaseUrlMap, network, request) {
		var requiredQueryParams = _List_fromArray(
			[
				A2(
				$elm$url$Url$Builder$string,
				'page_size',
				$elm$core$String$fromInt(request.jk)),
				A2(
				$elm$url$Url$Builder$string,
				'page_number',
				$elm$core$String$fromInt(request.jj))
			]);
		var finalQueryParams = _Utils_ap(
			requiredQueryParams,
			_Utils_ap(
				function () {
					var _v0 = request.eX;
					if (!_v0.$) {
						var support = _v0.a;
						return support ? _List_fromArray(
							[
								A2($elm$url$Url$Builder$string, 'support', 'true')
							]) : _List_fromArray(
							[
								A2($elm$url$Url$Builder$string, 'support', 'false')
							]);
					} else {
						return _List_Nil;
					}
				}(),
				_Utils_ap(
					function () {
						var _v1 = request.eD;
						if (!_v1.$) {
							var id = _v1.a;
							return _List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'proposal_id',
									$elm$core$String$fromInt(id))
								]);
						} else {
							return _List_Nil;
						}
					}(),
					_Utils_ap(
						function () {
							var _v2 = request.hI;
							if (!_v2.$) {
								var accountAddress = _v2.a;
								return _List_fromArray(
									[
										A2($elm$url$Url$Builder$string, 'account', accountAddress)
									]);
							} else {
								return _List_Nil;
							}
						}(),
						request.kE ? _List_fromArray(
							[
								A2($elm$url$Url$Builder$string, 'with_proposal_data', 'true')
							]) : _List_fromArray(
							[
								A2($elm$url$Url$Builder$string, 'with_proposal_data', 'false')
							])))));
		return A2(
			$elm$core$Maybe$map,
			function (apiBaseUrl) {
				return _Utils_ap(
					apiBaseUrl,
					A2(
						$elm$url$Url$Builder$relative,
						_List_fromArray(
							[
								'governance',
								$elm$core$String$toLower(
								$author$project$CompoundComponents$Eth$Network$networkName(network)),
								'all',
								'proposal_vote_receipts'
							]),
						finalQueryParams));
			},
			A2($elm$core$Dict$get, 'v3_api', apiBaseUrlMap));
	});
var $author$project$Governance$ProposalDetail$attempToLoadProposalId = F3(
	function (apiBaseUrlMap, network, proposalId) {
		var maybeRequestProposalsUrl = A3(
			$author$project$CompoundApi$Governance$ProposalService$Urls$proposalsRequestUrl,
			apiBaseUrlMap,
			network,
			{
				jj: 1,
				jk: 1,
				jq: _List_fromArray(
					[proposalId]),
				jQ: $elm$core$Maybe$Nothing,
				kC: $elm$core$Maybe$Just(true)
			});
		var maybeRequestForVoteReceiptsUrl = A3(
			$author$project$CompoundApi$Governance$ProposalVoteReceiptService$Urls$voteReceiptsRequestUrl,
			apiBaseUrlMap,
			network,
			{
				hI: $elm$core$Maybe$Nothing,
				jj: 1,
				jk: $author$project$Governance$ProposalDetail$desiredVoteReceiptsSize,
				eD: $elm$core$Maybe$Just(proposalId),
				eX: $elm$core$Maybe$Just(true),
				kE: false
			});
		var maybeRequestAgainstVoteReceiptsUrl = A3(
			$author$project$CompoundApi$Governance$ProposalVoteReceiptService$Urls$voteReceiptsRequestUrl,
			apiBaseUrlMap,
			network,
			{
				hI: $elm$core$Maybe$Nothing,
				jj: 1,
				jk: $author$project$Governance$ProposalDetail$desiredVoteReceiptsSize,
				eD: $elm$core$Maybe$Just(proposalId),
				eX: $elm$core$Maybe$Just(false),
				kE: false
			});
		var apiRequestCmds = $elm$core$Platform$Cmd$batch(
			A2(
				$elm$core$List$cons,
				function () {
					if (!maybeRequestProposalsUrl.$) {
						var requestProposalsUrl = maybeRequestProposalsUrl.a;
						return A2(
							$elm$http$Http$send,
							$author$project$Governance$ProposalDetail$ProposalsResponse,
							A2($elm$http$Http$get, requestProposalsUrl, $author$project$CompoundApi$Governance$ProposalService$Decoders$proposalsWithDetailResponseDecoder));
					} else {
						return $elm$core$Platform$Cmd$none;
					}
				}(),
				function () {
					var _v1 = _Utils_Tuple2(maybeRequestForVoteReceiptsUrl, maybeRequestAgainstVoteReceiptsUrl);
					if ((!_v1.a.$) && (!_v1.b.$)) {
						var requestForVoteReceiptsUrl = _v1.a.a;
						var requestAgainstVoteReceiptsUrl = _v1.b.a;
						return _List_fromArray(
							[
								A2(
								$elm$http$Http$send,
								$author$project$Governance$ProposalDetail$ForVoteReceiptsResponse,
								A2($elm$http$Http$get, requestForVoteReceiptsUrl, $author$project$CompoundApi$Governance$ProposalVoteReceiptService$Decoders$proposalVoteReceiptResponseDecoder)),
								A2(
								$elm$http$Http$send,
								$author$project$Governance$ProposalDetail$AgainstVoteReceiptsResponse,
								A2($elm$http$Http$get, requestAgainstVoteReceiptsUrl, $author$project$CompoundApi$Governance$ProposalVoteReceiptService$Decoders$proposalVoteReceiptResponseDecoder))
							]);
					} else {
						return _List_Nil;
					}
				}()));
		return _Utils_Tuple2($author$project$Governance$ProposalDetail$emptyState, apiRequestCmds);
	});
var $author$project$LandingSite$Comp$CompDistributionResponse = function (a) {
	return {$: 2, a: a};
};
var $author$project$LandingSite$Comp$HistoricalDataResponse = function (a) {
	return {$: 3, a: a};
};
var $author$project$LandingSite$Comp$V3RewardsSummaryResponse = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $author$project$V3Api$Decoders$v3NetworkName = function (network) {
	switch (network) {
		case 1:
			return 'mainnet';
		case 5:
			return 'goerli';
		case 12:
			return 'polygon-mainnet';
		case 13:
			return 'polygon-mumbai';
		case 14:
			return 'arbitrum-mainnet';
		case 18:
			return 'base-mainnet';
		default:
			return 'unsupported-v3-network';
	}
};
var $author$project$V3Api$Url$buildRewardsSummaryUrl = F3(
	function (apiBaseUrlMap, network, v3Market) {
		var v3MarketAddress = function () {
			switch (network) {
				case 1:
					switch (v3Market) {
						case 0:
							return '0xc3d688B66703497DAA19211EEdff47f25384cdc3';
						case 3:
							return '0xa17581a9e3356d9a858b789d68b4d866e593ae94';
						default:
							return 'unsupported-market';
					}
				case 5:
					switch (v3Market) {
						case 0:
							return '0x3ee77595a8459e93c2888b13adb354017b198188';
						case 3:
							return '0x9a539eec489aaa03d588212a164d0abdb5f08f5f';
						default:
							return 'unsupported-market';
					}
				case 12:
					if (!v3Market) {
						return '0xf25212e676d1f7f89cd72ffee66158f541246445';
					} else {
						return 'unsupported-market';
					}
				case 14:
					if (v3Market === 1) {
						return '0xA5EDBDD9646f8dFF606d7448e414884C7d905dCA';
					} else {
						return 'unsupported-market';
					}
				case 18:
					switch (v3Market) {
						case 2:
							return '0x9c4ec768c28520B50860ea7a15bd7213a9fF58bf';
						case 3:
							return '0x46e6b214b524310239732D51387075E0e70970bf';
						default:
							return 'unsupported-market';
					}
				default:
					return 'unsupported-market';
			}
		}();
		return A2(
			$elm$core$Maybe$map,
			function (apiBaseUrl) {
				return _Utils_ap(
					apiBaseUrl,
					A2(
						$elm$url$Url$Builder$relative,
						_List_fromArray(
							[
								'market',
								$elm$core$String$toLower(
								$author$project$V3Api$Decoders$v3NetworkName(network)),
								v3MarketAddress,
								'rewards',
								'summary'
							]),
						_List_Nil));
			},
			A2($elm$core$Dict$get, 'v3_api', apiBaseUrlMap));
	});
var $author$project$CompoundApi$Governance$HistoryService$Urls$governanceHistoryRequestUrl = F3(
	function (apiBaseUrlMap, network, _v0) {
		return A2(
			$elm$core$Maybe$map,
			function (apiBaseUrl) {
				return _Utils_ap(
					apiBaseUrl,
					A2(
						$elm$url$Url$Builder$relative,
						_List_fromArray(
							[
								'governance',
								$elm$core$String$toLower(
								$author$project$CompoundComponents$Eth$Network$networkName(network)),
								'comp',
								'history'
							]),
						_List_Nil));
			},
			A2($elm$core$Dict$get, 'v3_api', apiBaseUrlMap));
	});
var $author$project$CompoundApi$Governance$HistoryService$Models$GovernanceHistoryResponse = F4(
	function (proposals_created, comp_remaining, votes_delegated, voting_addresses) {
		return {ih: comp_remaining, js: proposals_created, ky: votes_delegated, kz: voting_addresses};
	});
var $author$project$CompoundApi$Governance$HistoryService$Decoders$governanceHistoryResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$CompoundApi$Governance$HistoryService$Models$GovernanceHistoryResponse,
	A2($elm$json$Json$Decode$field, 'proposals_created', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'comp_remaining', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
	A2($elm$json$Json$Decode$field, 'votes_delegated', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
	A2($elm$json$Json$Decode$field, 'voting_addresses', $elm$json$Json$Decode$int));
var $author$project$CompoundApi$Governance$CompService$Models$MarketCompDistributionResponse = F3(
	function (comp_rate, daily_comp, markets) {
		return {ig: comp_rate, ip: daily_comp, i_: markets};
	});
var $author$project$CompoundApi$Governance$CompService$Models$MarketCompDistibution = F7(
	function (address, borrower_daily_comp, supplier_daily_comp, symbol, underlying_address, underlying_name, underlying_symbol) {
		return {hO: address, h0: borrower_daily_comp, jW: supplier_daily_comp, j0: symbol, km: underlying_address, ko: underlying_name, kp: underlying_symbol};
	});
var $author$project$CompoundApi$Governance$CompService$Decoders$marketCompDistributionDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'underlying_symbol',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'underlying_name',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'underlying_address',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'symbol',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'supplier_daily_comp',
					$author$project$CompoundComponents$Eth$Decoders$stringDecimal,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'borrower_daily_comp',
						$author$project$CompoundComponents$Eth$Decoders$stringDecimal,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'address',
							$elm$json$Json$Decode$string,
							$elm$json$Json$Decode$succeed($author$project$CompoundApi$Governance$CompService$Models$MarketCompDistibution))))))));
var $author$project$CompoundApi$Governance$CompService$Decoders$marketCompDistributionResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$CompoundApi$Governance$CompService$Models$MarketCompDistributionResponse,
	A2($elm$json$Json$Decode$field, 'comp_rate', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
	A2($elm$json$Json$Decode$field, 'daily_comp', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
	A2(
		$elm$json$Json$Decode$field,
		'markets',
		$elm$json$Json$Decode$list($author$project$CompoundApi$Governance$CompService$Decoders$marketCompDistributionDecoder)));
var $author$project$CompoundApi$Governance$CompService$Urls$marketCompDistributionUrl = F3(
	function (apiBaseUrlMap, network, marketRequest) {
		var queryParams = function () {
			var _v0 = marketRequest.hP;
			if (!_v0.b) {
				return _List_Nil;
			} else {
				var addressList = _v0;
				var addressesQueryArg = A2($elm$core$String$join, ',', addressList);
				return _List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'addresses', addressesQueryArg)
					]);
			}
		}();
		return A2(
			$elm$core$Maybe$map,
			function (apiBaseUrl) {
				return _Utils_ap(
					apiBaseUrl,
					A2(
						$elm$url$Url$Builder$relative,
						_List_fromArray(
							[
								'governance',
								$elm$core$String$toLower(
								$author$project$CompoundComponents$Eth$Network$networkName(network)),
								'comp',
								'distribution'
							]),
						queryParams));
			},
			A2($elm$core$Dict$get, 'v3_api', apiBaseUrlMap));
	});
var $author$project$V3Api$Models$RewardsSummary = F4(
	function (supplyRewardsApr, borrowRewardsApr, supplyRewardsRatePerSecond, borrowRewardsRatePerSecond) {
		return {fx: borrowRewardsApr, fy: borrowRewardsRatePerSecond, jY: supplyRewardsApr, hj: supplyRewardsRatePerSecond};
	});
var $author$project$V3Api$Decoders$rewardsSummaryDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$V3Api$Models$RewardsSummary,
	A2($elm$json$Json$Decode$field, 'supply_rewards_apr', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
	A2($elm$json$Json$Decode$field, 'borrow_rewards_apr', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
	A2($elm$json$Json$Decode$field, 'supply_rewards_rate_per_second', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
	A2($elm$json$Json$Decode$field, 'borrow_rewards_rate_per_second', $author$project$CompoundComponents$Eth$Decoders$stringDecimal));
var $author$project$V3Api$Models$USDC = 0;
var $author$project$V3Api$Models$USDCe = 1;
var $author$project$V3Api$Models$USDbC = 2;
var $author$project$V3Api$Models$WETH = 3;
var $author$project$V3Api$Models$v3Markets = _List_fromArray(
	[
		_Utils_Tuple2(0, 1),
		_Utils_Tuple2(3, 1),
		_Utils_Tuple2(0, 12),
		_Utils_Tuple2(1, 14),
		_Utils_Tuple2(2, 18),
		_Utils_Tuple2(3, 18)
	]);
var $author$project$LandingSite$Comp$loadCompDetails = F2(
	function (apiBaseUrlMap, network) {
		var v3MarketSummaryCmds = A2(
			$elm$core$List$map,
			function (_v1) {
				var market = _v1.a;
				var marketNetwork = _v1.b;
				var maybeSummaryUrl = A3($author$project$V3Api$Url$buildRewardsSummaryUrl, apiBaseUrlMap, marketNetwork, market);
				return A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$Maybe$map,
						function (summaryUrl) {
							return A2(
								$elm$http$Http$send,
								A2($author$project$LandingSite$Comp$V3RewardsSummaryResponse, market, marketNetwork),
								A2($elm$http$Http$get, summaryUrl, $author$project$V3Api$Decoders$rewardsSummaryDecoder));
						},
						maybeSummaryUrl));
			},
			$author$project$V3Api$Models$v3Markets);
		var maybeRequestHistoryUrl = A3(
			$author$project$CompoundApi$Governance$HistoryService$Urls$governanceHistoryRequestUrl,
			apiBaseUrlMap,
			network,
			{});
		var maybeRequestCompDistributionUrl = A3(
			$author$project$CompoundApi$Governance$CompService$Urls$marketCompDistributionUrl,
			apiBaseUrlMap,
			network,
			{hP: _List_Nil});
		var requestCmds = function () {
			var _v0 = _Utils_Tuple2(maybeRequestHistoryUrl, maybeRequestCompDistributionUrl);
			if ((!_v0.a.$) && (!_v0.b.$)) {
				var requestHistoryUrl = _v0.a.a;
				var requestCompDistributionUrl = _v0.b.a;
				var historyRequest = A2($elm$http$Http$get, requestHistoryUrl, $author$project$CompoundApi$Governance$HistoryService$Decoders$governanceHistoryResponseDecoder);
				var compDistributionRequest = A2($elm$http$Http$get, requestCompDistributionUrl, $author$project$CompoundApi$Governance$CompService$Decoders$marketCompDistributionResponseDecoder);
				return _List_fromArray(
					[
						A2($elm$http$Http$send, $author$project$LandingSite$Comp$CompDistributionResponse, compDistributionRequest),
						A2($elm$http$Http$send, $author$project$LandingSite$Comp$HistoricalDataResponse, historyRequest)
					]);
			} else {
				return _List_fromArray(
					[$elm$core$Platform$Cmd$none]);
			}
		}();
		return _Utils_Tuple2(
			$author$project$LandingSite$Comp$emptyState,
			$elm$core$Platform$Cmd$batch(
				_Utils_ap(v3MarketSummaryCmds, requestCmds)));
	});
var $author$project$Governance$Home$HistoricalDataResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Governance$Home$LatestProposalsResponse = function (a) {
	return {$: 1, a: a};
};
var $author$project$Governance$Home$TopDelegatesResponse = function (a) {
	return {$: 2, a: a};
};
var $author$project$CompoundApi$Governance$AccountService$Models$Votes = 0;
var $author$project$CompoundApi$Governance$AccountService$Encoders$encodeAccountFilterEnum = function (accountFilterEnum) {
	switch (accountFilterEnum) {
		case 0:
			return 'votes';
		case 1:
			return 'balance';
		default:
			return 'proposals_created';
	}
};
var $author$project$CompoundApi$Governance$AccountService$Urls$governanceAccountRequestUrl = F3(
	function (apiBaseUrlMap, network, accountRequest) {
		var requiredQueryParams = _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$url$Url$Builder$string,
					'page_size',
					$elm$core$String$fromInt(accountRequest.jk)),
					A2(
					$elm$url$Url$Builder$string,
					'page_number',
					$elm$core$String$fromInt(accountRequest.jj))
				]),
			accountRequest.kD ? _List_fromArray(
				[
					A2($elm$url$Url$Builder$string, 'with_details', 'true')
				]) : _List_fromArray(
				[
					A2($elm$url$Url$Builder$string, 'with_details', 'false')
				]));
		var intermediateQueryParams = _Utils_ap(
			requiredQueryParams,
			function () {
				var _v1 = accountRequest.hP;
				if (!_v1.b) {
					return _List_Nil;
				} else {
					var addressList = _v1;
					var addressesQueryArg = A2($elm$core$String$join, ',', addressList);
					return _List_fromArray(
						[
							A2($elm$url$Url$Builder$string, 'addresses', addressesQueryArg)
						]);
				}
			}());
		var finalQueryParams = function () {
			var _v0 = accountRequest.jg;
			if (!_v0.$) {
				var actualOrderBy = _v0.a;
				return _Utils_ap(
					intermediateQueryParams,
					_List_fromArray(
						[
							A2(
							$elm$url$Url$Builder$string,
							'order_by',
							$author$project$CompoundApi$Governance$AccountService$Encoders$encodeAccountFilterEnum(actualOrderBy))
						]));
			} else {
				return intermediateQueryParams;
			}
		}();
		return A2(
			$elm$core$Maybe$map,
			function (apiBaseUrl) {
				return _Utils_ap(
					apiBaseUrl,
					A2(
						$elm$url$Url$Builder$relative,
						_List_fromArray(
							[
								'governance',
								$elm$core$String$toLower(
								$author$project$CompoundComponents$Eth$Network$networkName(network)),
								'comp',
								'accounts'
							]),
						finalQueryParams));
			},
			A2($elm$core$Dict$get, 'v3_api', apiBaseUrlMap));
	});
var $author$project$CompoundApi$Governance$AccountService$Models$GovernanceAccountResponse = function (accounts) {
	return {hK: accounts};
};
var $author$project$CompoundApi$Governance$Common$Models$GatheringVotes = 0;
var $author$project$CompoundApi$Governance$Common$Models$createCompAccount = F9(
	function (address, display_name, image_url, balance, votes, vote_weight, proposals_voted, rank, total_delegates) {
		return {
			hO: address,
			hT: A2($elm$core$Maybe$withDefault, $torreyatcitty$the_best_decimal$Decimal$zero, balance),
			iv: display_name,
			iP: image_url,
			jt: proposals_voted,
			jy: rank,
			ke: total_delegates,
			kw: vote_weight,
			kx: votes
		};
	});
var $author$project$CompoundApi$Governance$Common$Decoders$compAccountDecoder = function () {
	var addFakeCrowdProposal = function (compAccount) {
		var dummyProposal = $elm$core$Maybe$Just(
			{
				hL: _List_Nil,
				hR: {
					hJ: $elm$core$Maybe$Nothing,
					hO: '0xb0325dbe7fa891436e83a094f9f12848c78e449b',
					iv: $elm$core$Maybe$Just('Cal Beans'),
					iP: $elm$core$Maybe$Just('https://profile.compound.finance/1RfADD1lVZGGDW2ucqrjakqdkMAJf7VhG/bcmxV1E4UM7CezFzrL597/+DGVqaIh/Z+GxeM5E7ABs=')
				},
				im: $elm$time$Time$millisToPosix(1599242627 * 1000),
				iu: 'Morbi quis justo magna. Phasellus quis purus dictum, pulvinar ex ac, maximus nibh. Nam malesuada dolor vel tellus rutrum dapibus. In hac habitasse platea dictumst. Aliquam efficitur nec dui et egestas. Suspendisse egestas dapibus mi, sed mollis orci tempus ut. Donec ac erat vestibulum, sollicitudin ex eget, pellentesque sem.\u2028',
				jp: '0x8169522c2c57883e8ef80c498aab7820da539806',
				ju: $elm$core$Maybe$Nothing,
				jQ: 0,
				j5: 'Add Maker (MKR) to Compound'
			});
		return $elm$json$Json$Decode$succeed(
			_Utils_update(
				compAccount,
				{io: dummyProposal}));
	};
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'total_delegates',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'rank',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'proposals_voted',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'vote_weight',
					$author$project$CompoundComponents$Eth$Decoders$stringDecimal,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'votes',
						$author$project$CompoundComponents$Eth$Decoders$stringDecimal,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'balance',
							$elm$json$Json$Decode$maybe($author$project$CompoundComponents$Eth$Decoders$stringDecimal),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'image_url',
								$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'display_name',
									$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'address',
										$elm$json$Json$Decode$string,
										$elm$json$Json$Decode$succeed($author$project$CompoundApi$Governance$Common$Models$createCompAccount))))))))));
}();
var $author$project$CompoundApi$Governance$AccountService$Decoders$governanceAccountResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$CompoundApi$Governance$AccountService$Models$GovernanceAccountResponse,
	A2(
		$elm$json$Json$Decode$field,
		'accounts',
		$elm$json$Json$Decode$list($author$project$CompoundApi$Governance$Common$Decoders$compAccountDecoder)));
var $author$project$CompoundApi$Governance$ProposalService$Models$ProposalsResponse = F2(
	function (pagination_summary, proposals) {
		return {gP: pagination_summary, gZ: proposals};
	});
var $author$project$CompoundApi$Governance$ProposalService$Decoders$proposalsResponseDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$CompoundApi$Governance$ProposalService$Models$ProposalsResponse,
	A2($elm$json$Json$Decode$field, 'pagination_summary', $author$project$CompoundApi$Common$Decoders$paginationSummaryDecoder),
	A2(
		$elm$json$Json$Decode$field,
		'proposals',
		$elm$json$Json$Decode$list($author$project$CompoundApi$Governance$ProposalService$Decoders$proposalDecoder)));
var $author$project$Governance$Home$loadDashboard = F2(
	function (apiBaseUrlMap, network) {
		var maybeRequestTopDelegatesUrl = A3(
			$author$project$CompoundApi$Governance$AccountService$Urls$governanceAccountRequestUrl,
			apiBaseUrlMap,
			network,
			{
				hP: _List_Nil,
				jg: $elm$core$Maybe$Just(0),
				jj: 1,
				jk: 5,
				kD: false
			});
		var maybeRequestProposalsUrl = A3(
			$author$project$CompoundApi$Governance$ProposalService$Urls$proposalsRequestUrl,
			apiBaseUrlMap,
			network,
			{
				jj: 1,
				jk: 3,
				jq: _List_Nil,
				jQ: $elm$core$Maybe$Nothing,
				kC: $elm$core$Maybe$Just(false)
			});
		var maybeRequestHistoryUrl = A3(
			$author$project$CompoundApi$Governance$HistoryService$Urls$governanceHistoryRequestUrl,
			apiBaseUrlMap,
			network,
			{});
		var requestCmds = function () {
			var _v0 = _Utils_Tuple3(maybeRequestHistoryUrl, maybeRequestProposalsUrl, maybeRequestTopDelegatesUrl);
			if (((!_v0.a.$) && (!_v0.b.$)) && (!_v0.c.$)) {
				var requestHistoryUrl = _v0.a.a;
				var requestProposalsUrl = _v0.b.a;
				var requestTopDelegatesUrl = _v0.c.a;
				var topDelegatesRequest = A2($elm$http$Http$get, requestTopDelegatesUrl, $author$project$CompoundApi$Governance$AccountService$Decoders$governanceAccountResponseDecoder);
				var latestProposalsRequest = A2($elm$http$Http$get, requestProposalsUrl, $author$project$CompoundApi$Governance$ProposalService$Decoders$proposalsResponseDecoder);
				var historyRequest = A2($elm$http$Http$get, requestHistoryUrl, $author$project$CompoundApi$Governance$HistoryService$Decoders$governanceHistoryResponseDecoder);
				return _List_fromArray(
					[
						A2($elm$http$Http$send, $author$project$Governance$Home$LatestProposalsResponse, latestProposalsRequest),
						A2($elm$http$Http$send, $author$project$Governance$Home$TopDelegatesResponse, topDelegatesRequest),
						A2($elm$http$Http$send, $author$project$Governance$Home$HistoricalDataResponse, historyRequest)
					]);
			} else {
				return _List_fromArray(
					[$elm$core$Platform$Cmd$none]);
			}
		}();
		return $elm$core$Platform$Cmd$batch(requestCmds);
	});
var $author$project$Governance$ProposalOverview$AllProposalsResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Governance$ProposalOverview$desiredPageSize = 50;
var $author$project$Governance$ProposalOverview$loadProposals = F2(
	function (apiBaseUrlMap, network) {
		var maybeRequestProposalsUrl = A3(
			$author$project$CompoundApi$Governance$ProposalService$Urls$proposalsRequestUrl,
			apiBaseUrlMap,
			network,
			{
				jj: 1,
				jk: $author$project$Governance$ProposalOverview$desiredPageSize,
				jq: _List_Nil,
				jQ: $elm$core$Maybe$Nothing,
				kC: $elm$core$Maybe$Just(false)
			});
		var requestCmd = function () {
			if (!maybeRequestProposalsUrl.$) {
				var requestProposalsUrl = maybeRequestProposalsUrl.a;
				var allProposalsRequest = A2($elm$http$Http$get, requestProposalsUrl, $author$project$CompoundApi$Governance$ProposalService$Decoders$proposalsResponseDecoder);
				return A2($elm$http$Http$send, $author$project$Governance$ProposalOverview$AllProposalsResponse, allProposalsRequest);
			} else {
				return $elm$core$Platform$Cmd$none;
			}
		}();
		return requestCmd;
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$LandingSite$Main$handlePageUpdates = F2(
	function (newPage, oldModel) {
		switch (newPage.$) {
			case 1:
				var _v1 = A2($author$project$LandingSite$Comp$loadCompDetails, oldModel.M, oldModel.T);
				var updatedCompModel = _v1.a;
				var compCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						oldModel,
						{aY: updatedCompModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$LandingSite$Main$LandingCompMsg, compCmd));
			case 2:
				return _Utils_Tuple2(
					oldModel,
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$LandingSite$Main$GovernanceHomeMsg,
						A2($author$project$Governance$Home$loadDashboard, oldModel.M, oldModel.T)));
			case 3:
				var proposalId = newPage.a;
				var _v2 = A3($author$project$Governance$ProposalDetail$attempToLoadProposalId, oldModel.M, oldModel.T, proposalId);
				var updatedProposalDetailModel = _v2.a;
				var proposalDetailCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						oldModel,
						{aX: updatedProposalDetailModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$LandingSite$Main$GovernanceProposalDetailMsg, proposalDetailCmd));
			case 4:
				return _Utils_Tuple2(
					oldModel,
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$LandingSite$Main$GovernanceProposalOverviewMsg,
						A2($author$project$Governance$ProposalOverview$loadProposals, oldModel.M, oldModel.T)));
			default:
				return _Utils_Tuple2(oldModel, $elm$core$Platform$Cmd$none);
		}
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Strings$Translations$landing_site_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Compound is an algorithmic, autonomous interest rate protocol built for developers, to unlock a universe of open financial applications.';
		case 1:
			return 'Compuesto es un protocolo algorítmico de tasa de interés autónomo creado para desarrolladores, para desbloquear un universo de aplicaciones financieras abiertas.';
		case 2:
			return '复合是为开发人员构建的一种算法性，自主利率协议，用于解锁一系列开放式金融应用程序。';
		case 3:
			return 'Compound est un protocole algorithmique et autonome de taux d\'intérêt conçu pour les développeurs afin de déverrouiller un univers d\'applications financières ouvertes.';
		default:
			return 'Compound는 개방형 금융 애플리케이션에 대한 개발자의 접근성을 높이기 위해 구축된 알고리즘 방식의 자율 이자율 프로토콜입니다.';
	}
};
var $author$project$Strings$Translations$landing_site_governance_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Compound is managed by a decentralized community of COMP token-holders and their delegates, who propose and vote on upgrades to the protocol.';
		case 1:
			return 'El compuesto es administrado por una comunidad descentralizada de poseedores de tokens COMP y sus delegados, quienes proponen y votan sobre las actualizaciones del protocolo.';
		case 2:
			return 'Compound由COMP token持有人及其代表的去中心化社区管理，他们对协议的升级提出建议并进行投票。';
		case 3:
			return 'Compound est géré par une communauté décentralisée de détenteurs de jetons COMP et de leurs délégués, qui proposent et votent les mises à niveau du protocole.';
		default:
			return 'Compound는 COMP 토큰 보유자와 그들의 대리인으로 구성된 탈중앙화된 커뮤니티에 의해 운영되며, 이들은 프로토콜 업그레이드를 제안하고 투표에 참여합니다.';
	}
};
var $author$project$LandingSite$Page$metaDescriptionAndImage = F2(
	function (userLanguage, page) {
		var proposalMetaImageUrl = 'https://compound.finance/images/meta-tag-governance-proposal.png';
		var defaultMetaImageUrl = 'https://compound.finance/images/meta-tag.png';
		var defaultFaviconUrl = 'https://compound.finance/favicon.ico';
		switch (page.$) {
			case 2:
				return _Utils_Tuple3(
					$author$project$Strings$Translations$landing_site_governance_description(userLanguage),
					defaultMetaImageUrl,
					defaultFaviconUrl);
			case 3:
				return _Utils_Tuple3(
					$author$project$Strings$Translations$landing_site_governance_description(userLanguage),
					proposalMetaImageUrl,
					defaultFaviconUrl);
			case 4:
				return _Utils_Tuple3(
					$author$project$Strings$Translations$landing_site_governance_description(userLanguage),
					proposalMetaImageUrl,
					defaultFaviconUrl);
			default:
				return _Utils_Tuple3(
					$author$project$Strings$Translations$landing_site_description(userLanguage),
					defaultMetaImageUrl,
					defaultFaviconUrl);
		}
	});
var $elm$core$Basics$neq = _Utils_notEqual;
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$LandingSite$Main$pageTransition = _Platform_outgoingPort(
	'pageTransition',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$string(a),
					$elm$json$Json$Encode$string(b)
				]));
	});
var $author$project$LandingSite$Main$setPageMeta = _Platform_outgoingPort(
	'setPageMeta',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					function ($) {
					var a = $.a;
					var b = $.b;
					return A2(
						$elm$json$Json$Encode$list,
						$elm$core$Basics$identity,
						_List_fromArray(
							[
								$elm$json$Json$Encode$string(a),
								$elm$json$Json$Encode$string(b)
							]));
				}(a),
					function ($) {
					var a = $.a;
					var b = $.b;
					return A2(
						$elm$json$Json$Encode$list,
						$elm$core$Basics$identity,
						_List_fromArray(
							[
								$elm$json$Json$Encode$string(a),
								$elm$json$Json$Encode$string(b)
							]));
				}(b)
				]));
	});
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$Strings$Translations$site_title = function (lang) {
	switch (lang) {
		case 0:
			return 'Compound';
		case 1:
			return 'Compound';
		case 2:
			return 'Compound';
		case 3:
			return 'Compound';
		default:
			return 'Compound';
	}
};
var $author$project$LandingSite$Page$buildTitle = F2(
	function (userLanguage, subTitle) {
		if (subTitle.$ === 1) {
			return $author$project$Strings$Translations$site_title(userLanguage);
		} else {
			var s = subTitle.a;
			return $author$project$Strings$Translations$site_title(userLanguage) + (' | ' + s);
		}
	});
var $author$project$Strings$Translations$site_title_comp = function (lang) {
	switch (lang) {
		case 0:
			return 'Compound Governance Token';
		case 1:
			return 'Token de gobierno compuesto';
		case 2:
			return '复合治理令牌';
		case 3:
			return 'Jeton de gouvernance Compound';
		default:
			return 'Compound 거버넌스 토큰';
	}
};
var $author$project$Strings$Translations$site_title_governance = function (lang) {
	switch (lang) {
		case 0:
			return 'Governance';
		case 1:
			return 'Gobernancia';
		case 2:
			return '治理';
		case 3:
			return 'La gouvernance';
		default:
			return '거버넌스';
	}
};
var $author$project$Strings$Translations$site_title_proposal_detail = F2(
	function (lang, id) {
		switch (lang) {
			case 0:
				return 'Proposal Detail #' + (id + '');
			case 1:
				return 'Detalle de la propuesta #' + (id + '');
			case 2:
				return '投标详细信息 #' + (id + '');
			case 3:
				return 'Détail de la proposition #' + (id + '');
			default:
				return '제안서 세부 사항 #' + (id + '');
		}
	});
var $author$project$Strings$Translations$site_title_proposal_overview = function (lang) {
	switch (lang) {
		case 0:
			return 'Proposal Overview';
		case 1:
			return 'Resumen de la propuesta';
		case 2:
			return '提案概述';
		case 3:
			return 'Aperçu de la proposition';
		default:
			return '제안서 개요';
	}
};
var $author$project$LandingSite$Page$title = F2(
	function (userLanguage, page) {
		switch (page.$) {
			case 0:
				return A2($author$project$LandingSite$Page$buildTitle, userLanguage, $elm$core$Maybe$Nothing);
			case 1:
				return A2(
					$author$project$LandingSite$Page$buildTitle,
					userLanguage,
					$elm$core$Maybe$Just(
						$author$project$Strings$Translations$site_title_comp(userLanguage)));
			case 2:
				return A2(
					$author$project$LandingSite$Page$buildTitle,
					userLanguage,
					$elm$core$Maybe$Just(
						$author$project$Strings$Translations$site_title_governance(userLanguage)));
			case 3:
				var proposalId = page.a;
				return A2(
					$author$project$LandingSite$Page$buildTitle,
					userLanguage,
					$elm$core$Maybe$Just(
						A2(
							$author$project$Strings$Translations$site_title_proposal_detail,
							userLanguage,
							$elm$core$String$fromInt(proposalId))));
			default:
				return A2(
					$author$project$LandingSite$Page$buildTitle,
					userLanguage,
					$elm$core$Maybe$Just(
						$author$project$Strings$Translations$site_title_proposal_overview(userLanguage)));
		}
	});
var $author$project$LandingSite$Main$transitionPage = F2(
	function (model, url) {
		var _v0 = function () {
			if (!url.$) {
				var location = url.a;
				var newPage_ = $author$project$LandingSite$Page$getLandingPage(location);
				var newPages_ = function () {
					var _v2 = $elm$core$List$head(model.aF);
					if (!_v2.$) {
						var prevHead = _v2.a;
						return _Utils_eq(prevHead, newPage_) ? A2($elm$core$List$drop, 1, model.aF) : A2($elm$core$List$cons, model.aa, model.aF);
					} else {
						var _v3 = _Utils_Tuple2(model.aa, newPage_);
						if (!_v3.a.$) {
							var _v4 = _v3.a;
							return _List_Nil;
						} else {
							if (_v3.b.$ === 2) {
								var _v5 = _v3.b;
								return _List_Nil;
							} else {
								return _List_fromArray(
									[$author$project$LandingSite$Page$GovernanceHome]);
							}
						}
					}
				}();
				return _Utils_Tuple2(newPage_, newPages_);
			} else {
				return _Utils_Tuple2($author$project$LandingSite$Page$Home, model.aF);
			}
		}();
		var newPage = _v0.a;
		var newPages = _v0.b;
		var maybeScrollCmd = A2(
			$elm$core$Task$perform,
			$author$project$LandingSite$Main$DelayScroll,
			A2(
				$elm$core$Task$andThen,
				function (_v8) {
					return $elm$core$Task$succeed(newPage);
				},
				$elm$core$Process$sleep(25)));
		var pageTransitionCmd = $author$project$LandingSite$Main$pageTransition(
			_Utils_Tuple2(
				$author$project$LandingSite$Page$getHrefUrl(model.aa),
				$author$project$LandingSite$Page$getHrefUrl(newPage)));
		var _v6 = (!_Utils_eq(model.aa, newPage)) ? A2($author$project$LandingSite$Main$handlePageUpdates, newPage, model) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		var newModel = _v6.a;
		var newCmd = _v6.b;
		var _v7 = A2($author$project$LandingSite$Page$metaDescriptionAndImage, model.as, newPage);
		var metaDescription = _v7.a;
		var metaImageUrl = _v7.b;
		var favicon = _v7.c;
		return _Utils_Tuple2(
			_Utils_update(
				newModel,
				{aa: newPage, aF: newPages}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						pageTransitionCmd,
						maybeScrollCmd,
						$author$project$LandingSite$Main$setPageMeta(
						_Utils_Tuple2(
							_Utils_Tuple2(
								A2($author$project$LandingSite$Page$title, model.as, newPage),
								metaDescription),
							_Utils_Tuple2(metaImageUrl, favicon))),
						newCmd
					])));
	});
var $author$project$LandingSite$Main$transitionTo = F2(
	function (url, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var _v1 = A2($author$project$LandingSite$Main$transitionPage, model, url);
		var newModel = _v1.a;
		var pageCmd = _v1.b;
		return _Utils_Tuple2(
			newModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[pageCmd, cmd])));
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $author$project$LandingSite$Main$init = function (_v0) {
	var path = _v0.eA;
	var configAbiFiles = _v0.dv;
	var configurations = _v0.dx;
	var apiBaseUrlMap = _v0.M;
	var language = _v0.d3;
	var protoDocJson = _v0.ce;
	var targetNetwork = _v0.e1;
	var decodedApiBaseUrlMap = A2(
		$elm$core$Result$withDefault,
		$elm$core$Dict$empty,
		A2(
			$elm$json$Json$Decode$decodeValue,
			$elm$json$Json$Decode$dict($elm$json$Json$Decode$string),
			apiBaseUrlMap));
	var configs = A2(
		$elm$core$Result$withDefault,
		$elm$core$Dict$empty,
		A2($elm$json$Json$Decode$decodeValue, $author$project$Eth$Config$basicConfigDecoder, configurations));
	var _v1 = $author$project$Preferences$preferencesInit(
		$author$project$Strings$Translations$getLnFromCode(language));
	var initPreferences = _v1.a;
	return A2(
		$author$project$LandingSite$Main$transitionTo,
		$elm$url$Url$fromString(path),
		_Utils_Tuple2(
			{
				M: decodedApiBaseUrlMap,
				fI: configAbiFiles,
				dw: configs,
				az: $elm$core$Maybe$Nothing,
				be: $elm$time$Time$utc,
				cM: _List_Nil,
				bj: $author$project$Governance$Home$emptyState,
				aX: $author$project$Governance$ProposalDetail$emptyState,
				bk: $author$project$Governance$ProposalOverview$emptyState,
				bq: $author$project$LandingSite$CommonViews$emptyState,
				aY: $author$project$LandingSite$Comp$emptyState,
				ao: $author$project$LandingSite$Home$emptyState,
				b$: $elm$core$Maybe$Nothing,
				b0: $elm$core$Dict$empty,
				b7: false,
				T: $author$project$CompoundComponents$Eth$Network$networkFromName(targetNetwork),
				aa: $author$project$LandingSite$Page$Home,
				aF: _List_Nil,
				c1: initPreferences,
				ce: protoDocJson,
				as: initPreferences.as
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$LandingSite$Main$Tick, $elm$time$Time$now),
						A2($elm$core$Task$perform, $author$project$LandingSite$Main$SetTimeZone, $elm$time$Time$here)
					]))));
};
var $author$project$LandingSite$Main$UrlChange = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {gX: processes, hl: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.gX;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.hl);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $author$project$LandingSite$Main$LandingHomeMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$LandingSite$Home$translator = F2(
	function (_v0, msg) {
		var onInternalMessage = _v0.gL;
		var internal = msg;
		return onInternalMessage(internal);
	});
var $author$project$LandingSite$Main$landingHomeTranslator = $author$project$LandingSite$Home$translator(
	{gL: $author$project$LandingSite$Main$LandingHomeMsg});
var $elm$core$Platform$Sub$map = _Platform_map;
var $author$project$LandingSite$Main$onUrlChange = _Platform_incomingPort('onUrlChange', $elm$json$Json$Decode$string);
var $author$project$CompoundComponents$Utils$Time$seconds = 1;
var $author$project$LandingSite$Home$ForSelf = $elm$core$Basics$identity;
var $author$project$LandingSite$Home$ProjectsCarouselMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Bootstrap$Carousel$Hovered = 0;
var $author$project$Bootstrap$Carousel$Next = {$: 0};
var $author$project$Bootstrap$Carousel$SetAnimating = {$: 3};
var $author$project$Bootstrap$Carousel$StartTransition = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $elm$core$Basics$not = _Basics_not;
var $elm$browser$Browser$AnimationManager$Time = function (a) {
	return {$: 0, a: a};
};
var $elm$browser$Browser$AnimationManager$State = F3(
	function (subs, request, oldTime) {
		return {em: oldTime, g4: request, hi: subs};
	});
var $elm$browser$Browser$AnimationManager$init = $elm$core$Task$succeed(
	A3($elm$browser$Browser$AnimationManager$State, _List_Nil, $elm$core$Maybe$Nothing, 0));
var $elm$browser$Browser$AnimationManager$now = _Browser_now(0);
var $elm$browser$Browser$AnimationManager$rAF = _Browser_rAF(0);
var $elm$browser$Browser$AnimationManager$onEffects = F3(
	function (router, subs, _v0) {
		var request = _v0.g4;
		var oldTime = _v0.em;
		var _v1 = _Utils_Tuple2(request, subs);
		if (_v1.a.$ === 1) {
			if (!_v1.b.b) {
				var _v2 = _v1.a;
				return $elm$browser$Browser$AnimationManager$init;
			} else {
				var _v4 = _v1.a;
				return A2(
					$elm$core$Task$andThen,
					function (pid) {
						return A2(
							$elm$core$Task$andThen,
							function (time) {
								return $elm$core$Task$succeed(
									A3(
										$elm$browser$Browser$AnimationManager$State,
										subs,
										$elm$core$Maybe$Just(pid),
										time));
							},
							$elm$browser$Browser$AnimationManager$now);
					},
					$elm$core$Process$spawn(
						A2(
							$elm$core$Task$andThen,
							$elm$core$Platform$sendToSelf(router),
							$elm$browser$Browser$AnimationManager$rAF)));
			}
		} else {
			if (!_v1.b.b) {
				var pid = _v1.a.a;
				return A2(
					$elm$core$Task$andThen,
					function (_v3) {
						return $elm$browser$Browser$AnimationManager$init;
					},
					$elm$core$Process$kill(pid));
			} else {
				return $elm$core$Task$succeed(
					A3($elm$browser$Browser$AnimationManager$State, subs, request, oldTime));
			}
		}
	});
var $elm$browser$Browser$AnimationManager$onSelfMsg = F3(
	function (router, newTime, _v0) {
		var subs = _v0.hi;
		var oldTime = _v0.em;
		var send = function (sub) {
			if (!sub.$) {
				var tagger = sub.a;
				return A2(
					$elm$core$Platform$sendToApp,
					router,
					tagger(
						$elm$time$Time$millisToPosix(newTime)));
			} else {
				var tagger = sub.a;
				return A2(
					$elm$core$Platform$sendToApp,
					router,
					tagger(newTime - oldTime));
			}
		};
		return A2(
			$elm$core$Task$andThen,
			function (pid) {
				return A2(
					$elm$core$Task$andThen,
					function (_v1) {
						return $elm$core$Task$succeed(
							A3(
								$elm$browser$Browser$AnimationManager$State,
								subs,
								$elm$core$Maybe$Just(pid),
								newTime));
					},
					$elm$core$Task$sequence(
						A2($elm$core$List$map, send, subs)));
			},
			$elm$core$Process$spawn(
				A2(
					$elm$core$Task$andThen,
					$elm$core$Platform$sendToSelf(router),
					$elm$browser$Browser$AnimationManager$rAF)));
	});
var $elm$browser$Browser$AnimationManager$Delta = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$AnimationManager$subMap = F2(
	function (func, sub) {
		if (!sub.$) {
			var tagger = sub.a;
			return $elm$browser$Browser$AnimationManager$Time(
				A2($elm$core$Basics$composeL, func, tagger));
		} else {
			var tagger = sub.a;
			return $elm$browser$Browser$AnimationManager$Delta(
				A2($elm$core$Basics$composeL, func, tagger));
		}
	});
_Platform_effectManagers['Browser.AnimationManager'] = _Platform_createManager($elm$browser$Browser$AnimationManager$init, $elm$browser$Browser$AnimationManager$onEffects, $elm$browser$Browser$AnimationManager$onSelfMsg, 0, $elm$browser$Browser$AnimationManager$subMap);
var $elm$browser$Browser$AnimationManager$subscription = _Platform_leaf('Browser.AnimationManager');
var $elm$browser$Browser$AnimationManager$onAnimationFrame = function (tagger) {
	return $elm$browser$Browser$AnimationManager$subscription(
		$elm$browser$Browser$AnimationManager$Time(tagger));
};
var $elm$browser$Browser$Events$onAnimationFrame = $elm$browser$Browser$AnimationManager$onAnimationFrame;
var $author$project$Bootstrap$Carousel$subscriptions = F2(
	function (model, toMsg) {
		switch (model.a.$) {
			case 2:
				var _v1 = model.a;
				var interval = model.b.bn;
				var cycling = model.b.ag;
				var wrap = model.b.a9;
				var currentIndex = model.b.aS;
				var hovering = model.b.bY;
				var size = model.b.a3;
				var atEnd = (!wrap) && _Utils_eq(currentIndex, size - 1);
				return ((cycling === 1) && ((!(!hovering)) && ((!(!interval)) && (!atEnd)))) ? A2(
					$elm$time$Time$every,
					interval,
					function (_v2) {
						return toMsg(
							$author$project$Bootstrap$Carousel$StartTransition($author$project$Bootstrap$Carousel$Next));
					}) : $elm$core$Platform$Sub$none;
			case 0:
				var transition = model.a.a;
				return $elm$browser$Browser$Events$onAnimationFrame(
					function (_v3) {
						return toMsg($author$project$Bootstrap$Carousel$SetAnimating);
					});
			default:
				var transition = model.a.a;
				return $elm$core$Platform$Sub$none;
		}
	});
var $author$project$LandingSite$Home$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2(
				$author$project$Bootstrap$Carousel$subscriptions,
				model.aI,
				A2($elm$core$Basics$composeL, $elm$core$Basics$identity, $author$project$LandingSite$Home$ProjectsCarouselMsg))
			]));
};
var $author$project$LandingSite$Main$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, (1000.0 * 1.0) * $author$project$CompoundComponents$Utils$Time$seconds, $author$project$LandingSite$Main$Tick),
				$author$project$LandingSite$Main$onUrlChange(
				A2($elm$core$Basics$composeR, $elm$url$Url$fromString, $author$project$LandingSite$Main$UrlChange)),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$LandingSite$Main$landingHomeTranslator,
				$author$project$LandingSite$Home$subscriptions(model.ao))
			]));
};
var $author$project$LandingSite$Main$WrappedPreferencesMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$CompoundComponents$Console$log = _Platform_outgoingPort('log', $elm$json$Json$Encode$string);
var $author$project$CompoundComponents$Console$error = function (err) {
	return $author$project$CompoundComponents$Console$log('Error: ' + err);
};
var $author$project$LandingSite$Main$LandingCommonMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$LandingSite$Main$LandingCommonParentMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$LandingSite$CommonViews$translator = F2(
	function (_v0, msg) {
		var onInternalMessage = _v0.gL;
		var onParentMsg = _v0.gM;
		if (!msg.$) {
			var internal = msg.a;
			return onInternalMessage(internal);
		} else {
			var parentMsg = msg.a;
			return onParentMsg(parentMsg);
		}
	});
var $author$project$LandingSite$Main$landingCommonTranslator = $author$project$LandingSite$CommonViews$translator(
	{gL: $author$project$LandingSite$Main$LandingCommonMsg, gM: $author$project$LandingSite$Main$LandingCommonParentMsg});
var $author$project$LandingSite$Comp$translator = F2(
	function (_v0, msg) {
		var onInternalMessage = _v0.gL;
		var internal = msg;
		return onInternalMessage(internal);
	});
var $author$project$LandingSite$Main$landingCompTranslator = $author$project$LandingSite$Comp$translator(
	{gL: $author$project$LandingSite$Main$LandingCompMsg});
var $author$project$LandingSite$Main$AllCTokensLatestStatsResponse = function (a) {
	return {$: 4, a: a};
};
var $author$project$LandingSite$Main$V3TotalSummaryResponse = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var $author$project$V3Api$Url$buildTotalSummaryUrl = F3(
	function (apiBaseUrlMap, network, v3Market) {
		var v3MarketAddress = function () {
			switch (network) {
				case 1:
					switch (v3Market) {
						case 0:
							return '0xc3d688B66703497DAA19211EEdff47f25384cdc3';
						case 3:
							return '0xa17581a9e3356d9a858b789d68b4d866e593ae94';
						default:
							return 'unsupported-market';
					}
				case 5:
					switch (v3Market) {
						case 0:
							return '0x3ee77595a8459e93c2888b13adb354017b198188';
						case 3:
							return '0x9a539eec489aaa03d588212a164d0abdb5f08f5f';
						default:
							return 'unsupported-market';
					}
				case 12:
					if (!v3Market) {
						return '0xf25212e676d1f7f89cd72ffee66158f541246445';
					} else {
						return 'unsupported-market';
					}
				case 14:
					if (v3Market === 1) {
						return '0xA5EDBDD9646f8dFF606d7448e414884C7d905dCA';
					} else {
						return 'unsupported-market';
					}
				case 18:
					switch (v3Market) {
						case 2:
							return '0x9c4ec768c28520B50860ea7a15bd7213a9fF58bf';
						case 3:
							return '0x46e6b214b524310239732D51387075E0e70970bf';
						default:
							return 'unsupported-market';
					}
				default:
					return 'unsupported-market';
			}
		}();
		return A2(
			$elm$core$Maybe$map,
			function (apiBaseUrl) {
				return _Utils_ap(
					apiBaseUrl,
					A2(
						$elm$url$Url$Builder$relative,
						_List_fromArray(
							[
								'market',
								$elm$core$String$toLower(
								$author$project$V3Api$Decoders$v3NetworkName(network)),
								v3MarketAddress,
								'summary'
							]),
						_List_Nil));
			},
			A2($elm$core$Dict$get, 'v3_api', apiBaseUrlMap));
	});
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$CompoundApi$Presidio$CTokens$Urls$cTokenRequestUrl = F3(
	function (apiBaseUrlMap, network, requestOptions) {
		var queryMeta = _List_fromArray(
			[
				A2(
				$elm$url$Url$Builder$string,
				'meta',
				A2(
					$elm$json$Json$Encode$encode,
					0,
					$elm$json$Json$Encode$bool(requestOptions.gd)))
			]);
		var queryFinal = function () {
			var _v0 = requestOptions.hX;
			if (!_v0.$) {
				var timestamp = _v0.a;
				return _Utils_ap(
					queryMeta,
					_List_fromArray(
						[
							A2(
							$elm$url$Url$Builder$string,
							'block_timestamp',
							$elm$core$String$fromInt(timestamp))
						]));
			} else {
				return queryMeta;
			}
		}();
		return A2(
			$elm$core$Maybe$map,
			function (apiBaseUrl) {
				return _Utils_ap(
					apiBaseUrl,
					A2(
						$elm$url$Url$Builder$relative,
						_List_fromArray(
							[
								'legacy',
								$elm$core$String$toLower(
								$author$project$CompoundComponents$Eth$Network$networkName(network)),
								'ctokens'
							]),
						queryFinal));
			},
			A2($elm$core$Dict$get, 'v3_api', apiBaseUrlMap));
	});
var $author$project$CompoundApi$Presidio$CTokens$Models$CTokenResponse = function (cToken) {
	return {h4: cToken};
};
var $author$project$CompoundApi$Presidio$CTokens$Models$CToken = function (borrow_rate) {
	return function (borrow_cap) {
		return function (collateral_factor) {
			return function (exchange_rate) {
				return function (name) {
					return function (reserves) {
						return function (reserve_factor) {
							return function (supply_rate) {
								return function (symbol) {
									return function (token_address) {
										return function (total_borrows) {
											return function (total_supply) {
												return function (underlying_address) {
													return function (underlying_name) {
														return function (underlying_price) {
															return function (underlying_symbol) {
																return function (comp_supply_apy) {
																	return function (comp_borrow_apy) {
																		return function (total_supply_value_in_eth) {
																			return function (total_borrow_value_in_eth) {
																				return function (underlying_borrow_cap_in_eth) {
																					return {h_: borrow_cap, h$: borrow_rate, ic: collateral_factor, $8: comp_borrow_apy, ii: comp_supply_apy, iD: exchange_rate, i6: name, jB: reserve_factor, jC: reserves, jZ: supply_rate, j0: symbol, ho: token_address, kc: total_borrow_value_in_eth, kd: total_borrows, kh: total_supply, ki: total_supply_value_in_eth, km: underlying_address, kn: underlying_borrow_cap_in_eth, ko: underlying_name, fd: underlying_price, kp: underlying_symbol};
																				};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$CompoundComponents$DecoderHelper$andMap = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $cmditch$elm_bigint$BigInt$magnitude = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return _List_Nil;
		case 0:
			var mag = bigInt.a;
			return mag;
		default:
			var mag = bigInt.a;
			return mag;
	}
};
var $cmditch$elm_bigint$BigInt$MagnitudePair = $elm$core$Basics$identity;
var $cmditch$elm_bigint$BigInt$sameSizeRaw = F2(
	function (xs, ys) {
		var _v0 = _Utils_Tuple2(xs, ys);
		if (!_v0.a.b) {
			if (!_v0.b.b) {
				return _List_Nil;
			} else {
				var _v2 = _v0.b;
				var y = _v2.a;
				var ys_ = _v2.b;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(0, y),
					A2($cmditch$elm_bigint$BigInt$sameSizeRaw, _List_Nil, ys_));
			}
		} else {
			if (!_v0.b.b) {
				var _v1 = _v0.a;
				var x = _v1.a;
				var xs_ = _v1.b;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(x, 0),
					A2($cmditch$elm_bigint$BigInt$sameSizeRaw, xs_, _List_Nil));
			} else {
				var _v3 = _v0.a;
				var x = _v3.a;
				var xs_ = _v3.b;
				var _v4 = _v0.b;
				var y = _v4.a;
				var ys_ = _v4.b;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(x, y),
					A2($cmditch$elm_bigint$BigInt$sameSizeRaw, xs_, ys_));
			}
		}
	});
var $cmditch$elm_bigint$BigInt$sameSizeNotNormalized = F2(
	function (_v0, _v1) {
		var xs = _v0;
		var ys = _v1;
		return A2($cmditch$elm_bigint$BigInt$sameSizeRaw, xs, ys);
	});
var $cmditch$elm_bigint$BigInt$toPositiveSign = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return A2($cmditch$elm_bigint$BigInt$mkBigIntNotNormalised, 2, _List_Nil);
		case 1:
			var digits = bigInt.a;
			return A2(
				$cmditch$elm_bigint$BigInt$mkBigIntNotNormalised,
				0,
				$cmditch$elm_bigint$BigInt$reverseMagnitude(digits));
		default:
			var digits = bigInt.a;
			return A2($cmditch$elm_bigint$BigInt$mkBigIntNotNormalised, 0, digits);
	}
};
var $cmditch$elm_bigint$BigInt$add = F2(
	function (a, b) {
		var _v0 = $cmditch$elm_bigint$BigInt$toPositiveSign(b);
		var mb = _v0.b;
		var _v1 = $cmditch$elm_bigint$BigInt$toPositiveSign(a);
		var ma = _v1.b;
		var _v2 = A2($cmditch$elm_bigint$BigInt$sameSizeNotNormalized, ma, mb);
		var pairs = _v2;
		var added = A2(
			$elm$core$List$map,
			function (_v3) {
				var a_ = _v3.a;
				var b_ = _v3.b;
				return a_ + b_;
			},
			pairs);
		return $cmditch$elm_bigint$BigInt$normalise(
			A2($cmditch$elm_bigint$BigInt$BigIntNotNormalised, 0, added));
	});
var $cmditch$elm_bigint$BigInt$mulSingleDigit = F2(
	function (_v0, d) {
		var xs = _v0;
		return $cmditch$elm_bigint$BigInt$normaliseMagnitude(
			A2(
				$elm$core$List$map,
				$elm$core$Basics$mul(d),
				xs));
	});
var $cmditch$elm_bigint$BigInt$mulMagnitudes = F2(
	function (_v0, _v1) {
		var mag1 = _v0;
		var mag2 = _v1;
		if (!mag1.b) {
			return _List_Nil;
		} else {
			if (!mag1.b.b) {
				var m = mag1.a;
				return A2($cmditch$elm_bigint$BigInt$mulSingleDigit, mag2, m);
			} else {
				var m = mag1.a;
				var mx = mag1.b;
				var accum = A2($cmditch$elm_bigint$BigInt$mulSingleDigit, mag2, m);
				var _v3 = A2($cmditch$elm_bigint$BigInt$mulMagnitudes, mx, mag2);
				var rest = _v3;
				var bigInt = A2(
					$cmditch$elm_bigint$BigInt$add,
					A2($cmditch$elm_bigint$BigInt$mkBigInt, 0, accum),
					A2(
						$cmditch$elm_bigint$BigInt$mkBigInt,
						0,
						A2($elm$core$List$cons, 0, rest)));
				return $cmditch$elm_bigint$BigInt$magnitude(bigInt);
			}
		}
	});
var $cmditch$elm_bigint$BigInt$sign = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return 2;
		case 0:
			return 0;
		default:
			return 1;
	}
};
var $cmditch$elm_bigint$BigInt$signProduct = F2(
	function (x, y) {
		return ((x === 2) || (y === 2)) ? 2 : (_Utils_eq(x, y) ? 0 : 1);
	});
var $cmditch$elm_bigint$BigInt$mul = F2(
	function (int1, int2) {
		return A2(
			$cmditch$elm_bigint$BigInt$mkBigInt,
			A2(
				$cmditch$elm_bigint$BigInt$signProduct,
				$cmditch$elm_bigint$BigInt$sign(int1),
				$cmditch$elm_bigint$BigInt$sign(int2)),
			A2(
				$cmditch$elm_bigint$BigInt$mulMagnitudes,
				$cmditch$elm_bigint$BigInt$magnitude(int1),
				$cmditch$elm_bigint$BigInt$magnitude(int2)));
	});
var $torreyatcitty$the_best_decimal$Decimal$mul = F2(
	function (_v0, _v1) {
		var ma = _v0.a;
		var ea = _v0.b;
		var mb = _v1.a;
		var eb = _v1.b;
		return A2(
			$torreyatcitty$the_best_decimal$Decimal$Decimal,
			A2($cmditch$elm_bigint$BigInt$mul, ma, mb),
			ea + eb);
	});
var $author$project$CompoundApi$Presidio$CTokens$Decoders$calculateBorrowCap = function (_v0) {
	var borrow_cap = _v0.h_;
	var underlying_price = _v0.fd;
	return A2($torreyatcitty$the_best_decimal$Decimal$mul, underlying_price, borrow_cap);
};
var $author$project$CompoundApi$Presidio$CTokens$Decoders$calculateTotalBorrow = function (_v0) {
	var total_borrows = _v0.kd;
	var underlying_price = _v0.fd;
	return A2($torreyatcitty$the_best_decimal$Decimal$mul, underlying_price, total_borrows);
};
var $author$project$CompoundApi$Presidio$CTokens$Decoders$calculateTotalSupply = function (_v0) {
	var total_supply = _v0.kh;
	var exchange_rate = _v0.iD;
	var underlying_price = _v0.fd;
	return A2(
		$torreyatcitty$the_best_decimal$Decimal$mul,
		underlying_price,
		A2($torreyatcitty$the_best_decimal$Decimal$mul, exchange_rate, total_supply));
};
var $author$project$CompoundApi$Presidio$CTokens$Decoders$renameAugurToDeprecatedDecoder = function (apiToken) {
	var _v0 = ((apiToken.kp === 'REP') && (apiToken.ko === 'Augur')) ? _Utils_Tuple2('Augur v1 (Deprecated)', apiToken.kp) : _Utils_Tuple2(apiToken.ko, apiToken.kp);
	var underlyingName = _v0.a;
	var underlyingSymbol = _v0.b;
	return $elm$json$Json$Decode$succeed(
		_Utils_update(
			apiToken,
			{ko: underlyingName, kp: underlyingSymbol}));
};
var $author$project$CompoundComponents$Eth$Ethereum$areContractsEqual = F2(
	function (_v0, _v1) {
		var addressA = _v0;
		var addressB = _v1;
		return _Utils_eq(
			$elm$core$String$toLower(addressA),
			$elm$core$String$toLower(addressB));
	});
var $author$project$CompoundApi$Presidio$CTokens$Decoders$renameDaiNameToSaiLegacyDecoder = function (apiToken) {
	var _v0 = (A2($author$project$CompoundComponents$Eth$Ethereum$areContractsEqual, apiToken.ho, '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643') && A2(
		$author$project$CompoundComponents$Eth$Ethereum$areContractsEqual,
		A2($elm$core$Maybe$withDefault, '', apiToken.km),
		'0x6B175474E89094C44Da98b954EedeAC495271d0F')) ? _Utils_Tuple2('Dai', apiToken.kp) : ((A2($author$project$CompoundComponents$Eth$Ethereum$areContractsEqual, apiToken.ho, '0xF5DCe57282A584D2746FaF1593d3121Fcac444dC') && A2(
		$author$project$CompoundComponents$Eth$Ethereum$areContractsEqual,
		A2($elm$core$Maybe$withDefault, '', apiToken.km),
		'0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359')) ? _Utils_Tuple2('Sai (Deprecated)', 'SAI') : _Utils_Tuple2(apiToken.ko, apiToken.kp));
	var underlyingName = _v0.a;
	var underlyingSymbol = _v0.b;
	return $elm$json$Json$Decode$succeed(
		_Utils_update(
			apiToken,
			{ko: underlyingName, kp: underlyingSymbol}));
};
var $author$project$CompoundApi$Presidio$CTokens$Decoders$renameOldWbtcToLegacyDecoder = function (apiToken) {
	var underlyingName = (apiToken.j0 === 'cWBTC') ? 'Wrapped BTC (Legacy)' : apiToken.ko;
	return $elm$json$Json$Decode$succeed(
		_Utils_update(
			apiToken,
			{ko: underlyingName}));
};
var $author$project$CompoundApi$Presidio$CTokens$Decoders$renameUsdtToTetherDecoder = function (apiToken) {
	var _v0 = ((apiToken.kp === 'USDT') && (apiToken.ko === 'USDT')) ? _Utils_Tuple2('Tether', apiToken.kp) : _Utils_Tuple2(apiToken.ko, apiToken.kp);
	var underlyingName = _v0.a;
	var underlyingSymbol = _v0.b;
	return $elm$json$Json$Decode$succeed(
		_Utils_update(
			apiToken,
			{ko: underlyingName, kp: underlyingSymbol}));
};
var $author$project$CompoundApi$Presidio$CTokens$Decoders$cTokenDecoder = function () {
	var addEthValues = function (apiToken) {
		var supplyValue = $author$project$CompoundApi$Presidio$CTokens$Decoders$calculateTotalSupply(apiToken);
		var borrowValue = $author$project$CompoundApi$Presidio$CTokens$Decoders$calculateTotalBorrow(apiToken);
		var borrowCapValue = $author$project$CompoundApi$Presidio$CTokens$Decoders$calculateBorrowCap(apiToken);
		return $elm$json$Json$Decode$succeed(
			_Utils_update(
				apiToken,
				{kc: borrowValue, ki: supplyValue, kn: borrowCapValue}));
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		$author$project$CompoundApi$Presidio$CTokens$Decoders$renameOldWbtcToLegacyDecoder,
		A2(
			$elm$json$Json$Decode$andThen,
			$author$project$CompoundApi$Presidio$CTokens$Decoders$renameAugurToDeprecatedDecoder,
			A2(
				$elm$json$Json$Decode$andThen,
				$author$project$CompoundApi$Presidio$CTokens$Decoders$renameUsdtToTetherDecoder,
				A2(
					$elm$json$Json$Decode$andThen,
					$author$project$CompoundApi$Presidio$CTokens$Decoders$renameDaiNameToSaiLegacyDecoder,
					A2(
						$elm$json$Json$Decode$andThen,
						addEthValues,
						A2(
							$author$project$CompoundComponents$DecoderHelper$andMap,
							$elm$json$Json$Decode$succeed($torreyatcitty$the_best_decimal$Decimal$zero),
							A2(
								$author$project$CompoundComponents$DecoderHelper$andMap,
								$elm$json$Json$Decode$succeed($torreyatcitty$the_best_decimal$Decimal$zero),
								A2(
									$author$project$CompoundComponents$DecoderHelper$andMap,
									$elm$json$Json$Decode$succeed($torreyatcitty$the_best_decimal$Decimal$zero),
									A2(
										$author$project$CompoundComponents$DecoderHelper$andMap,
										A2($elm$json$Json$Decode$field, 'comp_borrow_apy', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
										A2(
											$author$project$CompoundComponents$DecoderHelper$andMap,
											A2($elm$json$Json$Decode$field, 'comp_supply_apy', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
											A2(
												$author$project$CompoundComponents$DecoderHelper$andMap,
												A2($elm$json$Json$Decode$field, 'underlying_symbol', $elm$json$Json$Decode$string),
												A2(
													$author$project$CompoundComponents$DecoderHelper$andMap,
													A2($elm$json$Json$Decode$field, 'underlying_price', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
													A2(
														$author$project$CompoundComponents$DecoderHelper$andMap,
														A2($elm$json$Json$Decode$field, 'underlying_name', $elm$json$Json$Decode$string),
														A2(
															$author$project$CompoundComponents$DecoderHelper$andMap,
															A2(
																$elm$json$Json$Decode$field,
																'underlying_address',
																$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
															A2(
																$author$project$CompoundComponents$DecoderHelper$andMap,
																A2($elm$json$Json$Decode$field, 'total_supply', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
																A2(
																	$author$project$CompoundComponents$DecoderHelper$andMap,
																	A2($elm$json$Json$Decode$field, 'total_borrows', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
																	A2(
																		$author$project$CompoundComponents$DecoderHelper$andMap,
																		A2($elm$json$Json$Decode$field, 'token_address', $elm$json$Json$Decode$string),
																		A2(
																			$author$project$CompoundComponents$DecoderHelper$andMap,
																			A2($elm$json$Json$Decode$field, 'symbol', $elm$json$Json$Decode$string),
																			A2(
																				$author$project$CompoundComponents$DecoderHelper$andMap,
																				A2($elm$json$Json$Decode$field, 'supply_rate', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
																				A2(
																					$author$project$CompoundComponents$DecoderHelper$andMap,
																					A2($elm$json$Json$Decode$field, 'reserve_factor', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
																					A2(
																						$author$project$CompoundComponents$DecoderHelper$andMap,
																						A2($elm$json$Json$Decode$field, 'reserves', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
																						A2(
																							$author$project$CompoundComponents$DecoderHelper$andMap,
																							A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
																							A2(
																								$author$project$CompoundComponents$DecoderHelper$andMap,
																								A2($elm$json$Json$Decode$field, 'exchange_rate', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
																								A2(
																									$author$project$CompoundComponents$DecoderHelper$andMap,
																									A2($elm$json$Json$Decode$field, 'collateral_factor', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
																									A2(
																										$author$project$CompoundComponents$DecoderHelper$andMap,
																										A2($elm$json$Json$Decode$field, 'borrow_cap', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
																										A2(
																											$author$project$CompoundComponents$DecoderHelper$andMap,
																											A2($elm$json$Json$Decode$field, 'borrow_rate', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
																											$elm$json$Json$Decode$succeed($author$project$CompoundApi$Presidio$CTokens$Models$CToken)))))))))))))))))))))))))));
}();
var $author$project$CompoundApi$Presidio$CTokens$Decoders$cTokenResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$CompoundApi$Presidio$CTokens$Models$CTokenResponse,
	A2(
		$elm$json$Json$Decode$field,
		'cToken',
		$elm$json$Json$Decode$list($author$project$CompoundApi$Presidio$CTokens$Decoders$cTokenDecoder)));
var $author$project$V3Api$Models$TotalSummary = F4(
	function (totalCollateralValue, totalBorrowValue, supplyRate, borrowRate) {
		return {hZ: borrowRate, jX: supplyRate, hr: totalBorrowValue, e7: totalCollateralValue};
	});
var $author$project$V3Api$Decoders$totalSummaryDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$V3Api$Models$TotalSummary,
	A2($elm$json$Json$Decode$field, 'total_collateral_value', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
	A2($elm$json$Json$Decode$field, 'total_borrow_value', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
	A2($elm$json$Json$Decode$field, 'supply_apr', $author$project$CompoundComponents$Eth$Decoders$stringDecimal),
	A2($elm$json$Json$Decode$field, 'borrow_apr', $author$project$CompoundComponents$Eth$Decoders$stringDecimal));
var $author$project$LandingSite$Main$loadMarkets = F2(
	function (apiBaseUrlMap, network) {
		var v3MarketSummaryCmds = A2(
			$elm$core$List$map,
			function (_v1) {
				var market = _v1.a;
				var marketNetwork = _v1.b;
				var maybeSummaryUrl = A3($author$project$V3Api$Url$buildTotalSummaryUrl, apiBaseUrlMap, marketNetwork, market);
				return A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$Maybe$map,
						function (summaryUrl) {
							return A2(
								$elm$http$Http$send,
								A2($author$project$LandingSite$Main$V3TotalSummaryResponse, market, marketNetwork),
								A2($elm$http$Http$get, summaryUrl, $author$project$V3Api$Decoders$totalSummaryDecoder));
						},
						maybeSummaryUrl));
			},
			$author$project$V3Api$Models$v3Markets);
		var maybeCTokenLatestUrl = A3(
			$author$project$CompoundApi$Presidio$CTokens$Urls$cTokenRequestUrl,
			apiBaseUrlMap,
			network,
			{hX: $elm$core$Maybe$Nothing, gd: true});
		var cTokensRequestCmds = function () {
			if (!maybeCTokenLatestUrl.$) {
				var cTokenLatestUrl = maybeCTokenLatestUrl.a;
				var cTokenLatestRequest = A2($elm$http$Http$get, cTokenLatestUrl, $author$project$CompoundApi$Presidio$CTokens$Decoders$cTokenResponseDecoder);
				return _List_fromArray(
					[
						A2($elm$http$Http$send, $author$project$LandingSite$Main$AllCTokensLatestStatsResponse, cTokenLatestRequest)
					]);
			} else {
				return _List_fromArray(
					[$elm$core$Platform$Cmd$none]);
			}
		}();
		return $elm$core$Platform$Cmd$batch(
			_Utils_ap(v3MarketSummaryCmds, cTokensRequestCmds));
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$LandingSite$Main$scrollTop = _Platform_outgoingPort(
	'scrollTop',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$LandingSite$Main$maybeScroll = function (page) {
	return $author$project$LandingSite$Main$scrollTop(0);
};
var $author$project$Preferences$askClearPreferencesPort = _Platform_outgoingPort(
	'askClearPreferencesPort',
	function ($) {
		return $elm$json$Json$Encode$object(_List_Nil);
	});
var $author$project$Preferences$askClearPreferences = $author$project$Preferences$askClearPreferencesPort(
	{});
var $author$project$CompoundComponents$DisplayCurrency$displayCurrencyToString = function (displayCurrency) {
	switch (displayCurrency) {
		case 0:
			return 'Ether';
		case 1:
			return 'USD';
		case 2:
			return 'GBP';
		default:
			return 'EUR';
	}
};
var $author$project$Preferences$savedLanguageToString = function (userLanguage) {
	switch (userLanguage) {
		case 0:
			return 'en';
		case 1:
			return 'es';
		case 2:
			return 'zh';
		case 3:
			return 'fr';
		default:
			return 'ko';
	}
};
var $author$project$Preferences$storePreferencesPort = _Platform_outgoingPort(
	'storePreferencesPort',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'borrowPaneOpen',
					$elm$json$Json$Encode$bool($.bc)),
					_Utils_Tuple2(
					'displayCurrency',
					$elm$json$Json$Encode$string($.bf)),
					_Utils_Tuple2(
					'supplyPaneOpen',
					$elm$json$Json$Encode$bool($.bE)),
					_Utils_Tuple2(
					'userLanguage',
					$elm$json$Json$Encode$string($.as))
				]));
	});
var $author$project$Preferences$storePreferences = function (_v0) {
	var displayCurrency = _v0.bf;
	var userLanguage = _v0.as;
	var supplyPaneOpen = _v0.bE;
	var borrowPaneOpen = _v0.bc;
	return $author$project$Preferences$storePreferencesPort(
		{
			bc: borrowPaneOpen,
			bf: $author$project$CompoundComponents$DisplayCurrency$displayCurrencyToString(displayCurrency),
			bE: supplyPaneOpen,
			as: $author$project$Preferences$savedLanguageToString(userLanguage)
		});
};
var $author$project$Preferences$preferencesUpdate = F2(
	function (msg, state) {
		switch (msg.$) {
			case 0:
				var displayCurrency = msg.a;
				var updatedState = _Utils_update(
					state,
					{bf: displayCurrency});
				return _Utils_Tuple2(
					updatedState,
					$author$project$Preferences$storePreferences(updatedState));
			case 1:
				var userLanguage = msg.a;
				var updatedState = _Utils_update(
					state,
					{as: userLanguage});
				return _Utils_Tuple2(
					updatedState,
					$author$project$Preferences$storePreferences(updatedState));
			case 2:
				var isOpen = msg.a;
				var updatedState = _Utils_update(
					state,
					{bE: isOpen});
				return _Utils_Tuple2(
					updatedState,
					$author$project$Preferences$storePreferences(updatedState));
			case 3:
				var isOpen = msg.a;
				var updatedState = _Utils_update(
					state,
					{bc: isOpen});
				return _Utils_Tuple2(
					updatedState,
					$author$project$Preferences$storePreferences(updatedState));
			case 4:
				var preferences = msg.a;
				return _Utils_Tuple2(preferences, $elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(state, $author$project$Preferences$askClearPreferences);
		}
	});
var $author$project$Utils$Http$showError = function (err) {
	switch (err.$) {
		case 0:
			var url = err.a;
			return 'BadUrl: ' + url;
		case 1:
			return 'Timeout';
		case 2:
			return 'NetworkError';
		case 3:
			var resp = err.a;
			return 'BadStatus ' + resp.hg.i2;
		default:
			var info = err.a;
			var resp = err.b;
			return 'BadPayload: ' + (info + ('; ' + resp.hg.i2));
	}
};
var $author$project$Governance$Home$Found = function (a) {
	return {$: 0, a: a};
};
var $author$project$Governance$Home$NotFound = {$: 1};
var $author$project$LandingSite$Port$handleImageError = _Platform_outgoingPort(
	'handleImageError',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'elementId',
					$elm$json$Json$Encode$string($.fQ)),
					_Utils_Tuple2(
					'imgSrc',
					$elm$json$Json$Encode$string($.gc))
				]));
	});
var $author$project$LandingSite$Port$imageError = F2(
	function (elementId, imgSrc) {
		var data = {fQ: elementId, gc: imgSrc};
		return $author$project$LandingSite$Port$handleImageError(data);
	});
var $author$project$Governance$Home$update = F2(
	function (internalMsg, model) {
		switch (internalMsg.$) {
			case 0:
				var result = internalMsg.a;
				if (!result.$) {
					var governanceHistory = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aW: $author$project$Governance$Home$Found(governanceHistory)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var errMsg = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aW: $author$project$Governance$Home$NotFound}),
						$author$project$CompoundComponents$Console$error(
							'Error from Proposals API, ' + $author$project$Utils$Http$showError(errMsg)));
				}
			case 1:
				var result = internalMsg.a;
				if (!result.$) {
					var latestProposalsResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								b_: $author$project$Governance$Home$Found(latestProposalsResponse)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var errMsg = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b_: $author$project$Governance$Home$NotFound}),
						$author$project$CompoundComponents$Console$error(
							'Error from Proposals API, ' + $author$project$Utils$Http$showError(errMsg)));
				}
			case 2:
				var result = internalMsg.a;
				if (!result.$) {
					var topDelegatesResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								cp: $author$project$Governance$Home$Found(topDelegatesResponse)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var errMsg = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{cp: $author$project$Governance$Home$NotFound}),
						$author$project$CompoundComponents$Console$error(
							'Error from Comp Accounts API, ' + $author$project$Utils$Http$showError(errMsg)));
				}
			case 3:
				var elementId = internalMsg.a;
				var imgSrc = internalMsg.b;
				return _Utils_Tuple2(
					model,
					A2($author$project$LandingSite$Port$imageError, elementId, imgSrc));
			default:
				var error = internalMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cM: A2($elm$core$List$cons, error, model.cM)
						}),
					$author$project$CompoundComponents$Console$error(error));
		}
	});
var $author$project$Governance$ProposalDetail$Found = function (a) {
	return {$: 0, a: a};
};
var $author$project$Governance$ProposalDetail$NotFound = {$: 1};
var $author$project$Governance$ProposalDetail$ProposerGovAccountResponse = function (a) {
	return {$: 1, a: a};
};
var $author$project$Governance$ProposalDetail$update = F4(
	function (apiBaseUrlMap, network, internalMsg, model) {
		switch (internalMsg.$) {
			case 0:
				var result = internalMsg.a;
				if (!result.$) {
					var proposalsResponse = result.a;
					var _v2 = $elm$core$List$head(proposalsResponse.gZ);
					if (!_v2.$) {
						var firstProposalDetail = _v2.a;
						var maybeRequestProposerAccountCmd = A2(
							$elm$core$Maybe$withDefault,
							$elm$core$Platform$Cmd$none,
							A2(
								$elm$core$Maybe$map,
								function (requestGovAccountUrl) {
									return A2(
										$elm$http$Http$send,
										$author$project$Governance$ProposalDetail$ProposerGovAccountResponse,
										A2($elm$http$Http$get, requestGovAccountUrl, $author$project$CompoundApi$Governance$AccountService$Decoders$governanceAccountResponseDecoder));
								},
								A3(
									$author$project$CompoundApi$Governance$AccountService$Urls$governanceAccountRequestUrl,
									apiBaseUrlMap,
									network,
									{
										hP: _List_fromArray(
											[firstProposalDetail.jv.hO]),
										jg: $elm$core$Maybe$Nothing,
										jj: 1,
										jk: 1,
										kD: false
									})));
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									ab: $author$project$Governance$ProposalDetail$Found(firstProposalDetail)
								}),
							maybeRequestProposerAccountCmd);
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ab: $author$project$Governance$ProposalDetail$NotFound}),
							$elm$core$Platform$Cmd$none);
					}
				} else {
					var errMsg = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ab: $author$project$Governance$ProposalDetail$NotFound}),
						$author$project$CompoundComponents$Console$error(
							'Error from Gov Proposal Detail API, ' + $author$project$Utils$Http$showError(errMsg)));
				}
			case 1:
				var result = internalMsg.a;
				if (!result.$) {
					var accountResponse = result.a;
					var targetCompAccount = $elm$core$List$head(accountResponse.hK);
					var nextCompAccountState = A2(
						$elm$core$Maybe$withDefault,
						$author$project$Governance$ProposalDetail$NotFound,
						A2($elm$core$Maybe$map, $author$project$Governance$ProposalDetail$Found, targetCompAccount));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{c2: nextCompAccountState}),
						$elm$core$Platform$Cmd$none);
				} else {
					var errMsg = result.a;
					return _Utils_Tuple2(
						model,
						$author$project$CompoundComponents$Console$error(
							'Error from Gov Account Profile API, ' + $author$project$Utils$Http$showError(errMsg)));
				}
			case 2:
				var result = internalMsg.a;
				if (!result.$) {
					var forVoteReceiptsResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bi: $author$project$Governance$ProposalDetail$Found(
									{bJ: forVoteReceiptsResponse.gP.kf, aO: forVoteReceiptsResponse.jr})
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var errMsg = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bi: $author$project$Governance$ProposalDetail$NotFound}),
						$author$project$CompoundComponents$Console$error(
							'Error from For Votes Receipts Proposal Detail API, ' + $author$project$Utils$Http$showError(errMsg)));
				}
			case 3:
				var result = internalMsg.a;
				if (!result.$) {
					var againstVoteReceiptsResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ba: $author$project$Governance$ProposalDetail$Found(
									{bJ: againstVoteReceiptsResponse.gP.kf, aO: againstVoteReceiptsResponse.jr})
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var errMsg = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ba: $author$project$Governance$ProposalDetail$NotFound}),
						$author$project$CompoundComponents$Console$error(
							'Error from Against Votes Receipts Proposal Detail API, ' + $author$project$Utils$Http$showError(errMsg)));
				}
			case 4:
				var showForVotes = internalMsg.a;
				var votesModalContent = function () {
					var _v6 = _Utils_Tuple3(model.ab, model.bi, model.ba);
					if (((!_v6.a.$) && (!_v6.b.$)) && (!_v6.c.$)) {
						var proposalDetail = _v6.a.a;
						var forVotes = _v6.b.a;
						var againstVotes = _v6.c.a;
						return $elm$core$Maybe$Just(
							{
								eC: proposalDetail,
								eM: showForVotes,
								fh: showForVotes ? forVotes : againstVotes
							});
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ch: votesModalContent}),
					$elm$core$Platform$Cmd$none);
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ch: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var elementId = internalMsg.a;
				var imgSrc = internalMsg.b;
				return _Utils_Tuple2(
					model,
					A2($author$project$LandingSite$Port$imageError, elementId, imgSrc));
			default:
				var error = internalMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cM: A2($elm$core$List$cons, error, model.cM)
						}),
					$author$project$CompoundComponents$Console$error(error));
		}
	});
var $author$project$Governance$ProposalOverview$Found = function (a) {
	return {$: 0, a: a};
};
var $author$project$Governance$ProposalOverview$NotFound = {$: 1};
var $author$project$Governance$ProposalOverview$update = F4(
	function (apiBaseUrlMap, network, internalMsg, model) {
		var currentPageNumber = model.bd;
		var proposalsPageMap = model.by;
		switch (internalMsg.$) {
			case 0:
				var result = internalMsg.a;
				if (!result.$) {
					var allProposalsResponse = result.a;
					var proposalMapUpdate = A3($elm$core$Dict$insert, allProposalsResponse.gP.jj, allProposalsResponse.gZ, proposalsPageMap);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								by: proposalMapUpdate,
								bI: $author$project$Governance$ProposalOverview$Found(allProposalsResponse.gP.kf)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var errMsg = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bI: $author$project$Governance$ProposalOverview$NotFound}),
						$author$project$CompoundComponents$Console$error(
							'Error from Proposals API, ' + $author$project$Utils$Http$showError(errMsg)));
				}
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bd: currentPageNumber - 1}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var loadNextPageCmd = _Utils_eq(
					A2($elm$core$Dict$get, currentPageNumber + 1, proposalsPageMap),
					$elm$core$Maybe$Nothing) ? A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$Maybe$map,
						function (requestProposalsUrl) {
							return A2(
								$elm$http$Http$send,
								$author$project$Governance$ProposalOverview$AllProposalsResponse,
								A2($elm$http$Http$get, requestProposalsUrl, $author$project$CompoundApi$Governance$ProposalService$Decoders$proposalsResponseDecoder));
						},
						A3(
							$author$project$CompoundApi$Governance$ProposalService$Urls$proposalsRequestUrl,
							apiBaseUrlMap,
							network,
							{
								jj: currentPageNumber + 1,
								jk: $author$project$Governance$ProposalOverview$desiredPageSize,
								jq: _List_Nil,
								jQ: $elm$core$Maybe$Nothing,
								kC: $elm$core$Maybe$Just(false)
							}))) : $elm$core$Platform$Cmd$none;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bd: currentPageNumber + 1}),
					loadNextPageCmd);
			default:
				var error = internalMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cM: A2($elm$core$List$cons, error, model.cM)
						}),
					$author$project$CompoundComponents$Console$error(error));
		}
	});
var $author$project$LandingSite$CommonViews$update = F2(
	function (msg, model) {
		var dropdownState = msg;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{cY: dropdownState}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Utils$Copy$copy = _Platform_outgoingPort('copy', $elm$json$Json$Encode$string);
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Basics$ge = _Utils_ge;
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $elm$core$Elm$JsArray$unsafeSet = _JsArray_unsafeSet;
var $elm$core$Array$setHelp = F4(
	function (shift, index, value, tree) {
		var pos = $elm$core$Array$bitMask & (index >>> shift);
		var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
		if (!_v0.$) {
			var subTree = _v0.a;
			var newSub = A4($elm$core$Array$setHelp, shift - $elm$core$Array$shiftStep, index, value, subTree);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$SubTree(newSub),
				tree);
		} else {
			var values = _v0.a;
			var newLeaf = A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, values);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$Leaf(newLeaf),
				tree);
		}
	});
var $elm$core$Array$set = F3(
	function (index, value, array) {
		var len = array.a;
		var startShift = array.b;
		var tree = array.c;
		var tail = array.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? array : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			tree,
			A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, tail)) : A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A4($elm$core$Array$setHelp, startShift, index, value, tree),
			tail));
	});
var $author$project$V3Api$Decoders$v3MarketStringMapping = F2(
	function (market, network) {
		var _v0 = _Utils_Tuple2(market, network);
		_v0$6:
		while (true) {
			switch (_v0.a) {
				case 0:
					switch (_v0.b) {
						case 1:
							var _v1 = _v0.a;
							var _v2 = _v0.b;
							return 'USDC:Ethereum';
						case 12:
							var _v5 = _v0.a;
							var _v6 = _v0.b;
							return 'USDC:Polygon';
						default:
							break _v0$6;
					}
				case 1:
					if (_v0.b === 14) {
						var _v7 = _v0.a;
						var _v8 = _v0.b;
						return 'USDC.e:Arbitrum';
					} else {
						break _v0$6;
					}
				case 2:
					if (_v0.b === 18) {
						var _v9 = _v0.a;
						var _v10 = _v0.b;
						return 'USDbC:Base';
					} else {
						break _v0$6;
					}
				default:
					switch (_v0.b) {
						case 1:
							var _v3 = _v0.a;
							var _v4 = _v0.b;
							return 'ETH:Ethereum';
						case 18:
							var _v11 = _v0.a;
							var _v12 = _v0.b;
							return 'ETH:Base';
						default:
							break _v0$6;
					}
			}
		}
		return 'Unknown';
	});
var $author$project$LandingSite$Comp$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var index = msg.a;
				var oldStateAtIndex = A2($elm$core$Array$get, index, model.aU);
				var newFaqAccordianState = function () {
					if (!oldStateAtIndex.$) {
						var oldState = oldStateAtIndex.a;
						return A3($elm$core$Array$set, index, !oldState, model.aU);
					} else {
						return model.aU;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aU: newFaqAccordianState}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var copyText = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Utils$Copy$copy(copyText));
			case 3:
				var result = msg.a;
				if (!result.$) {
					var governanceHistory = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								cV: $elm$core$Maybe$Just(governanceHistory)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var errMsg = result.a;
					return _Utils_Tuple2(
						model,
						$author$project$CompoundComponents$Console$error(
							'Error from Gov History API, ' + $author$project$Utils$Http$showError(errMsg)));
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var compDistributionResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								b6: $elm$core$Maybe$Just(compDistributionResponse)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var errMsg = result.a;
					return _Utils_Tuple2(
						model,
						$author$project$CompoundComponents$Console$error(
							'Error from Gov Account Profile API, ' + $author$project$Utils$Http$showError(errMsg)));
				}
			default:
				var market = msg.a;
				var network = msg.b;
				var result = msg.c;
				if (!result.$) {
					var v3ApiResponse = result.a;
					var updatedV3Summaries = A3(
						$elm$core$Dict$insert,
						A2($author$project$V3Api$Decoders$v3MarketStringMapping, market, network),
						_Utils_Tuple3(market, network, v3ApiResponse),
						model.b0);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b0: updatedV3Summaries}),
						$elm$core$Platform$Cmd$none);
				} else {
					var errMsg = result.a;
					return _Utils_Tuple2(
						model,
						$author$project$CompoundComponents$Console$error(
							'Error from v3 API, ' + $author$project$Utils$Http$showError(errMsg)));
				}
		}
	});
var $author$project$Bootstrap$Carousel$Number = function (a) {
	return {$: 2, a: a};
};
var $author$project$Bootstrap$Carousel$Animating = function (a) {
	return {$: 1, a: a};
};
var $author$project$Bootstrap$Carousel$Start = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$modBy = _Basics_modBy;
var $author$project$Bootstrap$Carousel$nextIndex = F3(
	function (stage, currentIndex, size) {
		var helper = function (transition) {
			switch (transition.$) {
				case 0:
					return A2($elm$core$Basics$modBy, size, currentIndex + 1);
				case 1:
					return A2($elm$core$Basics$modBy, size, currentIndex - 1);
				default:
					var m = transition.a;
					return A2($elm$core$Basics$modBy, size, m);
			}
		};
		switch (stage.$) {
			case 0:
				var transition = stage.a;
				return helper(transition);
			case 1:
				var transition = stage.a;
				return helper(transition);
			default:
				return currentIndex;
		}
	});
var $author$project$Bootstrap$Carousel$update = F2(
	function (message, model) {
		var tstage = model.a;
		var settings = model.b;
		var currentIndex = settings.aS;
		var size = settings.a3;
		switch (message.$) {
			case 1:
				return A2(
					$author$project$Bootstrap$Carousel$State,
					tstage,
					_Utils_update(
						settings,
						{ag: 0}));
			case 0:
				return A2(
					$author$project$Bootstrap$Carousel$State,
					tstage,
					_Utils_update(
						settings,
						{ag: 1}));
			case 5:
				var hovering = message.a;
				return A2(
					$author$project$Bootstrap$Carousel$State,
					tstage,
					_Utils_update(
						settings,
						{bY: hovering}));
			case 2:
				var transition = message.a;
				var newSettings = function () {
					var _v2 = settings.ag;
					if (_v2 === 2) {
						return _Utils_update(
							settings,
							{ag: 1});
					} else {
						return settings;
					}
				}();
				if (tstage.$ === 2) {
					return (!_Utils_eq(
						A3(
							$author$project$Bootstrap$Carousel$nextIndex,
							$author$project$Bootstrap$Carousel$Start(transition),
							currentIndex,
							size),
						currentIndex)) ? A2(
						$author$project$Bootstrap$Carousel$State,
						$author$project$Bootstrap$Carousel$Start(transition),
						newSettings) : A2($author$project$Bootstrap$Carousel$State, tstage, newSettings);
				} else {
					return A2($author$project$Bootstrap$Carousel$State, tstage, newSettings);
				}
			case 3:
				switch (tstage.$) {
					case 2:
						return model;
					case 0:
						var transition = tstage.a;
						return A2(
							$author$project$Bootstrap$Carousel$State,
							$author$project$Bootstrap$Carousel$Animating(transition),
							settings);
					default:
						var transition = tstage.a;
						return A2(
							$author$project$Bootstrap$Carousel$State,
							$author$project$Bootstrap$Carousel$Animating(transition),
							settings);
				}
			default:
				var size_ = message.a;
				if (tstage.$ === 2) {
					return A2(
						$author$project$Bootstrap$Carousel$State,
						$author$project$Bootstrap$Carousel$NotAnimating,
						_Utils_update(
							settings,
							{a3: size_}));
				} else {
					return A2(
						$author$project$Bootstrap$Carousel$State,
						$author$project$Bootstrap$Carousel$NotAnimating,
						_Utils_update(
							settings,
							{
								aS: A3($author$project$Bootstrap$Carousel$nextIndex, tstage, currentIndex, size),
								a3: size
							}));
				}
		}
	});
var $author$project$LandingSite$Home$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var error = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cM: A2($elm$core$List$cons, error, model.cM)
						}),
					$author$project$CompoundComponents$Console$error(error));
			case 1:
				var subMsg = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aI: A2($author$project$Bootstrap$Carousel$update, subMsg, model.aI)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var projectCategory = msg.a;
				var index = function () {
					switch (projectCategory) {
						case 3:
							return 0;
						case 0:
							return 1;
						case 1:
							return 2;
						default:
							return 3;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aI: A2(
								$author$project$Bootstrap$Carousel$update,
								$author$project$Bootstrap$Carousel$StartTransition(
									$author$project$Bootstrap$Carousel$Number(index)),
								model.aI),
							c6: projectCategory
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$LandingSite$Main$update = F2(
	function (msg, model) {
		var page = model.aa;
		var apiBaseUrlMap = model.M;
		var network = model.T;
		switch (msg.$) {
			case 0:
				var location = msg.a;
				return A2($author$project$LandingSite$Main$transitionPage, model, location);
			case 1:
				var time = msg.a;
				var loadMarketsCmd = _Utils_eq(model.az, $elm$core$Maybe$Nothing) ? A2($author$project$LandingSite$Main$loadMarkets, apiBaseUrlMap, network) : $elm$core$Platform$Cmd$none;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							az: $elm$core$Maybe$Just(time)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[loadMarketsCmd])));
			case 2:
				var timeZone = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{be: timeZone}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var preferencesMsg = msg.a;
				var _v1 = A2($author$project$Preferences$preferencesUpdate, preferencesMsg, model.c1);
				var updatedPreferences = _v1.a;
				var preferencesCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c1: updatedPreferences}),
					A2($elm$core$Platform$Cmd$map, $author$project$LandingSite$Main$WrappedPreferencesMsg, preferencesCmd));
			case 4:
				var result = msg.a;
				if (!result.$) {
					var cTokenResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ao: model.ao,
								b$: $elm$core$Maybe$Just(cTokenResponse)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var errMsg = result.a;
					return _Utils_Tuple2(
						model,
						$author$project$CompoundComponents$Console$error(
							'Error from CToken API, ' + $author$project$Utils$Http$showError(errMsg)));
				}
			case 5:
				var market = msg.a;
				var marketNetwork = msg.b;
				var result = msg.c;
				if (!result.$) {
					var v3ApiResponse = result.a;
					var updatedV3Summaries = A3(
						$elm$core$Dict$insert,
						A2($author$project$V3Api$Decoders$v3MarketStringMapping, market, marketNetwork),
						_Utils_Tuple3(market, marketNetwork, v3ApiResponse),
						model.b0);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b0: updatedV3Summaries}),
						$elm$core$Platform$Cmd$none);
				} else {
					var errMsg = result.a;
					return _Utils_Tuple2(
						model,
						$author$project$CompoundComponents$Console$error(
							'Error from v3 API, ' + $author$project$Utils$Http$showError(errMsg)));
				}
			case 6:
				if (!msg.a.$) {
					var newMobileMenuShowing = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b7: newMobileMenuShowing}),
						$elm$core$Platform$Cmd$none);
				} else {
					var newNetwork = msg.a.a;
					return A2(
						$author$project$LandingSite$Main$handlePageUpdates,
						model.aa,
						_Utils_update(
							model,
							{az: $elm$core$Maybe$Nothing, bj: $author$project$Governance$Home$emptyState, aX: $author$project$Governance$ProposalDetail$emptyState, bk: $author$project$Governance$ProposalOverview$emptyState, bq: $author$project$LandingSite$CommonViews$emptyState, aY: $author$project$LandingSite$Comp$emptyState, ao: $author$project$LandingSite$Home$emptyState, b$: $elm$core$Maybe$Nothing, b7: false, T: newNetwork}));
				}
			case 7:
				var landingCommonMsg = msg.a;
				var _v4 = A2($author$project$LandingSite$CommonViews$update, landingCommonMsg, model.bq);
				var updatedLandingCommonModel = _v4.a;
				var landingCommonCmd = _v4.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bq: updatedLandingCommonModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$LandingSite$Main$landingCommonTranslator, landingCommonCmd));
			case 8:
				var landingCompMsg = msg.a;
				var _v5 = A2($author$project$LandingSite$Comp$update, landingCompMsg, model.aY);
				var updatedLandingCompModel = _v5.a;
				var landingCompCmd = _v5.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aY: updatedLandingCompModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$LandingSite$Main$landingCompTranslator, landingCompCmd));
			case 9:
				var landingHomeMsg = msg.a;
				var _v6 = A2($author$project$LandingSite$Home$update, landingHomeMsg, model.ao);
				var updatedlandingHomeModel = _v6.a;
				var landingHomeCmd = _v6.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ao: updatedlandingHomeModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$LandingSite$Main$landingHomeTranslator, landingHomeCmd));
			case 10:
				var homePageMsg = msg.a;
				var _v7 = A2($author$project$Governance$Home$update, homePageMsg, model.bj);
				var updatedGovHomeModel = _v7.a;
				var govHomePageCmd = _v7.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bj: updatedGovHomeModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$LandingSite$Main$GovernanceHomeMsg, govHomePageCmd));
			case 11:
				var proposalDetailPageMsg = msg.a;
				var _v8 = A4($author$project$Governance$ProposalDetail$update, model.M, model.T, proposalDetailPageMsg, model.aX);
				var updatedProposalDetailPageModel = _v8.a;
				var proposalDetailPageCmd = _v8.b;
				var updatePageTitleCmd = function () {
					var _v9 = updatedProposalDetailPageModel.ab;
					if (!_v9.$) {
						var proposal = _v9.a;
						var _v10 = A2($author$project$LandingSite$Page$metaDescriptionAndImage, model.as, page);
						var metaImageUrl = _v10.b;
						var favicon = _v10.c;
						return $author$project$LandingSite$Main$setPageMeta(
							_Utils_Tuple2(
								_Utils_Tuple2(
									A2($author$project$LandingSite$Page$title, model.as, page),
									proposal.j5),
								_Utils_Tuple2(metaImageUrl, favicon)));
					} else {
						return $elm$core$Platform$Cmd$none;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: updatedProposalDetailPageModel}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$LandingSite$Main$GovernanceProposalDetailMsg, proposalDetailPageCmd),
								updatePageTitleCmd
							])));
			case 12:
				var proposalOverviewPageMsg = msg.a;
				var _v11 = A4($author$project$Governance$ProposalOverview$update, model.M, model.T, proposalOverviewPageMsg, model.bk);
				var updatedProposalOverviewModel = _v11.a;
				var proposalOverviewPageCmd = _v11.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bk: updatedProposalOverviewModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$LandingSite$Main$GovernanceProposalOverviewMsg, proposalOverviewPageCmd));
			case 13:
				var page_ = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$LandingSite$Main$maybeScroll(page_));
			default:
				var error = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cM: model.cM}),
					$author$project$CompoundComponents$Console$log(error));
		}
	});
var $elm$html$Html$div = _VirtualDom_node('div');
var $author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id = function (idName) {
	return A2($elm$html$Html$Attributes$attribute, 'id', idName);
};
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $author$project$LandingSite$CommonViews$ForSelf = function (a) {
	return {$: 0, a: a};
};
var $author$project$CompoundComponents$Utils$CompoundHtmlAttributes$PageNavigation = 0;
var $author$project$LandingSite$CommonViews$ToggleNetworkDropdown = $elm$core$Basics$identity;
var $author$project$Strings$Translations$app = function (lang) {
	switch (lang) {
		case 0:
			return 'App';
		case 1:
			return 'Aplicación';
		case 2:
			return 'App';
		case 3:
			return 'App';
		default:
			return '앱';
	}
};
var $author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class = function (className) {
	return A2($elm$html$Html$Attributes$attribute, 'class', className);
};
var $author$project$Strings$Translations$compound_2 = function (lang) {
	switch (lang) {
		case 0:
			return 'Compound II';
		case 1:
			return 'Compuesto II';
		case 2:
			return '化合物Ⅱ';
		case 3:
			return 'Composé II';
		default:
			return '화합물 II';
	}
};
var $author$project$Strings$Translations$compound_3 = function (lang) {
	switch (lang) {
		case 0:
			return 'Compound III';
		case 1:
			return 'Compuesto III';
		case 2:
			return '化合物Ⅲ';
		case 3:
			return 'Composé III';
		default:
			return '화합물 III';
	}
};
var $author$project$Strings$Translations$copyright = function (lang) {
	switch (lang) {
		case 0:
			return '© 2022 Compound Labs, Inc.';
		case 1:
			return '© 2022 Compound Labs, Inc.';
		case 2:
			return '版权©2022 Compound Labs，Inc.';
		case 3:
			return '© 2022 Compound Labs, Inc.';
		default:
			return '© 2022 Compound Labs, Inc.';
	}
};
var $author$project$Strings$Translations$dashboard = function (lang) {
	switch (lang) {
		case 0:
			return 'Dashboard';
		case 1:
			return 'Tablero';
		case 2:
			return '总览';
		case 3:
			return 'Tableau de bord';
		default:
			return '대시보드';
	}
};
var $author$project$Strings$Translations$documentation = function (lang) {
	switch (lang) {
		case 0:
			return 'Documentation';
		case 1:
			return 'Documentación';
		case 2:
			return '文档';
		case 3:
			return 'Documentation';
		default:
			return '선적 서류 비치';
	}
};
var $elm$html$Html$footer = _VirtualDom_node('footer');
var $author$project$Strings$Translations$forums = function (lang) {
	switch (lang) {
		case 0:
			return 'Forums';
		case 1:
			return 'El';
		case 2:
			return '论坛';
		case 3:
			return 'Les forums';
		default:
			return '포럼';
	}
};
var $author$project$LandingSite$Page$getForumsUrl = 'https://www.comp.xyz/';
var $author$project$LandingSite$Page$getLeaderboardUrl = 'https://www.tally.xyz/gov/compound/delegates';
var $author$project$Strings$Translations$governance = function (lang) {
	switch (lang) {
		case 0:
			return 'Governance';
		case 1:
			return 'Gobernanza';
		case 2:
			return '治理';
		case 3:
			return 'La gouvernance';
		default:
			return '거버넌스';
	}
};
var $elm$html$Html$label = _VirtualDom_node('label');
var $author$project$Strings$Translations$leaderboard = function (lang) {
	switch (lang) {
		case 0:
			return 'Leaderboard';
		case 1:
			return 'Tabla de clasificación';
		case 2:
			return '排行榜';
		case 3:
			return 'Classement';
		default:
			return '순위표';
	}
};
var $author$project$Strings$Translations$markets = function (lang) {
	switch (lang) {
		case 0:
			return 'Markets';
		case 1:
			return 'Mercados';
		case 2:
			return '市场';
		case 3:
			return 'Marchés';
		default:
			return '마켓';
	}
};
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Strings$Translations$proposals = function (lang) {
	switch (lang) {
		case 0:
			return 'Proposals';
		case 1:
			return 'Propuestas';
		case 2:
			return '提案';
		case 3:
			return 'Les propositions';
		default:
			return '제안서';
	}
};
var $author$project$Strings$Translations$security = function (lang) {
	switch (lang) {
		case 0:
			return 'Security';
		case 1:
			return 'Seguridad';
		case 2:
			return '安全';
		case 3:
			return 'Sécurité';
		default:
			return '보안';
	}
};
var $author$project$Utils$AppLinksHelper$v2AppHref = A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://v2-app.compound.finance');
var $author$project$Utils$AppLinksHelper$v2DocsHref = A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://docs.compound.finance/v2');
var $author$project$Utils$AppLinksHelper$v2MarketsHref = A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://app.compound.finance/markets?market=v2');
var $author$project$Utils$AppLinksHelper$v2SecurityHref = A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://docs.compound.finance/v2/security');
var $author$project$Utils$AppLinksHelper$v3AppHref = A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://app.compound.finance/?market=usdc-mainnet');
var $author$project$Utils$AppLinksHelper$v3DocsHref = A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://docs.compound.finance');
var $author$project$Utils$AppLinksHelper$v3MarketsHref = A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://app.compound.finance/markets?market=usdc-mainnet');
var $author$project$Utils$AppLinksHelper$v3SecurityHref = A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://docs.compound.finance/#security');
var $author$project$LandingSite$CommonViews$pageFooter = F3(
	function (userLanguage, _v0, model) {
		var languageSelectorCover = model.cY ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('cover clear active'),
					$elm$html$Html$Events$onClick(
					$author$project$LandingSite$CommonViews$ForSelf(false))
				]),
			_List_Nil) : $elm$html$Html$text('');
		return A2(
			$elm$html$Html$footer,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('footer'),
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('landing')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('container-large')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('row top')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-2 logo mobile-hide')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$a,
											A2(
												$elm$core$List$append,
												A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 0, '/'),
												_List_fromArray(
													[
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('mark')
													])),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-12 logo mobile-only')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$a,
											A2(
												$elm$core$List$append,
												A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 0, '/'),
												_List_fromArray(
													[
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('brand')
													])),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-8 col-xs-12 row links-holder')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-4 col-xs-6 links')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$p,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$compound_2(userLanguage))
														])),
													A2(
													$elm$html$Html$a,
													$author$project$Utils$AppLinksHelper$v2AppHref,
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$app(userLanguage))
														])),
													A2(
													$elm$html$Html$a,
													$author$project$Utils$AppLinksHelper$v2MarketsHref,
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$markets(userLanguage))
														])),
													A2(
													$elm$html$Html$a,
													$author$project$Utils$AppLinksHelper$v2DocsHref,
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$documentation(userLanguage))
														])),
													A2(
													$elm$html$Html$a,
													$author$project$Utils$AppLinksHelper$v2SecurityHref,
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$security(userLanguage))
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-4 col-xs-6 links')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$p,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$compound_3(userLanguage))
														])),
													A2(
													$elm$html$Html$a,
													$author$project$Utils$AppLinksHelper$v3AppHref,
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$app(userLanguage))
														])),
													A2(
													$elm$html$Html$a,
													$author$project$Utils$AppLinksHelper$v3MarketsHref,
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$markets(userLanguage))
														])),
													A2(
													$elm$html$Html$a,
													$author$project$Utils$AppLinksHelper$v3DocsHref,
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$documentation(userLanguage))
														])),
													A2(
													$elm$html$Html$a,
													$author$project$Utils$AppLinksHelper$v3SecurityHref,
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$security(userLanguage))
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-4 col-xs-6 links')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$p,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$governance(userLanguage))
														])),
													A2(
													$elm$html$Html$a,
													A2(
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href,
														0,
														$author$project$LandingSite$Page$getHrefUrl($author$project$LandingSite$Page$GovernanceHome)),
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$dashboard(userLanguage))
														])),
													A2(
													$elm$html$Html$a,
													A2(
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href,
														0,
														$author$project$LandingSite$Page$getHrefUrl($author$project$LandingSite$Page$GovernanceProposalOverview)),
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$proposals(userLanguage))
														])),
													A2(
													$elm$html$Html$a,
													A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, $author$project$LandingSite$Page$getForumsUrl),
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$forums(userLanguage))
														])),
													A2(
													$elm$html$Html$a,
													A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, $author$project$LandingSite$Page$getLeaderboardUrl),
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$leaderboard(userLanguage))
														]))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('mobile-hide col-xs-2 text-right')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$a,
											A2(
												$elm$core$List$cons,
												$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('dapp button'),
												A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://app.compound.finance/')),
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$Strings$Translations$app(userLanguage))
												]))
										]))
								])),
							languageSelectorCover,
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('bottom')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$label,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('small')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											$author$project$Strings$Translations$copyright(userLanguage))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('social')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$a,
											_Utils_ap(
												_List_fromArray(
													[
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('icn discord'),
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank')
													]),
												A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://compound.finance/discord')),
											_List_Nil),
											A2(
											$elm$html$Html$a,
											_Utils_ap(
												_List_fromArray(
													[
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('icn github'),
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank')
													]),
												A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://github.com/compound-finance/compound-protocol')),
											_List_Nil),
											A2(
											$elm$html$Html$a,
											_Utils_ap(
												_List_fromArray(
													[
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('icn medium'),
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank')
													]),
												A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://medium.com/compound-finance')),
											_List_Nil),
											A2(
											$elm$html$Html$a,
											_Utils_ap(
												_List_fromArray(
													[
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('icn twitter'),
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank')
													]),
												A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://twitter.com/compoundfinance')),
											_List_Nil)
										]))
								]))
						]))
				]));
	});
var $author$project$LandingSite$CommonViews$ForParent = function (a) {
	return {$: 1, a: a};
};
var $author$project$LandingSite$CommonViews$ToggleMobileNavMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Strings$Translations$docs = function (lang) {
	switch (lang) {
		case 0:
			return 'Docs';
		case 1:
			return 'Docs';
		case 2:
			return '文档';
		case 3:
			return 'Documents';
		default:
			return '문서';
	}
};
var $elm$html$Html$header = _VirtualDom_node('header');
var $author$project$Strings$Translations$home = function (lang) {
	switch (lang) {
		case 0:
			return 'Home';
		case 1:
			return 'Casa';
		case 2:
			return '主页';
		case 3:
			return 'Accueil';
		default:
			return '홈페이지';
	}
};
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$LandingSite$CommonViews$pageHeader = F3(
	function (userLanguage, page, shouldShowMobileMenu) {
		var mobileHeaderClass = shouldShowMobileMenu ? 'active' : '';
		var headerClass = function () {
			switch (page.$) {
				case 1:
					return '';
				case 2:
					return '';
				case 3:
					return '';
				case 4:
					return '';
				default:
					return '';
			}
		}();
		var _v0 = function () {
			var emptyClasses = {bW: '', gr: ''};
			switch (page.$) {
				case 2:
					return _Utils_update(
						emptyClasses,
						{bW: 'active'});
				case 3:
					return _Utils_update(
						emptyClasses,
						{bW: 'active'});
				case 4:
					return _Utils_update(
						emptyClasses,
						{bW: 'active'});
				default:
					return emptyClasses;
			}
		}();
		var marketsClass = _v0.gr;
		var governanceClass = _v0.bW;
		var _v2 = _Utils_Tuple2(
			'https://app.compound.finance/',
			$author$project$Strings$Translations$app(userLanguage));
		var appButtonLink = _v2.a;
		var appButtonText = _v2.b;
		return A2(
			$elm$html$Html$header,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('header'),
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('landing ' + headerClass)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('container-large')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('row align-middle')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-3 col-xs-10 logo')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$a,
											A2(
												$elm$core$List$append,
												A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 0, '/'),
												_List_fromArray(
													[
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('brand'),
														$elm$html$Html$Events$onClick(
														$author$project$LandingSite$CommonViews$ForParent(
															$author$project$LandingSite$CommonViews$ToggleMobileNavMsg(false)))
													])),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('mobile-hide col-xs-6 text-center links')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$a,
											A2(
												$elm$core$List$cons,
												$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class(marketsClass),
												$author$project$Utils$AppLinksHelper$v3MarketsHref),
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$Strings$Translations$markets(userLanguage))
												])),
											A2(
											$elm$html$Html$a,
											A2(
												$elm$core$List$cons,
												$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class(governanceClass),
												A2(
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href,
													0,
													$author$project$LandingSite$Page$getHrefUrl($author$project$LandingSite$Page$GovernanceHome))),
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$Strings$Translations$governance(userLanguage))
												])),
											A2(
											$elm$html$Html$a,
											A2(
												$elm$core$List$cons,
												$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class(marketsClass),
												$author$project$Utils$AppLinksHelper$v3DocsHref),
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$Strings$Translations$docs(userLanguage))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('mobile-hide col-xs-3 text-right')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$a,
											A2(
												$elm$core$List$cons,
												$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('dapp button hollow'),
												A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, appButtonLink)),
											_List_fromArray(
												[
													$elm$html$Html$text(appButtonText)
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('mobile-only col-xs-2 hamburger ' + mobileHeaderClass),
											$elm$html$Html$Events$onClick(
											$author$project$LandingSite$CommonViews$ForParent(
												$author$project$LandingSite$CommonViews$ToggleMobileNavMsg(!shouldShowMobileMenu)))
										]),
									_List_fromArray(
										[
											A2($elm$html$Html$span, _List_Nil, _List_Nil),
											A2($elm$html$Html$span, _List_Nil, _List_Nil),
											A2($elm$html$Html$span, _List_Nil, _List_Nil)
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('mobile-header ' + (mobileHeaderClass + (' ' + headerClass)))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('links')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$ul,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$a,
											A2(
												$elm$core$List$cons,
												$elm$html$Html$Events$onClick(
													$author$project$LandingSite$CommonViews$ForParent(
														$author$project$LandingSite$CommonViews$ToggleMobileNavMsg(false))),
												A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 0, '/')),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$li,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$p,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$home(userLanguage))
																]))
														]))
												])),
											A2(
											$elm$html$Html$a,
											A2(
												$elm$core$List$cons,
												$elm$html$Html$Events$onClick(
													$author$project$LandingSite$CommonViews$ForParent(
														$author$project$LandingSite$CommonViews$ToggleMobileNavMsg(false))),
												$author$project$Utils$AppLinksHelper$v3MarketsHref),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$li,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$p,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$markets(userLanguage))
																]))
														]))
												])),
											A2(
											$elm$html$Html$a,
											A2(
												$elm$core$List$cons,
												$elm$html$Html$Events$onClick(
													$author$project$LandingSite$CommonViews$ForParent(
														$author$project$LandingSite$CommonViews$ToggleMobileNavMsg(false))),
												A2(
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href,
													0,
													$author$project$LandingSite$Page$getHrefUrl($author$project$LandingSite$Page$GovernanceHome))),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$li,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$p,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$governance(userLanguage))
																]))
														]))
												])),
											A2(
											$elm$html$Html$a,
											A2(
												$elm$core$List$cons,
												$elm$html$Html$Events$onClick(
													$author$project$LandingSite$CommonViews$ForParent(
														$author$project$LandingSite$CommonViews$ToggleMobileNavMsg(false))),
												$author$project$Utils$AppLinksHelper$v3DocsHref),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$li,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$p,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$docs(userLanguage))
																]))
														]))
												]))
										]))
								])),
							A2(
							$elm$html$Html$a,
							A2(
								$elm$core$List$cons,
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('dapp button hollow'),
								A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, appButtonLink)),
							_List_fromArray(
								[
									$elm$html$Html$text(appButtonText)
								]))
						]))
				]));
	});
var $author$project$Governance$Home$translator = F2(
	function (_v0, msg) {
		var onInternalMessage = _v0.gL;
		var internal = msg;
		return onInternalMessage(internal);
	});
var $author$project$LandingSite$Main$governanceHomeTranslator = $author$project$Governance$Home$translator(
	{gL: $author$project$LandingSite$Main$GovernanceHomeMsg});
var $author$project$Governance$ProposalDetail$translator = F2(
	function (_v0, msg) {
		var onInternalMessage = _v0.gL;
		var internal = msg;
		return onInternalMessage(internal);
	});
var $author$project$LandingSite$Main$proposalDetailTranslator = $author$project$Governance$ProposalDetail$translator(
	{gL: $author$project$LandingSite$Main$GovernanceProposalDetailMsg});
var $author$project$Governance$ProposalOverview$translator = F2(
	function (_v0, msg) {
		var onInternalMessage = _v0.gL;
		var internal = msg;
		return onInternalMessage(internal);
	});
var $author$project$LandingSite$Main$proposalOverviewTranslator = $author$project$Governance$ProposalOverview$translator(
	{gL: $author$project$LandingSite$Main$GovernanceProposalOverviewMsg});
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Strings$Translations$compound_governance = function (lang) {
	switch (lang) {
		case 0:
			return 'Compound Governance';
		case 1:
			return 'Gobierno compuesto';
		case 2:
			return 'Compound 治理';
		case 3:
			return 'Gouvernance Compounde';
		default:
			return 'Compound 거버넌스';
	}
};
var $author$project$Strings$Translations$compound_governance_overview = function (lang) {
	switch (lang) {
		case 0:
			return 'Compound is managed by a decentralized community of COMP token-holders and their delegates, who propose and vote on upgrades to the protocol.';
		case 1:
			return 'El compuesto es administrado por una comunidad descentralizada de poseedores de tokens COMP y sus delegados, quienes proponen y votan sobre las actualizaciones del protocolo.';
		case 2:
			return 'Compound由COMP token持有人及其代表的去中心化社区管理，他们对协议的升级提出建议并进行投票。';
		case 3:
			return 'Compound est géré par une communauté décentralisée de détenteurs de jetons COMP et de leurs délégués, qui proposent et votent les mises à niveau du protocole.';
		default:
			return 'Compound는 COMP 토큰 보유자와 그들의 대리인으로 구성된 탈중앙화된 커뮤니티에 의해 운영되며, 이들은 프로토콜 업그레이드를 제안하고 투표에 참여합니다.';
	}
};
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $author$project$Strings$Translations$learn_more = function (lang) {
	switch (lang) {
		case 0:
			return 'Learn more';
		case 1:
			return 'Aprende más';
		case 2:
			return '学到更多';
		case 3:
			return 'Apprendre encore plus';
		default:
			return '자세히 알아보기';
	}
};
var $author$project$Governance$Home$educationalOverviewContent = function (userLanguage) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('legacy-panel')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('educational-overview')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('gov-description'),
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-6')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h4,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Strings$Translations$compound_governance(userLanguage))
									])),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Strings$Translations$compound_governance_overview(userLanguage))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('call-to-action')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$a,
										A2(
											$elm$core$List$cons,
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank'),
											A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://medium.com/compound-finance/compound-governance-5531f524cf68')),
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$Strings$Translations$learn_more(userLanguage))
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('arrow'),
												$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('arrow')
											]),
										_List_Nil)
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('gov-image'),
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-6')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('gov-illo')
									]),
								_List_Nil)
							]))
					]))
			]));
};
var $author$project$Strings$Translations$governance_overview = function (lang) {
	switch (lang) {
		case 0:
			return 'Governance Overview';
		case 1:
			return 'Resumen de gobernanza';
		case 2:
			return '治理概况';
		case 3:
			return 'Aperçu de la gouvernance';
		default:
			return '거버넌스 개요';
	}
};
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $author$project$Governance$CommonViews$loadingProposalRow = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal proposal--loading')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content__description')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content__description__title proposal__content__description__title--loading')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content__description__details proposal__content__description__details--loading')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content__description__details__tag proposal__content__description__details__tag--loading')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content__description__details__text')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content__description__details__text__time--loading')
												]),
											_List_Nil)
										]))
								]))
						]))
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__current-state-view')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__current-state-view__state proposal__current-state-view__state--loading')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__current-state-view__state__text--loading')
								]),
							_List_Nil)
						]))
				]))
		]));
var $author$project$Strings$Translations$no_active_proposals = function (lang) {
	switch (lang) {
		case 0:
			return 'No proposals have been created.';
		case 1:
			return 'No se han creado propuestas.';
		case 2:
			return '尚未创建提案。';
		case 3:
			return 'Aucune proposition n\'a été créée.';
		default:
			return '신규 제안서가 없습니다.';
	}
};
var $author$project$Governance$CommonViews$noProposalsRow = function (userLanguage) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal proposal--empty')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Strings$Translations$no_active_proposals(userLanguage))
					]))
			]));
};
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $torreyatcitty$the_best_decimal$Decimal$addDecimals = F2(
	function (i, _v0) {
		addDecimals:
		while (true) {
			var m = _v0.a;
			var e = _v0.b;
			var mul10 = function (x) {
				return A2(
					$cmditch$elm_bigint$BigInt$mul,
					x,
					$cmditch$elm_bigint$BigInt$fromInt(10));
			};
			if (!i) {
				return A2($torreyatcitty$the_best_decimal$Decimal$Decimal, m, e);
			} else {
				if (i > 0) {
					var $temp$i = i - 1,
						$temp$_v0 = A2(
						$torreyatcitty$the_best_decimal$Decimal$Decimal,
						mul10(m),
						e - 1);
					i = $temp$i;
					_v0 = $temp$_v0;
					continue addDecimals;
				} else {
					return A2($torreyatcitty$the_best_decimal$Decimal$Decimal, m, e);
				}
			}
		}
	});
var $torreyatcitty$the_best_decimal$Decimal$toExponent = F2(
	function (e, _v0) {
		var md = _v0.a;
		var ed = _v0.b;
		return A2(
			$torreyatcitty$the_best_decimal$Decimal$addDecimals,
			ed - e,
			A2($torreyatcitty$the_best_decimal$Decimal$Decimal, md, ed));
	});
var $torreyatcitty$the_best_decimal$Decimal$toCommonExponent = function (_v0) {
	var a = _v0.a;
	var b = _v0.b;
	var _v1 = b;
	var mb = _v1.a;
	var eb = _v1.b;
	var _v2 = a;
	var ma = _v2.a;
	var ea = _v2.b;
	var exponent = A2($elm$core$Basics$min, ea, eb);
	return _Utils_Tuple2(
		A2($torreyatcitty$the_best_decimal$Decimal$toExponent, exponent, a),
		A2($torreyatcitty$the_best_decimal$Decimal$toExponent, exponent, b));
};
var $torreyatcitty$the_best_decimal$Decimal$add = F2(
	function (a, b) {
		var _v0 = $torreyatcitty$the_best_decimal$Decimal$toCommonExponent(
			_Utils_Tuple2(a, b));
		var ra = _v0.a;
		var rb = _v0.b;
		var _v1 = rb;
		var mb = _v1.a;
		var eb = _v1.b;
		var _v2 = ra;
		var ma = _v2.a;
		var ea = _v2.b;
		return A2(
			$torreyatcitty$the_best_decimal$Decimal$Decimal,
			A2($cmditch$elm_bigint$BigInt$add, ma, mb),
			ea);
	});
var $elm$core$String$fromFloat = _String_fromNumber;
var $torreyatcitty$the_best_decimal$Decimal$fromFloat = function (f) {
	return $torreyatcitty$the_best_decimal$Decimal$fromString(
		$elm$core$String$fromFloat(f));
};
var $elm$core$String$toFloat = _String_toFloat;
var $cmditch$elm_bigint$BigInt$compareMagnitude = F4(
	function (x, y, xs, ys) {
		compareMagnitude:
		while (true) {
			var _v0 = _Utils_Tuple2(xs, ys);
			if (!_v0.a.b) {
				if (!_v0.b.b) {
					return A2($elm$core$Basics$compare, x, y);
				} else {
					return 0;
				}
			} else {
				if (!_v0.b.b) {
					return 2;
				} else {
					var _v1 = _v0.a;
					var x_ = _v1.a;
					var xss = _v1.b;
					var _v2 = _v0.b;
					var y_ = _v2.a;
					var yss = _v2.b;
					if (_Utils_eq(x_, y_)) {
						var $temp$x = x,
							$temp$y = y,
							$temp$xs = xss,
							$temp$ys = yss;
						x = $temp$x;
						y = $temp$y;
						xs = $temp$xs;
						ys = $temp$ys;
						continue compareMagnitude;
					} else {
						var $temp$x = x_,
							$temp$y = y_,
							$temp$xs = xss,
							$temp$ys = yss;
						x = $temp$x;
						y = $temp$y;
						xs = $temp$xs;
						ys = $temp$ys;
						continue compareMagnitude;
					}
				}
			}
		}
	});
var $cmditch$elm_bigint$BigInt$orderNegate = function (x) {
	switch (x) {
		case 0:
			return 2;
		case 1:
			return 1;
		default:
			return 0;
	}
};
var $cmditch$elm_bigint$BigInt$compare = F2(
	function (int1, int2) {
		var _v0 = _Utils_Tuple2(int1, int2);
		switch (_v0.a.$) {
			case 0:
				if (!_v0.b.$) {
					var mag1 = _v0.a.a;
					var mag2 = _v0.b.a;
					return A4($cmditch$elm_bigint$BigInt$compareMagnitude, 0, 0, mag1, mag2);
				} else {
					return 2;
				}
			case 1:
				if (_v0.b.$ === 1) {
					var mag1 = _v0.a.a;
					var mag2 = _v0.b.a;
					return $cmditch$elm_bigint$BigInt$orderNegate(
						A4($cmditch$elm_bigint$BigInt$compareMagnitude, 0, 0, mag1, mag2));
				} else {
					return 0;
				}
			default:
				switch (_v0.b.$) {
					case 0:
						var _v1 = _v0.a;
						return 0;
					case 2:
						var _v2 = _v0.a;
						var _v3 = _v0.b;
						return 1;
					default:
						var _v4 = _v0.a;
						return 2;
				}
		}
	});
var $cmditch$elm_bigint$BigInt$lt = F2(
	function (x, y) {
		return !A2($cmditch$elm_bigint$BigInt$compare, x, y);
	});
var $cmditch$elm_bigint$BigInt$gte = F2(
	function (x, y) {
		return !A2($cmditch$elm_bigint$BigInt$lt, x, y);
	});
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $torreyatcitty$the_best_decimal$Decimal$insert_decimal_period = F2(
	function (pos, s) {
		var extra_zeros = pos - $elm$core$String$length(s);
		var padded_s = (extra_zeros >= 0) ? _Utils_ap(
			A2($elm$core$String$repeat, extra_zeros + 1, '0'),
			s) : s;
		var before = A2($elm$core$String$dropRight, pos, padded_s);
		var after = A2($elm$core$String$right, pos, padded_s);
		return before + ('.' + after);
	});
var $cmditch$elm_bigint$BigInt$negate = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return $cmditch$elm_bigint$BigInt$Zer;
		case 0:
			var mag = bigInt.a;
			return $cmditch$elm_bigint$BigInt$Neg(mag);
		default:
			var mag = bigInt.a;
			return $cmditch$elm_bigint$BigInt$Pos(mag);
	}
};
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $cmditch$elm_bigint$BigInt$fillZeroes = A2(
	$elm$core$Basics$composeL,
	A2($elm$core$String$padLeft, $cmditch$elm_bigint$Constants$maxDigitMagnitude, '0'),
	$elm$core$String$fromInt);
var $cmditch$elm_bigint$BigInt$revMagnitudeToString = function (_v0) {
	var digits = _v0;
	var _v1 = $elm$core$List$reverse(digits);
	if (!_v1.b) {
		return '0';
	} else {
		var x = _v1.a;
		var xs = _v1.b;
		return $elm$core$String$concat(
			A2(
				$elm$core$List$cons,
				$elm$core$String$fromInt(x),
				A2($elm$core$List$map, $cmditch$elm_bigint$BigInt$fillZeroes, xs)));
	}
};
var $cmditch$elm_bigint$BigInt$toString = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return '0';
		case 0:
			var mag = bigInt.a;
			return $cmditch$elm_bigint$BigInt$revMagnitudeToString(mag);
		default:
			var mag = bigInt.a;
			return '-' + $cmditch$elm_bigint$BigInt$revMagnitudeToString(mag);
	}
};
var $torreyatcitty$the_best_decimal$Decimal$toString = function (_v0) {
	var m = _v0.a;
	var e = _v0.b;
	var sign = A2(
		$cmditch$elm_bigint$BigInt$gte,
		m,
		$cmditch$elm_bigint$BigInt$fromInt(0)) ? '' : '-';
	var add_zeros = function (n) {
		return A2($elm$core$String$repeat, n, '0');
	};
	var abs_m = A2(
		$cmditch$elm_bigint$BigInt$gte,
		m,
		$cmditch$elm_bigint$BigInt$fromInt(0)) ? m : $cmditch$elm_bigint$BigInt$negate(m);
	var s = $cmditch$elm_bigint$BigInt$toString(abs_m);
	var _v1 = A2($elm$core$Basics$compare, e, 0);
	switch (_v1) {
		case 1:
			return _Utils_ap(sign, s);
		case 2:
			return _Utils_ap(
				sign,
				_Utils_ap(
					s,
					add_zeros(e)));
		default:
			return _Utils_ap(
				sign,
				A2($torreyatcitty$the_best_decimal$Decimal$insert_decimal_period, 0 - e, s));
	}
};
var $torreyatcitty$the_best_decimal$Decimal$toFloat = function (d) {
	var _v0 = $elm$core$String$toFloat(
		$torreyatcitty$the_best_decimal$Decimal$toString(d));
	if (!_v0.$) {
		var a = _v0.a;
		return a;
	} else {
		return 42.0;
	}
};
var $torreyatcitty$the_best_decimal$Decimal$fastdiv = F2(
	function (a, b) {
		var fb = $torreyatcitty$the_best_decimal$Decimal$toFloat(b);
		var fa = $torreyatcitty$the_best_decimal$Decimal$toFloat(a);
		var res = fa / fb;
		return $torreyatcitty$the_best_decimal$Decimal$fromFloat(res);
	});
var $author$project$CompoundComponents$Functions$default = F2(
	function (maybeVal, defaultVal) {
		if (!maybeVal.$) {
			var val = maybeVal.a;
			return val;
		} else {
			return defaultVal;
		}
	});
var $author$project$CompoundComponents$Utils$NumberFormatter$dropRight = F2(
	function (dropChar, string) {
		var reducer = F2(
			function (nextChar, res) {
				if (res.$ === 1) {
					return _Utils_eq(nextChar, dropChar) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
						$elm$core$String$fromChar(nextChar));
				} else {
					var str = res.a;
					return $elm$core$Maybe$Just(
						A2($elm$core$String$cons, nextChar, str));
				}
			});
		return A2(
			$author$project$CompoundComponents$Functions$default,
			A3($elm$core$String$foldr, reducer, $elm$core$Maybe$Nothing, string),
			'');
	});
var $author$project$CompoundComponents$Utils$NumberFormatter$splitThousands = function (integers) {
	var reversedSplitThousands = function (value) {
		return ($elm$core$String$length(value) > 3) ? A2(
			$elm$core$List$cons,
			A2($elm$core$String$right, 3, value),
			reversedSplitThousands(
				A2($elm$core$String$dropRight, 3, value))) : _List_fromArray(
			[value]);
	};
	return $elm$core$List$reverse(
		reversedSplitThousands(integers));
};
var $author$project$CompoundComponents$Utils$NumberFormatter$formatToDecimalPlaces = F3(
	function (decimalPlaces, dropZeros, decimalValue) {
		var floatString = $torreyatcitty$the_best_decimal$Decimal$toString(decimalValue);
		var dropper = dropZeros ? $author$project$CompoundComponents$Utils$NumberFormatter$dropRight('0') : function (i) {
			return i;
		};
		var _v0 = function () {
			var _v1 = A2($elm$core$String$split, '.', floatString);
			if (!_v1.b) {
				return _Utils_Tuple2('', '');
			} else {
				if (!_v1.b.b) {
					var headValue = _v1.a;
					return _Utils_Tuple2(
						A2(
							$elm$core$String$join,
							',',
							$author$project$CompoundComponents$Utils$NumberFormatter$splitThousands(headValue)),
						'');
				} else {
					if (!_v1.b.b.b) {
						var headValue = _v1.a;
						var _v2 = _v1.b;
						var tailValue = _v2.a;
						return _Utils_Tuple2(
							A2(
								$elm$core$String$join,
								',',
								$author$project$CompoundComponents$Utils$NumberFormatter$splitThousands(headValue)),
							A2(
								$elm$core$String$left,
								decimalPlaces,
								dropper(tailValue)));
					} else {
						return _Utils_Tuple2('', '');
					}
				}
			}
		}();
		var digitString = _v0.a;
		var decimalString = _v0.b;
		var _v3 = $elm$core$String$isEmpty(decimalString);
		if (_v3) {
			return digitString;
		} else {
			return digitString + ('.' + decimalString);
		}
	});
var $torreyatcitty$the_best_decimal$Decimal$abs = function (_v0) {
	var m = _v0.a;
	var e = _v0.b;
	var _v1 = A2(
		$cmditch$elm_bigint$BigInt$compare,
		m,
		$cmditch$elm_bigint$BigInt$fromInt(0));
	if (!_v1) {
		return A2(
			$torreyatcitty$the_best_decimal$Decimal$Decimal,
			$cmditch$elm_bigint$BigInt$negate(m),
			e);
	} else {
		return A2($torreyatcitty$the_best_decimal$Decimal$Decimal, m, e);
	}
};
var $torreyatcitty$the_best_decimal$Decimal$compare = F2(
	function (a, b) {
		var _v0 = $torreyatcitty$the_best_decimal$Decimal$toCommonExponent(
			_Utils_Tuple2(a, b));
		var fa = _v0.a;
		var fb = _v0.b;
		var _v1 = fb;
		var mb = _v1.a;
		var eb = _v1.b;
		var _v2 = fa;
		var ma = _v2.a;
		var ea = _v2.b;
		return A2($cmditch$elm_bigint$BigInt$compare, ma, mb);
	});
var $torreyatcitty$the_best_decimal$Decimal$lt = F2(
	function (a, b) {
		var _v0 = A2($torreyatcitty$the_best_decimal$Decimal$compare, a, b);
		if (!_v0) {
			return true;
		} else {
			return false;
		}
	});
var $author$project$CompoundComponents$Utils$NumberFormatter$getSignAndAbsValue = function (decimalValue) {
	var signString = A2($torreyatcitty$the_best_decimal$Decimal$lt, decimalValue, $torreyatcitty$the_best_decimal$Decimal$zero) ? '- ' : '';
	return _Utils_Tuple2(
		signString,
		$torreyatcitty$the_best_decimal$Decimal$abs(decimalValue));
};
var $torreyatcitty$the_best_decimal$Decimal$gt = F2(
	function (a, b) {
		var _v0 = A2($torreyatcitty$the_best_decimal$Decimal$compare, a, b);
		if (_v0 === 2) {
			return true;
		} else {
			return false;
		}
	});
var $torreyatcitty$the_best_decimal$Decimal$one = $torreyatcitty$the_best_decimal$Decimal$fromInt(1);
var $author$project$CompoundComponents$Utils$NumberFormatter$commonFormatInThousandsOrMillions = F2(
	function (currencySymbol, decimalValue) {
		var thousandsValue = A2(
			$elm$core$Maybe$withDefault,
			$torreyatcitty$the_best_decimal$Decimal$zero,
			A2(
				$torreyatcitty$the_best_decimal$Decimal$fastdiv,
				decimalValue,
				$torreyatcitty$the_best_decimal$Decimal$fromInt(1000)));
		var millionsValue = A2(
			$elm$core$Maybe$withDefault,
			$torreyatcitty$the_best_decimal$Decimal$zero,
			A2(
				$torreyatcitty$the_best_decimal$Decimal$fastdiv,
				decimalValue,
				$torreyatcitty$the_best_decimal$Decimal$fromInt(1000000)));
		var _v0 = A2($torreyatcitty$the_best_decimal$Decimal$gt, millionsValue, $torreyatcitty$the_best_decimal$Decimal$one) ? _Utils_Tuple3(millionsValue, 2, 'M') : _Utils_Tuple3(thousandsValue, 0, 'k');
		var scaledValueToUse = _v0.a;
		var roundedDecimals = _v0.b;
		var valueSuffix = _v0.c;
		var _v1 = $author$project$CompoundComponents$Utils$NumberFormatter$getSignAndAbsValue(scaledValueToUse);
		var signString = _v1.a;
		var absoluteRoundedValue = _v1.b;
		return _Utils_ap(
			signString,
			_Utils_ap(
				currencySymbol,
				_Utils_ap(
					A3($author$project$CompoundComponents$Utils$NumberFormatter$formatToDecimalPlaces, roundedDecimals, false, absoluteRoundedValue),
					valueSuffix)));
	});
var $torreyatcitty$the_best_decimal$Decimal$getDigit = F2(
	function (n, d) {
		var decimalAsString = $torreyatcitty$the_best_decimal$Decimal$toString(d);
		var toInt = function (targetDecimal) {
			switch (targetDecimal) {
				case '1':
					return 1;
				case '2':
					return 2;
				case '3':
					return 3;
				case '4':
					return 4;
				case '5':
					return 5;
				case '6':
					return 6;
				case '7':
					return 7;
				case '8':
					return 8;
				case '9':
					return 9;
				case '0':
					return 0;
				case '':
					return 0;
				default:
					return -1;
			}
		};
		var _v0 = _Utils_Tuple2(
			A2($elm$core$String$split, '.', decimalAsString),
			A2($elm$core$Basics$compare, n, 0));
		_v0$6:
		while (true) {
			if (_v0.a.b) {
				if (!_v0.a.b.b) {
					switch (_v0.b) {
						case 2:
							var _v1 = _v0.a;
							var a = _v1.a;
							var _v2 = _v0.b;
							return toInt(
								A2(
									$elm$core$String$right,
									1,
									A2($elm$core$String$dropRight, n, a)));
						case 1:
							var _v3 = _v0.a;
							var a = _v3.a;
							var _v4 = _v0.b;
							return toInt(
								A2($elm$core$String$right, 1, a));
						default:
							var _v5 = _v0.a;
							var a = _v5.a;
							var _v6 = _v0.b;
							return 0;
					}
				} else {
					if (!_v0.a.b.b.b) {
						switch (_v0.b) {
							case 2:
								var _v7 = _v0.a;
								var a = _v7.a;
								var _v8 = _v7.b;
								var b = _v8.a;
								var _v9 = _v0.b;
								return toInt(
									A2(
										$elm$core$String$right,
										1,
										A2($elm$core$String$dropRight, n, a)));
							case 1:
								var _v10 = _v0.a;
								var a = _v10.a;
								var _v11 = _v10.b;
								var b = _v11.a;
								var _v12 = _v0.b;
								return toInt(
									A2($elm$core$String$right, 1, a));
							default:
								var _v13 = _v0.a;
								var a = _v13.a;
								var _v14 = _v13.b;
								var b = _v14.a;
								var _v15 = _v0.b;
								return toInt(
									A2(
										$elm$core$String$left,
										1,
										A2($elm$core$String$dropLeft, (-n) - 1, b)));
						}
					} else {
						break _v0$6;
					}
				}
			} else {
				break _v0$6;
			}
		}
		return -13;
	});
var $torreyatcitty$the_best_decimal$Decimal$signAsInt = function (d) {
	var _v0 = A2($torreyatcitty$the_best_decimal$Decimal$compare, d, $torreyatcitty$the_best_decimal$Decimal$zero);
	switch (_v0) {
		case 0:
			return -1;
		case 1:
			return 0;
		default:
			return 1;
	}
};
var $torreyatcitty$the_best_decimal$Decimal$truncate = F2(
	function (n, d) {
		var decimalAsString = $torreyatcitty$the_best_decimal$Decimal$toString(d);
		var toDecimal = function (s) {
			var _v7 = $torreyatcitty$the_best_decimal$Decimal$fromString(s);
			if (!_v7.$) {
				var a = _v7.a;
				return a;
			} else {
				return $torreyatcitty$the_best_decimal$Decimal$zero;
			}
		};
		var _v0 = _Utils_Tuple2(
			A2($elm$core$String$split, '.', decimalAsString),
			n >= 0);
		_v0$4:
		while (true) {
			if (_v0.a.b) {
				if (!_v0.a.b.b) {
					if (_v0.b) {
						var _v1 = _v0.a;
						var a = _v1.a;
						return toDecimal(
							_Utils_ap(
								A2($elm$core$String$dropRight, n, a),
								A2($elm$core$String$repeat, n, '0')));
					} else {
						var _v2 = _v0.a;
						var a = _v2.a;
						return toDecimal(a);
					}
				} else {
					if (!_v0.a.b.b.b) {
						if (_v0.b) {
							var _v3 = _v0.a;
							var a = _v3.a;
							var _v4 = _v3.b;
							var b = _v4.a;
							return toDecimal(
								_Utils_ap(
									A2($elm$core$String$dropRight, n, a),
									A2($elm$core$String$repeat, n, '0')));
						} else {
							var _v5 = _v0.a;
							var a = _v5.a;
							var _v6 = _v5.b;
							var b = _v6.a;
							return toDecimal(
								a + ('.' + A2($elm$core$String$left, -n, b)));
						}
					} else {
						break _v0$4;
					}
				}
			} else {
				break _v0$4;
			}
		}
		return $torreyatcitty$the_best_decimal$Decimal$zero;
	});
var $torreyatcitty$the_best_decimal$Decimal$round = F2(
	function (n, d) {
		var to_increment = A2(
			$torreyatcitty$the_best_decimal$Decimal$fromIntWithExponent,
			$torreyatcitty$the_best_decimal$Decimal$signAsInt(d),
			n);
		var t = A2($torreyatcitty$the_best_decimal$Decimal$truncate, n, d);
		var next_digit = A2($torreyatcitty$the_best_decimal$Decimal$getDigit, n - 1, d);
		return (next_digit >= 5) ? A2($torreyatcitty$the_best_decimal$Decimal$add, t, to_increment) : t;
	});
var $author$project$CompoundComponents$Utils$NumberFormatter$interestRateAsPercentage = function (interestRate) {
	return A2(
		$torreyatcitty$the_best_decimal$Decimal$round,
		-2,
		A2(
			$torreyatcitty$the_best_decimal$Decimal$mul,
			interestRate,
			$torreyatcitty$the_best_decimal$Decimal$fromInt(100)));
};
var $author$project$CompoundComponents$Utils$NumberFormatter$formatPercentageToNearestWhole = function (decimalValue) {
	return A3(
		$author$project$CompoundComponents$Utils$NumberFormatter$formatToDecimalPlaces,
		0,
		false,
		$author$project$CompoundComponents$Utils$NumberFormatter$interestRateAsPercentage(decimalValue)) + '%';
};
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $author$project$CompoundComponents$Utils$CompoundHtmlAttributes$style = F2(
	function (styleName, styleValue) {
		return A2($elm$html$Html$Attributes$style, styleName, styleValue);
	});
var $author$project$Governance$CommonViews$proposalActiveTalliesView = F2(
	function (_v0, _v1) {
		var for_votes = _v1.f_;
		var against_votes = _v1.fp;
		var totalVotes = A2($torreyatcitty$the_best_decimal$Decimal$add, for_votes, against_votes);
		var forPercent = A2(
			$elm$core$Maybe$withDefault,
			$torreyatcitty$the_best_decimal$Decimal$zero,
			A2($torreyatcitty$the_best_decimal$Decimal$fastdiv, for_votes, totalVotes));
		var againstPercent = A2(
			$elm$core$Maybe$withDefault,
			$torreyatcitty$the_best_decimal$Decimal$zero,
			A2($torreyatcitty$the_best_decimal$Decimal$fastdiv, against_votes, totalVotes));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__active-votes')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__active-votes__row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__active-votes__row__vote-bar')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__active-votes__row__vote-bar__fill proposal__active-votes__row__vote-bar__fill--for'),
											A2(
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$style,
											'width',
											$author$project$CompoundComponents$Utils$NumberFormatter$formatPercentageToNearestWhole(forPercent))
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__active-votes__row__value')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									A2($author$project$CompoundComponents$Utils$NumberFormatter$commonFormatInThousandsOrMillions, '', for_votes))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__active-votes__row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__active-votes__row__vote-bar')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__active-votes__row__vote-bar__fill proposal__active-votes__row__vote-bar__fill--against'),
											A2(
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$style,
											'width',
											$author$project$CompoundComponents$Utils$NumberFormatter$formatPercentageToNearestWhole(againstPercent))
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__active-votes__row__value')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									A2($author$project$CompoundComponents$Utils$NumberFormatter$commonFormatInThousandsOrMillions, '', against_votes))
								]))
						]))
				]));
	});
var $author$project$Strings$Translations$canceled_time = F2(
	function (lang, time) {
		switch (lang) {
			case 0:
				return 'Canceled ' + (time + '');
			case 1:
				return 'Cancelado ' + (time + '');
			case 2:
				return '已取消' + (time + '');
			case 3:
				return 'Annulé ' + (time + '');
			default:
				return '취소: ' + (time + '');
		}
	});
var $ryannhg$date_format$DateFormat$DayOfMonthSuffix = {$: 6};
var $ryannhg$date_format$DateFormat$dayOfMonthSuffix = $ryannhg$date_format$DateFormat$DayOfMonthSuffix;
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $author$project$CompoundComponents$Utils$Time$posixToSeconds = function (time) {
	var ms = $elm$time$Time$posixToMillis(time);
	var msFloat = ms / 1000;
	return $elm$core$Basics$floor(msFloat);
};
var $author$project$CompoundComponents$Utils$Time$differenceInSeconds = F2(
	function (end, start) {
		return $author$project$CompoundComponents$Utils$Time$posixToSeconds(end) - $author$project$CompoundComponents$Utils$Time$posixToSeconds(start);
	});
var $elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						$elm$core$List$cons,
						sep,
						A2($elm$core$List$cons, x, rest));
				});
			var spersed = A3($elm$core$List$foldr, step, _List_Nil, tl);
			return A2($elm$core$List$cons, hd, spersed);
		}
	});
var $author$project$Strings$Translations$day = function (lang) {
	switch (lang) {
		case 0:
			return 'day';
		case 1:
			return 'día';
		case 2:
			return '天';
		case 3:
			return 'journée';
		default:
			return '일';
	}
};
var $author$project$Strings$Translations$days = function (lang) {
	switch (lang) {
		case 0:
			return 'days';
		case 1:
			return 'dias';
		case 2:
			return '天';
		case 3:
			return 'journées';
		default:
			return '일';
	}
};
var $author$project$Strings$Translations$hour = function (lang) {
	switch (lang) {
		case 0:
			return 'hr';
		case 1:
			return 'hora';
		case 2:
			return '小时';
		case 3:
			return 'heure';
		default:
			return '시간';
	}
};
var $author$project$Strings$Translations$hours = function (lang) {
	switch (lang) {
		case 0:
			return 'hrs';
		case 1:
			return 'horas';
		case 2:
			return '小时';
		case 3:
			return 'heures';
		default:
			return '시간';
	}
};
var $author$project$Strings$Translations$minute = function (lang) {
	switch (lang) {
		case 0:
			return 'min';
		case 1:
			return 'minuto';
		case 2:
			return '分钟';
		case 3:
			return 'minute';
		default:
			return '분';
	}
};
var $author$project$Strings$Translations$minutes = function (lang) {
	switch (lang) {
		case 0:
			return 'mins';
		case 1:
			return 'minutos';
		case 2:
			return '分钟';
		case 3:
			return 'minutes';
		default:
			return '분';
	}
};
var $author$project$Strings$Translations$second = function (lang) {
	switch (lang) {
		case 0:
			return 'second';
		case 1:
			return 'segundo';
		case 2:
			return '第二';
		case 3:
			return 'seconde';
		default:
			return '초';
	}
};
var $author$project$Strings$Translations$seconds = function (lang) {
	switch (lang) {
		case 0:
			return 'seconds';
		case 1:
			return 'segundos';
		case 2:
			return '秒';
		case 3:
			return 'secondes';
		default:
			return '초';
	}
};
var $author$project$Strings$Translations$year = function (lang) {
	switch (lang) {
		case 0:
			return 'year';
		case 1:
			return 'año';
		case 2:
			return '年';
		case 3:
			return 'année';
		default:
			return '년';
	}
};
var $author$project$Strings$Translations$years = function (lang) {
	switch (lang) {
		case 0:
			return 'years';
		case 1:
			return 'años';
		case 2:
			return '年份';
		case 3:
			return 'ans';
		default:
			return '년';
	}
};
var $author$project$Utils$TimeWords$timeElements = function (userLanguage) {
	return _List_fromArray(
		[
			{
			aZ: ((60 * 60) * 24) * 365,
			a$: $author$project$Strings$Translations$years(userLanguage),
			a2: $author$project$Strings$Translations$year(userLanguage)
		},
			{
			aZ: (60 * 60) * 24,
			a$: $author$project$Strings$Translations$days(userLanguage),
			a2: $author$project$Strings$Translations$day(userLanguage)
		},
			{
			aZ: 60 * 60,
			a$: $author$project$Strings$Translations$hours(userLanguage),
			a2: $author$project$Strings$Translations$hour(userLanguage)
		},
			{
			aZ: 60,
			a$: $author$project$Strings$Translations$minutes(userLanguage),
			a2: $author$project$Strings$Translations$minute(userLanguage)
		},
			{
			aZ: 1,
			a$: $author$project$Strings$Translations$seconds(userLanguage),
			a2: $author$project$Strings$Translations$second(userLanguage)
		}
		]);
};
var $author$project$Strings$Translations$time_ago = F2(
	function (lang, time) {
		switch (lang) {
			case 0:
				return '' + (time + ' ago');
			case 1:
				return 'hace ' + (time + '');
			case 2:
				return '' + (time + ' 过去');
			case 3:
				return 'Il y a ' + (time + '');
			default:
				return '' + (time + ' 전');
		}
	});
var $author$project$Utils$TimeWords$distanceTimeInWords = F2(
	function (userLanguage, time) {
		var str = A3(
			$elm$core$List$foldl,
			$elm$core$Basics$append,
			'',
			A2(
				$elm$core$List$intersperse,
				', ',
				A3(
					$elm$core$List$foldl,
					F2(
						function (_v0, _v1) {
							var singleName = _v0.a2;
							var pluralName = _v0.a$;
							var len = _v0.aZ;
							var currEls = _v1.a;
							var remaining = _v1.b;
							if ((_Utils_cmp(remaining, len) > 0) && ($elm$core$List$length(currEls) < 2)) {
								var amt = (remaining / len) | 0;
								var name = (amt === 1) ? singleName : pluralName;
								var el = $elm$core$String$fromInt(amt) + (' ' + name);
								return _Utils_Tuple2(
									A2($elm$core$List$cons, el, currEls),
									remaining - (amt * len));
							} else {
								return _Utils_Tuple2(currEls, remaining);
							}
						}),
					_Utils_Tuple2(
						_List_Nil,
						$elm$core$Basics$abs(time)),
					$author$project$Utils$TimeWords$timeElements(userLanguage)).a));
		return (time < 0) ? A2($author$project$Strings$Translations$time_ago, userLanguage, str) : str;
	});
var $author$project$Strings$Translations$ends_on = F2(
	function (lang, time) {
		switch (lang) {
			case 0:
				return 'Ends on ' + (time + '');
			case 1:
				return 'Finaliza el ' + (time + '');
			case 2:
				return '于' + (time + '结束');
			case 3:
				return 'Se termine le ' + (time + '');
			default:
				return '종료 시간: ' + (time + '');
		}
	});
var $author$project$Strings$Translations$executed_time = F2(
	function (lang, time) {
		switch (lang) {
			case 0:
				return 'Executed ' + (time + '');
			case 1:
				return 'Ejecutado ' + (time + '');
			case 2:
				return '已执行' + (time + '');
			case 3:
				return 'Exécuté ' + (time + '');
			default:
				return '실행: ' + (time + '');
		}
	});
var $author$project$Strings$Translations$expired_time = F2(
	function (lang, time) {
		switch (lang) {
			case 0:
				return 'Expired ' + (time + '');
			case 1:
				return 'Cancelado ' + (time + '');
			case 2:
				return '已取消' + (time + '');
			case 3:
				return 'Temps expiré}}';
			default:
				return '만료: ' + (time + '');
		}
	});
var $author$project$Strings$Translations$failed_time = F2(
	function (lang, time) {
		switch (lang) {
			case 0:
				return 'Failed ' + (time + '');
			case 1:
				return 'Falló ' + (time + '');
			case 2:
				return '' + (time + '失败');
			case 3:
				return 'Échec ' + (time + '');
			default:
				return '부결: ' + (time + '');
		}
	});
var $ryannhg$date_format$DateFormat$Language$Language = F6(
	function (toMonthName, toMonthAbbreviation, toWeekdayName, toWeekdayAbbreviation, toAmPm, toOrdinalSuffix) {
		return {j6: toAmPm, j7: toMonthAbbreviation, j8: toMonthName, bH: toOrdinalSuffix, ka: toWeekdayAbbreviation, kb: toWeekdayName};
	});
var $ryannhg$date_format$DateFormat$Language$toEnglishAmPm = function (hour) {
	return (hour > 11) ? 'pm' : 'am';
};
var $ryannhg$date_format$DateFormat$Language$toEnglishMonthName = function (month) {
	switch (month) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $ryannhg$date_format$DateFormat$Language$toEnglishSuffix = function (num) {
	var _v0 = A2($elm$core$Basics$modBy, 100, num);
	switch (_v0) {
		case 11:
			return 'th';
		case 12:
			return 'th';
		case 13:
			return 'th';
		default:
			var _v1 = A2($elm$core$Basics$modBy, 10, num);
			switch (_v1) {
				case 1:
					return 'st';
				case 2:
					return 'nd';
				case 3:
					return 'rd';
				default:
					return 'th';
			}
	}
};
var $ryannhg$date_format$DateFormat$Language$toEnglishWeekdayName = function (weekday) {
	switch (weekday) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $ryannhg$date_format$DateFormat$Language$english = A6(
	$ryannhg$date_format$DateFormat$Language$Language,
	$ryannhg$date_format$DateFormat$Language$toEnglishMonthName,
	A2(
		$elm$core$Basics$composeR,
		$ryannhg$date_format$DateFormat$Language$toEnglishMonthName,
		$elm$core$String$left(3)),
	$ryannhg$date_format$DateFormat$Language$toEnglishWeekdayName,
	A2(
		$elm$core$Basics$composeR,
		$ryannhg$date_format$DateFormat$Language$toEnglishWeekdayName,
		$elm$core$String$left(3)),
	$ryannhg$date_format$DateFormat$Language$toEnglishAmPm,
	$ryannhg$date_format$DateFormat$Language$toEnglishSuffix);
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.o, posixMinutes) < 0) {
					return posixMinutes + era.d;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $ryannhg$date_format$DateFormat$amPm = F3(
	function (language, zone, posix) {
		return language.j6(
			A2($elm$time$Time$toHour, zone, posix));
	});
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		fL: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		gH: month,
		hG: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).fL;
	});
var $ryannhg$date_format$DateFormat$dayOfMonth = $elm$time$Time$toDay;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $ryannhg$date_format$DateFormat$days = _List_fromArray(
	[6, 0, 1, 2, 3, 4, 5]);
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$time$Time$toWeekday = F2(
	function (zone, time) {
		var _v0 = A2(
			$elm$core$Basics$modBy,
			7,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60 * 24));
		switch (_v0) {
			case 0:
				return 3;
			case 1:
				return 4;
			case 2:
				return 5;
			case 3:
				return 6;
			case 4:
				return 0;
			case 5:
				return 1;
			default:
				return 2;
		}
	});
var $ryannhg$date_format$DateFormat$dayOfWeek = F2(
	function (zone, posix) {
		return function (_v1) {
			var i = _v1.a;
			return i;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				_Utils_Tuple2(0, 6),
				$elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (_v0) {
							var day = _v0.b;
							return _Utils_eq(
								day,
								A2($elm$time$Time$toWeekday, zone, posix));
						},
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (i, day) {
									return _Utils_Tuple2(i, day);
								}),
							$ryannhg$date_format$DateFormat$days)))));
	});
var $ryannhg$date_format$DateFormat$isLeapYear = function (year_) {
	return (!(!A2($elm$core$Basics$modBy, 4, year_))) ? false : ((!(!A2($elm$core$Basics$modBy, 100, year_))) ? true : ((!(!A2($elm$core$Basics$modBy, 400, year_))) ? false : true));
};
var $ryannhg$date_format$DateFormat$daysInMonth = F2(
	function (year_, month) {
		switch (month) {
			case 0:
				return 31;
			case 1:
				return $ryannhg$date_format$DateFormat$isLeapYear(year_) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $ryannhg$date_format$DateFormat$months = _List_fromArray(
	[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).gH;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $ryannhg$date_format$DateFormat$monthPair = F2(
	function (zone, posix) {
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(0, 0),
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (_v0) {
						var i = _v0.a;
						var m = _v0.b;
						return _Utils_eq(
							m,
							A2($elm$time$Time$toMonth, zone, posix));
					},
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (a, b) {
								return _Utils_Tuple2(a, b);
							}),
						$ryannhg$date_format$DateFormat$months))));
	});
var $ryannhg$date_format$DateFormat$monthNumber_ = F2(
	function (zone, posix) {
		return 1 + function (_v0) {
			var i = _v0.a;
			var m = _v0.b;
			return i;
		}(
			A2($ryannhg$date_format$DateFormat$monthPair, zone, posix));
	});
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).hG;
	});
var $ryannhg$date_format$DateFormat$dayOfYear = F2(
	function (zone, posix) {
		var monthsBeforeThisOne = A2(
			$elm$core$List$take,
			A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix) - 1,
			$ryannhg$date_format$DateFormat$months);
		var daysBeforeThisMonth = $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				$ryannhg$date_format$DateFormat$daysInMonth(
					A2($elm$time$Time$toYear, zone, posix)),
				monthsBeforeThisOne));
		return daysBeforeThisMonth + A2($ryannhg$date_format$DateFormat$dayOfMonth, zone, posix);
	});
var $ryannhg$date_format$DateFormat$quarter = F2(
	function (zone, posix) {
		return (A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix) / 4) | 0;
	});
var $ryannhg$date_format$DateFormat$toFixedLength = F2(
	function (totalChars, num) {
		var numStr = $elm$core$String$fromInt(num);
		var numZerosNeeded = totalChars - $elm$core$String$length(numStr);
		var zeros = A2(
			$elm$core$String$join,
			'',
			A2(
				$elm$core$List$map,
				function (_v0) {
					return '0';
				},
				A2($elm$core$List$range, 1, numZerosNeeded)));
		return _Utils_ap(zeros, numStr);
	});
var $elm$time$Time$toMillis = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			1000,
			$elm$time$Time$posixToMillis(time));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $ryannhg$date_format$DateFormat$toNonMilitary = function (num) {
	return (!num) ? 12 : ((num <= 12) ? num : (num - 12));
};
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $elm$core$String$toUpper = _String_toUpper;
var $elm$core$Basics$round = _Basics_round;
var $ryannhg$date_format$DateFormat$millisecondsPerYear = $elm$core$Basics$round((((1000 * 60) * 60) * 24) * 365.25);
var $ryannhg$date_format$DateFormat$firstDayOfYear = F2(
	function (zone, time) {
		return $elm$time$Time$millisToPosix(
			$ryannhg$date_format$DateFormat$millisecondsPerYear * A2($elm$time$Time$toYear, zone, time));
	});
var $ryannhg$date_format$DateFormat$weekOfYear = F2(
	function (zone, posix) {
		var firstDay = A2($ryannhg$date_format$DateFormat$firstDayOfYear, zone, posix);
		var firstDayOffset = A2($ryannhg$date_format$DateFormat$dayOfWeek, zone, firstDay);
		var daysSoFar = A2($ryannhg$date_format$DateFormat$dayOfYear, zone, posix);
		return (((daysSoFar + firstDayOffset) / 7) | 0) + 1;
	});
var $ryannhg$date_format$DateFormat$year = F2(
	function (zone, time) {
		return $elm$core$String$fromInt(
			A2($elm$time$Time$toYear, zone, time));
	});
var $ryannhg$date_format$DateFormat$piece = F4(
	function (language, zone, posix, token) {
		switch (token.$) {
			case 0:
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix));
			case 1:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.bH(num));
				}(
					A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix));
			case 2:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix));
			case 3:
				return language.j7(
					A2($elm$time$Time$toMonth, zone, posix));
			case 4:
				return language.j8(
					A2($elm$time$Time$toMonth, zone, posix));
			case 17:
				return $elm$core$String$fromInt(
					1 + A2($ryannhg$date_format$DateFormat$quarter, zone, posix));
			case 18:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.bH(num));
				}(
					1 + A2($ryannhg$date_format$DateFormat$quarter, zone, posix));
			case 5:
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$dayOfMonth, zone, posix));
			case 6:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.bH(num));
				}(
					A2($ryannhg$date_format$DateFormat$dayOfMonth, zone, posix));
			case 7:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($ryannhg$date_format$DateFormat$dayOfMonth, zone, posix));
			case 8:
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$dayOfYear, zone, posix));
			case 9:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.bH(num));
				}(
					A2($ryannhg$date_format$DateFormat$dayOfYear, zone, posix));
			case 10:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					3,
					A2($ryannhg$date_format$DateFormat$dayOfYear, zone, posix));
			case 11:
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$dayOfWeek, zone, posix));
			case 12:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.bH(num));
				}(
					A2($ryannhg$date_format$DateFormat$dayOfWeek, zone, posix));
			case 13:
				return language.ka(
					A2($elm$time$Time$toWeekday, zone, posix));
			case 14:
				return language.kb(
					A2($elm$time$Time$toWeekday, zone, posix));
			case 19:
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$weekOfYear, zone, posix));
			case 20:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.bH(num));
				}(
					A2($ryannhg$date_format$DateFormat$weekOfYear, zone, posix));
			case 21:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($ryannhg$date_format$DateFormat$weekOfYear, zone, posix));
			case 15:
				return A2(
					$elm$core$String$right,
					2,
					A2($ryannhg$date_format$DateFormat$year, zone, posix));
			case 16:
				return A2($ryannhg$date_format$DateFormat$year, zone, posix);
			case 22:
				return $elm$core$String$toUpper(
					A3($ryannhg$date_format$DateFormat$amPm, language, zone, posix));
			case 23:
				return $elm$core$String$toLower(
					A3($ryannhg$date_format$DateFormat$amPm, language, zone, posix));
			case 24:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toHour, zone, posix));
			case 25:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($elm$time$Time$toHour, zone, posix));
			case 26:
				return $elm$core$String$fromInt(
					$ryannhg$date_format$DateFormat$toNonMilitary(
						A2($elm$time$Time$toHour, zone, posix)));
			case 27:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					$ryannhg$date_format$DateFormat$toNonMilitary(
						A2($elm$time$Time$toHour, zone, posix)));
			case 28:
				return $elm$core$String$fromInt(
					1 + A2($elm$time$Time$toHour, zone, posix));
			case 29:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					1 + A2($elm$time$Time$toHour, zone, posix));
			case 30:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toMinute, zone, posix));
			case 31:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($elm$time$Time$toMinute, zone, posix));
			case 32:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toSecond, zone, posix));
			case 33:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($elm$time$Time$toSecond, zone, posix));
			case 34:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toMillis, zone, posix));
			case 35:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					3,
					A2($elm$time$Time$toMillis, zone, posix));
			default:
				var string = token.a;
				return string;
		}
	});
var $ryannhg$date_format$DateFormat$formatWithLanguage = F4(
	function (language, tokens, zone, time) {
		return A2(
			$elm$core$String$join,
			'',
			A2(
				$elm$core$List$map,
				A3($ryannhg$date_format$DateFormat$piece, language, zone, time),
				tokens));
	});
var $ryannhg$date_format$DateFormat$format = $ryannhg$date_format$DateFormat$formatWithLanguage($ryannhg$date_format$DateFormat$Language$english);
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $ryannhg$date_format$DateFormat$MonthNameFull = {$: 4};
var $ryannhg$date_format$DateFormat$monthNameFull = $ryannhg$date_format$DateFormat$MonthNameFull;
var $author$project$Strings$Translations$proposal_state_active = function (lang) {
	switch (lang) {
		case 0:
			return 'Active';
		case 1:
			return 'Activo';
		case 2:
			return '投票中';
		case 3:
			return 'actif';
		default:
			return '활성화';
	}
};
var $author$project$Strings$Translations$proposal_state_failed = function (lang) {
	switch (lang) {
		case 0:
			return 'Failed';
		case 1:
			return 'Ha fallado';
		case 2:
			return '已失败';
		case 3:
			return 'Échoué';
		default:
			return '부결';
	}
};
var $author$project$Strings$Translations$proposal_state_passed = function (lang) {
	switch (lang) {
		case 0:
			return 'Passed';
		case 1:
			return 'Pasado';
		case 2:
			return '已通过';
		case 3:
			return 'Réussi';
		default:
			return '가결';
	}
};
var $author$project$Strings$Translations$proposal_state_pending = function (lang) {
	switch (lang) {
		case 0:
			return 'Review';
		case 1:
			return 'Revisar';
		case 2:
			return '审查';
		case 3:
			return 'Revoir';
		default:
			return '리뷰';
	}
};
var $author$project$Strings$Translations$queued_time = F2(
	function (lang, time_queued) {
		switch (lang) {
			case 0:
				return 'Queued ' + (time_queued + '');
			case 1:
				return 'En cola ' + (time_queued + '');
			case 2:
				return '已排队' + (time_queued + '');
			case 3:
				return 'Mis en file d\'attente ' + (time_queued + '');
			default:
				return '대기: ' + (time_queued + '');
		}
	});
var $author$project$Strings$Translations$succeeded_time = F2(
	function (lang, time) {
		switch (lang) {
			case 0:
				return 'Succeeded ' + (time + '');
			case 1:
				return 'Sucedido ' + (time + '');
			case 2:
				return '成功' + (time + '');
			case 3:
				return 'Réussi ' + (time + '');
			default:
				return '성공: ' + (time + '');
		}
	});
var $ryannhg$date_format$DateFormat$Text = function (a) {
	return {$: 36, a: a};
};
var $ryannhg$date_format$DateFormat$text = $ryannhg$date_format$DateFormat$Text;
var $author$project$Strings$Translations$time_left = F2(
	function (lang, time) {
		switch (lang) {
			case 0:
				return '' + (time + ' left');
			case 1:
				return '' + (time + ' restante');
			case 2:
				return '' + (time + ' 剩下的时间');
			case 3:
				return '' + (time + ' restant');
			default:
				return '' + (time + ' 남음');
		}
	});
var $author$project$Strings$Translations$time_until_voting = F2(
	function (lang, time) {
		switch (lang) {
			case 0:
				return '' + (time + ' until voting');
			case 1:
				return '' + (time + ' hasta la votación');
			case 2:
				return '' + (time + '直到投票');
			case 3:
				return '' + (time + ' jusqu\'au vote');
			default:
				return '투표까지 ' + (time + '');
		}
	});
var $ryannhg$date_format$DateFormat$YearNumber = {$: 16};
var $ryannhg$date_format$DateFormat$yearNumber = $ryannhg$date_format$DateFormat$YearNumber;
var $author$project$Governance$CommonViews$proposalContentView = F5(
	function (userLanguage, timezone, maybeCurrentTime, _v0, asHeader) {
		var id = _v0.gb;
		var title = _v0.j5;
		var states = _v0.hf;
		var stateTimeFormatter = A2(
			$ryannhg$date_format$DateFormat$format,
			_List_fromArray(
				[
					$ryannhg$date_format$DateFormat$monthNameFull,
					$ryannhg$date_format$DateFormat$text(' '),
					$ryannhg$date_format$DateFormat$dayOfMonthSuffix,
					$ryannhg$date_format$DateFormat$text(', '),
					$ryannhg$date_format$DateFormat$yearNumber
				]),
			timezone);
		var maybeCurrentState = $elm$core$List$head(
			$elm$core$List$reverse(states));
		var _v1 = asHeader ? _Utils_Tuple3(' proposal__content--header', ' proposal__content__description__title--header', ' proposal__content__description__details--large') : _Utils_Tuple3('', '', '');
		var headerContentClass = _v1.a;
		var headerTitleClass = _v1.b;
		var headerDetailsClass = _v1.c;
		if (!maybeCurrentState.$) {
			var currentState = maybeCurrentState.a;
			var prependZeros = function (value) {
				return ($elm$core$String$length(value) < 3) ? prependZeros(
					A2($elm$core$String$cons, '0', value)) : value;
			};
			var _v3 = function () {
				var _v4 = currentState.jQ;
				if (_v4 === 1) {
					var timeString = function () {
						var _v5 = _Utils_Tuple2(maybeCurrentTime, currentState.iB);
						if (!_v5.b.$) {
							if (!_v5.a.$) {
								var currentTime = _v5.a.a;
								var endTime = _v5.b.a;
								var difference = A2($author$project$CompoundComponents$Utils$Time$differenceInSeconds, endTime, currentTime);
								return A2(
									$author$project$Strings$Translations$time_left,
									userLanguage,
									A2($author$project$Utils$TimeWords$distanceTimeInWords, userLanguage, difference));
							} else {
								var endTime = _v5.b.a;
								return A2(
									$author$project$Strings$Translations$ends_on,
									userLanguage,
									stateTimeFormatter(endTime));
							}
						} else {
							return '';
						}
					}();
					var pulse = asHeader ? $elm$html$Html$text('') : A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('pulsating-dot proposal__content__pulsating-dot mobile-hide')
							]),
						_List_Nil);
					return _Utils_Tuple3(
						pulse,
						A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content__description__details__tag proposal__content__description__details__tag--active')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Strings$Translations$proposal_state_active(userLanguage))
								])),
						A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content__description__details__text proposal__content__description__details__text--active')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											prependZeros(
												$elm$core$String$fromInt(id)))
										])),
									A2(
									$elm$html$Html$span,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('•')
										])),
									A2(
									$elm$html$Html$span,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(timeString)
										]))
								])));
				} else {
					var states_ = A2(
						$elm$core$List$map,
						function ($) {
							return $.jQ;
						},
						states);
					var tag_ = A2($elm$core$List$member, 2, states_) ? A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content__description__details__tag proposal__content__description__details__tag--passed')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Strings$Translations$proposal_state_passed(userLanguage))
							])) : (A2($elm$core$List$member, 3, states_) ? A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content__description__details__tag proposal__content__description__details__tag--not-passed')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Strings$Translations$proposal_state_failed(userLanguage))
							])) : (A2($elm$core$List$member, 6, states_) ? A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content__description__details__tag proposal__content__description__details__tag--not-passed')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Strings$Translations$proposal_state_failed(userLanguage))
							])) : A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content__description__details__tag proposal__content__description__details__tag--not-passed')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Strings$Translations$proposal_state_pending(userLanguage))
							]))));
					var detailsText_ = function () {
						var _v6 = currentState.jQ;
						switch (_v6) {
							case 0:
								var _v7 = _Utils_Tuple2(maybeCurrentTime, currentState.iB);
								if (!_v7.a.$) {
									if (!_v7.b.$) {
										var currentTime = _v7.a.a;
										var endTime = _v7.b.a;
										var difference = A2($author$project$CompoundComponents$Utils$Time$differenceInSeconds, endTime, currentTime);
										return A2(
											$author$project$Strings$Translations$time_until_voting,
											userLanguage,
											A2($author$project$Utils$TimeWords$distanceTimeInWords, userLanguage, difference));
									} else {
										var currentTime = _v7.a.a;
										var difference = A2($author$project$CompoundComponents$Utils$Time$differenceInSeconds, currentTime, currentState.jP);
										return A2(
											$author$project$Strings$Translations$time_ago,
											userLanguage,
											A2($author$project$Utils$TimeWords$distanceTimeInWords, userLanguage, difference));
									}
								} else {
									return A2(
										$author$project$Strings$Translations$ends_on,
										userLanguage,
										stateTimeFormatter(currentState.jP));
								}
							case 2:
								return A2(
									$author$project$Strings$Translations$succeeded_time,
									userLanguage,
									stateTimeFormatter(currentState.jP));
							case 3:
								return A2(
									$author$project$Strings$Translations$failed_time,
									userLanguage,
									stateTimeFormatter(currentState.jP));
							case 4:
								return A2(
									$author$project$Strings$Translations$queued_time,
									userLanguage,
									stateTimeFormatter(currentState.jP));
							case 5:
								return A2(
									$author$project$Strings$Translations$executed_time,
									userLanguage,
									stateTimeFormatter(currentState.jP));
							case 6:
								return A2(
									$author$project$Strings$Translations$canceled_time,
									userLanguage,
									stateTimeFormatter(currentState.jP));
							case 7:
								return A2(
									$author$project$Strings$Translations$expired_time,
									userLanguage,
									stateTimeFormatter(currentState.jP));
							default:
								return '';
						}
					}();
					return _Utils_Tuple3(
						$elm$html$Html$text(''),
						tag_,
						A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content__description__details__text proposal__content__description__details__text')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											prependZeros(
												$elm$core$String$fromInt(id)))
										])),
									A2(
									$elm$html$Html$span,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('•')
										])),
									A2(
									$elm$html$Html$span,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(detailsText_)
										]))
								])));
				}
			}();
			var pulsatingDot = _v3.a;
			var tag = _v3.b;
			var detailsText = _v3.c;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content' + headerContentClass)
					]),
				_List_fromArray(
					[
						pulsatingDot,
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content__description')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content__description__title' + headerTitleClass)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(title)
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content__description__details' + headerDetailsClass)
									]),
								_List_fromArray(
									[tag, detailsText]))
							]))
					]));
		} else {
			return $elm$html$Html$text('');
		}
	});
var $author$project$Strings$Translations$proposal_state_canceled = function (lang) {
	switch (lang) {
		case 0:
			return 'Canceled';
		case 1:
			return 'Cancelado';
		case 2:
			return '取消';
		case 3:
			return 'Annulé';
		default:
			return '취소';
	}
};
var $author$project$Strings$Translations$proposal_state_executed = function (lang) {
	switch (lang) {
		case 0:
			return 'Executed';
		case 1:
			return 'Ejecutado';
		case 2:
			return '已执行';
		case 3:
			return 'Réalisé';
		default:
			return '실행';
	}
};
var $author$project$Strings$Translations$proposal_state_expired = function (lang) {
	switch (lang) {
		case 0:
			return 'Expired';
		case 1:
			return 'Caducado';
		case 2:
			return '已过期';
		case 3:
			return 'Expiré';
		default:
			return '만료';
	}
};
var $author$project$Strings$Translations$proposal_state_queued = function (lang) {
	switch (lang) {
		case 0:
			return 'Queued';
		case 1:
			return 'Puesto en cola';
		case 2:
			return '排队';
		case 3:
			return 'En file d\'attente';
		default:
			return '대기';
	}
};
var $author$project$Governance$CommonViews$proposalRow = F4(
	function (userLanguage, timezone, maybeCurrentTime, proposal) {
		var maybeCurrentState = $elm$core$List$head(
			$elm$core$List$reverse(proposal.hf));
		var detailView = function () {
			if (!maybeCurrentState.$) {
				var currentState = maybeCurrentState.a;
				var _v1 = currentState.jQ;
				switch (_v1) {
					case 0:
						return $elm$html$Html$text('');
					case 1:
						return A2($author$project$Governance$CommonViews$proposalActiveTalliesView, userLanguage, proposal);
					case 2:
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__current-state-view')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__current-state-view__state proposal__current-state-view__state--active')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__current-state-view__state__text')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$Strings$Translations$proposal_state_passed(userLanguage))
												]))
										]))
								]));
					case 3:
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__current-state-view')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__current-state-view__state proposal__current-state-view__state--failed')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__current-state-view__state__text')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$Strings$Translations$proposal_state_failed(userLanguage))
												]))
										]))
								]));
					case 4:
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__current-state-view')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__current-state-view__state proposal__current-state-view__state--active')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__current-state-view__state__text')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$Strings$Translations$proposal_state_queued(userLanguage))
												]))
										]))
								]));
					case 5:
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__current-state-view')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__current-state-view__state proposal__current-state-view__state--active')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__current-state-view__state__text')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$Strings$Translations$proposal_state_executed(userLanguage))
												]))
										]))
								]));
					case 6:
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__current-state-view')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__current-state-view__state proposal__current-state-view__state--failed')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__current-state-view__state__text')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$Strings$Translations$proposal_state_canceled(userLanguage))
												]))
										]))
								]));
					default:
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__current-state-view')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__current-state-view__state proposal__current-state-view__state--failed')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__current-state-view__state__text')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$Strings$Translations$proposal_state_expired(userLanguage))
												]))
										]))
								]));
				}
			} else {
				return $elm$html$Html$text('');
			}
		}();
		return A2(
			$elm$html$Html$a,
			A2(
				$elm$core$List$cons,
				$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal'),
				A2(
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href,
					0,
					$author$project$LandingSite$Page$getHrefUrl(
						$author$project$LandingSite$Page$GovernanceProposalDetail(proposal.gb)))),
			_List_fromArray(
				[
					A5($author$project$Governance$CommonViews$proposalContentView, userLanguage, timezone, maybeCurrentTime, proposal, false),
					detailView
				]));
	});
var $author$project$Strings$Translations$recent_proposals = function (lang) {
	switch (lang) {
		case 0:
			return 'Recent Proposals';
		case 1:
			return 'Propuestas activas';
		case 2:
			return '最新提案';
		case 3:
			return 'Propositions récentes';
		default:
			return '최근 제안서';
	}
};
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $author$project$Strings$Translations$view_all_proposals = function (lang) {
	switch (lang) {
		case 0:
			return 'View All Proposals';
		case 1:
			return 'Ver todas las propuestas';
		case 2:
			return '查看所有提案';
		case 3:
			return 'Voir toutes les propositions';
		default:
			return '모든 제안서 보기';
	}
};
var $author$project$Governance$Home$importantProposalsPane = F4(
	function (userLanguage, timezone, maybeCurrentTime, model) {
		var latestProposalsAndViewAll = function () {
			var _v0 = model.b_;
			switch (_v0.$) {
				case 0:
					var proposals = _v0.a.gZ;
					if (!proposals.b) {
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposals')
									]),
								_List_fromArray(
									[
										$author$project$Governance$CommonViews$noProposalsRow(userLanguage)
									]))
							]);
					} else {
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposals')
									]),
								A2(
									$elm$core$List$map,
									function (proposal) {
										return A4($author$project$Governance$CommonViews$proposalRow, userLanguage, timezone, maybeCurrentTime, proposal);
									},
									proposals)),
								A2(
								$elm$html$Html$a,
								A2(
									$elm$core$List$cons,
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__footer'),
									A2(
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href,
										0,
										$author$project$LandingSite$Page$getHrefUrl($author$project$LandingSite$Page$GovernanceProposalOverview))),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Strings$Translations$view_all_proposals(userLanguage))
									]))
							]);
					}
				case 2:
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposals')
								]),
							A2($elm$core$List$repeat, 3, $author$project$Governance$CommonViews$loadingProposalRow)),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__footer  panel__footer--disabled')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Strings$Translations$view_all_proposals(userLanguage))
								]))
						]);
				default:
					return _List_Nil;
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('important-proposals-pane')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel')
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__header')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h4,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											$author$project$Strings$Translations$recent_proposals(userLanguage))
										]))
								])),
						latestProposalsAndViewAll))
				]));
	});
var $author$project$Strings$Translations$comp_distributed = function (lang) {
	switch (lang) {
		case 0:
			return 'COMP Distributed';
		case 1:
			return 'COMP distribuido';
		case 2:
			return 'COMP分布式';
		case 3:
			return 'COMP distribué';
		default:
			return '분배된 COMP';
	}
};
var $author$project$Strings$Translations$comp_remaining = function (lang) {
	switch (lang) {
		case 0:
			return 'COMP Remaining';
		case 1:
			return 'COMP restante';
		case 2:
			return '剩余COMP';
		case 3:
			return 'COMP Restant';
		default:
			return '미분배 COMP 수량';
	}
};
var $author$project$CompoundComponents$Utils$NumberFormatter$formatPercentage = function (decimalValue) {
	return A3(
		$author$project$CompoundComponents$Utils$NumberFormatter$formatToDecimalPlaces,
		2,
		false,
		$author$project$CompoundComponents$Utils$NumberFormatter$interestRateAsPercentage(decimalValue)) + '%';
};
var $author$project$LandingSite$Comp$initialComp = $torreyatcitty$the_best_decimal$Decimal$fromInt(4504949);
var $author$project$Strings$Translations$view = function (lang) {
	switch (lang) {
		case 0:
			return 'View';
		case 1:
			return 'Ver';
		case 2:
			return '视图';
		case 3:
			return 'Vue';
		default:
			return '보기';
	}
};
var $author$project$Governance$Home$compDistributionPanel = F2(
	function (userLanguage, model) {
		var content = function () {
			var _v0 = model.aW;
			switch (_v0.$) {
				case 0:
					var govHistory = _v0.a;
					var remainingComp = govHistory.ih;
					var initialComp = $author$project$LandingSite$Comp$initialComp;
					var remainingCompPercentage = A2(
						$elm$core$Maybe$withDefault,
						$torreyatcitty$the_best_decimal$Decimal$zero,
						A2($torreyatcitty$the_best_decimal$Decimal$fastdiv, remainingComp, initialComp));
					return A2(
						$elm$html$Html$a,
						_Utils_ap(
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('comp-distribution-panel'),
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('content'),
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank')
								]),
							A2(
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href,
								0,
								$author$project$LandingSite$Page$getHrefUrl($author$project$LandingSite$Page$Comp))),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('headline')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												A3($author$project$CompoundComponents$Utils$NumberFormatter$formatToDecimalPlaces, 0, false, remainingComp))
											])),
										A2(
										$elm$html$Html$label,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$Strings$Translations$comp_remaining(userLanguage))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('comp-remaining-view')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('view-action')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('action-text')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														$author$project$Strings$Translations$view(userLanguage))
													])),
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('arrow'),
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('arrow')
													]),
												_List_Nil)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('comp-remaining__bar')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('comp-remaining__bar__fill'),
														A2(
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$style,
														'width',
														$author$project$CompoundComponents$Utils$NumberFormatter$formatPercentage(remainingCompPercentage))
													]),
												_List_Nil)
											]))
									]))
							]));
				case 1:
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('content')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('headline')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('...')
									])),
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Strings$Translations$comp_distributed(userLanguage))
									]))
							]));
				default:
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('content')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('headline headline--loading')
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__label panel__label--loading')
									]),
								_List_Nil)
							]));
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-6 col-xs-12')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('legacy-panel dark')
						]),
					_List_fromArray(
						[content]))
				]));
	});
var $author$project$Governance$Home$loadingOverviewHeaderPanel = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-3 col-xs-6')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('legacy-panel dark')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('content')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('headline headline--loading')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__label panel__label--loading')
								]),
							_List_Nil)
						]))
				]))
		]));
var $author$project$Governance$Home$overviewHeaderPanel = function (_v0) {
	var headlineText = _v0.a;
	var labelText = _v0.b;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-3 col-xs-6')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('legacy-panel dark')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('content')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('headline')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(headlineText)
									])),
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(labelText)
									]))
							]))
					]))
			]));
};
var $author$project$Strings$Translations$votes_delegated = function (lang) {
	switch (lang) {
		case 0:
			return 'Votes Delegated';
		case 1:
			return 'Voto delegado';
		case 2:
			return '委托投票';
		case 3:
			return 'Votes délégués';
		default:
			return '위임된 투표 수';
	}
};
var $author$project$Strings$Translations$voting_addresses = function (lang) {
	switch (lang) {
		case 0:
			return 'Voting Addresses';
		case 1:
			return 'Direcciones de votación';
		case 2:
			return '投票地址';
		case 3:
			return 'Adresses de vote';
		default:
			return '투표 주소';
	}
};
var $author$project$Governance$Home$overviewHeader = F2(
	function (userLanguage, model) {
		var panels = A2(
			$elm$core$List$cons,
			A2($author$project$Governance$Home$compDistributionPanel, userLanguage, model),
			function () {
				var _v0 = model.aW;
				switch (_v0.$) {
					case 0:
						var govHistory = _v0.a;
						return A2(
							$elm$core$List$map,
							$author$project$Governance$Home$overviewHeaderPanel,
							_List_fromArray(
								[
									_Utils_Tuple2(
									A3($author$project$CompoundComponents$Utils$NumberFormatter$formatToDecimalPlaces, 0, true, govHistory.ky),
									$author$project$Strings$Translations$votes_delegated(userLanguage)),
									_Utils_Tuple2(
									$elm$core$String$fromInt(govHistory.kz),
									$author$project$Strings$Translations$voting_addresses(userLanguage))
								]));
					case 1:
						return A2(
							$elm$core$List$map,
							$author$project$Governance$Home$overviewHeaderPanel,
							_List_fromArray(
								[
									_Utils_Tuple2(
									'...',
									$author$project$Strings$Translations$votes_delegated(userLanguage)),
									_Utils_Tuple2(
									'...',
									$author$project$Strings$Translations$voting_addresses(userLanguage))
								]));
					default:
						return A2($elm$core$List$repeat, 2, $author$project$Governance$Home$loadingOverviewHeaderPanel);
				}
			}());
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('dashboard-overview'),
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('row')
				]),
			panels);
	});
var $elm$html$Html$section = _VirtualDom_node('section');
var $author$project$Governance$CommonViews$DelegateSummary = F7(
	function (address, display_name, image_url, votes, vote_weight, proposals_voted, rank) {
		return {hO: address, iv: display_name, iP: image_url, jt: proposals_voted, jy: rank, kw: vote_weight, kx: votes};
	});
var $author$project$Governance$Home$ForSelf = $elm$core$Basics$identity;
var $author$project$Governance$Home$ImageError = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Utils$GovProfileImageHelper$Small = 0;
var $author$project$CompoundComponents$Utils$NumberFormatter$tokenDecimalPlaces = 4;
var $author$project$CompoundComponents$Utils$NumberFormatter$formatTokenBalance = function (decimalValue) {
	var roundedValue = A2($torreyatcitty$the_best_decimal$Decimal$round, -$author$project$CompoundComponents$Utils$NumberFormatter$tokenDecimalPlaces, decimalValue);
	var _v0 = $author$project$CompoundComponents$Utils$NumberFormatter$getSignAndAbsValue(roundedValue);
	var signString = _v0.a;
	var absoluteDecimalValue = _v0.b;
	return _Utils_ap(
		signString,
		A3($author$project$CompoundComponents$Utils$NumberFormatter$formatToDecimalPlaces, $author$project$CompoundComponents$Utils$NumberFormatter$tokenDecimalPlaces, true, absoluteDecimalValue));
};
var $author$project$LandingSite$Page$getAccountProfileUrl = function (address) {
	return 'https://www.tally.xyz/profile/' + (address + '?governanceId=eip155:1:0xc0Da02939E1441F497fd74F78cE7Decb17B66529');
};
var $torreyatcitty$the_best_decimal$Decimal$eq = F2(
	function (a, b) {
		var _v0 = A2($torreyatcitty$the_best_decimal$Decimal$compare, a, b);
		if (_v0 === 1) {
			return true;
		} else {
			return false;
		}
	});
var $torreyatcitty$the_best_decimal$Decimal$gte = F2(
	function (a, b) {
		return A2($torreyatcitty$the_best_decimal$Decimal$gt, a, b) || A2($torreyatcitty$the_best_decimal$Decimal$eq, a, b);
	});
var $author$project$Utils$GovProfileImageHelper$ProfileImageError = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$html$Html$img = _VirtualDom_node('img');
var $author$project$CompoundComponents$Utils$CompoundHtmlAttributes$onError = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'error',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$CompoundComponents$Utils$CompoundHtmlAttributes$src = function (srcName) {
	return A2($elm$html$Html$Attributes$attribute, 'src', srcName);
};
var $author$project$Utils$GovProfileImageHelper$profileImage = function (_v0) {
	var maybeProfileImageUrl = _v0.i1;
	var address = _v0.hO;
	var size = _v0.a3;
	var showProposerIcon = _v0.jJ;
	var useLightStyle = _v0.kt;
	var proposerIcon = function () {
		if (showProposerIcon) {
			var extraProposerIconSizeClass = (!size) ? ' gov-profile-image__proposer-icon--small' : '';
			var extraProposerIconLightClass = useLightStyle ? ' gov-profile-image__proposer-icon--light' : '';
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('gov-profile-image__proposer-icon' + (extraProposerIconSizeClass + extraProposerIconLightClass))
					]),
				_List_Nil);
		} else {
			return $elm$html$Html$text('');
		}
	}();
	var profileImageUrl = A2($elm$core$Maybe$withDefault, '/images/icn-default-avatar-large.svg', maybeProfileImageUrl);
	var extraClass = function () {
		switch (size) {
			case 0:
				return ' gov-profile-image--small';
			case 1:
				return '';
			default:
				return ' gov-profile-image--large';
		}
	}();
	var elementId = 'img-' + address;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('gov-profile-image' + extraClass)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id(elementId),
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('gov-profile-image__raw-image'),
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$src(profileImageUrl),
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$onError(
						A2($author$project$Utils$GovProfileImageHelper$ProfileImageError, elementId, '/images/icn-default-avatar-large.svg'))
					]),
				_List_Nil)
			]));
};
var $author$project$Utils$GovernanceHelper$proposalThreshold = function (network) {
	return (network === 1) ? $torreyatcitty$the_best_decimal$Decimal$fromInt(25000) : $torreyatcitty$the_best_decimal$Decimal$fromInt(100000);
};
var $author$project$CompoundComponents$Eth$Ethereum$shortenedAddressString = F3(
	function (numberOfDigitsLeft, numberOfDigitsRight, address) {
		return A2($elm$core$String$left, numberOfDigitsLeft + 2, address) + ('...' + A2($elm$core$String$right, numberOfDigitsRight, address));
	});
var $author$project$Governance$CommonViews$delegateSummaryRow = F4(
	function (_v0, network, onImageErrorMsg, delegateSummary) {
		var voteWeightString = A2(
			$elm$core$Maybe$withDefault,
			'–',
			A2($elm$core$Maybe$map, $author$project$CompoundComponents$Utils$NumberFormatter$formatPercentage, delegateSummary.kw));
		var profileImageComponent = A2(
			$elm$html$Html$map,
			function (profileImageMsg) {
				var profileElementId = profileImageMsg.a;
				var defaultImage = profileImageMsg.b;
				return A2(onImageErrorMsg, profileElementId, defaultImage);
			},
			$author$project$Utils$GovProfileImageHelper$profileImage(
				{
					hO: delegateSummary.hO,
					i1: delegateSummary.iP,
					jJ: A2(
						$torreyatcitty$the_best_decimal$Decimal$gte,
						delegateSummary.kx,
						$author$project$Utils$GovernanceHelper$proposalThreshold(network)),
					a3: 0,
					kt: true
				}));
		var mobileDisplayName = A2(
			$elm$core$Maybe$withDefault,
			A3($author$project$CompoundComponents$Eth$Ethereum$shortenedAddressString, 4, 4, delegateSummary.hO),
			delegateSummary.iv);
		var displayName = A2($elm$core$Maybe$withDefault, delegateSummary.hO, delegateSummary.iv);
		return A2(
			$elm$html$Html$a,
			_Utils_ap(
				_List_fromArray(
					[
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('delegatee-summary'),
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank')
					]),
				A2(
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href,
					2,
					$author$project$LandingSite$Page$getAccountProfileUrl(delegateSummary.hO))),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-10 col-sm-6')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('delegatee-summary__details')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('delegatee-summary__details__rank-view')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$label,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('delegatee-summary__details__rank')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													$elm$core$String$fromInt(delegateSummary.jy))
												]))
										])),
									profileImageComponent,
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('delegatee-summary__details__display-name mobile-hide')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(displayName)
										])),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('delegatee-summary__details__display-name delegatee-summary__details__display-name--mobile')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(mobileDisplayName)
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-2 text-right mobile-hide')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$CompoundComponents$Utils$NumberFormatter$formatTokenBalance(delegateSummary.kx))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-2 text-right')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(voteWeightString)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-2 text-right mobile-hide')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(delegateSummary.jt))
						]))
				]));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $author$project$Governance$CommonViews$loadingDelegateSummaryRow = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('delegatee-summary delegatee-summary--loading')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-10 col-sm-6')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('delegatee-summary__details')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('delegatee-summary__details__rank-view')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$label,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('delegatee-summary__details__rank delegatee-summary__details__rank--loading')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('gov-profile-image gov-profile-image--small gov-profile-image--loading')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('delegatee-summary__details__display-name delegatee-summary__details__display-name--loading mobile-hide')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('delegatee-summary__details__display-name delegatee-summary__details__display-name--loading delegatee-summary__details__display-name--mobile')
								]),
							_List_Nil)
						]))
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-2 text-right mobile-hide delegatee-summary__votes-view')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('delegatee-summary__votes-view__votes--loading')
						]),
					_List_Nil)
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-2 text-right delegatee-summary__vote-weight-view')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('delegatee-summary__vote-weight-view__vote-weight--loading')
						]),
					_List_Nil)
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-2 text-right mobile-hide delegatee-summary__proposals-created-view')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('delegatee-summary__proposals-created-view__proposals-created--loading')
						]),
					_List_Nil)
				]))
		]));
var $author$project$Strings$Translations$no_top_addresses = function (lang) {
	switch (lang) {
		case 0:
			return 'No votes have been delegated.';
		case 1:
			return 'No se han delegado votos.';
		case 2:
			return '没有投票被委派。';
		case 3:
			return 'Aucun vote n\'a été délégué.';
		default:
			return '위임된 투표가 없습니다.';
	}
};
var $author$project$Governance$CommonViews$noDelegateSummaryRow = function (userLanguage) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('delegatee-summary delegatee-summary--empty')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Strings$Translations$no_top_addresses(userLanguage))
					]))
			]));
};
var $author$project$Strings$Translations$proposals_voted = function (lang) {
	switch (lang) {
		case 0:
			return 'Proposals Voted';
		case 1:
			return 'Propuestas Votadas';
		case 2:
			return '提案已投票';
		case 3:
			return 'Propositions votées';
		default:
			return '투표 참여 횟수';
	}
};
var $author$project$Strings$Translations$rank = function (lang) {
	switch (lang) {
		case 0:
			return 'Rank';
		case 1:
			return 'Rango';
		case 2:
			return '排名';
		case 3:
			return 'Rang';
		default:
			return '순위';
	}
};
var $author$project$Strings$Translations$top_addresses_by_voting_weight = function (lang) {
	switch (lang) {
		case 0:
			return 'Top Addresses by Voting Weight';
		case 1:
			return 'Direcciones principales por peso de votación';
		case 2:
			return '投票权重排序';
		case 3:
			return 'Principales adresses par poids de vote';
		default:
			return '투표 가중치 별 상위 주소';
	}
};
var $author$project$Strings$Translations$view_leaderboard = function (lang) {
	switch (lang) {
		case 0:
			return 'View Leaderboard';
		case 1:
			return 'Ver tabla de clasificación';
		case 2:
			return '查看排行榜';
		case 3:
			return 'Afficher le classement';
		default:
			return '순위표 보기';
	}
};
var $author$project$Strings$Translations$vote_weight = function (lang) {
	switch (lang) {
		case 0:
			return 'Vote Weight';
		case 1:
			return 'Peso del voto';
		case 2:
			return '投票权重';
		case 3:
			return 'Poids du vote';
		default:
			return '투표 가중치';
	}
};
var $author$project$Strings$Translations$votes = function (lang) {
	switch (lang) {
		case 0:
			return 'Votes';
		case 1:
			return 'Votos';
		case 2:
			return '投票数';
		case 3:
			return 'Votes';
		default:
			return '투표 수';
	}
};
var $author$project$Governance$Home$topAddressesPane = F3(
	function (userLanguage, network, model) {
		var topFiveAddressRowsAndViewAll = function () {
			var _v0 = model.cp;
			switch (_v0.$) {
				case 0:
					var topDelegates = _v0.a;
					var onImageErrorMsg = F2(
						function (elementId, imgSrc) {
							return A2($author$project$Governance$Home$ImageError, elementId, imgSrc);
						});
					var importantDelegates = A2(
						$elm$core$List$map,
						A3($author$project$Governance$CommonViews$delegateSummaryRow, userLanguage, network, onImageErrorMsg),
						A2(
							$elm$core$List$filterMap,
							function (compAccount) {
								var _v1 = compAccount.jy;
								if (!_v1.$) {
									var actualRank = _v1.a;
									var votesOverTotalDelegated = function () {
										var _v2 = model.aW;
										if (!_v2.$) {
											var govHistory = _v2.a;
											return A2($torreyatcitty$the_best_decimal$Decimal$fastdiv, compAccount.kx, govHistory.ky);
										} else {
											return $elm$core$Maybe$Nothing;
										}
									}();
									var displayName = compAccount.iv;
									return $elm$core$Maybe$Just(
										A7($author$project$Governance$CommonViews$DelegateSummary, compAccount.hO, displayName, compAccount.iP, compAccount.kx, votesOverTotalDelegated, compAccount.jt, actualRank));
								} else {
									return $elm$core$Maybe$Nothing;
								}
							},
							topDelegates.hK));
					return ($elm$core$List$length(importantDelegates) > 0) ? _List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('top-addresses')
								]),
							importantDelegates),
							A2(
							$elm$html$Html$a,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__footer'),
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank')
									]),
								A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, $author$project$LandingSite$Page$getLeaderboardUrl)),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Strings$Translations$view_leaderboard(userLanguage))
								]))
						]) : _List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('top-addresses')
								]),
							_List_fromArray(
								[
									$author$project$Governance$CommonViews$noDelegateSummaryRow(userLanguage)
								]))
						]);
				case 2:
					return _Utils_ap(
						A2($elm$core$List$repeat, 5, $author$project$Governance$CommonViews$loadingDelegateSummaryRow),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__footer panel__footer--disabled')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Strings$Translations$view_leaderboard(userLanguage))
									]))
							]));
				default:
					return _List_Nil;
			}
		}();
		var columnHeaders = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__labels')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-10 col-sm-6')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$label,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Strings$Translations$rank(userLanguage))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-2 text-right mobile-hide')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$label,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Strings$Translations$votes(userLanguage))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-2 text-right')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$label,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Strings$Translations$vote_weight(userLanguage))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-2 text-right mobile-hide')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$label,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Strings$Translations$proposals_voted(userLanguage))
								]))
						]))
				]));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('top-addresses-pane')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel')
						]),
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__header')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h4,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$Strings$Translations$top_addresses_by_voting_weight(userLanguage))
											]))
									])),
								columnHeaders
							]),
						topFiveAddressRowsAndViewAll))
				]));
	});
var $author$project$Governance$Home$view = F5(
	function (userLanguage, timezone, maybeCurrentTime, network, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('Dashboard')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$section,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('hero')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h2,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											$author$project$Strings$Translations$governance_overview(userLanguage))
										])),
									A2($author$project$Governance$Home$overviewHeader, userLanguage, model)
								]))
						])),
					A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('content')
										]),
									_List_fromArray(
										[
											A4($author$project$Governance$Home$importantProposalsPane, userLanguage, timezone, maybeCurrentTime, model),
											A3($author$project$Governance$Home$topAddressesPane, userLanguage, network, model),
											$author$project$Governance$Home$educationalOverviewContent(userLanguage)
										]))
								]))
						]))
				]));
	});
var $author$project$Strings$Translations$details = function (lang) {
	switch (lang) {
		case 0:
			return 'Details';
		case 1:
			return 'Detalles';
		case 2:
			return '细节';
		case 3:
			return 'Détails';
		default:
			return '세부 사항';
	}
};
var $author$project$Governance$ProposalDetail$loadingActionRow = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__action-row')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__action-row__number details-panel__action-row__number--loading')
				]),
			_List_Nil),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__action-row__title details-panel__action-row__title--loading')
				]),
			_List_Nil)
		]));
var $author$project$Governance$ProposalDetail$loadingParagraph = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__description__paragraph')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__description__paragraph__line')
				]),
			_List_Nil),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__description__paragraph__line')
				]),
			_List_Nil),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__description__paragraph__line')
				]),
			_List_Nil),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__description__paragraph__line')
				]),
			_List_Nil),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__description__paragraph__line')
				]),
			_List_Nil),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__description__paragraph__line')
				]),
			_List_Nil)
		]));
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $dillonkearns$elm_markdown$Markdown$Parser$problemToString = function (problem) {
	switch (problem.$) {
		case 0:
			var string = problem.a;
			return 'Expecting ' + string;
		case 1:
			return 'Expecting int';
		case 2:
			return 'Expecting hex';
		case 3:
			return 'Expecting octal';
		case 4:
			return 'Expecting binary';
		case 5:
			return 'Expecting float';
		case 6:
			return 'Expecting number';
		case 7:
			return 'Expecting variable';
		case 8:
			var string = problem.a;
			return 'Expecting symbol ' + string;
		case 9:
			var string = problem.a;
			return 'Expecting keyword ' + string;
		case 10:
			return 'Expecting keyword end';
		case 11:
			return 'Unexpected char';
		case 12:
			var problemDescription = problem.a;
			return problemDescription;
		default:
			return 'Bad repeat';
	}
};
var $dillonkearns$elm_markdown$Markdown$Parser$deadEndToString = function (deadEnd) {
	return 'Problem at row ' + ($elm$core$String$fromInt(deadEnd.jE) + ('\n' + $dillonkearns$elm_markdown$Markdown$Parser$problemToString(deadEnd.jo)));
};
var $author$project$Governance$CommonViews$deadEndsToString = function (deadEnds) {
	return A2(
		$elm$core$String$join,
		'\n',
		A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$deadEndToString, deadEnds));
};
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$align = $elm$html$Html$Attributes$stringProperty('align');
var $elm$html$Html$Attributes$alt = $elm$html$Html$Attributes$stringProperty('alt');
var $elm$html$Html$blockquote = _VirtualDom_node('blockquote');
var $elm$html$Html$br = _VirtualDom_node('br');
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$code = _VirtualDom_node('code');
var $elm$html$Html$del = _VirtualDom_node('del');
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $elm$html$Html$em = _VirtualDom_node('em');
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $elm$html$Html$h5 = _VirtualDom_node('h5');
var $elm$html$Html$h6 = _VirtualDom_node('h6');
var $elm$html$Html$hr = _VirtualDom_node('hr');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$ol = _VirtualDom_node('ol');
var $dillonkearns$elm_markdown$Markdown$HtmlRenderer$HtmlRenderer = $elm$core$Basics$identity;
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $dillonkearns$elm_markdown$Markdown$Html$resultOr = F2(
	function (ra, rb) {
		if (ra.$ === 1) {
			var singleError = ra.a;
			if (!rb.$) {
				var okValue = rb.a;
				return $elm$core$Result$Ok(okValue);
			} else {
				var errorsSoFar = rb.a;
				return $elm$core$Result$Err(
					A2($elm$core$List$cons, singleError, errorsSoFar));
			}
		} else {
			var okValue = ra.a;
			return $elm$core$Result$Ok(okValue);
		}
	});
var $dillonkearns$elm_markdown$Markdown$Html$attributesToString = function (attributes) {
	return A2(
		$elm$core$String$join,
		' ',
		A2(
			$elm$core$List$map,
			function (_v0) {
				var name = _v0.i6;
				var value = _v0.bK;
				return name + ('=\"' + (value + '\"'));
			},
			attributes));
};
var $dillonkearns$elm_markdown$Markdown$Html$tagToString = F2(
	function (tagName, attributes) {
		return $elm$core$List$isEmpty(attributes) ? ('<' + (tagName + '>')) : ('<' + (tagName + (' ' + ($dillonkearns$elm_markdown$Markdown$Html$attributesToString(attributes) + '>'))));
	});
var $dillonkearns$elm_markdown$Markdown$Html$oneOf = function (decoders) {
	var unwrappedDecoders = A2(
		$elm$core$List$map,
		function (_v1) {
			var rawDecoder = _v1;
			return rawDecoder;
		},
		decoders);
	return function (rawDecoder) {
		return F3(
			function (tagName, attributes, innerBlocks) {
				return A2(
					$elm$core$Result$mapError,
					function (errors) {
						if (!errors.b) {
							return 'Ran into a oneOf with no possibilities!';
						} else {
							if (!errors.b.b) {
								var singleError = errors.a;
								return 'Problem with the given value:\n\n' + (A2($dillonkearns$elm_markdown$Markdown$Html$tagToString, tagName, attributes) + ('\n\n' + (singleError + '\n')));
							} else {
								return 'oneOf failed parsing this value:\n    ' + (A2($dillonkearns$elm_markdown$Markdown$Html$tagToString, tagName, attributes) + ('\n\nParsing failed in the following 2 ways:\n\n\n' + (A2(
									$elm$core$String$join,
									'\n\n',
									A2(
										$elm$core$List$indexedMap,
										F2(
											function (index, error) {
												return '(' + ($elm$core$String$fromInt(index + 1) + (') ' + error));
											}),
										errors)) + '\n')));
							}
						}
					},
					A3(rawDecoder, tagName, attributes, innerBlocks));
			});
	}(
		A3(
			$elm$core$List$foldl,
			F2(
				function (decoder, soFar) {
					return F3(
						function (tagName, attributes, children) {
							return A2(
								$dillonkearns$elm_markdown$Markdown$Html$resultOr,
								A3(decoder, tagName, attributes, children),
								A3(soFar, tagName, attributes, children));
						});
				}),
			F3(
				function (tagName, attributes, children) {
					return $elm$core$Result$Err(_List_Nil);
				}),
			unwrappedDecoders));
};
var $elm$html$Html$pre = _VirtualDom_node('pre');
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $elm$html$Html$Attributes$start = function (n) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'start',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$strong = _VirtualDom_node('strong');
var $elm$html$Html$table = _VirtualDom_node('table');
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $elm$html$Html$td = _VirtualDom_node('td');
var $elm$html$Html$th = _VirtualDom_node('th');
var $elm$html$Html$thead = _VirtualDom_node('thead');
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$core$String$words = _String_words;
var $dillonkearns$elm_markdown$Markdown$Renderer$defaultHtmlRenderer = {
	dm: $elm$html$Html$blockquote(_List_Nil),
	dp: function (_v0) {
		var body = _v0.hY;
		var language = _v0.d3;
		var classes = function () {
			var _v1 = A2($elm$core$Maybe$map, $elm$core$String$words, language);
			if ((!_v1.$) && _v1.a.b) {
				var _v2 = _v1.a;
				var actualLanguage = _v2.a;
				return _List_fromArray(
					[
						$elm$html$Html$Attributes$class('language-' + actualLanguage)
					]);
			} else {
				return _List_Nil;
			}
		}();
		return A2(
			$elm$html$Html$pre,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$code,
					classes,
					_List_fromArray(
						[
							$elm$html$Html$text(body)
						]))
				]));
	},
	dq: function (content) {
		return A2(
			$elm$html$Html$code,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(content)
				]));
	},
	dN: function (children) {
		return A2($elm$html$Html$em, _List_Nil, children);
	},
	dS: A2($elm$html$Html$br, _List_Nil, _List_Nil),
	iL: function (_v3) {
		var level = _v3.iY;
		var children = _v3.ia;
		switch (level) {
			case 0:
				return A2($elm$html$Html$h1, _List_Nil, children);
			case 1:
				return A2($elm$html$Html$h2, _List_Nil, children);
			case 2:
				return A2($elm$html$Html$h3, _List_Nil, children);
			case 3:
				return A2($elm$html$Html$h4, _List_Nil, children);
			case 4:
				return A2($elm$html$Html$h5, _List_Nil, children);
			default:
				return A2($elm$html$Html$h6, _List_Nil, children);
		}
	},
	iO: $dillonkearns$elm_markdown$Markdown$Html$oneOf(_List_Nil),
	dV: function (imageInfo) {
		var _v5 = imageInfo.j5;
		if (!_v5.$) {
			var title = _v5.a;
			return A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(imageInfo.jM),
						$elm$html$Html$Attributes$alt(imageInfo.dh),
						$elm$html$Html$Attributes$title(title)
					]),
				_List_Nil);
		} else {
			return A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(imageInfo.jM),
						$elm$html$Html$Attributes$alt(imageInfo.dh)
					]),
				_List_Nil);
		}
	},
	gq: F2(
		function (link, content) {
			var _v6 = link.j5;
			if (!_v6.$) {
				var title = _v6.a;
				return A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href(link.fN),
							$elm$html$Html$Attributes$title(title)
						]),
					content);
			} else {
				return A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href(link.fN)
						]),
					content);
			}
		}),
	en: F2(
		function (startingIndex, items) {
			return A2(
				$elm$html$Html$ol,
				function () {
					if (startingIndex === 1) {
						return _List_fromArray(
							[
								$elm$html$Html$Attributes$start(startingIndex)
							]);
					} else {
						return _List_Nil;
					}
				}(),
				A2(
					$elm$core$List$map,
					function (itemBlocks) {
						return A2($elm$html$Html$li, _List_Nil, itemBlocks);
					},
					items));
		}),
	gQ: $elm$html$Html$p(_List_Nil),
	eU: function (children) {
		return A2($elm$html$Html$del, _List_Nil, children);
	},
	eV: function (children) {
		return A2($elm$html$Html$strong, _List_Nil, children);
	},
	eY: $elm$html$Html$table(_List_Nil),
	eZ: $elm$html$Html$tbody(_List_Nil),
	e_: function (maybeAlignment) {
		var attrs = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				$elm$core$List$singleton,
				A2(
					$elm$core$Maybe$map,
					$elm$html$Html$Attributes$align,
					A2(
						$elm$core$Maybe$map,
						function (alignment) {
							switch (alignment) {
								case 0:
									return 'left';
								case 2:
									return 'center';
								default:
									return 'right';
							}
						},
						maybeAlignment))));
		return $elm$html$Html$td(attrs);
	},
	e$: $elm$html$Html$thead(_List_Nil),
	e0: function (maybeAlignment) {
		var attrs = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				$elm$core$List$singleton,
				A2(
					$elm$core$Maybe$map,
					$elm$html$Html$Attributes$align,
					A2(
						$elm$core$Maybe$map,
						function (alignment) {
							switch (alignment) {
								case 0:
									return 'left';
								case 2:
									return 'center';
								default:
									return 'right';
							}
						},
						maybeAlignment))));
		return $elm$html$Html$th(attrs);
	},
	c9: $elm$html$Html$tr(_List_Nil),
	e2: $elm$html$Html$text,
	e4: A2($elm$html$Html$hr, _List_Nil, _List_Nil),
	fe: function (items) {
		return A2(
			$elm$html$Html$ul,
			_List_Nil,
			A2(
				$elm$core$List$map,
				function (item) {
					var task = item.a;
					var children = item.b;
					var checkbox = function () {
						switch (task) {
							case 0:
								return $elm$html$Html$text('');
							case 1:
								return A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$disabled(true),
											$elm$html$Html$Attributes$checked(false),
											$elm$html$Html$Attributes$type_('checkbox')
										]),
									_List_Nil);
							default:
								return A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$disabled(true),
											$elm$html$Html$Attributes$checked(true),
											$elm$html$Html$Attributes$type_('checkbox')
										]),
									_List_Nil);
						}
					}();
					return A2(
						$elm$html$Html$li,
						_List_Nil,
						A2($elm$core$List$cons, checkbox, children));
				},
				items));
	}
};
var $elm$core$Char$fromCode = _Char_fromCode;
var $author$project$Governance$CommonViews$getLetterFromIndex = function (index) {
	return $elm$core$String$fromChar(
		$elm$core$Char$fromCode(index + 97));
};
var $dillonkearns$elm_markdown$Markdown$RawBlock$BlankLine = {$: 10};
var $dillonkearns$elm_markdown$Markdown$Block$BlockQuote = function (a) {
	return {$: 3, a: a};
};
var $dillonkearns$elm_markdown$Markdown$RawBlock$BlockQuote = function (a) {
	return {$: 11, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$Cdata = function (a) {
	return {$: 4, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$CodeBlock = function (a) {
	return {$: 7, a: a};
};
var $dillonkearns$elm_markdown$Markdown$RawBlock$CodeBlock = function (a) {
	return {$: 5, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$CodeSpan = function (a) {
	return {$: 6, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$CompletedTask = 2;
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$Emphasis = function (a) {
	return {$: 3, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Inline$Emphasis = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$Parser$EmptyBlock = {$: 0};
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$HardLineBreak = {$: 8};
var $dillonkearns$elm_markdown$Markdown$Block$Heading = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$RawBlock$Heading = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$RawBlock$Html = function (a) {
	return {$: 2, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$HtmlBlock = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$HtmlComment = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$HtmlDeclaration = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$Block$HtmlElement = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $dillonkearns$elm_markdown$Markdown$Block$HtmlInline = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$Image = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $dillonkearns$elm_markdown$Markdown$Block$IncompleteTask = 1;
var $dillonkearns$elm_markdown$Markdown$RawBlock$IndentedCodeBlock = function (a) {
	return {$: 6, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Parser$InlineProblem = function (a) {
	return {$: 2, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$Link = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $dillonkearns$elm_markdown$Markdown$Block$ListItem = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$NoTask = 0;
var $dillonkearns$elm_markdown$Markdown$RawBlock$OpenBlockOrParagraph = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$OrderedList = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $dillonkearns$elm_markdown$Markdown$RawBlock$OrderedListBlock = F6(
	function (a, b, c, d, e, f) {
		return {$: 4, a: a, b: b, c: c, d: d, e: e, f: f};
	});
var $dillonkearns$elm_markdown$Markdown$Block$Paragraph = function (a) {
	return {$: 5, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$Markdown$RawBlock$ParsedBlockQuote = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$ProcessingInstruction = function (a) {
	return {$: 2, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$Strikethrough = function (a) {
	return {$: 5, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$Strong = function (a) {
	return {$: 4, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$Table = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$RawBlock$Table = function (a) {
	return {$: 8, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Table$Table = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$Table$TableDelimiterRow = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$Block$Text = function (a) {
	return {$: 7, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$ThematicBreak = {$: 8};
var $dillonkearns$elm_markdown$Markdown$RawBlock$ThematicBreak = {$: 7};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$Block$UnorderedList = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$RawBlock$UnorderedListBlock = F4(
	function (a, b, c, d) {
		return {$: 3, a: a, b: b, c: c, d: d};
	});
var $dillonkearns$elm_markdown$Markdown$RawBlock$UnparsedInlines = $elm$core$Basics$identity;
var $dillonkearns$elm_markdown$Markdown$Parser$addReference = F2(
	function (state, linkRef) {
		return {
			a: A2($elm$core$List$cons, linkRef, state.a),
			b: state.b
		};
	});
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0;
	return function (s0) {
		var _v1 = parse(s0);
		if (_v1.$ === 1) {
			var x = _v1.b;
			return A2($elm$parser$Parser$Advanced$Bad, false, x);
		} else {
			var a = _v1.b;
			var s1 = _v1.c;
			return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
		}
	};
};
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.jM);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.d, offset) < 0,
					0,
					{fF: col, g: s0.g, m: s0.m, d: offset, jE: row, jM: s0.jM});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.d, s.jE, s.fF, s);
	};
};
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $dillonkearns$elm_markdown$Whitespace$isSpaceOrTab = function (_char) {
	switch (_char) {
		case ' ':
			return true;
		case '\t':
			return true;
		default:
			return false;
	}
};
var $dillonkearns$elm_markdown$Parser$Token$carriageReturn = A2(
	$elm$parser$Parser$Advanced$Token,
	'\r',
	$elm$parser$Parser$Expecting('a carriage return'));
var $dillonkearns$elm_markdown$Parser$Token$newline = A2(
	$elm$parser$Parser$Advanced$Token,
	'\n',
	$elm$parser$Parser$Expecting('a newline'));
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {fF: col, ik: contextStack, jo: problem, jE: row};
	});
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.jE, s.fF, x, s.g));
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.d, s.jE, s.fF, s.jM);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{fF: newCol, g: s.g, m: s.m, d: newOffset, jE: newRow, jM: s.jM});
	};
};
var $dillonkearns$elm_markdown$Whitespace$lineEnd = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			$elm$parser$Parser$Advanced$token($dillonkearns$elm_markdown$Parser$Token$newline),
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$token($dillonkearns$elm_markdown$Parser$Token$carriageReturn),
			$elm$parser$Parser$Advanced$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$Advanced$token($dillonkearns$elm_markdown$Parser$Token$newline),
						$elm$parser$Parser$Advanced$succeed(0)
					])))
		]));
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $dillonkearns$elm_markdown$Markdown$Parser$blankLine = A2(
	$elm$parser$Parser$Advanced$map,
	function (_v0) {
		return $dillonkearns$elm_markdown$Markdown$RawBlock$BlankLine;
	},
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$backtrackable(
			$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$Whitespace$isSpaceOrTab)),
		$dillonkearns$elm_markdown$Whitespace$lineEnd));
var $dillonkearns$elm_markdown$Parser$Token$space = A2(
	$elm$parser$Parser$Advanced$Token,
	' ',
	$elm$parser$Parser$Expecting('a space'));
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $dillonkearns$elm_markdown$Markdown$Parser$blockQuoteStarts = _List_fromArray(
	[
		$elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			'>',
			$elm$parser$Parser$Expecting('>'))),
		A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$backtrackable(
			$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$space)),
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						'>',
						$elm$parser$Parser$Expecting(' >'))),
					$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						' >',
						$elm$parser$Parser$Expecting('  >'))),
					$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						'  >',
						$elm$parser$Parser$Expecting('   >')))
				])))
	]);
var $dillonkearns$elm_markdown$Whitespace$isLineEnd = function (_char) {
	switch (_char) {
		case '\n':
			return true;
		case '\r':
			return true;
		default:
			return false;
	}
};
var $dillonkearns$elm_markdown$Helpers$chompUntilLineEndOrEnd = $elm$parser$Parser$Advanced$chompWhile(
	A2($elm$core$Basics$composeL, $elm$core$Basics$not, $dillonkearns$elm_markdown$Whitespace$isLineEnd));
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.d, s1.d, s0.jM),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.jM),
			s.d) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $dillonkearns$elm_markdown$Helpers$endOfFile = $elm$parser$Parser$Advanced$end(
	$elm$parser$Parser$Expecting('the end of the input'));
var $dillonkearns$elm_markdown$Helpers$lineEndOrEnd = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[$dillonkearns$elm_markdown$Whitespace$lineEnd, $dillonkearns$elm_markdown$Helpers$endOfFile]));
var $dillonkearns$elm_markdown$Markdown$Parser$blockQuote = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Markdown$RawBlock$BlockQuote),
			$elm$parser$Parser$Advanced$oneOf($dillonkearns$elm_markdown$Markdown$Parser$blockQuoteStarts)),
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$space),
					$elm$parser$Parser$Advanced$succeed(0)
				]))),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$getChompedString($dillonkearns$elm_markdown$Helpers$chompUntilLineEndOrEnd),
		$dillonkearns$elm_markdown$Helpers$lineEndOrEnd));
var $dillonkearns$elm_markdown$Markdown$Parser$deadEndsToString = function (deadEnds) {
	return A2(
		$elm$core$String$join,
		'\n',
		A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$deadEndToString, deadEnds));
};
var $dillonkearns$elm_markdown$HtmlParser$Cdata = function (a) {
	return {$: 3, a: a};
};
var $dillonkearns$elm_markdown$HtmlParser$Element = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $dillonkearns$elm_markdown$HtmlParser$Text = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.d, s.jM);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{fF: 1, g: s.g, m: s.m, d: s.d + 1, jE: s.jE + 1, jM: s.jM}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{fF: s.fF + 1, g: s.g, m: s.m, d: newOffset, jE: s.jE, jM: s.jM}));
		};
	});
var $dillonkearns$elm_markdown$HtmlParser$expectTagNameCharacter = $elm$parser$Parser$Expecting('at least 1 tag name character');
var $dillonkearns$elm_markdown$HtmlParser$tagNameCharacter = function (c) {
	switch (c) {
		case ' ':
			return false;
		case '\r':
			return false;
		case '\n':
			return false;
		case '\t':
			return false;
		case '/':
			return false;
		case '<':
			return false;
		case '>':
			return false;
		case '\"':
			return false;
		case '\'':
			return false;
		case '=':
			return false;
		default:
			return true;
	}
};
var $dillonkearns$elm_markdown$HtmlParser$tagName = A2(
	$elm$parser$Parser$Advanced$mapChompedString,
	F2(
		function (name, _v0) {
			return $elm$core$String$toLower(name);
		}),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		A2($elm$parser$Parser$Advanced$chompIf, $dillonkearns$elm_markdown$HtmlParser$tagNameCharacter, $dillonkearns$elm_markdown$HtmlParser$expectTagNameCharacter),
		$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$HtmlParser$tagNameCharacter)));
var $dillonkearns$elm_markdown$HtmlParser$attributeName = $dillonkearns$elm_markdown$HtmlParser$tagName;
var $dillonkearns$elm_markdown$HtmlParser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$token(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $dillonkearns$elm_markdown$HtmlParser$entities = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('amp', '&'),
			_Utils_Tuple2('lt', '<'),
			_Utils_Tuple2('gt', '>'),
			_Utils_Tuple2('apos', '\''),
			_Utils_Tuple2('quot', '\"')
		]));
var $elm$core$Result$fromMaybe = F2(
	function (err, maybe) {
		if (!maybe.$) {
			var v = maybe.a;
			return $elm$core$Result$Ok(v);
		} else {
			return $elm$core$Result$Err(err);
		}
	});
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $dillonkearns$elm_markdown$HtmlParser$decodeEscape = function (s) {
	return A2($elm$core$String$startsWith, '#x', s) ? A2(
		$elm$core$Result$mapError,
		$elm$parser$Parser$Problem,
		A2(
			$elm$core$Result$map,
			$elm$core$Char$fromCode,
			$rtfeldman$elm_hex$Hex$fromString(
				A2($elm$core$String$dropLeft, 2, s)))) : (A2($elm$core$String$startsWith, '#', s) ? A2(
		$elm$core$Result$fromMaybe,
		$elm$parser$Parser$Problem('Invalid escaped character: ' + s),
		A2(
			$elm$core$Maybe$map,
			$elm$core$Char$fromCode,
			$elm$core$String$toInt(
				A2($elm$core$String$dropLeft, 1, s)))) : A2(
		$elm$core$Result$fromMaybe,
		$elm$parser$Parser$Problem('No entity named \"&' + (s + ';\" found.')),
		A2($elm$core$Dict$get, s, $dillonkearns$elm_markdown$HtmlParser$entities)));
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $dillonkearns$elm_markdown$HtmlParser$escapedChar = function (end_) {
	var process = function (entityStr) {
		var _v0 = $dillonkearns$elm_markdown$HtmlParser$decodeEscape(entityStr);
		if (!_v0.$) {
			var c = _v0.a;
			return $elm$parser$Parser$Advanced$succeed(c);
		} else {
			var e = _v0.a;
			return $elm$parser$Parser$Advanced$problem(e);
		}
	};
	var isEntityChar = function (c) {
		return (!_Utils_eq(c, end_)) && (c !== ';');
	};
	return A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
			$dillonkearns$elm_markdown$HtmlParser$symbol('&')),
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$andThen,
				process,
				$elm$parser$Parser$Advanced$getChompedString(
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						A2(
							$elm$parser$Parser$Advanced$chompIf,
							isEntityChar,
							$elm$parser$Parser$Expecting('an entity character')),
						$elm$parser$Parser$Advanced$chompWhile(isEntityChar)))),
			$dillonkearns$elm_markdown$HtmlParser$symbol(';')));
};
var $dillonkearns$elm_markdown$HtmlParser$textStringStep = F3(
	function (closingChar, predicate, accum) {
		return A2(
			$elm$parser$Parser$Advanced$andThen,
			function (soFar) {
				return $elm$parser$Parser$Advanced$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$parser$Parser$Advanced$map,
							function (escaped) {
								return $elm$parser$Parser$Advanced$Loop(
									_Utils_ap(
										accum,
										_Utils_ap(
											soFar,
											$elm$core$String$fromChar(escaped))));
							},
							$dillonkearns$elm_markdown$HtmlParser$escapedChar(closingChar)),
							$elm$parser$Parser$Advanced$succeed(
							$elm$parser$Parser$Advanced$Done(
								_Utils_ap(accum, soFar)))
						]));
			},
			$elm$parser$Parser$Advanced$getChompedString(
				$elm$parser$Parser$Advanced$chompWhile(predicate)));
	});
var $dillonkearns$elm_markdown$HtmlParser$textString = function (closingChar) {
	var predicate = function (c) {
		return (!_Utils_eq(c, closingChar)) && (c !== '&');
	};
	return A2(
		$elm$parser$Parser$Advanced$loop,
		'',
		A2($dillonkearns$elm_markdown$HtmlParser$textStringStep, closingChar, predicate));
};
var $dillonkearns$elm_markdown$HtmlParser$attributeValue = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
				$dillonkearns$elm_markdown$HtmlParser$symbol('\"')),
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$dillonkearns$elm_markdown$HtmlParser$textString('\"'),
				$dillonkearns$elm_markdown$HtmlParser$symbol('\"'))),
			A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
				$dillonkearns$elm_markdown$HtmlParser$symbol('\'')),
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$dillonkearns$elm_markdown$HtmlParser$textString('\''),
				$dillonkearns$elm_markdown$HtmlParser$symbol('\'')))
		]));
var $dillonkearns$elm_markdown$HtmlParser$keepOldest = F2(
	function (_new, mValue) {
		if (!mValue.$) {
			var v = mValue.a;
			return $elm$core$Maybe$Just(v);
		} else {
			return $elm$core$Maybe$Just(_new);
		}
	});
var $dillonkearns$elm_markdown$HtmlParser$isWhitespace = function (c) {
	switch (c) {
		case ' ':
			return true;
		case '\r':
			return true;
		case '\n':
			return true;
		case '\t':
			return true;
		default:
			return false;
	}
};
var $dillonkearns$elm_markdown$HtmlParser$whiteSpace = $elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$HtmlParser$isWhitespace);
var $dillonkearns$elm_markdown$HtmlParser$attributesStep = function (attrs) {
	var process = F2(
		function (name, value) {
			return $elm$parser$Parser$Advanced$Loop(
				A3(
					$elm$core$Dict$update,
					$elm$core$String$toLower(name),
					$dillonkearns$elm_markdown$HtmlParser$keepOldest(value),
					attrs));
		});
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$keeper,
					$elm$parser$Parser$Advanced$succeed(process),
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						A2(
							$elm$parser$Parser$Advanced$ignorer,
							A2($elm$parser$Parser$Advanced$ignorer, $dillonkearns$elm_markdown$HtmlParser$attributeName, $dillonkearns$elm_markdown$HtmlParser$whiteSpace),
							$dillonkearns$elm_markdown$HtmlParser$symbol('=')),
						$dillonkearns$elm_markdown$HtmlParser$whiteSpace)),
				A2($elm$parser$Parser$Advanced$ignorer, $dillonkearns$elm_markdown$HtmlParser$attributeValue, $dillonkearns$elm_markdown$HtmlParser$whiteSpace)),
				$elm$parser$Parser$Advanced$succeed(
				$elm$parser$Parser$Advanced$Done(attrs))
			]));
};
var $dillonkearns$elm_markdown$HtmlParser$attributes = A2(
	$elm$parser$Parser$Advanced$map,
	A2(
		$elm$core$Dict$foldl,
		F3(
			function (key, value, accum) {
				return A2(
					$elm$core$List$cons,
					{i6: key, bK: value},
					accum);
			}),
		_List_Nil),
	A2($elm$parser$Parser$Advanced$loop, $elm$core$Dict$empty, $dillonkearns$elm_markdown$HtmlParser$attributesStep));
var $elm$parser$Parser$Advanced$chompUntilEndOr = function (str) {
	return function (s) {
		var _v0 = A5(_Parser_findSubString, str, s.d, s.jE, s.fF, s.jM);
		var newOffset = _v0.a;
		var newRow = _v0.b;
		var newCol = _v0.c;
		var adjustedOffset = (newOffset < 0) ? $elm$core$String$length(s.jM) : newOffset;
		return A3(
			$elm$parser$Parser$Advanced$Good,
			_Utils_cmp(s.d, adjustedOffset) < 0,
			0,
			{fF: newCol, g: s.g, m: s.m, d: adjustedOffset, jE: newRow, jM: s.jM});
	};
};
var $dillonkearns$elm_markdown$HtmlParser$cdata = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
		$dillonkearns$elm_markdown$HtmlParser$symbol('<![CDATA[')),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$getChompedString(
			$elm$parser$Parser$Advanced$chompUntilEndOr(']]>')),
		$dillonkearns$elm_markdown$HtmlParser$symbol(']]>')));
var $dillonkearns$elm_markdown$HtmlParser$childrenStep = F2(
	function (options, accum) {
		return A2(
			$elm$parser$Parser$Advanced$map,
			function (f) {
				return f(accum);
			},
			$elm$parser$Parser$Advanced$oneOf(options));
	});
var $dillonkearns$elm_markdown$HtmlParser$fail = function (str) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(str));
};
var $dillonkearns$elm_markdown$HtmlParser$closingTag = function (startTagName) {
	var closingTagName = A2(
		$elm$parser$Parser$Advanced$andThen,
		function (endTagName) {
			return _Utils_eq(startTagName, endTagName) ? $elm$parser$Parser$Advanced$succeed(0) : $dillonkearns$elm_markdown$HtmlParser$fail('tag name mismatch: ' + (startTagName + (' and ' + endTagName)));
		},
		$dillonkearns$elm_markdown$HtmlParser$tagName);
	return A2(
		$elm$parser$Parser$Advanced$ignorer,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$dillonkearns$elm_markdown$HtmlParser$symbol('</'),
					$dillonkearns$elm_markdown$HtmlParser$whiteSpace),
				closingTagName),
			$dillonkearns$elm_markdown$HtmlParser$whiteSpace),
		$dillonkearns$elm_markdown$HtmlParser$symbol('>'));
};
var $dillonkearns$elm_markdown$HtmlParser$Comment = function (a) {
	return {$: 2, a: a};
};
var $dillonkearns$elm_markdown$HtmlParser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $dillonkearns$elm_markdown$HtmlParser$comment = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$HtmlParser$Comment),
		$elm$parser$Parser$Advanced$token(
			$dillonkearns$elm_markdown$HtmlParser$toToken('<!--'))),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$getChompedString(
			$elm$parser$Parser$Advanced$chompUntilEndOr('-->')),
		$elm$parser$Parser$Advanced$token(
			$dillonkearns$elm_markdown$HtmlParser$toToken('-->'))));
var $dillonkearns$elm_markdown$HtmlParser$Declaration = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $dillonkearns$elm_markdown$HtmlParser$expectUppercaseCharacter = $elm$parser$Parser$Expecting('at least 1 uppercase character');
var $dillonkearns$elm_markdown$HtmlParser$allUppercase = $elm$parser$Parser$Advanced$getChompedString(
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		A2($elm$parser$Parser$Advanced$chompIf, $elm$core$Char$isUpper, $dillonkearns$elm_markdown$HtmlParser$expectUppercaseCharacter),
		$elm$parser$Parser$Advanced$chompWhile($elm$core$Char$isUpper)));
var $dillonkearns$elm_markdown$HtmlParser$oneOrMoreWhiteSpace = A2(
	$elm$parser$Parser$Advanced$ignorer,
	A2(
		$elm$parser$Parser$Advanced$chompIf,
		$dillonkearns$elm_markdown$HtmlParser$isWhitespace,
		$elm$parser$Parser$Expecting('at least one whitespace')),
	$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$HtmlParser$isWhitespace));
var $dillonkearns$elm_markdown$HtmlParser$docType = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$HtmlParser$Declaration),
			$dillonkearns$elm_markdown$HtmlParser$symbol('<!')),
		A2($elm$parser$Parser$Advanced$ignorer, $dillonkearns$elm_markdown$HtmlParser$allUppercase, $dillonkearns$elm_markdown$HtmlParser$oneOrMoreWhiteSpace)),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$getChompedString(
			$elm$parser$Parser$Advanced$chompUntilEndOr('>')),
		$dillonkearns$elm_markdown$HtmlParser$symbol('>')));
var $dillonkearns$elm_markdown$HtmlParser$ProcessingInstruction = function (a) {
	return {$: 4, a: a};
};
var $dillonkearns$elm_markdown$HtmlParser$processingInstruction = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$HtmlParser$ProcessingInstruction),
		$dillonkearns$elm_markdown$HtmlParser$symbol('<?')),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$getChompedString(
			$elm$parser$Parser$Advanced$chompUntilEndOr('?>')),
		$dillonkearns$elm_markdown$HtmlParser$symbol('?>')));
var $dillonkearns$elm_markdown$HtmlParser$isNotTextNodeIgnoreChar = function (c) {
	switch (c) {
		case '<':
			return false;
		case '&':
			return false;
		default:
			return true;
	}
};
var $dillonkearns$elm_markdown$HtmlParser$textNodeStringStepOptions = _List_fromArray(
	[
		A2(
		$elm$parser$Parser$Advanced$map,
		function (_v0) {
			return $elm$parser$Parser$Advanced$Loop(0);
		},
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$chompIf,
				$dillonkearns$elm_markdown$HtmlParser$isNotTextNodeIgnoreChar,
				$elm$parser$Parser$Expecting('is not & or <')),
			$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$HtmlParser$isNotTextNodeIgnoreChar))),
		A2(
		$elm$parser$Parser$Advanced$map,
		function (_v1) {
			return $elm$parser$Parser$Advanced$Loop(0);
		},
		$dillonkearns$elm_markdown$HtmlParser$escapedChar('<')),
		$elm$parser$Parser$Advanced$succeed(
		$elm$parser$Parser$Advanced$Done(0))
	]);
var $dillonkearns$elm_markdown$HtmlParser$textNodeStringStep = function (_v0) {
	return $elm$parser$Parser$Advanced$oneOf($dillonkearns$elm_markdown$HtmlParser$textNodeStringStepOptions);
};
var $dillonkearns$elm_markdown$HtmlParser$textNodeString = $elm$parser$Parser$Advanced$getChompedString(
	A2($elm$parser$Parser$Advanced$loop, 0, $dillonkearns$elm_markdown$HtmlParser$textNodeStringStep));
var $dillonkearns$elm_markdown$HtmlParser$children = function (startTagName) {
	return A2(
		$elm$parser$Parser$Advanced$loop,
		_List_Nil,
		$dillonkearns$elm_markdown$HtmlParser$childrenStep(
			$dillonkearns$elm_markdown$HtmlParser$childrenStepOptions(startTagName)));
};
var $dillonkearns$elm_markdown$HtmlParser$childrenStepOptions = function (startTagName) {
	return _List_fromArray(
		[
			A2(
			$elm$parser$Parser$Advanced$map,
			F2(
				function (_v1, accum) {
					return $elm$parser$Parser$Advanced$Done(
						$elm$core$List$reverse(accum));
				}),
			$dillonkearns$elm_markdown$HtmlParser$closingTag(startTagName)),
			A2(
			$elm$parser$Parser$Advanced$andThen,
			function (text) {
				return $elm$core$String$isEmpty(text) ? A2(
					$elm$parser$Parser$Advanced$map,
					F2(
						function (_v2, accum) {
							return $elm$parser$Parser$Advanced$Done(
								$elm$core$List$reverse(accum));
						}),
					$dillonkearns$elm_markdown$HtmlParser$closingTag(startTagName)) : $elm$parser$Parser$Advanced$succeed(
					function (accum) {
						return $elm$parser$Parser$Advanced$Loop(
							A2(
								$elm$core$List$cons,
								$dillonkearns$elm_markdown$HtmlParser$Text(text),
								accum));
					});
			},
			$dillonkearns$elm_markdown$HtmlParser$textNodeString),
			A2(
			$elm$parser$Parser$Advanced$map,
			F2(
				function (_new, accum) {
					return $elm$parser$Parser$Advanced$Loop(
						A2($elm$core$List$cons, _new, accum));
				}),
			$dillonkearns$elm_markdown$HtmlParser$cyclic$html())
		]);
};
var $dillonkearns$elm_markdown$HtmlParser$elementContinuation = function (startTagName) {
	return A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed(
					$dillonkearns$elm_markdown$HtmlParser$Element(startTagName)),
				$dillonkearns$elm_markdown$HtmlParser$whiteSpace),
			A2($elm$parser$Parser$Advanced$ignorer, $dillonkearns$elm_markdown$HtmlParser$attributes, $dillonkearns$elm_markdown$HtmlParser$whiteSpace)),
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$Advanced$map,
					function (_v0) {
						return _List_Nil;
					},
					$dillonkearns$elm_markdown$HtmlParser$symbol('/>')),
					A2(
					$elm$parser$Parser$Advanced$keeper,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
						$dillonkearns$elm_markdown$HtmlParser$symbol('>')),
					$dillonkearns$elm_markdown$HtmlParser$children(startTagName))
				])));
};
function $dillonkearns$elm_markdown$HtmlParser$cyclic$html() {
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2($elm$parser$Parser$Advanced$map, $dillonkearns$elm_markdown$HtmlParser$Cdata, $dillonkearns$elm_markdown$HtmlParser$cdata),
				$dillonkearns$elm_markdown$HtmlParser$processingInstruction,
				$dillonkearns$elm_markdown$HtmlParser$comment,
				$dillonkearns$elm_markdown$HtmlParser$docType,
				$dillonkearns$elm_markdown$HtmlParser$cyclic$element()
			]));
}
function $dillonkearns$elm_markdown$HtmlParser$cyclic$element() {
	return A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
			$dillonkearns$elm_markdown$HtmlParser$symbol('<')),
		A2($elm$parser$Parser$Advanced$andThen, $dillonkearns$elm_markdown$HtmlParser$elementContinuation, $dillonkearns$elm_markdown$HtmlParser$tagName));
}
var $dillonkearns$elm_markdown$HtmlParser$html = $dillonkearns$elm_markdown$HtmlParser$cyclic$html();
$dillonkearns$elm_markdown$HtmlParser$cyclic$html = function () {
	return $dillonkearns$elm_markdown$HtmlParser$html;
};
var $dillonkearns$elm_markdown$HtmlParser$element = $dillonkearns$elm_markdown$HtmlParser$cyclic$element();
$dillonkearns$elm_markdown$HtmlParser$cyclic$element = function () {
	return $dillonkearns$elm_markdown$HtmlParser$element;
};
var $dillonkearns$elm_markdown$Parser$Token$tab = A2(
	$elm$parser$Parser$Advanced$Token,
	'\t',
	$elm$parser$Parser$Expecting('a tab'));
var $dillonkearns$elm_markdown$Markdown$Parser$exactlyFourSpaces = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$tab),
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$backtrackable(
				$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$space)),
			$elm$parser$Parser$Advanced$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$Advanced$symbol(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'   ',
							$elm$parser$Parser$ExpectingSymbol('Indentation'))),
						$elm$parser$Parser$Advanced$symbol(
						A2(
							$elm$parser$Parser$Advanced$Token,
							' \t',
							$elm$parser$Parser$ExpectingSymbol('Indentation'))),
						$elm$parser$Parser$Advanced$symbol(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'  \t',
							$elm$parser$Parser$ExpectingSymbol('Indentation')))
					])))
		]));
var $dillonkearns$elm_markdown$Markdown$Parser$indentedCodeBlock = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Markdown$RawBlock$IndentedCodeBlock),
		$dillonkearns$elm_markdown$Markdown$Parser$exactlyFourSpaces),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$getChompedString($dillonkearns$elm_markdown$Helpers$chompUntilLineEndOrEnd),
		$dillonkearns$elm_markdown$Helpers$lineEndOrEnd));
var $dillonkearns$elm_markdown$Markdown$Helpers$isEven = function (_int) {
	return !A2($elm$core$Basics$modBy, 2, _int);
};
var $dillonkearns$elm_markdown$Markdown$Block$Loose = 0;
var $dillonkearns$elm_markdown$Markdown$Block$Tight = 1;
var $dillonkearns$elm_markdown$Markdown$Parser$isTightBoolToListDisplay = function (isTight) {
	return isTight ? 1 : 0;
};
var $dillonkearns$elm_markdown$Markdown$Parser$joinRawStringsWith = F3(
	function (joinWith, string1, string2) {
		var _v0 = _Utils_Tuple2(string1, string2);
		if (_v0.a === '') {
			return string2;
		} else {
			if (_v0.b === '') {
				return string1;
			} else {
				return _Utils_ap(
					string1,
					_Utils_ap(joinWith, string2));
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$Parser$joinStringsPreserveAll = F2(
	function (string1, string2) {
		return string1 + ('\n' + string2);
	});
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $dillonkearns$elm_markdown$Markdown$Parser$innerParagraphParser = A2(
	$elm$parser$Parser$Advanced$mapChompedString,
	F2(
		function (rawLine, _v0) {
			return $dillonkearns$elm_markdown$Markdown$RawBlock$OpenBlockOrParagraph(rawLine);
		}),
	$dillonkearns$elm_markdown$Helpers$chompUntilLineEndOrEnd);
var $dillonkearns$elm_markdown$Markdown$Parser$openBlockOrParagraphParser = A2($elm$parser$Parser$Advanced$ignorer, $dillonkearns$elm_markdown$Markdown$Parser$innerParagraphParser, $dillonkearns$elm_markdown$Helpers$lineEndOrEnd);
var $dillonkearns$elm_markdown$Markdown$OrderedList$ListItem = F4(
	function (order, intended, marker, body) {
		return {hY: body, iV: intended, iZ: marker, jf: order};
	});
var $elm$parser$Parser$Advanced$getCol = function (s) {
	return A3($elm$parser$Parser$Advanced$Good, false, s.fF, s);
};
var $dillonkearns$elm_markdown$Markdown$OrderedList$orderedListEmptyItemParser = A2(
	$elm$parser$Parser$Advanced$keeper,
	$elm$parser$Parser$Advanced$succeed(
		function (bodyStartPos) {
			return _Utils_Tuple2(bodyStartPos, '');
		}),
	A2($elm$parser$Parser$Advanced$ignorer, $elm$parser$Parser$Advanced$getCol, $dillonkearns$elm_markdown$Helpers$lineEndOrEnd));
var $dillonkearns$elm_markdown$Parser$Extra$chompOneOrMore = function (condition) {
	return A2(
		$elm$parser$Parser$Advanced$ignorer,
		A2(
			$elm$parser$Parser$Advanced$chompIf,
			condition,
			$elm$parser$Parser$Problem('Expected one or more character')),
		$elm$parser$Parser$Advanced$chompWhile(condition));
};
var $dillonkearns$elm_markdown$Markdown$OrderedList$orderedListItemBodyParser = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed(
				F2(
					function (bodyStartPos, item) {
						return _Utils_Tuple2(bodyStartPos, item);
					})),
			$dillonkearns$elm_markdown$Parser$Extra$chompOneOrMore($dillonkearns$elm_markdown$Whitespace$isSpaceOrTab)),
		$elm$parser$Parser$Advanced$getCol),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$getChompedString($dillonkearns$elm_markdown$Helpers$chompUntilLineEndOrEnd),
		$dillonkearns$elm_markdown$Helpers$lineEndOrEnd));
var $dillonkearns$elm_markdown$Markdown$OrderedList$Dot = 0;
var $dillonkearns$elm_markdown$Markdown$OrderedList$Paren = 1;
var $dillonkearns$elm_markdown$Parser$Token$closingParen = A2(
	$elm$parser$Parser$Advanced$Token,
	')',
	$elm$parser$Parser$Expecting('a `)`'));
var $dillonkearns$elm_markdown$Parser$Token$dot = A2(
	$elm$parser$Parser$Advanced$Token,
	'.',
	$elm$parser$Parser$Expecting('a `.`'));
var $dillonkearns$elm_markdown$Markdown$OrderedList$orderedListMarkerParser = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed(0),
			$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$dot)),
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed(1),
			$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$closingParen))
		]));
var $dillonkearns$elm_markdown$Parser$Extra$positiveInteger = A2(
	$elm$parser$Parser$Advanced$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	$dillonkearns$elm_markdown$Parser$Extra$chompOneOrMore($elm$core$Char$isDigit));
var $dillonkearns$elm_markdown$Markdown$OrderedList$positiveIntegerMaxOf9Digits = A2(
	$elm$parser$Parser$Advanced$andThen,
	function (parsed) {
		return (parsed <= 999999999) ? $elm$parser$Parser$Advanced$succeed(parsed) : $elm$parser$Parser$Advanced$problem(
			$elm$parser$Parser$Problem('Starting numbers must be nine digits or less.'));
	},
	$dillonkearns$elm_markdown$Parser$Extra$positiveInteger);
var $dillonkearns$elm_markdown$Whitespace$space = $elm$parser$Parser$Advanced$token($dillonkearns$elm_markdown$Parser$Token$space);
var $dillonkearns$elm_markdown$Parser$Extra$upTo = F2(
	function (n, parser) {
		var _v0 = A2($elm$core$List$repeat, n, parser);
		if (!_v0.b) {
			return $elm$parser$Parser$Advanced$succeed(0);
		} else {
			var firstParser = _v0.a;
			var remainingParsers = _v0.b;
			return A3(
				$elm$core$List$foldl,
				F2(
					function (p, parsers) {
						return $elm$parser$Parser$Advanced$oneOf(
							_List_fromArray(
								[
									A2($elm$parser$Parser$Advanced$ignorer, p, parsers),
									$elm$parser$Parser$Advanced$succeed(0)
								]));
					}),
				$elm$parser$Parser$Advanced$oneOf(
					_List_fromArray(
						[
							firstParser,
							$elm$parser$Parser$Advanced$succeed(0)
						])),
				remainingParsers);
		}
	});
var $dillonkearns$elm_markdown$Markdown$OrderedList$validateStartsWith1 = function (parsed) {
	if (parsed === 1) {
		return $elm$parser$Parser$Advanced$succeed(parsed);
	} else {
		return $elm$parser$Parser$Advanced$problem(
			$elm$parser$Parser$Problem('Lists inside a paragraph or after a paragraph without a blank line must start with 1'));
	}
};
var $dillonkearns$elm_markdown$Markdown$OrderedList$orderedListOrderParser = function (previousWasBody) {
	return previousWasBody ? A2(
		$elm$parser$Parser$Advanced$andThen,
		$dillonkearns$elm_markdown$Markdown$OrderedList$validateStartsWith1,
		A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
				A2($dillonkearns$elm_markdown$Parser$Extra$upTo, 3, $dillonkearns$elm_markdown$Whitespace$space)),
			$dillonkearns$elm_markdown$Markdown$OrderedList$positiveIntegerMaxOf9Digits)) : A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
			A2($dillonkearns$elm_markdown$Parser$Extra$upTo, 3, $dillonkearns$elm_markdown$Whitespace$space)),
		$dillonkearns$elm_markdown$Markdown$OrderedList$positiveIntegerMaxOf9Digits);
};
var $dillonkearns$elm_markdown$Markdown$OrderedList$parser = function (previousWasBody) {
	var parseSubsequentItem = F5(
		function (start, order, marker, mid, _v0) {
			var end = _v0.a;
			var body = _v0.b;
			return ((end - mid) <= 4) ? A4($dillonkearns$elm_markdown$Markdown$OrderedList$ListItem, order, end - start, marker, body) : A4(
				$dillonkearns$elm_markdown$Markdown$OrderedList$ListItem,
				order,
				(mid - start) + 1,
				marker,
				_Utils_ap(
					A2($elm$core$String$repeat, (end - mid) - 1, ' '),
					body));
		});
	return A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$keeper,
					A2(
						$elm$parser$Parser$Advanced$keeper,
						$elm$parser$Parser$Advanced$succeed(parseSubsequentItem),
						$elm$parser$Parser$Advanced$getCol),
					$elm$parser$Parser$Advanced$backtrackable(
						$dillonkearns$elm_markdown$Markdown$OrderedList$orderedListOrderParser(previousWasBody))),
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$OrderedList$orderedListMarkerParser)),
			$elm$parser$Parser$Advanced$getCol),
		previousWasBody ? $dillonkearns$elm_markdown$Markdown$OrderedList$orderedListItemBodyParser : $elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[$dillonkearns$elm_markdown$Markdown$OrderedList$orderedListEmptyItemParser, $dillonkearns$elm_markdown$Markdown$OrderedList$orderedListItemBodyParser])));
};
var $dillonkearns$elm_markdown$Markdown$Parser$orderedListBlock = function (previousWasBody) {
	return A2(
		$elm$parser$Parser$Advanced$map,
		function (item) {
			return A6($dillonkearns$elm_markdown$Markdown$RawBlock$OrderedListBlock, true, item.iV, item.iZ, item.jf, _List_Nil, item.hY);
		},
		$dillonkearns$elm_markdown$Markdown$OrderedList$parser(previousWasBody));
};
var $dillonkearns$elm_markdown$Markdown$Inline$CodeInline = function (a) {
	return {$: 2, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Inline$HardLineBreak = {$: 1};
var $dillonkearns$elm_markdown$Markdown$Inline$HtmlInline = function (a) {
	return {$: 5, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Inline$Image = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $dillonkearns$elm_markdown$Markdown$Inline$Link = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $dillonkearns$elm_markdown$Markdown$Inline$Strikethrough = function (a) {
	return {$: 7, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Inline$Text = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$matchToInline = function (_v0) {
	var match = _v0;
	var _v1 = match.hw;
	switch (_v1.$) {
		case 0:
			return $dillonkearns$elm_markdown$Markdown$Inline$Text(match.e2);
		case 1:
			return $dillonkearns$elm_markdown$Markdown$Inline$HardLineBreak;
		case 2:
			return $dillonkearns$elm_markdown$Markdown$Inline$CodeInline(match.e2);
		case 3:
			var _v2 = _v1.a;
			var text = _v2.a;
			var url = _v2.b;
			return A3(
				$dillonkearns$elm_markdown$Markdown$Inline$Link,
				url,
				$elm$core$Maybe$Nothing,
				_List_fromArray(
					[
						$dillonkearns$elm_markdown$Markdown$Inline$Text(text)
					]));
		case 4:
			var _v3 = _v1.a;
			var url = _v3.a;
			var maybeTitle = _v3.b;
			return A3(
				$dillonkearns$elm_markdown$Markdown$Inline$Link,
				url,
				maybeTitle,
				$dillonkearns$elm_markdown$Markdown$InlineParser$matchesToInlines(match.t));
		case 5:
			var _v4 = _v1.a;
			var url = _v4.a;
			var maybeTitle = _v4.b;
			return A3(
				$dillonkearns$elm_markdown$Markdown$Inline$Image,
				url,
				maybeTitle,
				$dillonkearns$elm_markdown$Markdown$InlineParser$matchesToInlines(match.t));
		case 6:
			var model = _v1.a;
			return $dillonkearns$elm_markdown$Markdown$Inline$HtmlInline(model);
		case 7:
			var length = _v1.a;
			return A2(
				$dillonkearns$elm_markdown$Markdown$Inline$Emphasis,
				length,
				$dillonkearns$elm_markdown$Markdown$InlineParser$matchesToInlines(match.t));
		default:
			return $dillonkearns$elm_markdown$Markdown$Inline$Strikethrough(
				$dillonkearns$elm_markdown$Markdown$InlineParser$matchesToInlines(match.t));
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$matchesToInlines = function (matches) {
	return A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$InlineParser$matchToInline, matches);
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$Match = $elm$core$Basics$identity;
var $dillonkearns$elm_markdown$Markdown$InlineParser$prepareChildMatch = F2(
	function (parentMatch, childMatch) {
		return {i: childMatch.i - parentMatch.w, t: childMatch.t, o: childMatch.o - parentMatch.w, e2: childMatch.e2, L: childMatch.L - parentMatch.w, w: childMatch.w - parentMatch.w, hw: childMatch.hw};
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$addChild = F2(
	function (parentMatch, childMatch) {
		return {
			i: parentMatch.i,
			t: A2(
				$elm$core$List$cons,
				A2($dillonkearns$elm_markdown$Markdown$InlineParser$prepareChildMatch, parentMatch, childMatch),
				parentMatch.t),
			o: parentMatch.o,
			e2: parentMatch.e2,
			L: parentMatch.L,
			w: parentMatch.w,
			hw: parentMatch.hw
		};
	});
var $elm$core$List$sortBy = _List_sortBy;
var $dillonkearns$elm_markdown$Markdown$InlineParser$organizeChildren = function (_v4) {
	var match = _v4;
	return {
		i: match.i,
		t: $dillonkearns$elm_markdown$Markdown$InlineParser$organizeMatches(match.t),
		o: match.o,
		e2: match.e2,
		L: match.L,
		w: match.w,
		hw: match.hw
	};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$organizeMatches = function (matches) {
	var _v2 = A2(
		$elm$core$List$sortBy,
		function (_v3) {
			var match = _v3;
			return match.o;
		},
		matches);
	if (!_v2.b) {
		return _List_Nil;
	} else {
		var first = _v2.a;
		var rest = _v2.b;
		return A3($dillonkearns$elm_markdown$Markdown$InlineParser$organizeMatchesHelp, rest, first, _List_Nil);
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$organizeMatchesHelp = F3(
	function (remaining, _v0, matchesTail) {
		organizeMatchesHelp:
		while (true) {
			var prevMatch = _v0;
			if (!remaining.b) {
				return A2(
					$elm$core$List$cons,
					$dillonkearns$elm_markdown$Markdown$InlineParser$organizeChildren(prevMatch),
					matchesTail);
			} else {
				var match = remaining.a;
				var rest = remaining.b;
				if (_Utils_cmp(prevMatch.i, match.o) < 1) {
					var $temp$remaining = rest,
						$temp$_v0 = match,
						$temp$matchesTail = A2(
						$elm$core$List$cons,
						$dillonkearns$elm_markdown$Markdown$InlineParser$organizeChildren(prevMatch),
						matchesTail);
					remaining = $temp$remaining;
					_v0 = $temp$_v0;
					matchesTail = $temp$matchesTail;
					continue organizeMatchesHelp;
				} else {
					if ((_Utils_cmp(prevMatch.o, match.o) < 0) && (_Utils_cmp(prevMatch.i, match.i) > 0)) {
						var $temp$remaining = rest,
							$temp$_v0 = A2($dillonkearns$elm_markdown$Markdown$InlineParser$addChild, prevMatch, match),
							$temp$matchesTail = matchesTail;
						remaining = $temp$remaining;
						_v0 = $temp$_v0;
						matchesTail = $temp$matchesTail;
						continue organizeMatchesHelp;
					} else {
						var $temp$remaining = rest,
							$temp$_v0 = prevMatch,
							$temp$matchesTail = matchesTail;
						remaining = $temp$remaining;
						_v0 = $temp$_v0;
						matchesTail = $temp$matchesTail;
						continue organizeMatchesHelp;
					}
				}
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$NormalType = {$: 0};
var $dillonkearns$elm_markdown$Markdown$Helpers$containsAmpersand = function (string) {
	return A2($elm$core$String$contains, '&', string);
};
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {c: index, b5: match, ja: number, eW: submatches};
	});
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{h7: false, i5: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $dillonkearns$elm_markdown$Markdown$Entity$decimalRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('&#([0-9]{1,8});'));
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $dillonkearns$elm_markdown$Markdown$Entity$isBadEndUnicode = function (_int) {
	var remain_ = A2($elm$core$Basics$modBy, 16, _int);
	var remain = A2($elm$core$Basics$modBy, 131070, _int);
	return (_int >= 131070) && ((((0 <= remain) && (remain <= 15)) || ((65536 <= remain) && (remain <= 65551))) && ((remain_ === 14) || (remain_ === 15)));
};
var $dillonkearns$elm_markdown$Markdown$Entity$isValidUnicode = function (_int) {
	return (_int === 9) || ((_int === 10) || ((_int === 13) || ((_int === 133) || (((32 <= _int) && (_int <= 126)) || (((160 <= _int) && (_int <= 55295)) || (((57344 <= _int) && (_int <= 64975)) || (((65008 <= _int) && (_int <= 65533)) || ((65536 <= _int) && (_int <= 1114109)))))))));
};
var $dillonkearns$elm_markdown$Markdown$Entity$validUnicode = function (_int) {
	return ($dillonkearns$elm_markdown$Markdown$Entity$isValidUnicode(_int) && (!$dillonkearns$elm_markdown$Markdown$Entity$isBadEndUnicode(_int))) ? $elm$core$String$fromChar(
		$elm$core$Char$fromCode(_int)) : $elm$core$String$fromChar(
		$elm$core$Char$fromCode(65533));
};
var $dillonkearns$elm_markdown$Markdown$Entity$replaceDecimal = function (match) {
	var _v0 = match.eW;
	if (_v0.b && (!_v0.a.$)) {
		var first = _v0.a.a;
		var _v1 = $elm$core$String$toInt(first);
		if (!_v1.$) {
			var v = _v1.a;
			return $dillonkearns$elm_markdown$Markdown$Entity$validUnicode(v);
		} else {
			return match.b5;
		}
	} else {
		return match.b5;
	}
};
var $dillonkearns$elm_markdown$Markdown$Entity$replaceDecimals = A2($elm$regex$Regex$replace, $dillonkearns$elm_markdown$Markdown$Entity$decimalRegex, $dillonkearns$elm_markdown$Markdown$Entity$replaceDecimal);
var $dillonkearns$elm_markdown$Markdown$Entity$entitiesRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('&([0-9a-zA-Z]+);'));
var $dillonkearns$elm_markdown$Markdown$Entity$entities = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('quot', 34),
			_Utils_Tuple2('amp', 38),
			_Utils_Tuple2('apos', 39),
			_Utils_Tuple2('lt', 60),
			_Utils_Tuple2('gt', 62),
			_Utils_Tuple2('nbsp', 160),
			_Utils_Tuple2('iexcl', 161),
			_Utils_Tuple2('cent', 162),
			_Utils_Tuple2('pound', 163),
			_Utils_Tuple2('curren', 164),
			_Utils_Tuple2('yen', 165),
			_Utils_Tuple2('brvbar', 166),
			_Utils_Tuple2('sect', 167),
			_Utils_Tuple2('uml', 168),
			_Utils_Tuple2('copy', 169),
			_Utils_Tuple2('ordf', 170),
			_Utils_Tuple2('laquo', 171),
			_Utils_Tuple2('not', 172),
			_Utils_Tuple2('shy', 173),
			_Utils_Tuple2('reg', 174),
			_Utils_Tuple2('macr', 175),
			_Utils_Tuple2('deg', 176),
			_Utils_Tuple2('plusmn', 177),
			_Utils_Tuple2('sup2', 178),
			_Utils_Tuple2('sup3', 179),
			_Utils_Tuple2('acute', 180),
			_Utils_Tuple2('micro', 181),
			_Utils_Tuple2('para', 182),
			_Utils_Tuple2('middot', 183),
			_Utils_Tuple2('cedil', 184),
			_Utils_Tuple2('sup1', 185),
			_Utils_Tuple2('ordm', 186),
			_Utils_Tuple2('raquo', 187),
			_Utils_Tuple2('frac14', 188),
			_Utils_Tuple2('frac12', 189),
			_Utils_Tuple2('frac34', 190),
			_Utils_Tuple2('iquest', 191),
			_Utils_Tuple2('Agrave', 192),
			_Utils_Tuple2('Aacute', 193),
			_Utils_Tuple2('Acirc', 194),
			_Utils_Tuple2('Atilde', 195),
			_Utils_Tuple2('Auml', 196),
			_Utils_Tuple2('Aring', 197),
			_Utils_Tuple2('AElig', 198),
			_Utils_Tuple2('Ccedil', 199),
			_Utils_Tuple2('Egrave', 200),
			_Utils_Tuple2('Eacute', 201),
			_Utils_Tuple2('Ecirc', 202),
			_Utils_Tuple2('Euml', 203),
			_Utils_Tuple2('Igrave', 204),
			_Utils_Tuple2('Iacute', 205),
			_Utils_Tuple2('Icirc', 206),
			_Utils_Tuple2('Iuml', 207),
			_Utils_Tuple2('ETH', 208),
			_Utils_Tuple2('Ntilde', 209),
			_Utils_Tuple2('Ograve', 210),
			_Utils_Tuple2('Oacute', 211),
			_Utils_Tuple2('Ocirc', 212),
			_Utils_Tuple2('Otilde', 213),
			_Utils_Tuple2('Ouml', 214),
			_Utils_Tuple2('times', 215),
			_Utils_Tuple2('Oslash', 216),
			_Utils_Tuple2('Ugrave', 217),
			_Utils_Tuple2('Uacute', 218),
			_Utils_Tuple2('Ucirc', 219),
			_Utils_Tuple2('Uuml', 220),
			_Utils_Tuple2('Yacute', 221),
			_Utils_Tuple2('THORN', 222),
			_Utils_Tuple2('szlig', 223),
			_Utils_Tuple2('agrave', 224),
			_Utils_Tuple2('aacute', 225),
			_Utils_Tuple2('acirc', 226),
			_Utils_Tuple2('atilde', 227),
			_Utils_Tuple2('auml', 228),
			_Utils_Tuple2('aring', 229),
			_Utils_Tuple2('aelig', 230),
			_Utils_Tuple2('ccedil', 231),
			_Utils_Tuple2('egrave', 232),
			_Utils_Tuple2('eacute', 233),
			_Utils_Tuple2('ecirc', 234),
			_Utils_Tuple2('euml', 235),
			_Utils_Tuple2('igrave', 236),
			_Utils_Tuple2('iacute', 237),
			_Utils_Tuple2('icirc', 238),
			_Utils_Tuple2('iuml', 239),
			_Utils_Tuple2('eth', 240),
			_Utils_Tuple2('ntilde', 241),
			_Utils_Tuple2('ograve', 242),
			_Utils_Tuple2('oacute', 243),
			_Utils_Tuple2('ocirc', 244),
			_Utils_Tuple2('otilde', 245),
			_Utils_Tuple2('ouml', 246),
			_Utils_Tuple2('divide', 247),
			_Utils_Tuple2('oslash', 248),
			_Utils_Tuple2('ugrave', 249),
			_Utils_Tuple2('uacute', 250),
			_Utils_Tuple2('ucirc', 251),
			_Utils_Tuple2('uuml', 252),
			_Utils_Tuple2('yacute', 253),
			_Utils_Tuple2('thorn', 254),
			_Utils_Tuple2('yuml', 255),
			_Utils_Tuple2('OElig', 338),
			_Utils_Tuple2('oelig', 339),
			_Utils_Tuple2('Scaron', 352),
			_Utils_Tuple2('scaron', 353),
			_Utils_Tuple2('Yuml', 376),
			_Utils_Tuple2('fnof', 402),
			_Utils_Tuple2('circ', 710),
			_Utils_Tuple2('tilde', 732),
			_Utils_Tuple2('Alpha', 913),
			_Utils_Tuple2('Beta', 914),
			_Utils_Tuple2('Gamma', 915),
			_Utils_Tuple2('Delta', 916),
			_Utils_Tuple2('Epsilon', 917),
			_Utils_Tuple2('Zeta', 918),
			_Utils_Tuple2('Eta', 919),
			_Utils_Tuple2('Theta', 920),
			_Utils_Tuple2('Iota', 921),
			_Utils_Tuple2('Kappa', 922),
			_Utils_Tuple2('Lambda', 923),
			_Utils_Tuple2('Mu', 924),
			_Utils_Tuple2('Nu', 925),
			_Utils_Tuple2('Xi', 926),
			_Utils_Tuple2('Omicron', 927),
			_Utils_Tuple2('Pi', 928),
			_Utils_Tuple2('Rho', 929),
			_Utils_Tuple2('Sigma', 931),
			_Utils_Tuple2('Tau', 932),
			_Utils_Tuple2('Upsilon', 933),
			_Utils_Tuple2('Phi', 934),
			_Utils_Tuple2('Chi', 935),
			_Utils_Tuple2('Psi', 936),
			_Utils_Tuple2('Omega', 937),
			_Utils_Tuple2('alpha', 945),
			_Utils_Tuple2('beta', 946),
			_Utils_Tuple2('gamma', 947),
			_Utils_Tuple2('delta', 948),
			_Utils_Tuple2('epsilon', 949),
			_Utils_Tuple2('zeta', 950),
			_Utils_Tuple2('eta', 951),
			_Utils_Tuple2('theta', 952),
			_Utils_Tuple2('iota', 953),
			_Utils_Tuple2('kappa', 954),
			_Utils_Tuple2('lambda', 955),
			_Utils_Tuple2('mu', 956),
			_Utils_Tuple2('nu', 957),
			_Utils_Tuple2('xi', 958),
			_Utils_Tuple2('omicron', 959),
			_Utils_Tuple2('pi', 960),
			_Utils_Tuple2('rho', 961),
			_Utils_Tuple2('sigmaf', 962),
			_Utils_Tuple2('sigma', 963),
			_Utils_Tuple2('tau', 964),
			_Utils_Tuple2('upsilon', 965),
			_Utils_Tuple2('phi', 966),
			_Utils_Tuple2('chi', 967),
			_Utils_Tuple2('psi', 968),
			_Utils_Tuple2('omega', 969),
			_Utils_Tuple2('thetasym', 977),
			_Utils_Tuple2('upsih', 978),
			_Utils_Tuple2('piv', 982),
			_Utils_Tuple2('ensp', 8194),
			_Utils_Tuple2('emsp', 8195),
			_Utils_Tuple2('thinsp', 8201),
			_Utils_Tuple2('zwnj', 8204),
			_Utils_Tuple2('zwj', 8205),
			_Utils_Tuple2('lrm', 8206),
			_Utils_Tuple2('rlm', 8207),
			_Utils_Tuple2('ndash', 8211),
			_Utils_Tuple2('mdash', 8212),
			_Utils_Tuple2('lsquo', 8216),
			_Utils_Tuple2('rsquo', 8217),
			_Utils_Tuple2('sbquo', 8218),
			_Utils_Tuple2('ldquo', 8220),
			_Utils_Tuple2('rdquo', 8221),
			_Utils_Tuple2('bdquo', 8222),
			_Utils_Tuple2('dagger', 8224),
			_Utils_Tuple2('Dagger', 8225),
			_Utils_Tuple2('bull', 8226),
			_Utils_Tuple2('hellip', 8230),
			_Utils_Tuple2('permil', 8240),
			_Utils_Tuple2('prime', 8242),
			_Utils_Tuple2('Prime', 8243),
			_Utils_Tuple2('lsaquo', 8249),
			_Utils_Tuple2('rsaquo', 8250),
			_Utils_Tuple2('oline', 8254),
			_Utils_Tuple2('frasl', 8260),
			_Utils_Tuple2('euro', 8364),
			_Utils_Tuple2('image', 8465),
			_Utils_Tuple2('weierp', 8472),
			_Utils_Tuple2('real', 8476),
			_Utils_Tuple2('trade', 8482),
			_Utils_Tuple2('alefsym', 8501),
			_Utils_Tuple2('larr', 8592),
			_Utils_Tuple2('uarr', 8593),
			_Utils_Tuple2('rarr', 8594),
			_Utils_Tuple2('darr', 8595),
			_Utils_Tuple2('harr', 8596),
			_Utils_Tuple2('crarr', 8629),
			_Utils_Tuple2('lArr', 8656),
			_Utils_Tuple2('uArr', 8657),
			_Utils_Tuple2('rArr', 8658),
			_Utils_Tuple2('dArr', 8659),
			_Utils_Tuple2('hArr', 8660),
			_Utils_Tuple2('forall', 8704),
			_Utils_Tuple2('part', 8706),
			_Utils_Tuple2('exist', 8707),
			_Utils_Tuple2('empty', 8709),
			_Utils_Tuple2('nabla', 8711),
			_Utils_Tuple2('isin', 8712),
			_Utils_Tuple2('notin', 8713),
			_Utils_Tuple2('ni', 8715),
			_Utils_Tuple2('prod', 8719),
			_Utils_Tuple2('sum', 8721),
			_Utils_Tuple2('minus', 8722),
			_Utils_Tuple2('lowast', 8727),
			_Utils_Tuple2('radic', 8730),
			_Utils_Tuple2('prop', 8733),
			_Utils_Tuple2('infin', 8734),
			_Utils_Tuple2('ang', 8736),
			_Utils_Tuple2('and', 8743),
			_Utils_Tuple2('or', 8744),
			_Utils_Tuple2('cap', 8745),
			_Utils_Tuple2('cup', 8746),
			_Utils_Tuple2('int', 8747),
			_Utils_Tuple2('there4', 8756),
			_Utils_Tuple2('sim', 8764),
			_Utils_Tuple2('cong', 8773),
			_Utils_Tuple2('asymp', 8776),
			_Utils_Tuple2('ne', 8800),
			_Utils_Tuple2('equiv', 8801),
			_Utils_Tuple2('le', 8804),
			_Utils_Tuple2('ge', 8805),
			_Utils_Tuple2('sub', 8834),
			_Utils_Tuple2('sup', 8835),
			_Utils_Tuple2('nsub', 8836),
			_Utils_Tuple2('sube', 8838),
			_Utils_Tuple2('supe', 8839),
			_Utils_Tuple2('oplus', 8853),
			_Utils_Tuple2('otimes', 8855),
			_Utils_Tuple2('perp', 8869),
			_Utils_Tuple2('sdot', 8901),
			_Utils_Tuple2('lceil', 8968),
			_Utils_Tuple2('rceil', 8969),
			_Utils_Tuple2('lfloor', 8970),
			_Utils_Tuple2('rfloor', 8971),
			_Utils_Tuple2('lang', 9001),
			_Utils_Tuple2('rang', 9002),
			_Utils_Tuple2('loz', 9674),
			_Utils_Tuple2('spades', 9824),
			_Utils_Tuple2('clubs', 9827),
			_Utils_Tuple2('hearts', 9829),
			_Utils_Tuple2('diams', 9830)
		]));
var $dillonkearns$elm_markdown$Markdown$Entity$replaceEntity = function (match) {
	var _v0 = match.eW;
	if (_v0.b && (!_v0.a.$)) {
		var first = _v0.a.a;
		var _v1 = A2($elm$core$Dict$get, first, $dillonkearns$elm_markdown$Markdown$Entity$entities);
		if (!_v1.$) {
			var code = _v1.a;
			return $elm$core$String$fromChar(
				$elm$core$Char$fromCode(code));
		} else {
			return match.b5;
		}
	} else {
		return match.b5;
	}
};
var $dillonkearns$elm_markdown$Markdown$Entity$replaceEntities = A2($elm$regex$Regex$replace, $dillonkearns$elm_markdown$Markdown$Entity$entitiesRegex, $dillonkearns$elm_markdown$Markdown$Entity$replaceEntity);
var $dillonkearns$elm_markdown$Markdown$Helpers$escapableRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\+)([!\"#$%&\\\'()*+,./:;<=>?@[\\\\\\]^_`{|}~-])'));
var $dillonkearns$elm_markdown$Markdown$Helpers$replaceEscapable = A2(
	$elm$regex$Regex$replace,
	$dillonkearns$elm_markdown$Markdown$Helpers$escapableRegex,
	function (regexMatch) {
		var _v0 = regexMatch.eW;
		if (((_v0.b && (!_v0.a.$)) && _v0.b.b) && (!_v0.b.a.$)) {
			var backslashes = _v0.a.a;
			var _v1 = _v0.b;
			var escapedStr = _v1.a.a;
			return _Utils_ap(
				A2(
					$elm$core$String$repeat,
					($elm$core$String$length(backslashes) / 2) | 0,
					'\\'),
				escapedStr);
		} else {
			return regexMatch.b5;
		}
	});
var $dillonkearns$elm_markdown$Markdown$Entity$hexadecimalRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('&#[Xx]([0-9a-fA-F]{1,8});'));
var $elm$core$String$foldl = _String_foldl;
var $dillonkearns$elm_markdown$Markdown$Entity$hexToInt = function (string) {
	var folder = F2(
		function (hexDigit, _int) {
			return ((_int * 16) + A2(
				$elm$core$Basics$modBy,
				39,
				$elm$core$Char$toCode(hexDigit))) - 9;
		});
	return A3(
		$elm$core$String$foldl,
		folder,
		0,
		$elm$core$String$toLower(string));
};
var $dillonkearns$elm_markdown$Markdown$Entity$replaceHexadecimal = function (match) {
	var _v0 = match.eW;
	if (_v0.b && (!_v0.a.$)) {
		var first = _v0.a.a;
		return $dillonkearns$elm_markdown$Markdown$Entity$validUnicode(
			$dillonkearns$elm_markdown$Markdown$Entity$hexToInt(first));
	} else {
		return match.b5;
	}
};
var $dillonkearns$elm_markdown$Markdown$Entity$replaceHexadecimals = A2($elm$regex$Regex$replace, $dillonkearns$elm_markdown$Markdown$Entity$hexadecimalRegex, $dillonkearns$elm_markdown$Markdown$Entity$replaceHexadecimal);
var $dillonkearns$elm_markdown$Markdown$Helpers$formatStr = function (str) {
	var withEscapes = $dillonkearns$elm_markdown$Markdown$Helpers$replaceEscapable(str);
	return $dillonkearns$elm_markdown$Markdown$Helpers$containsAmpersand(withEscapes) ? $dillonkearns$elm_markdown$Markdown$Entity$replaceHexadecimals(
		$dillonkearns$elm_markdown$Markdown$Entity$replaceDecimals(
			$dillonkearns$elm_markdown$Markdown$Entity$replaceEntities(withEscapes))) : withEscapes;
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$normalMatch = function (text) {
	return {
		i: 0,
		t: _List_Nil,
		o: 0,
		e2: $dillonkearns$elm_markdown$Markdown$Helpers$formatStr(text),
		L: 0,
		w: 0,
		hw: $dillonkearns$elm_markdown$Markdown$InlineParser$NormalType
	};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$parseTextMatch = F3(
	function (rawText, _v2, parsedMatches) {
		var matchModel = _v2;
		var updtMatch = {
			i: matchModel.i,
			t: A3($dillonkearns$elm_markdown$Markdown$InlineParser$parseTextMatches, matchModel.e2, _List_Nil, matchModel.t),
			o: matchModel.o,
			e2: matchModel.e2,
			L: matchModel.L,
			w: matchModel.w,
			hw: matchModel.hw
		};
		if (!parsedMatches.b) {
			var finalStr = A2($elm$core$String$dropLeft, matchModel.i, rawText);
			return $elm$core$String$isEmpty(finalStr) ? _List_fromArray(
				[updtMatch]) : _List_fromArray(
				[
					updtMatch,
					$dillonkearns$elm_markdown$Markdown$InlineParser$normalMatch(finalStr)
				]);
		} else {
			var matchHead = parsedMatches.a;
			var matchesTail = parsedMatches.b;
			var _v4 = matchHead.hw;
			if (!_v4.$) {
				return A2($elm$core$List$cons, updtMatch, parsedMatches);
			} else {
				return _Utils_eq(matchModel.i, matchHead.o) ? A2($elm$core$List$cons, updtMatch, parsedMatches) : ((_Utils_cmp(matchModel.i, matchHead.o) < 0) ? A2(
					$elm$core$List$cons,
					updtMatch,
					A2(
						$elm$core$List$cons,
						$dillonkearns$elm_markdown$Markdown$InlineParser$normalMatch(
							A3($elm$core$String$slice, matchModel.i, matchHead.o, rawText)),
						parsedMatches)) : parsedMatches);
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$parseTextMatches = F3(
	function (rawText, parsedMatches, matches) {
		parseTextMatches:
		while (true) {
			if (!matches.b) {
				if (!parsedMatches.b) {
					return $elm$core$String$isEmpty(rawText) ? _List_Nil : _List_fromArray(
						[
							$dillonkearns$elm_markdown$Markdown$InlineParser$normalMatch(rawText)
						]);
				} else {
					var matchModel = parsedMatches.a;
					return (matchModel.o > 0) ? A2(
						$elm$core$List$cons,
						$dillonkearns$elm_markdown$Markdown$InlineParser$normalMatch(
							A2($elm$core$String$left, matchModel.o, rawText)),
						parsedMatches) : parsedMatches;
				}
			} else {
				var match = matches.a;
				var matchesTail = matches.b;
				var $temp$rawText = rawText,
					$temp$parsedMatches = A3($dillonkearns$elm_markdown$Markdown$InlineParser$parseTextMatch, rawText, match, parsedMatches),
					$temp$matches = matchesTail;
				rawText = $temp$rawText;
				parsedMatches = $temp$parsedMatches;
				matches = $temp$matches;
				continue parseTextMatches;
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$angleBracketLTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\<)'));
var $elm$regex$Regex$find = _Regex_findAtMost(_Regex_infinity);
var $dillonkearns$elm_markdown$Markdown$InlineParser$AngleBracketOpen = {$: 4};
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToAngleBracketLToken = function (regMatch) {
	var _v0 = regMatch.eW;
	if ((_v0.b && _v0.b.b) && (!_v0.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var delimiter = _v1.a.a;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		return $dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength) ? $elm$core$Maybe$Just(
			{c: regMatch.c + backslashesLength, d5: 1, e: $dillonkearns$elm_markdown$Markdown$InlineParser$AngleBracketOpen}) : $elm$core$Maybe$Nothing;
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$findAngleBracketLTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToAngleBracketLToken,
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$angleBracketLTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$angleBracketRTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\>)'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$AngleBracketClose = function (a) {
	return {$: 5, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$Escaped = 0;
var $dillonkearns$elm_markdown$Markdown$InlineParser$NotEscaped = 1;
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToAngleBracketRToken = function (regMatch) {
	var _v0 = regMatch.eW;
	if ((_v0.b && _v0.b.b) && (!_v0.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		return $elm$core$Maybe$Just(
			{
				c: regMatch.c + backslashesLength,
				d5: 1,
				e: $dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength) ? $dillonkearns$elm_markdown$Markdown$InlineParser$AngleBracketClose(1) : $dillonkearns$elm_markdown$Markdown$InlineParser$AngleBracketClose(0)
			});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$findAngleBracketRTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToAngleBracketRToken,
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$angleBracketRTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$asteriskEmphasisTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)([^*])?(\\*+)([^*])?'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$EmphasisToken = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$isPunctuation = function (c) {
	switch (c) {
		case '!':
			return true;
		case '\"':
			return true;
		case '#':
			return true;
		case '%':
			return true;
		case '&':
			return true;
		case '\'':
			return true;
		case '(':
			return true;
		case ')':
			return true;
		case '*':
			return true;
		case ',':
			return true;
		case '-':
			return true;
		case '.':
			return true;
		case '/':
			return true;
		case ':':
			return true;
		case ';':
			return true;
		case '?':
			return true;
		case '@':
			return true;
		case '[':
			return true;
		case ']':
			return true;
		case '_':
			return true;
		case '{':
			return true;
		case '}':
			return true;
		case '~':
			return true;
		default:
			return false;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$containPunctuation = A2(
	$elm$core$String$foldl,
	F2(
		function (c, accum) {
			return accum || $dillonkearns$elm_markdown$Markdown$InlineParser$isPunctuation(c);
		}),
	false);
var $dillonkearns$elm_markdown$Markdown$InlineParser$isWhitespace = function (c) {
	switch (c) {
		case ' ':
			return true;
		case '\u000C':
			return true;
		case '\n':
			return true;
		case '\r':
			return true;
		case '\t':
			return true;
		case '\u000B':
			return true;
		case '\u00A0':
			return true;
		case '\u2028':
			return true;
		case '\u2029':
			return true;
		default:
			return false;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$containSpace = A2(
	$elm$core$String$foldl,
	F2(
		function (c, accum) {
			return accum || $dillonkearns$elm_markdown$Markdown$InlineParser$isWhitespace(c);
		}),
	false);
var $dillonkearns$elm_markdown$Markdown$InlineParser$getFringeRank = function (mstring) {
	if (!mstring.$) {
		var string = mstring.a;
		return ($elm$core$String$isEmpty(string) || $dillonkearns$elm_markdown$Markdown$InlineParser$containSpace(string)) ? 0 : ($dillonkearns$elm_markdown$Markdown$InlineParser$containPunctuation(string) ? 1 : 2);
	} else {
		return 0;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToEmphasisToken = F3(
	function (_char, rawText, regMatch) {
		var _v0 = regMatch.eW;
		if ((((_v0.b && _v0.b.b) && _v0.b.b.b) && (!_v0.b.b.a.$)) && _v0.b.b.b.b) {
			var maybeBackslashes = _v0.a;
			var _v1 = _v0.b;
			var maybeLeftFringe = _v1.a;
			var _v2 = _v1.b;
			var delimiter = _v2.a.a;
			var _v3 = _v2.b;
			var maybeRightFringe = _v3.a;
			var rFringeRank = $dillonkearns$elm_markdown$Markdown$InlineParser$getFringeRank(maybeRightFringe);
			var leftFringeLength = function () {
				if (!maybeLeftFringe.$) {
					var left = maybeLeftFringe.a;
					return $elm$core$String$length(left);
				} else {
					return 0;
				}
			}();
			var mLeftFringe = ((!(!regMatch.c)) && (!leftFringeLength)) ? $elm$core$Maybe$Just(
				A3($elm$core$String$slice, regMatch.c - 1, regMatch.c, rawText)) : maybeLeftFringe;
			var backslashesLength = function () {
				if (!maybeBackslashes.$) {
					var backslashes = maybeBackslashes.a;
					return $elm$core$String$length(backslashes);
				} else {
					return 0;
				}
			}();
			var isEscaped = ((!$dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength)) && (!leftFringeLength)) || function () {
				if ((!mLeftFringe.$) && (mLeftFringe.a === '\\')) {
					return true;
				} else {
					return false;
				}
			}();
			var delimiterLength = isEscaped ? ($elm$core$String$length(delimiter) - 1) : $elm$core$String$length(delimiter);
			var lFringeRank = isEscaped ? 1 : $dillonkearns$elm_markdown$Markdown$InlineParser$getFringeRank(mLeftFringe);
			if ((delimiterLength <= 0) || ((_char === '_') && ((lFringeRank === 2) && (rFringeRank === 2)))) {
				return $elm$core$Maybe$Nothing;
			} else {
				var index = ((regMatch.c + backslashesLength) + leftFringeLength) + (isEscaped ? 1 : 0);
				return $elm$core$Maybe$Just(
					{
						c: index,
						d5: delimiterLength,
						e: A2(
							$dillonkearns$elm_markdown$Markdown$InlineParser$EmphasisToken,
							_char,
							{cR: lFringeRank, c4: rFringeRank})
					});
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$findAsteriskEmphasisTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		A2($dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToEmphasisToken, '*', str),
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$asteriskEmphasisTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$codeTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\`+)'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$CodeToken = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToCodeToken = function (regMatch) {
	var _v0 = regMatch.eW;
	if ((_v0.b && _v0.b.b) && (!_v0.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var backtick = _v1.a.a;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		return $elm$core$Maybe$Just(
			{
				c: regMatch.c + backslashesLength,
				d5: $elm$core$String$length(backtick),
				e: $dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength) ? $dillonkearns$elm_markdown$Markdown$InlineParser$CodeToken(1) : $dillonkearns$elm_markdown$Markdown$InlineParser$CodeToken(0)
			});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$findCodeTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToCodeToken,
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$codeTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$hardBreakTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(?:(\\\\+)|( {2,}))\\n'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakToken = {$: 9};
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToHardBreakToken = function (regMatch) {
	var _v0 = regMatch.eW;
	_v0$2:
	while (true) {
		if (_v0.b) {
			if (!_v0.a.$) {
				var backslashes = _v0.a.a;
				var backslashesLength = $elm$core$String$length(backslashes);
				return (!$dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength)) ? $elm$core$Maybe$Just(
					{c: (regMatch.c + backslashesLength) - 1, d5: 2, e: $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakToken}) : $elm$core$Maybe$Nothing;
			} else {
				if (_v0.b.b && (!_v0.b.a.$)) {
					var _v1 = _v0.b;
					return $elm$core$Maybe$Just(
						{
							c: regMatch.c,
							d5: $elm$core$String$length(regMatch.b5),
							e: $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakToken
						});
				} else {
					break _v0$2;
				}
			}
		} else {
			break _v0$2;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToSoftHardBreakToken = function (regMatch) {
	var _v0 = regMatch.eW;
	_v0$2:
	while (true) {
		if (_v0.b) {
			if (!_v0.a.$) {
				var backslashes = _v0.a.a;
				var backslashesLength = $elm$core$String$length(backslashes);
				return $dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength) ? $elm$core$Maybe$Just(
					{c: regMatch.c + backslashesLength, d5: 1, e: $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakToken}) : $elm$core$Maybe$Just(
					{c: (regMatch.c + backslashesLength) - 1, d5: 2, e: $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakToken});
			} else {
				if (_v0.b.b) {
					var _v1 = _v0.b;
					var maybeSpaces = _v1.a;
					return $elm$core$Maybe$Just(
						{
							c: regMatch.c,
							d5: $elm$core$String$length(regMatch.b5),
							e: $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakToken
						});
				} else {
					break _v0$2;
				}
			}
		} else {
			break _v0$2;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$softAsHardLineBreak = false;
var $dillonkearns$elm_markdown$Markdown$InlineParser$softAsHardLineBreakTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(?:(\\\\+)|( *))\\n'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$findHardBreakTokens = function (str) {
	return $dillonkearns$elm_markdown$Markdown$InlineParser$softAsHardLineBreak ? A2(
		$elm$core$List$filterMap,
		$dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToSoftHardBreakToken,
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$softAsHardLineBreakTokenRegex, str)) : A2(
		$elm$core$List$filterMap,
		$dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToHardBreakToken,
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$hardBreakTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$linkImageCloseTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\])'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$SquareBracketClose = {$: 3};
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToLinkImageCloseToken = function (regMatch) {
	var _v0 = regMatch.eW;
	if ((_v0.b && _v0.b.b) && (!_v0.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		return $dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength) ? $elm$core$Maybe$Just(
			{c: regMatch.c + backslashesLength, d5: 1, e: $dillonkearns$elm_markdown$Markdown$InlineParser$SquareBracketClose}) : $elm$core$Maybe$Nothing;
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$findLinkImageCloseTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToLinkImageCloseToken,
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$linkImageCloseTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$linkImageOpenTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\!)?(\\[)'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$Active = 0;
var $dillonkearns$elm_markdown$Markdown$InlineParser$ImageOpenToken = {$: 2};
var $dillonkearns$elm_markdown$Markdown$InlineParser$LinkOpenToken = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToLinkImageOpenToken = function (regMatch) {
	var _v0 = regMatch.eW;
	if (((_v0.b && _v0.b.b) && _v0.b.b.b) && (!_v0.b.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var maybeImageOpen = _v1.a;
		var _v2 = _v1.b;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		var isEscaped = !$dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength);
		var index = isEscaped ? ((regMatch.c + backslashesLength) + 1) : (regMatch.c + backslashesLength);
		if (isEscaped) {
			if (!maybeImageOpen.$) {
				return $elm$core$Maybe$Just(
					{
						c: index,
						d5: 1,
						e: $dillonkearns$elm_markdown$Markdown$InlineParser$LinkOpenToken(0)
					});
			} else {
				return $elm$core$Maybe$Nothing;
			}
		} else {
			if (!maybeImageOpen.$) {
				return $elm$core$Maybe$Just(
					{c: index, d5: 2, e: $dillonkearns$elm_markdown$Markdown$InlineParser$ImageOpenToken});
			} else {
				return $elm$core$Maybe$Just(
					{
						c: index,
						d5: 1,
						e: $dillonkearns$elm_markdown$Markdown$InlineParser$LinkOpenToken(0)
					});
			}
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$findLinkImageOpenTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToLinkImageOpenToken,
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$linkImageOpenTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$StrikethroughToken = function (a) {
	return {$: 10, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToStrikethroughToken = function (regMatch) {
	var _v0 = regMatch.eW;
	if ((_v0.b && _v0.b.b) && (!_v0.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var tilde = _v1.a.a;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		var _v2 = $dillonkearns$elm_markdown$Markdown$Helpers$isEven(backslashesLength) ? _Utils_Tuple2(
			$elm$core$String$length(tilde),
			$dillonkearns$elm_markdown$Markdown$InlineParser$StrikethroughToken(1)) : _Utils_Tuple2(
			$elm$core$String$length(tilde),
			$dillonkearns$elm_markdown$Markdown$InlineParser$StrikethroughToken(0));
		var length = _v2.a;
		var meaning = _v2.b;
		return $elm$core$Maybe$Just(
			{c: regMatch.c + backslashesLength, d5: length, e: meaning});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$strikethroughTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(~{2,})([^~])?'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$findStrikethroughTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToStrikethroughToken,
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$strikethroughTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$underlineEmphasisTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)([^_])?(\\_+)([^_])?'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$findUnderlineEmphasisTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		A2($dillonkearns$elm_markdown$Markdown$InlineParser$regMatchToEmphasisToken, '_', str),
		A2($elm$regex$Regex$find, $dillonkearns$elm_markdown$Markdown$InlineParser$underlineEmphasisTokenRegex, str));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$mergeByIndex = F2(
	function (left, right) {
		if (left.b) {
			var lfirst = left.a;
			var lrest = left.b;
			if (right.b) {
				var rfirst = right.a;
				var rrest = right.b;
				return (_Utils_cmp(lfirst.c, rfirst.c) < 0) ? A2(
					$elm$core$List$cons,
					lfirst,
					A2($dillonkearns$elm_markdown$Markdown$InlineParser$mergeByIndex, lrest, right)) : A2(
					$elm$core$List$cons,
					rfirst,
					A2($dillonkearns$elm_markdown$Markdown$InlineParser$mergeByIndex, left, rrest));
			} else {
				return left;
			}
		} else {
			return right;
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$tokenize = function (rawText) {
	return A2(
		$dillonkearns$elm_markdown$Markdown$InlineParser$mergeByIndex,
		$dillonkearns$elm_markdown$Markdown$InlineParser$findAngleBracketRTokens(rawText),
		A2(
			$dillonkearns$elm_markdown$Markdown$InlineParser$mergeByIndex,
			$dillonkearns$elm_markdown$Markdown$InlineParser$findAngleBracketLTokens(rawText),
			A2(
				$dillonkearns$elm_markdown$Markdown$InlineParser$mergeByIndex,
				$dillonkearns$elm_markdown$Markdown$InlineParser$findHardBreakTokens(rawText),
				A2(
					$dillonkearns$elm_markdown$Markdown$InlineParser$mergeByIndex,
					$dillonkearns$elm_markdown$Markdown$InlineParser$findLinkImageCloseTokens(rawText),
					A2(
						$dillonkearns$elm_markdown$Markdown$InlineParser$mergeByIndex,
						$dillonkearns$elm_markdown$Markdown$InlineParser$findLinkImageOpenTokens(rawText),
						A2(
							$dillonkearns$elm_markdown$Markdown$InlineParser$mergeByIndex,
							$dillonkearns$elm_markdown$Markdown$InlineParser$findStrikethroughTokens(rawText),
							A2(
								$dillonkearns$elm_markdown$Markdown$InlineParser$mergeByIndex,
								$dillonkearns$elm_markdown$Markdown$InlineParser$findUnderlineEmphasisTokens(rawText),
								A2(
									$dillonkearns$elm_markdown$Markdown$InlineParser$mergeByIndex,
									$dillonkearns$elm_markdown$Markdown$InlineParser$findAsteriskEmphasisTokens(rawText),
									$dillonkearns$elm_markdown$Markdown$InlineParser$findCodeTokens(rawText)))))))));
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$CodeType = {$: 2};
var $dillonkearns$elm_markdown$Markdown$InlineParser$EmphasisType = function (a) {
	return {$: 7, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$HtmlType = function (a) {
	return {$: 6, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$ImageType = function (a) {
	return {$: 5, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$Inactive = 1;
var $dillonkearns$elm_markdown$Markdown$InlineParser$LinkType = function (a) {
	return {$: 4, a: a};
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$StrikethroughType = {$: 8};
var $dillonkearns$elm_markdown$Markdown$InlineParser$AutolinkType = function (a) {
	return {$: 3, a: a};
};
var $elm$regex$Regex$contains = _Regex_contains;
var $dillonkearns$elm_markdown$Markdown$InlineParser$decodeUrlRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('%(?:3B|2C|2F|3F|3A|40|26|3D|2B|24|23|25)'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$encodeUrl = A2(
	$elm$core$Basics$composeR,
	$elm$url$Url$percentEncode,
	A2(
		$elm$regex$Regex$replace,
		$dillonkearns$elm_markdown$Markdown$InlineParser$decodeUrlRegex,
		function (match) {
			return A2(
				$elm$core$Maybe$withDefault,
				match.b5,
				$elm$url$Url$percentDecode(match.b5));
		}));
var $dillonkearns$elm_markdown$Markdown$InlineParser$urlRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^([A-Za-z][A-Za-z0-9.+\\-]{1,31}:[^<>\\x00-\\x20]*)$'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$autolinkToMatch = function (_v0) {
	var match = _v0;
	return A2($elm$regex$Regex$contains, $dillonkearns$elm_markdown$Markdown$InlineParser$urlRegex, match.e2) ? $elm$core$Result$Ok(
		_Utils_update(
			match,
			{
				hw: $dillonkearns$elm_markdown$Markdown$InlineParser$AutolinkType(
					_Utils_Tuple2(
						match.e2,
						$dillonkearns$elm_markdown$Markdown$InlineParser$encodeUrl(match.e2)))
			})) : $elm$core$Result$Err(match);
};
var $elm$regex$Regex$findAtMost = _Regex_findAtMost;
var $dillonkearns$elm_markdown$Markdown$Helpers$insideSquareBracketRegex = '[^\\[\\]\\\\]*(?:\\\\.[^\\[\\]\\\\]*)*';
var $dillonkearns$elm_markdown$Markdown$InlineParser$refLabelRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^\\[\\s*(' + ($dillonkearns$elm_markdown$Markdown$Helpers$insideSquareBracketRegex + ')\\s*\\]')));
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $dillonkearns$elm_markdown$Markdown$Helpers$cleanWhitespaces = function (original) {
	return original;
};
var $dillonkearns$elm_markdown$Markdown$Helpers$prepareRefLabel = A2($elm$core$Basics$composeR, $dillonkearns$elm_markdown$Markdown$Helpers$cleanWhitespaces, $elm$core$String$toLower);
var $dillonkearns$elm_markdown$Markdown$InlineParser$prepareUrlAndTitle = F2(
	function (rawUrl, maybeTitle) {
		return _Utils_Tuple2(
			$dillonkearns$elm_markdown$Markdown$InlineParser$encodeUrl(
				$dillonkearns$elm_markdown$Markdown$Helpers$formatStr(rawUrl)),
			A2($elm$core$Maybe$map, $dillonkearns$elm_markdown$Markdown$Helpers$formatStr, maybeTitle));
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$refRegexToMatch = F3(
	function (matchModel, references, maybeRegexMatch) {
		var refLabel = function (str) {
			return $elm$core$String$isEmpty(str) ? matchModel.e2 : str;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				matchModel.e2,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Maybe$Nothing,
					A2(
						$elm$core$Maybe$andThen,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.eW;
							},
							$elm$core$List$head),
						maybeRegexMatch))));
		var _v0 = A2(
			$elm$core$Dict$get,
			$dillonkearns$elm_markdown$Markdown$Helpers$prepareRefLabel(refLabel),
			references);
		if (_v0.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v1 = _v0.a;
			var rawUrl = _v1.a;
			var maybeTitle = _v1.b;
			var type_ = function () {
				var _v3 = matchModel.hw;
				if (_v3.$ === 5) {
					return $dillonkearns$elm_markdown$Markdown$InlineParser$ImageType(
						A2($dillonkearns$elm_markdown$Markdown$InlineParser$prepareUrlAndTitle, rawUrl, maybeTitle));
				} else {
					return $dillonkearns$elm_markdown$Markdown$InlineParser$LinkType(
						A2($dillonkearns$elm_markdown$Markdown$InlineParser$prepareUrlAndTitle, rawUrl, maybeTitle));
				}
			}();
			var regexMatchLength = function () {
				if (!maybeRegexMatch.$) {
					var match = maybeRegexMatch.a.b5;
					return $elm$core$String$length(match);
				} else {
					return 0;
				}
			}();
			return $elm$core$Maybe$Just(
				_Utils_update(
					matchModel,
					{i: matchModel.i + regexMatchLength, hw: type_}));
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$checkForInlineReferences = F3(
	function (remainText, _v0, references) {
		var tempMatch = _v0;
		var matches = A3($elm$regex$Regex$findAtMost, 1, $dillonkearns$elm_markdown$Markdown$InlineParser$refLabelRegex, remainText);
		return A3(
			$dillonkearns$elm_markdown$Markdown$InlineParser$refRegexToMatch,
			tempMatch,
			references,
			$elm$core$List$head(matches));
	});
var $dillonkearns$elm_markdown$Markdown$Helpers$lineEndChars = '\\f\\v\\r\\n';
var $dillonkearns$elm_markdown$Markdown$Helpers$whiteSpaceChars = ' \\t\\f\\v\\r\\n';
var $dillonkearns$elm_markdown$Markdown$InlineParser$hrefRegex = '(?:<([^<>' + ($dillonkearns$elm_markdown$Markdown$Helpers$lineEndChars + (']*)>|([^' + ($dillonkearns$elm_markdown$Markdown$Helpers$whiteSpaceChars + ('\\(\\)\\\\]*(?:\\\\.[^' + ($dillonkearns$elm_markdown$Markdown$Helpers$whiteSpaceChars + '\\(\\)\\\\]*)*))')))));
var $dillonkearns$elm_markdown$Markdown$Helpers$titleRegex = '(?:[' + ($dillonkearns$elm_markdown$Markdown$Helpers$whiteSpaceChars + (']+' + ('(?:\'([^\'\\\\]*(?:\\\\.[^\'\\\\]*)*)\'|' + ('\"([^\"\\\\]*(?:\\\\.[^\"\\\\]*)*)\"|' + '\\(([^\\)\\\\]*(?:\\\\.[^\\)\\\\]*)*)\\)))?'))));
var $dillonkearns$elm_markdown$Markdown$InlineParser$inlineLinkTypeOrImageTypeRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^\\(\\s*' + ($dillonkearns$elm_markdown$Markdown$InlineParser$hrefRegex + ($dillonkearns$elm_markdown$Markdown$Helpers$titleRegex + '\\s*\\)'))));
var $dillonkearns$elm_markdown$Markdown$Helpers$returnFirstJust = function (maybes) {
	var process = F2(
		function (a, maybeFound) {
			if (!maybeFound.$) {
				var found = maybeFound.a;
				return $elm$core$Maybe$Just(found);
			} else {
				return a;
			}
		});
	return A3($elm$core$List$foldl, process, $elm$core$Maybe$Nothing, maybes);
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$inlineLinkTypeOrImageTypeRegexToMatch = F2(
	function (matchModel, regexMatch) {
		var _v0 = regexMatch.eW;
		if ((((_v0.b && _v0.b.b) && _v0.b.b.b) && _v0.b.b.b.b) && _v0.b.b.b.b.b) {
			var maybeRawUrlAngleBrackets = _v0.a;
			var _v1 = _v0.b;
			var maybeRawUrlWithoutBrackets = _v1.a;
			var _v2 = _v1.b;
			var maybeTitleSingleQuotes = _v2.a;
			var _v3 = _v2.b;
			var maybeTitleDoubleQuotes = _v3.a;
			var _v4 = _v3.b;
			var maybeTitleParenthesis = _v4.a;
			var maybeTitle = $dillonkearns$elm_markdown$Markdown$Helpers$returnFirstJust(
				_List_fromArray(
					[maybeTitleSingleQuotes, maybeTitleDoubleQuotes, maybeTitleParenthesis]));
			var toMatch = function (rawUrl) {
				return _Utils_update(
					matchModel,
					{
						i: matchModel.i + $elm$core$String$length(regexMatch.b5),
						hw: function () {
							var _v5 = matchModel.hw;
							if (_v5.$ === 5) {
								return $dillonkearns$elm_markdown$Markdown$InlineParser$ImageType;
							} else {
								return $dillonkearns$elm_markdown$Markdown$InlineParser$LinkType;
							}
						}()(
							A2($dillonkearns$elm_markdown$Markdown$InlineParser$prepareUrlAndTitle, rawUrl, maybeTitle))
					});
			};
			var maybeRawUrl = $dillonkearns$elm_markdown$Markdown$Helpers$returnFirstJust(
				_List_fromArray(
					[maybeRawUrlAngleBrackets, maybeRawUrlWithoutBrackets]));
			return $elm$core$Maybe$Just(
				toMatch(
					A2($elm$core$Maybe$withDefault, '', maybeRawUrl)));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$checkForInlineLinkTypeOrImageType = F3(
	function (remainText, _v0, refs) {
		var tempMatch = _v0;
		var _v1 = A3($elm$regex$Regex$findAtMost, 1, $dillonkearns$elm_markdown$Markdown$InlineParser$inlineLinkTypeOrImageTypeRegex, remainText);
		if (_v1.b) {
			var first = _v1.a;
			var _v2 = A2($dillonkearns$elm_markdown$Markdown$InlineParser$inlineLinkTypeOrImageTypeRegexToMatch, tempMatch, first);
			if (!_v2.$) {
				var match = _v2.a;
				return $elm$core$Maybe$Just(match);
			} else {
				return A3($dillonkearns$elm_markdown$Markdown$InlineParser$checkForInlineReferences, remainText, tempMatch, refs);
			}
		} else {
			return A3($dillonkearns$elm_markdown$Markdown$InlineParser$checkForInlineReferences, remainText, tempMatch, refs);
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$checkParsedAheadOverlapping = F2(
	function (_v0, remainMatches) {
		var match = _v0;
		var overlappingMatches = $elm$core$List$filter(
			function (_v1) {
				var testMatch = _v1;
				return (_Utils_cmp(match.i, testMatch.o) > 0) && (_Utils_cmp(match.i, testMatch.i) < 0);
			});
		return ($elm$core$List$isEmpty(remainMatches) || $elm$core$List$isEmpty(
			overlappingMatches(remainMatches))) ? $elm$core$Maybe$Just(
			A2($elm$core$List$cons, match, remainMatches)) : $elm$core$Maybe$Nothing;
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$emailRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^([a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~\\-]+@[a-zA-Z0-9](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?)*)$'));
var $dillonkearns$elm_markdown$Markdown$InlineParser$emailAutolinkTypeToMatch = function (_v0) {
	var match = _v0;
	return A2($elm$regex$Regex$contains, $dillonkearns$elm_markdown$Markdown$InlineParser$emailRegex, match.e2) ? $elm$core$Result$Ok(
		_Utils_update(
			match,
			{
				hw: $dillonkearns$elm_markdown$Markdown$InlineParser$AutolinkType(
					_Utils_Tuple2(
						match.e2,
						'mailto:' + $dillonkearns$elm_markdown$Markdown$InlineParser$encodeUrl(match.e2)))
			})) : $elm$core$Result$Err(match);
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$findTokenHelp = F3(
	function (innerTokens, isToken, tokens) {
		findTokenHelp:
		while (true) {
			if (!tokens.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var nextToken = tokens.a;
				var remainingTokens = tokens.b;
				if (isToken(nextToken)) {
					return $elm$core$Maybe$Just(
						_Utils_Tuple3(
							nextToken,
							$elm$core$List$reverse(innerTokens),
							remainingTokens));
				} else {
					var $temp$innerTokens = A2($elm$core$List$cons, nextToken, innerTokens),
						$temp$isToken = isToken,
						$temp$tokens = remainingTokens;
					innerTokens = $temp$innerTokens;
					isToken = $temp$isToken;
					tokens = $temp$tokens;
					continue findTokenHelp;
				}
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$findToken = F2(
	function (isToken, tokens) {
		return A3($dillonkearns$elm_markdown$Markdown$InlineParser$findTokenHelp, _List_Nil, isToken, tokens);
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$HtmlToken = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$NotOpening = 1;
var $elm$parser$Parser$Advanced$getOffset = function (s) {
	return A3($elm$parser$Parser$Advanced$Good, false, s.d, s);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{fF: 1, g: _List_Nil, m: 1, d: 0, jE: 1, jM: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$htmlToToken = F2(
	function (rawText, _v0) {
		var match = _v0;
		var consumedCharacters = A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$keeper,
					$elm$parser$Parser$Advanced$succeed(
						F3(
							function (startOffset, htmlTag, endOffset) {
								return {f9: htmlTag, d5: endOffset - startOffset};
							})),
					$elm$parser$Parser$Advanced$getOffset),
				$dillonkearns$elm_markdown$HtmlParser$html),
			$elm$parser$Parser$Advanced$getOffset);
		var parsed = A2(
			$elm$parser$Parser$Advanced$run,
			consumedCharacters,
			A2($elm$core$String$dropLeft, match.o, rawText));
		if (!parsed.$) {
			var htmlTag = parsed.a.f9;
			var length = parsed.a.d5;
			var htmlToken = A2($dillonkearns$elm_markdown$Markdown$InlineParser$HtmlToken, 1, htmlTag);
			return $elm$core$Maybe$Just(
				{c: match.o, d5: length, e: htmlToken});
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $dillonkearns$elm_markdown$Markdown$Helpers$ifError = F2(
	function (_function, result) {
		if (!result.$) {
			return result;
		} else {
			var err = result.a;
			return _function(err);
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$isCloseToken = F2(
	function (htmlModel, token) {
		return false;
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$isCodeTokenPair = F2(
	function (closeToken, openToken) {
		var _v0 = openToken.e;
		if (!_v0.$) {
			if (!_v0.a) {
				var _v1 = _v0.a;
				return _Utils_eq(openToken.d5 - 1, closeToken.d5);
			} else {
				var _v2 = _v0.a;
				return _Utils_eq(openToken.d5, closeToken.d5);
			}
		} else {
			return false;
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$isLinkTypeOrImageOpenToken = function (token) {
	var _v0 = token.e;
	switch (_v0.$) {
		case 1:
			return true;
		case 2:
			return true;
		default:
			return false;
	}
};
var $dillonkearns$elm_markdown$Markdown$InlineParser$isOpenEmphasisToken = F2(
	function (closeToken, openToken) {
		var _v0 = openToken.e;
		if (_v0.$ === 7) {
			var openChar = _v0.a;
			var open = _v0.b;
			var _v1 = closeToken.e;
			if (_v1.$ === 7) {
				var closeChar = _v1.a;
				var close = _v1.b;
				return _Utils_eq(openChar, closeChar) ? ((_Utils_eq(open.cR, open.c4) || _Utils_eq(close.cR, close.c4)) ? ((!(!A2($elm$core$Basics$modBy, 3, closeToken.d5 + openToken.d5))) || ((!A2($elm$core$Basics$modBy, 3, closeToken.d5)) && (!A2($elm$core$Basics$modBy, 3, openToken.d5)))) : true) : false;
			} else {
				return false;
			}
		} else {
			return false;
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$isStrikethroughTokenPair = F2(
	function (closeToken, openToken) {
		var _v0 = function () {
			var _v1 = openToken.e;
			if (_v1.$ === 10) {
				if (!_v1.a) {
					var _v2 = _v1.a;
					return _Utils_Tuple2(true, openToken.d5 - 1);
				} else {
					var _v3 = _v1.a;
					return _Utils_Tuple2(true, openToken.d5);
				}
			} else {
				return _Utils_Tuple2(false, 0);
			}
		}();
		var openTokenIsStrikethrough = _v0.a;
		var openTokenLength = _v0.b;
		var _v4 = function () {
			var _v5 = closeToken.e;
			if (_v5.$ === 10) {
				if (!_v5.a) {
					var _v6 = _v5.a;
					return _Utils_Tuple2(true, closeToken.d5 - 1);
				} else {
					var _v7 = _v5.a;
					return _Utils_Tuple2(true, closeToken.d5);
				}
			} else {
				return _Utils_Tuple2(false, 0);
			}
		}();
		var closeTokenIsStrikethrough = _v4.a;
		var closeTokenLength = _v4.b;
		return closeTokenIsStrikethrough && (openTokenIsStrikethrough && _Utils_eq(closeTokenLength, openTokenLength));
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakType = {$: 1};
var $dillonkearns$elm_markdown$Markdown$InlineParser$tokenToMatch = F2(
	function (token, type_) {
		return {i: token.c + token.d5, t: _List_Nil, o: token.c, e2: '', L: 0, w: 0, hw: type_};
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$lineBreakTTM = F5(
	function (remaining, tokens, matches, refs, rawText) {
		lineBreakTTM:
		while (true) {
			if (!remaining.b) {
				return matches;
			} else {
				var token = remaining.a;
				var tokensTail = remaining.b;
				var _v1 = token.e;
				if (_v1.$ === 9) {
					var $temp$remaining = tokensTail,
						$temp$tokens = tokens,
						$temp$matches = A2(
						$elm$core$List$cons,
						A2($dillonkearns$elm_markdown$Markdown$InlineParser$tokenToMatch, token, $dillonkearns$elm_markdown$Markdown$InlineParser$HardLineBreakType),
						matches),
						$temp$refs = refs,
						$temp$rawText = rawText;
					remaining = $temp$remaining;
					tokens = $temp$tokens;
					matches = $temp$matches;
					refs = $temp$refs;
					rawText = $temp$rawText;
					continue lineBreakTTM;
				} else {
					var $temp$remaining = tokensTail,
						$temp$tokens = A2($elm$core$List$cons, token, tokens),
						$temp$matches = matches,
						$temp$refs = refs,
						$temp$rawText = rawText;
					remaining = $temp$remaining;
					tokens = $temp$tokens;
					matches = $temp$matches;
					refs = $temp$refs;
					rawText = $temp$rawText;
					continue lineBreakTTM;
				}
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$removeParsedAheadTokens = F2(
	function (_v0, tokensTail) {
		var match = _v0;
		return A2(
			$elm$core$List$filter,
			function (token) {
				return _Utils_cmp(token.c, match.i) > -1;
			},
			tokensTail);
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$angleBracketsToMatch = F6(
	function (closeToken, escaped, matches, references, rawText, _v46) {
		var openToken = _v46.a;
		var remainTokens = _v46.c;
		var result = A2(
			$dillonkearns$elm_markdown$Markdown$Helpers$ifError,
			$dillonkearns$elm_markdown$Markdown$InlineParser$emailAutolinkTypeToMatch,
			$dillonkearns$elm_markdown$Markdown$InlineParser$autolinkToMatch(
				A7(
					$dillonkearns$elm_markdown$Markdown$InlineParser$tokenPairToMatch,
					references,
					rawText,
					function (s) {
						return s;
					},
					$dillonkearns$elm_markdown$Markdown$InlineParser$CodeType,
					openToken,
					closeToken,
					_List_Nil)));
		if (result.$ === 1) {
			var tempMatch = result.a;
			if (escaped === 1) {
				var _v49 = A2($dillonkearns$elm_markdown$Markdown$InlineParser$htmlToToken, rawText, tempMatch);
				if (!_v49.$) {
					var newToken = _v49.a;
					return $elm$core$Maybe$Just(
						_Utils_Tuple2(
							A2($elm$core$List$cons, newToken, remainTokens),
							matches));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			} else {
				return $elm$core$Maybe$Nothing;
			}
		} else {
			var newMatch = result.a;
			return $elm$core$Maybe$Just(
				_Utils_Tuple2(
					remainTokens,
					A2($elm$core$List$cons, newMatch, matches)));
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$codeAutolinkTypeHtmlTagTTM = F5(
	function (remaining, tokens, matches, references, rawText) {
		codeAutolinkTypeHtmlTagTTM:
		while (true) {
			if (!remaining.b) {
				return A5(
					$dillonkearns$elm_markdown$Markdown$InlineParser$htmlElementTTM,
					$elm$core$List$reverse(tokens),
					_List_Nil,
					matches,
					references,
					rawText);
			} else {
				var token = remaining.a;
				var tokensTail = remaining.b;
				var _v38 = token.e;
				switch (_v38.$) {
					case 0:
						var isEscaped = _v38.a;
						var _v39 = A2(
							$dillonkearns$elm_markdown$Markdown$InlineParser$findToken,
							$dillonkearns$elm_markdown$Markdown$InlineParser$isCodeTokenPair(token),
							tokens);
						if (!_v39.$) {
							var code = _v39.a;
							var _v40 = A5($dillonkearns$elm_markdown$Markdown$InlineParser$codeToMatch, token, matches, references, rawText, code);
							var newTokens = _v40.a;
							var newMatches = _v40.b;
							var $temp$remaining = tokensTail,
								$temp$tokens = newTokens,
								$temp$matches = newMatches,
								$temp$references = references,
								$temp$rawText = rawText;
							remaining = $temp$remaining;
							tokens = $temp$tokens;
							matches = $temp$matches;
							references = $temp$references;
							rawText = $temp$rawText;
							continue codeAutolinkTypeHtmlTagTTM;
						} else {
							var $temp$remaining = tokensTail,
								$temp$tokens = A2($elm$core$List$cons, token, tokens),
								$temp$matches = matches,
								$temp$references = references,
								$temp$rawText = rawText;
							remaining = $temp$remaining;
							tokens = $temp$tokens;
							matches = $temp$matches;
							references = $temp$references;
							rawText = $temp$rawText;
							continue codeAutolinkTypeHtmlTagTTM;
						}
					case 5:
						var isEscaped = _v38.a;
						var isAngleBracketOpen = function (_v45) {
							var meaning = _v45.e;
							if (meaning.$ === 4) {
								return true;
							} else {
								return false;
							}
						};
						var _v41 = A2($dillonkearns$elm_markdown$Markdown$InlineParser$findToken, isAngleBracketOpen, tokens);
						if (!_v41.$) {
							var found = _v41.a;
							var _v42 = A6($dillonkearns$elm_markdown$Markdown$InlineParser$angleBracketsToMatch, token, isEscaped, matches, references, rawText, found);
							if (!_v42.$) {
								var _v43 = _v42.a;
								var newTokens = _v43.a;
								var newMatches = _v43.b;
								var $temp$remaining = tokensTail,
									$temp$tokens = A2(
									$elm$core$List$filter,
									A2($elm$core$Basics$composeL, $elm$core$Basics$not, isAngleBracketOpen),
									newTokens),
									$temp$matches = newMatches,
									$temp$references = references,
									$temp$rawText = rawText;
								remaining = $temp$remaining;
								tokens = $temp$tokens;
								matches = $temp$matches;
								references = $temp$references;
								rawText = $temp$rawText;
								continue codeAutolinkTypeHtmlTagTTM;
							} else {
								var $temp$remaining = tokensTail,
									$temp$tokens = A2(
									$elm$core$List$filter,
									A2($elm$core$Basics$composeL, $elm$core$Basics$not, isAngleBracketOpen),
									tokens),
									$temp$matches = matches,
									$temp$references = references,
									$temp$rawText = rawText;
								remaining = $temp$remaining;
								tokens = $temp$tokens;
								matches = $temp$matches;
								references = $temp$references;
								rawText = $temp$rawText;
								continue codeAutolinkTypeHtmlTagTTM;
							}
						} else {
							var $temp$remaining = tokensTail,
								$temp$tokens = A2(
								$elm$core$List$filter,
								A2($elm$core$Basics$composeL, $elm$core$Basics$not, isAngleBracketOpen),
								tokens),
								$temp$matches = matches,
								$temp$references = references,
								$temp$rawText = rawText;
							remaining = $temp$remaining;
							tokens = $temp$tokens;
							matches = $temp$matches;
							references = $temp$references;
							rawText = $temp$rawText;
							continue codeAutolinkTypeHtmlTagTTM;
						}
					default:
						var $temp$remaining = tokensTail,
							$temp$tokens = A2($elm$core$List$cons, token, tokens),
							$temp$matches = matches,
							$temp$references = references,
							$temp$rawText = rawText;
						remaining = $temp$remaining;
						tokens = $temp$tokens;
						matches = $temp$matches;
						references = $temp$references;
						rawText = $temp$rawText;
						continue codeAutolinkTypeHtmlTagTTM;
				}
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$codeToMatch = F5(
	function (closeToken, matches, references, rawText, _v34) {
		var openToken = _v34.a;
		var remainTokens = _v34.c;
		var updatedOpenToken = function () {
			var _v35 = openToken.e;
			if ((!_v35.$) && (!_v35.a)) {
				var _v36 = _v35.a;
				return _Utils_update(
					openToken,
					{c: openToken.c + 1, d5: openToken.d5 - 1});
			} else {
				return openToken;
			}
		}();
		var match = A7($dillonkearns$elm_markdown$Markdown$InlineParser$tokenPairToMatch, references, rawText, $dillonkearns$elm_markdown$Markdown$Helpers$cleanWhitespaces, $dillonkearns$elm_markdown$Markdown$InlineParser$CodeType, updatedOpenToken, closeToken, _List_Nil);
		return _Utils_Tuple2(
			remainTokens,
			A2($elm$core$List$cons, match, matches));
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$emphasisTTM = F5(
	function (remaining, tokens, matches, references, rawText) {
		emphasisTTM:
		while (true) {
			if (!remaining.b) {
				return A5(
					$dillonkearns$elm_markdown$Markdown$InlineParser$strikethroughTTM,
					$elm$core$List$reverse(tokens),
					_List_Nil,
					matches,
					references,
					rawText);
			} else {
				var token = remaining.a;
				var tokensTail = remaining.b;
				var _v29 = token.e;
				if (_v29.$ === 7) {
					var _char = _v29.a;
					var leftFringeRank = _v29.b.cR;
					var rightFringeRank = _v29.b.c4;
					if (_Utils_eq(leftFringeRank, rightFringeRank)) {
						if ((!(!rightFringeRank)) && ((_char !== '_') || (rightFringeRank === 1))) {
							var _v30 = A2(
								$dillonkearns$elm_markdown$Markdown$InlineParser$findToken,
								$dillonkearns$elm_markdown$Markdown$InlineParser$isOpenEmphasisToken(token),
								tokens);
							if (!_v30.$) {
								var found = _v30.a;
								var _v31 = A5($dillonkearns$elm_markdown$Markdown$InlineParser$emphasisToMatch, references, rawText, token, tokensTail, found);
								var newRemaining = _v31.a;
								var match = _v31.b;
								var newTokens = _v31.c;
								var $temp$remaining = newRemaining,
									$temp$tokens = newTokens,
									$temp$matches = A2($elm$core$List$cons, match, matches),
									$temp$references = references,
									$temp$rawText = rawText;
								remaining = $temp$remaining;
								tokens = $temp$tokens;
								matches = $temp$matches;
								references = $temp$references;
								rawText = $temp$rawText;
								continue emphasisTTM;
							} else {
								var $temp$remaining = tokensTail,
									$temp$tokens = A2($elm$core$List$cons, token, tokens),
									$temp$matches = matches,
									$temp$references = references,
									$temp$rawText = rawText;
								remaining = $temp$remaining;
								tokens = $temp$tokens;
								matches = $temp$matches;
								references = $temp$references;
								rawText = $temp$rawText;
								continue emphasisTTM;
							}
						} else {
							var $temp$remaining = tokensTail,
								$temp$tokens = tokens,
								$temp$matches = matches,
								$temp$references = references,
								$temp$rawText = rawText;
							remaining = $temp$remaining;
							tokens = $temp$tokens;
							matches = $temp$matches;
							references = $temp$references;
							rawText = $temp$rawText;
							continue emphasisTTM;
						}
					} else {
						if (_Utils_cmp(leftFringeRank, rightFringeRank) < 0) {
							var $temp$remaining = tokensTail,
								$temp$tokens = A2($elm$core$List$cons, token, tokens),
								$temp$matches = matches,
								$temp$references = references,
								$temp$rawText = rawText;
							remaining = $temp$remaining;
							tokens = $temp$tokens;
							matches = $temp$matches;
							references = $temp$references;
							rawText = $temp$rawText;
							continue emphasisTTM;
						} else {
							var _v32 = A2(
								$dillonkearns$elm_markdown$Markdown$InlineParser$findToken,
								$dillonkearns$elm_markdown$Markdown$InlineParser$isOpenEmphasisToken(token),
								tokens);
							if (!_v32.$) {
								var found = _v32.a;
								var _v33 = A5($dillonkearns$elm_markdown$Markdown$InlineParser$emphasisToMatch, references, rawText, token, tokensTail, found);
								var newRemaining = _v33.a;
								var match = _v33.b;
								var newTokens = _v33.c;
								var $temp$remaining = newRemaining,
									$temp$tokens = newTokens,
									$temp$matches = A2($elm$core$List$cons, match, matches),
									$temp$references = references,
									$temp$rawText = rawText;
								remaining = $temp$remaining;
								tokens = $temp$tokens;
								matches = $temp$matches;
								references = $temp$references;
								rawText = $temp$rawText;
								continue emphasisTTM;
							} else {
								var $temp$remaining = tokensTail,
									$temp$tokens = tokens,
									$temp$matches = matches,
									$temp$references = references,
									$temp$rawText = rawText;
								remaining = $temp$remaining;
								tokens = $temp$tokens;
								matches = $temp$matches;
								references = $temp$references;
								rawText = $temp$rawText;
								continue emphasisTTM;
							}
						}
					}
				} else {
					var $temp$remaining = tokensTail,
						$temp$tokens = A2($elm$core$List$cons, token, tokens),
						$temp$matches = matches,
						$temp$references = references,
						$temp$rawText = rawText;
					remaining = $temp$remaining;
					tokens = $temp$tokens;
					matches = $temp$matches;
					references = $temp$references;
					rawText = $temp$rawText;
					continue emphasisTTM;
				}
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$emphasisToMatch = F5(
	function (references, rawText, closeToken, tokensTail, _v27) {
		var openToken = _v27.a;
		var innerTokens = _v27.b;
		var remainTokens = _v27.c;
		var remainLength = openToken.d5 - closeToken.d5;
		var updt = (!remainLength) ? {cG: closeToken, b9: openToken, c3: remainTokens, db: tokensTail} : ((remainLength > 0) ? {
			cG: closeToken,
			b9: _Utils_update(
				openToken,
				{c: openToken.c + remainLength, d5: closeToken.d5}),
			c3: A2(
				$elm$core$List$cons,
				_Utils_update(
					openToken,
					{d5: remainLength}),
				remainTokens),
			db: tokensTail
		} : {
			cG: _Utils_update(
				closeToken,
				{d5: openToken.d5}),
			b9: openToken,
			c3: remainTokens,
			db: A2(
				$elm$core$List$cons,
				_Utils_update(
					closeToken,
					{c: closeToken.c + openToken.d5, d5: -remainLength}),
				tokensTail)
		});
		var match = A7(
			$dillonkearns$elm_markdown$Markdown$InlineParser$tokenPairToMatch,
			references,
			rawText,
			function (s) {
				return s;
			},
			$dillonkearns$elm_markdown$Markdown$InlineParser$EmphasisType(updt.b9.d5),
			updt.b9,
			updt.cG,
			$elm$core$List$reverse(innerTokens));
		return _Utils_Tuple3(updt.db, match, updt.c3);
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$htmlElementTTM = F5(
	function (remaining, tokens, matches, references, rawText) {
		htmlElementTTM:
		while (true) {
			if (!remaining.b) {
				return A5(
					$dillonkearns$elm_markdown$Markdown$InlineParser$linkImageTypeTTM,
					$elm$core$List$reverse(tokens),
					_List_Nil,
					matches,
					references,
					rawText);
			} else {
				var token = remaining.a;
				var tokensTail = remaining.b;
				var _v23 = token.e;
				if (_v23.$ === 6) {
					var isOpen = _v23.a;
					var htmlModel = _v23.b;
					if (isOpen === 1) {
						var $temp$remaining = tokensTail,
							$temp$tokens = tokens,
							$temp$matches = A2(
							$elm$core$List$cons,
							A2(
								$dillonkearns$elm_markdown$Markdown$InlineParser$tokenToMatch,
								token,
								$dillonkearns$elm_markdown$Markdown$InlineParser$HtmlType(htmlModel)),
							matches),
							$temp$references = references,
							$temp$rawText = rawText;
						remaining = $temp$remaining;
						tokens = $temp$tokens;
						matches = $temp$matches;
						references = $temp$references;
						rawText = $temp$rawText;
						continue htmlElementTTM;
					} else {
						var _v25 = A2(
							$dillonkearns$elm_markdown$Markdown$InlineParser$findToken,
							$dillonkearns$elm_markdown$Markdown$InlineParser$isCloseToken(htmlModel),
							tokensTail);
						if (_v25.$ === 1) {
							var $temp$remaining = tokensTail,
								$temp$tokens = tokens,
								$temp$matches = A2(
								$elm$core$List$cons,
								A2(
									$dillonkearns$elm_markdown$Markdown$InlineParser$tokenToMatch,
									token,
									$dillonkearns$elm_markdown$Markdown$InlineParser$HtmlType(htmlModel)),
								matches),
								$temp$references = references,
								$temp$rawText = rawText;
							remaining = $temp$remaining;
							tokens = $temp$tokens;
							matches = $temp$matches;
							references = $temp$references;
							rawText = $temp$rawText;
							continue htmlElementTTM;
						} else {
							var _v26 = _v25.a;
							var closeToken = _v26.a;
							var innerTokens = _v26.b;
							var newTail = _v26.c;
							var newMatch = A7(
								$dillonkearns$elm_markdown$Markdown$InlineParser$tokenPairToMatch,
								references,
								rawText,
								function (s) {
									return s;
								},
								$dillonkearns$elm_markdown$Markdown$InlineParser$HtmlType(htmlModel),
								token,
								closeToken,
								innerTokens);
							var $temp$remaining = newTail,
								$temp$tokens = tokens,
								$temp$matches = A2($elm$core$List$cons, newMatch, matches),
								$temp$references = references,
								$temp$rawText = rawText;
							remaining = $temp$remaining;
							tokens = $temp$tokens;
							matches = $temp$matches;
							references = $temp$references;
							rawText = $temp$rawText;
							continue htmlElementTTM;
						}
					}
				} else {
					var $temp$remaining = tokensTail,
						$temp$tokens = A2($elm$core$List$cons, token, tokens),
						$temp$matches = matches,
						$temp$references = references,
						$temp$rawText = rawText;
					remaining = $temp$remaining;
					tokens = $temp$tokens;
					matches = $temp$matches;
					references = $temp$references;
					rawText = $temp$rawText;
					continue htmlElementTTM;
				}
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$linkImageTypeTTM = F5(
	function (remaining, tokens, matches, references, rawText) {
		linkImageTypeTTM:
		while (true) {
			if (!remaining.b) {
				return A5(
					$dillonkearns$elm_markdown$Markdown$InlineParser$emphasisTTM,
					$elm$core$List$reverse(tokens),
					_List_Nil,
					matches,
					references,
					rawText);
			} else {
				var token = remaining.a;
				var tokensTail = remaining.b;
				var _v18 = token.e;
				if (_v18.$ === 3) {
					var _v19 = A2($dillonkearns$elm_markdown$Markdown$InlineParser$findToken, $dillonkearns$elm_markdown$Markdown$InlineParser$isLinkTypeOrImageOpenToken, tokens);
					if (!_v19.$) {
						var found = _v19.a;
						var _v20 = A6($dillonkearns$elm_markdown$Markdown$InlineParser$linkOrImageTypeToMatch, token, tokensTail, matches, references, rawText, found);
						if (!_v20.$) {
							var _v21 = _v20.a;
							var x = _v21.a;
							var newMatches = _v21.b;
							var newTokens = _v21.c;
							var $temp$remaining = x,
								$temp$tokens = newTokens,
								$temp$matches = newMatches,
								$temp$references = references,
								$temp$rawText = rawText;
							remaining = $temp$remaining;
							tokens = $temp$tokens;
							matches = $temp$matches;
							references = $temp$references;
							rawText = $temp$rawText;
							continue linkImageTypeTTM;
						} else {
							var $temp$remaining = tokensTail,
								$temp$tokens = tokens,
								$temp$matches = matches,
								$temp$references = references,
								$temp$rawText = rawText;
							remaining = $temp$remaining;
							tokens = $temp$tokens;
							matches = $temp$matches;
							references = $temp$references;
							rawText = $temp$rawText;
							continue linkImageTypeTTM;
						}
					} else {
						var $temp$remaining = tokensTail,
							$temp$tokens = tokens,
							$temp$matches = matches,
							$temp$references = references,
							$temp$rawText = rawText;
						remaining = $temp$remaining;
						tokens = $temp$tokens;
						matches = $temp$matches;
						references = $temp$references;
						rawText = $temp$rawText;
						continue linkImageTypeTTM;
					}
				} else {
					var $temp$remaining = tokensTail,
						$temp$tokens = A2($elm$core$List$cons, token, tokens),
						$temp$matches = matches,
						$temp$references = references,
						$temp$rawText = rawText;
					remaining = $temp$remaining;
					tokens = $temp$tokens;
					matches = $temp$matches;
					references = $temp$references;
					rawText = $temp$rawText;
					continue linkImageTypeTTM;
				}
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$linkOrImageTypeToMatch = F6(
	function (closeToken, tokensTail, oldMatches, references, rawText, _v8) {
		var openToken = _v8.a;
		var innerTokens = _v8.b;
		var remainTokens = _v8.c;
		var removeOpenToken = _Utils_Tuple3(
			tokensTail,
			oldMatches,
			_Utils_ap(innerTokens, remainTokens));
		var remainText = A2($elm$core$String$dropLeft, closeToken.c + 1, rawText);
		var inactivateLinkOpenToken = function (token) {
			var _v16 = token.e;
			if (_v16.$ === 1) {
				return _Utils_update(
					token,
					{
						e: $dillonkearns$elm_markdown$Markdown$InlineParser$LinkOpenToken(1)
					});
			} else {
				return token;
			}
		};
		var findTempMatch = function (isLinkType) {
			return A7(
				$dillonkearns$elm_markdown$Markdown$InlineParser$tokenPairToMatch,
				references,
				rawText,
				function (s) {
					return s;
				},
				isLinkType ? $dillonkearns$elm_markdown$Markdown$InlineParser$LinkType(
					_Utils_Tuple2('', $elm$core$Maybe$Nothing)) : $dillonkearns$elm_markdown$Markdown$InlineParser$ImageType(
					_Utils_Tuple2('', $elm$core$Maybe$Nothing)),
				openToken,
				closeToken,
				$elm$core$List$reverse(innerTokens));
		};
		var _v9 = openToken.e;
		switch (_v9.$) {
			case 2:
				var tempMatch = findTempMatch(false);
				var _v10 = A3($dillonkearns$elm_markdown$Markdown$InlineParser$checkForInlineLinkTypeOrImageType, remainText, tempMatch, references);
				if (_v10.$ === 1) {
					return $elm$core$Maybe$Just(removeOpenToken);
				} else {
					var match = _v10.a;
					var _v11 = A2($dillonkearns$elm_markdown$Markdown$InlineParser$checkParsedAheadOverlapping, match, oldMatches);
					if (!_v11.$) {
						var matches = _v11.a;
						return $elm$core$Maybe$Just(
							_Utils_Tuple3(
								A2($dillonkearns$elm_markdown$Markdown$InlineParser$removeParsedAheadTokens, match, tokensTail),
								matches,
								remainTokens));
					} else {
						return $elm$core$Maybe$Just(removeOpenToken);
					}
				}
			case 1:
				if (!_v9.a) {
					var _v12 = _v9.a;
					var tempMatch = findTempMatch(true);
					var _v13 = A3($dillonkearns$elm_markdown$Markdown$InlineParser$checkForInlineLinkTypeOrImageType, remainText, tempMatch, references);
					if (_v13.$ === 1) {
						return $elm$core$Maybe$Just(removeOpenToken);
					} else {
						var match = _v13.a;
						var _v14 = A2($dillonkearns$elm_markdown$Markdown$InlineParser$checkParsedAheadOverlapping, match, oldMatches);
						if (!_v14.$) {
							var matches = _v14.a;
							return $elm$core$Maybe$Just(
								_Utils_Tuple3(
									A2($dillonkearns$elm_markdown$Markdown$InlineParser$removeParsedAheadTokens, match, tokensTail),
									matches,
									A2($elm$core$List$map, inactivateLinkOpenToken, remainTokens)));
						} else {
							return $elm$core$Maybe$Just(removeOpenToken);
						}
					}
				} else {
					var _v15 = _v9.a;
					return $elm$core$Maybe$Just(removeOpenToken);
				}
			default:
				return $elm$core$Maybe$Nothing;
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$strikethroughTTM = F5(
	function (remaining, tokens, matches, references, rawText) {
		strikethroughTTM:
		while (true) {
			if (!remaining.b) {
				return A5(
					$dillonkearns$elm_markdown$Markdown$InlineParser$lineBreakTTM,
					$elm$core$List$reverse(tokens),
					_List_Nil,
					matches,
					references,
					rawText);
			} else {
				var token = remaining.a;
				var tokensTail = remaining.b;
				var _v5 = token.e;
				if (_v5.$ === 10) {
					var isEscaped = _v5.a;
					var _v6 = A2(
						$dillonkearns$elm_markdown$Markdown$InlineParser$findToken,
						$dillonkearns$elm_markdown$Markdown$InlineParser$isStrikethroughTokenPair(token),
						tokens);
					if (!_v6.$) {
						var content = _v6.a;
						var _v7 = A5($dillonkearns$elm_markdown$Markdown$InlineParser$strikethroughToMatch, token, matches, references, rawText, content);
						var newTokens = _v7.a;
						var newMatches = _v7.b;
						var $temp$remaining = tokensTail,
							$temp$tokens = newTokens,
							$temp$matches = newMatches,
							$temp$references = references,
							$temp$rawText = rawText;
						remaining = $temp$remaining;
						tokens = $temp$tokens;
						matches = $temp$matches;
						references = $temp$references;
						rawText = $temp$rawText;
						continue strikethroughTTM;
					} else {
						var $temp$remaining = tokensTail,
							$temp$tokens = A2($elm$core$List$cons, token, tokens),
							$temp$matches = matches,
							$temp$references = references,
							$temp$rawText = rawText;
						remaining = $temp$remaining;
						tokens = $temp$tokens;
						matches = $temp$matches;
						references = $temp$references;
						rawText = $temp$rawText;
						continue strikethroughTTM;
					}
				} else {
					var $temp$remaining = tokensTail,
						$temp$tokens = A2($elm$core$List$cons, token, tokens),
						$temp$matches = matches,
						$temp$references = references,
						$temp$rawText = rawText;
					remaining = $temp$remaining;
					tokens = $temp$tokens;
					matches = $temp$matches;
					references = $temp$references;
					rawText = $temp$rawText;
					continue strikethroughTTM;
				}
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$strikethroughToMatch = F5(
	function (closeToken, matches, references, rawText, _v1) {
		var openToken = _v1.a;
		var remainTokens = _v1.c;
		var updatedOpenToken = function () {
			var _v2 = openToken.e;
			if ((_v2.$ === 10) && (!_v2.a)) {
				var _v3 = _v2.a;
				return _Utils_update(
					openToken,
					{c: openToken.c + 1, d5: openToken.d5 - 1});
			} else {
				return openToken;
			}
		}();
		var match = A7($dillonkearns$elm_markdown$Markdown$InlineParser$tokenPairToMatch, references, rawText, $dillonkearns$elm_markdown$Markdown$Helpers$cleanWhitespaces, $dillonkearns$elm_markdown$Markdown$InlineParser$StrikethroughType, updatedOpenToken, closeToken, _List_Nil);
		return _Utils_Tuple2(
			remainTokens,
			A2($elm$core$List$cons, match, matches));
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$tokenPairToMatch = F7(
	function (references, rawText, processText, type_, openToken, closeToken, innerTokens) {
		var textStart = openToken.c + openToken.d5;
		var textEnd = closeToken.c;
		var text = processText(
			A3($elm$core$String$slice, textStart, textEnd, rawText));
		var start = openToken.c;
		var end = closeToken.c + closeToken.d5;
		var match = {i: end, t: _List_Nil, o: start, e2: text, L: textEnd, w: textStart, hw: type_};
		var matches = A2(
			$elm$core$List$map,
			function (_v0) {
				var matchModel = _v0;
				return A2($dillonkearns$elm_markdown$Markdown$InlineParser$prepareChildMatch, match, matchModel);
			},
			A4($dillonkearns$elm_markdown$Markdown$InlineParser$tokensToMatches, innerTokens, _List_Nil, references, rawText));
		return {i: end, t: matches, o: start, e2: text, L: textEnd, w: textStart, hw: type_};
	});
var $dillonkearns$elm_markdown$Markdown$InlineParser$tokensToMatches = F4(
	function (tokens, matches, references, rawText) {
		return A5($dillonkearns$elm_markdown$Markdown$InlineParser$codeAutolinkTypeHtmlTagTTM, tokens, _List_Nil, matches, references, rawText);
	});
var $elm$core$String$trim = _String_trim;
var $dillonkearns$elm_markdown$Markdown$InlineParser$parse = F2(
	function (refs, rawText_) {
		var rawText = $elm$core$String$trim(rawText_);
		var tokens = $dillonkearns$elm_markdown$Markdown$InlineParser$tokenize(rawText);
		return $dillonkearns$elm_markdown$Markdown$InlineParser$matchesToInlines(
			A3(
				$dillonkearns$elm_markdown$Markdown$InlineParser$parseTextMatches,
				rawText,
				_List_Nil,
				$dillonkearns$elm_markdown$Markdown$InlineParser$organizeMatches(
					A4($dillonkearns$elm_markdown$Markdown$InlineParser$tokensToMatches, tokens, _List_Nil, refs, rawText))));
	});
var $dillonkearns$elm_markdown$Markdown$Parser$thisIsDefinitelyNotAnHtmlTag = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			$elm$parser$Parser$Advanced$token(
			A2(
				$elm$parser$Parser$Advanced$Token,
				' ',
				$elm$parser$Parser$Expecting(' '))),
			$elm$parser$Parser$Advanced$token(
			A2(
				$elm$parser$Parser$Advanced$Token,
				'>',
				$elm$parser$Parser$Expecting('>'))),
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$chompIf,
					$elm$core$Char$isAlpha,
					$elm$parser$Parser$Expecting('Alpha')),
				$elm$parser$Parser$Advanced$chompWhile(
					function (c) {
						return $elm$core$Char$isAlphaNum(c) || (c === '-');
					})),
			$elm$parser$Parser$Advanced$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$Advanced$token(
						A2(
							$elm$parser$Parser$Advanced$Token,
							':',
							$elm$parser$Parser$Expecting(':'))),
						$elm$parser$Parser$Advanced$token(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'@',
							$elm$parser$Parser$Expecting('@'))),
						$elm$parser$Parser$Advanced$token(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'\\',
							$elm$parser$Parser$Expecting('\\'))),
						$elm$parser$Parser$Advanced$token(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'+',
							$elm$parser$Parser$Expecting('+'))),
						$elm$parser$Parser$Advanced$token(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'.',
							$elm$parser$Parser$Expecting('.')))
					])))
		]));
var $dillonkearns$elm_markdown$Markdown$Parser$parseAsParagraphInsteadOfHtmlBlock = $elm$parser$Parser$Advanced$backtrackable(
	A2(
		$elm$parser$Parser$Advanced$mapChompedString,
		F2(
			function (rawLine, _v0) {
				return $dillonkearns$elm_markdown$Markdown$RawBlock$OpenBlockOrParagraph(rawLine);
			}),
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$token(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'<',
							$elm$parser$Parser$Expecting('<'))),
					$dillonkearns$elm_markdown$Markdown$Parser$thisIsDefinitelyNotAnHtmlTag),
				$dillonkearns$elm_markdown$Helpers$chompUntilLineEndOrEnd),
			$dillonkearns$elm_markdown$Helpers$lineEndOrEnd)));
var $dillonkearns$elm_markdown$Markdown$Table$TableHeader = $elm$core$Basics$identity;
var $dillonkearns$elm_markdown$Parser$Token$parseString = function (str) {
	return $elm$parser$Parser$Advanced$token(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$Expecting(str)));
};
var $dillonkearns$elm_markdown$Markdown$TableParser$parseCellHelper = function (_v0) {
	var curr = _v0.a;
	var acc = _v0.b;
	var _return = A2(
		$elm$core$Maybe$withDefault,
		$elm$parser$Parser$Advanced$Done(acc),
		A2(
			$elm$core$Maybe$map,
			function (cell) {
				return $elm$parser$Parser$Advanced$Done(
					A2($elm$core$List$cons, cell, acc));
			},
			curr));
	var finishCell = A2(
		$elm$core$Maybe$withDefault,
		$elm$parser$Parser$Advanced$Loop(
			_Utils_Tuple2($elm$core$Maybe$Nothing, acc)),
		A2(
			$elm$core$Maybe$map,
			function (cell) {
				return $elm$parser$Parser$Advanced$Loop(
					_Utils_Tuple2(
						$elm$core$Maybe$Nothing,
						A2($elm$core$List$cons, cell, acc)));
			},
			curr));
	var addToCurrent = function (c) {
		return _Utils_ap(
			A2($elm$core$Maybe$withDefault, '', curr),
			c);
	};
	var continueCell = function (c) {
		return $elm$parser$Parser$Advanced$Loop(
			_Utils_Tuple2(
				$elm$core$Maybe$Just(
					addToCurrent(c)),
				acc));
	};
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$Advanced$map,
				function (_v1) {
					return _return;
				},
				$dillonkearns$elm_markdown$Parser$Token$parseString('|\n')),
				A2(
				$elm$parser$Parser$Advanced$map,
				function (_v2) {
					return _return;
				},
				$dillonkearns$elm_markdown$Parser$Token$parseString('\n')),
				A2(
				$elm$parser$Parser$Advanced$map,
				function (_v3) {
					return _return;
				},
				$elm$parser$Parser$Advanced$end(
					$elm$parser$Parser$Expecting('end'))),
				A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$backtrackable(
					$elm$parser$Parser$Advanced$succeed(
						continueCell('|'))),
				$dillonkearns$elm_markdown$Parser$Token$parseString('\\\\|')),
				A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$backtrackable(
					$elm$parser$Parser$Advanced$succeed(
						continueCell('\\'))),
				$dillonkearns$elm_markdown$Parser$Token$parseString('\\\\')),
				A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$backtrackable(
					$elm$parser$Parser$Advanced$succeed(
						continueCell('|'))),
				$dillonkearns$elm_markdown$Parser$Token$parseString('\\|')),
				A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$backtrackable(
					$elm$parser$Parser$Advanced$succeed(finishCell)),
				$dillonkearns$elm_markdown$Parser$Token$parseString('|')),
				A2(
				$elm$parser$Parser$Advanced$mapChompedString,
				F2(
					function (_char, _v4) {
						return continueCell(_char);
					}),
				A2(
					$elm$parser$Parser$Advanced$chompIf,
					$elm$core$Basics$always(true),
					$elm$parser$Parser$Problem('No character found')))
			]));
};
var $dillonkearns$elm_markdown$Markdown$TableParser$parseCells = A2(
	$elm$parser$Parser$Advanced$map,
	A2(
		$elm$core$List$foldl,
		F2(
			function (cell, acc) {
				return A2(
					$elm$core$List$cons,
					$elm$core$String$trim(cell),
					acc);
			}),
		_List_Nil),
	A2(
		$elm$parser$Parser$Advanced$loop,
		_Utils_Tuple2($elm$core$Maybe$Nothing, _List_Nil),
		$dillonkearns$elm_markdown$Markdown$TableParser$parseCellHelper));
var $dillonkearns$elm_markdown$Markdown$TableParser$rowParser = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					$dillonkearns$elm_markdown$Parser$Token$parseString('|'),
					$elm$parser$Parser$Advanced$succeed(0)
				]))),
	$dillonkearns$elm_markdown$Markdown$TableParser$parseCells);
var $dillonkearns$elm_markdown$Markdown$TableParser$parseHeader = F2(
	function (_v0, headersRow) {
		var columnAlignments = _v0.b;
		var headersWithAlignment = function (headers) {
			return A3(
				$elm$core$List$map2,
				F2(
					function (headerCell, alignment) {
						return {cw: alignment, bp: headerCell};
					}),
				headers,
				columnAlignments);
		};
		var combineHeaderAndDelimiter = function (headers) {
			return _Utils_eq(
				$elm$core$List$length(headers),
				$elm$core$List$length(columnAlignments)) ? $elm$core$Result$Ok(
				headersWithAlignment(headers)) : $elm$core$Result$Err(
				'Tables must have the same number of header columns (' + ($elm$core$String$fromInt(
					$elm$core$List$length(headers)) + (') as delimiter columns (' + ($elm$core$String$fromInt(
					$elm$core$List$length(columnAlignments)) + ')'))));
		};
		var _v1 = A2($elm$parser$Parser$Advanced$run, $dillonkearns$elm_markdown$Markdown$TableParser$rowParser, headersRow);
		if (!_v1.$) {
			var headers = _v1.a;
			return combineHeaderAndDelimiter(headers);
		} else {
			return $elm$core$Result$Err('Unable to parse previous line as a table header');
		}
	});
var $dillonkearns$elm_markdown$Markdown$CodeBlock$CodeBlock = F2(
	function (language, body) {
		return {hY: body, d3: language};
	});
var $dillonkearns$elm_markdown$Markdown$CodeBlock$infoString = function (fenceCharacter) {
	var toInfoString = F2(
		function (str, _v2) {
			var _v1 = $elm$core$String$trim(str);
			if (_v1 === '') {
				return $elm$core$Maybe$Nothing;
			} else {
				var trimmed = _v1;
				return $elm$core$Maybe$Just(trimmed);
			}
		});
	var _v0 = fenceCharacter.cQ;
	if (!_v0) {
		return A2(
			$elm$parser$Parser$Advanced$mapChompedString,
			toInfoString,
			$elm$parser$Parser$Advanced$chompWhile(
				function (c) {
					return (c !== '`') && (!$dillonkearns$elm_markdown$Whitespace$isLineEnd(c));
				}));
	} else {
		return A2(
			$elm$parser$Parser$Advanced$mapChompedString,
			toInfoString,
			$elm$parser$Parser$Advanced$chompWhile(
				A2($elm$core$Basics$composeL, $elm$core$Basics$not, $dillonkearns$elm_markdown$Whitespace$isLineEnd)));
	}
};
var $dillonkearns$elm_markdown$Markdown$CodeBlock$Backtick = 0;
var $dillonkearns$elm_markdown$Parser$Token$backtick = A2(
	$elm$parser$Parser$Advanced$Token,
	'`',
	$elm$parser$Parser$Expecting('a \'`\''));
var $dillonkearns$elm_markdown$Markdown$CodeBlock$backtick = {cE: '`', cQ: 0, da: $dillonkearns$elm_markdown$Parser$Token$backtick};
var $dillonkearns$elm_markdown$Markdown$CodeBlock$colToIndentation = function (_int) {
	switch (_int) {
		case 1:
			return $elm$parser$Parser$Advanced$succeed(0);
		case 2:
			return $elm$parser$Parser$Advanced$succeed(1);
		case 3:
			return $elm$parser$Parser$Advanced$succeed(2);
		case 4:
			return $elm$parser$Parser$Advanced$succeed(3);
		default:
			return $elm$parser$Parser$Advanced$problem(
				$elm$parser$Parser$Expecting('Fenced code blocks should be indented no more than 3 spaces'));
	}
};
var $dillonkearns$elm_markdown$Markdown$CodeBlock$fenceOfAtLeast = F2(
	function (minLength, fenceCharacter) {
		var builtTokens = A3(
			$elm$core$List$foldl,
			F2(
				function (t, p) {
					return A2($elm$parser$Parser$Advanced$ignorer, p, t);
				}),
			$elm$parser$Parser$Advanced$succeed(0),
			A2(
				$elm$core$List$repeat,
				minLength,
				$elm$parser$Parser$Advanced$token(fenceCharacter.da)));
		return A2(
			$elm$parser$Parser$Advanced$mapChompedString,
			F2(
				function (str, _v0) {
					return _Utils_Tuple2(
						fenceCharacter,
						$elm$core$String$length(str));
				}),
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				builtTokens,
				$elm$parser$Parser$Advanced$chompWhile(
					$elm$core$Basics$eq(fenceCharacter.cE))));
	});
var $dillonkearns$elm_markdown$Markdown$CodeBlock$Tilde = 1;
var $dillonkearns$elm_markdown$Parser$Token$tilde = A2(
	$elm$parser$Parser$Advanced$Token,
	'~',
	$elm$parser$Parser$Expecting('a `~`'));
var $dillonkearns$elm_markdown$Markdown$CodeBlock$tilde = {cE: '~', cQ: 1, da: $dillonkearns$elm_markdown$Parser$Token$tilde};
var $dillonkearns$elm_markdown$Whitespace$upToThreeSpaces = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$dillonkearns$elm_markdown$Whitespace$space,
				$elm$parser$Parser$Advanced$oneOf(
					_List_fromArray(
						[
							$dillonkearns$elm_markdown$Whitespace$space,
							$elm$parser$Parser$Advanced$succeed(0)
						]))),
			$elm$parser$Parser$Advanced$oneOf(
				_List_fromArray(
					[
						$dillonkearns$elm_markdown$Whitespace$space,
						$elm$parser$Parser$Advanced$succeed(0)
					]))),
			$elm$parser$Parser$Advanced$succeed(0)
		]));
var $dillonkearns$elm_markdown$Markdown$CodeBlock$openingFence = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed(
				F2(
					function (indent, _v0) {
						var character = _v0.a;
						var length = _v0.b;
						return {cF: character, d0: indent, d5: length};
					})),
			$dillonkearns$elm_markdown$Whitespace$upToThreeSpaces),
		A2($elm$parser$Parser$Advanced$andThen, $dillonkearns$elm_markdown$Markdown$CodeBlock$colToIndentation, $elm$parser$Parser$Advanced$getCol)),
	$elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2($dillonkearns$elm_markdown$Markdown$CodeBlock$fenceOfAtLeast, 3, $dillonkearns$elm_markdown$Markdown$CodeBlock$backtick),
				A2($dillonkearns$elm_markdown$Markdown$CodeBlock$fenceOfAtLeast, 3, $dillonkearns$elm_markdown$Markdown$CodeBlock$tilde)
			])));
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $dillonkearns$elm_markdown$Whitespace$isSpace = $elm$core$Basics$eq(' ');
var $dillonkearns$elm_markdown$Markdown$CodeBlock$closingFence = F2(
	function (minLength, fenceCharacter) {
		return A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						$elm$parser$Parser$Advanced$succeed(0),
						$dillonkearns$elm_markdown$Whitespace$upToThreeSpaces),
					A2($dillonkearns$elm_markdown$Markdown$CodeBlock$fenceOfAtLeast, minLength, fenceCharacter)),
				$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$Whitespace$isSpace)),
			$dillonkearns$elm_markdown$Helpers$lineEndOrEnd);
	});
var $dillonkearns$elm_markdown$Markdown$CodeBlock$codeBlockLine = function (indented) {
	return A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
			A2($dillonkearns$elm_markdown$Parser$Extra$upTo, indented, $dillonkearns$elm_markdown$Whitespace$space)),
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2($elm$parser$Parser$Advanced$ignorer, $elm$parser$Parser$Advanced$getOffset, $dillonkearns$elm_markdown$Helpers$chompUntilLineEndOrEnd),
			$dillonkearns$elm_markdown$Helpers$lineEndOrEnd));
};
var $elm$parser$Parser$Advanced$getSource = function (s) {
	return A3($elm$parser$Parser$Advanced$Good, false, s.jM, s);
};
var $dillonkearns$elm_markdown$Markdown$CodeBlock$remainingBlockHelp = function (_v0) {
	var fence = _v0.a;
	var body = _v0.b;
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed(
					$elm$parser$Parser$Advanced$Done(body)),
				$elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd)),
				A2(
				$elm$parser$Parser$Advanced$mapChompedString,
				F2(
					function (lineEnd, _v1) {
						return $elm$parser$Parser$Advanced$Loop(
							_Utils_Tuple2(
								fence,
								_Utils_ap(body, lineEnd)));
					}),
				$dillonkearns$elm_markdown$Whitespace$lineEnd),
				$elm$parser$Parser$Advanced$backtrackable(
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed(
						$elm$parser$Parser$Advanced$Done(body)),
					A2($dillonkearns$elm_markdown$Markdown$CodeBlock$closingFence, fence.d5, fence.cF))),
				A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$keeper,
					A2(
						$elm$parser$Parser$Advanced$keeper,
						$elm$parser$Parser$Advanced$succeed(
							F3(
								function (start, end, source) {
									return $elm$parser$Parser$Advanced$Loop(
										_Utils_Tuple2(
											fence,
											_Utils_ap(
												body,
												A3($elm$core$String$slice, start, end, source))));
								})),
						$dillonkearns$elm_markdown$Markdown$CodeBlock$codeBlockLine(fence.d0)),
					$elm$parser$Parser$Advanced$getOffset),
				$elm$parser$Parser$Advanced$getSource)
			]));
};
var $dillonkearns$elm_markdown$Markdown$CodeBlock$remainingBlock = function (fence) {
	return A2(
		$elm$parser$Parser$Advanced$loop,
		_Utils_Tuple2(fence, ''),
		$dillonkearns$elm_markdown$Markdown$CodeBlock$remainingBlockHelp);
};
var $dillonkearns$elm_markdown$Markdown$CodeBlock$parser = A2(
	$elm$parser$Parser$Advanced$andThen,
	function (fence) {
		return A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$keeper,
				$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Markdown$CodeBlock$CodeBlock),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$dillonkearns$elm_markdown$Markdown$CodeBlock$infoString(fence.cF),
					$dillonkearns$elm_markdown$Helpers$lineEndOrEnd)),
			$dillonkearns$elm_markdown$Markdown$CodeBlock$remainingBlock(fence));
	},
	$dillonkearns$elm_markdown$Markdown$CodeBlock$openingFence);
var $elm$core$String$endsWith = _String_endsWith;
var $dillonkearns$elm_markdown$Markdown$Heading$dropTrailingHashes = function (headingString) {
	return A2($elm$core$String$endsWith, '#', headingString) ? $dillonkearns$elm_markdown$Markdown$Heading$dropTrailingHashes(
		A2($elm$core$String$dropRight, 1, headingString)) : headingString;
};
var $elm$core$String$trimRight = _String_trimRight;
var $dillonkearns$elm_markdown$Markdown$Heading$dropClosingSequence = function (headingString) {
	var droppedTrailingHashesString = $dillonkearns$elm_markdown$Markdown$Heading$dropTrailingHashes(headingString);
	return (A2($elm$core$String$endsWith, ' ', droppedTrailingHashesString) || $elm$core$String$isEmpty(droppedTrailingHashesString)) ? $elm$core$String$trimRight(droppedTrailingHashesString) : headingString;
};
var $dillonkearns$elm_markdown$Parser$Token$hash = A2(
	$elm$parser$Parser$Advanced$Token,
	'#',
	$elm$parser$Parser$Expecting('a `#`'));
var $dillonkearns$elm_markdown$Markdown$Heading$isHash = function (c) {
	if ('#' === c) {
		return true;
	} else {
		return false;
	}
};
var $elm$parser$Parser$Advanced$spaces = $elm$parser$Parser$Advanced$chompWhile(
	function (c) {
		return (c === ' ') || ((c === '\n') || (c === '\r'));
	});
var $dillonkearns$elm_markdown$Markdown$Heading$parser = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Markdown$RawBlock$Heading),
				A2(
					$elm$parser$Parser$Advanced$andThen,
					function (startingSpaces) {
						var startSpace = $elm$core$String$length(startingSpaces);
						return (startSpace >= 4) ? $elm$parser$Parser$Advanced$problem(
							$elm$parser$Parser$Expecting('heading with < 4 spaces in front')) : $elm$parser$Parser$Advanced$succeed(startSpace);
					},
					$elm$parser$Parser$Advanced$getChompedString($elm$parser$Parser$Advanced$spaces))),
			$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$hash)),
		A2(
			$elm$parser$Parser$Advanced$andThen,
			function (additionalHashes) {
				var level = $elm$core$String$length(additionalHashes) + 1;
				return (level >= 7) ? $elm$parser$Parser$Advanced$problem(
					$elm$parser$Parser$Expecting('heading with < 7 #\'s')) : $elm$parser$Parser$Advanced$succeed(level);
			},
			$elm$parser$Parser$Advanced$getChompedString(
				$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$Markdown$Heading$isHash)))),
	$elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed(''),
				$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$newline)),
				A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$Advanced$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$space),
								$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$tab)
							]))),
				A2(
					$elm$parser$Parser$Advanced$mapChompedString,
					F2(
						function (headingText, _v0) {
							return $dillonkearns$elm_markdown$Markdown$Heading$dropClosingSequence(
								$elm$core$String$trim(headingText));
						}),
					$dillonkearns$elm_markdown$Helpers$chompUntilLineEndOrEnd))
			])));
var $elm$parser$Parser$Advanced$findSubString = _Parser_findSubString;
var $elm$parser$Parser$Advanced$fromInfo = F4(
	function (row, col, x, context) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, row, col, x, context));
	});
var $elm$parser$Parser$Advanced$chompUntil = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$findSubString, str, s.d, s.jE, s.fF, s.jM);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A4($elm$parser$Parser$Advanced$fromInfo, newRow, newCol, expecting, s.g)) : A3(
			$elm$parser$Parser$Advanced$Good,
			_Utils_cmp(s.d, newOffset) < 0,
			0,
			{fF: newCol, g: s.g, m: s.m, d: newOffset, jE: newRow, jM: s.jM});
	};
};
var $dillonkearns$elm_markdown$Parser$Token$greaterThan = A2(
	$elm$parser$Parser$Advanced$Token,
	'>',
	$elm$parser$Parser$Expecting('a `>`'));
var $elm$parser$Parser$Advanced$Located = F3(
	function (row, col, context) {
		return {fF: col, g: context, jE: row};
	});
var $elm$parser$Parser$Advanced$changeContext = F2(
	function (newContext, s) {
		return {fF: s.fF, g: newContext, m: s.m, d: s.d, jE: s.jE, jM: s.jM};
	});
var $elm$parser$Parser$Advanced$inContext = F2(
	function (context, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(
				A2(
					$elm$parser$Parser$Advanced$changeContext,
					A2(
						$elm$core$List$cons,
						A3($elm$parser$Parser$Advanced$Located, s0.jE, s0.fF, context),
						s0.g),
					s0));
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					a,
					A2($elm$parser$Parser$Advanced$changeContext, s0.g, s1));
			} else {
				var step = _v1;
				return step;
			}
		};
	});
var $dillonkearns$elm_markdown$Whitespace$isWhitespace = function (_char) {
	switch (_char) {
		case ' ':
			return true;
		case '\n':
			return true;
		case '\t':
			return true;
		case '\u000B':
			return true;
		case '\u000C':
			return true;
		case '\r':
			return true;
		default:
			return false;
	}
};
var $dillonkearns$elm_markdown$Parser$Token$lessThan = A2(
	$elm$parser$Parser$Advanced$Token,
	'<',
	$elm$parser$Parser$Expecting('a `<`'));
var $dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$destinationParser = A2(
	$elm$parser$Parser$Advanced$inContext,
	'link destination',
	$elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed($elm$url$Url$percentEncode),
					$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$lessThan)),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$getChompedString(
						$elm$parser$Parser$Advanced$chompUntil($dillonkearns$elm_markdown$Parser$Token$greaterThan)),
					$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$greaterThan))),
				$elm$parser$Parser$Advanced$getChompedString(
				$dillonkearns$elm_markdown$Parser$Extra$chompOneOrMore(
					A2($elm$core$Basics$composeL, $elm$core$Basics$not, $dillonkearns$elm_markdown$Whitespace$isWhitespace)))
			])));
var $dillonkearns$elm_markdown$Parser$Token$closingSquareBracket = A2(
	$elm$parser$Parser$Advanced$Token,
	']',
	$elm$parser$Parser$Expecting('a `]`'));
var $dillonkearns$elm_markdown$Parser$Token$openingSquareBracket = A2(
	$elm$parser$Parser$Advanced$Token,
	'[',
	$elm$parser$Parser$Expecting('a `[`'));
var $dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$labelParser = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Markdown$Helpers$prepareRefLabel),
		$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$openingSquareBracket)),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$getChompedString(
			$elm$parser$Parser$Advanced$chompUntil($dillonkearns$elm_markdown$Parser$Token$closingSquareBracket)),
		$elm$parser$Parser$Advanced$symbol(
			A2(
				$elm$parser$Parser$Advanced$Token,
				']:',
				$elm$parser$Parser$Expecting(']:')))));
var $dillonkearns$elm_markdown$Parser$Token$doubleQuote = A2(
	$elm$parser$Parser$Advanced$Token,
	'\"',
	$elm$parser$Parser$Expecting('a double quote'));
var $dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$hasNoBlankLine = function (str) {
	return A2($elm$core$String$contains, '\n\n', str) ? $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Expecting('no blank line')) : $elm$parser$Parser$Advanced$succeed(str);
};
var $dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$onlyWhitespaceTillNewline = A2(
	$elm$parser$Parser$Advanced$ignorer,
	$elm$parser$Parser$Advanced$chompWhile(
		function (c) {
			return (!$dillonkearns$elm_markdown$Whitespace$isLineEnd(c)) && $dillonkearns$elm_markdown$Whitespace$isWhitespace(c);
		}),
	$dillonkearns$elm_markdown$Helpers$lineEndOrEnd);
var $dillonkearns$elm_markdown$Whitespace$requiredWhitespace = A2(
	$elm$parser$Parser$Advanced$ignorer,
	A2(
		$elm$parser$Parser$Advanced$chompIf,
		$dillonkearns$elm_markdown$Whitespace$isWhitespace,
		$elm$parser$Parser$Expecting('Required whitespace')),
	$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$Whitespace$isWhitespace));
var $dillonkearns$elm_markdown$Parser$Token$singleQuote = A2(
	$elm$parser$Parser$Advanced$Token,
	'\'',
	$elm$parser$Parser$Expecting('a single quote'));
var $dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$titleParser = function () {
	var inSingleQuotes = A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($elm$core$Maybe$Just),
			$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$singleQuote)),
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$andThen,
					$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$hasNoBlankLine,
					$elm$parser$Parser$Advanced$getChompedString(
						$elm$parser$Parser$Advanced$chompUntil($dillonkearns$elm_markdown$Parser$Token$singleQuote))),
				$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$singleQuote)),
			$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$onlyWhitespaceTillNewline));
	var inDoubleQuotes = A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($elm$core$Maybe$Just),
			$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$doubleQuote)),
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$andThen,
					$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$hasNoBlankLine,
					$elm$parser$Parser$Advanced$getChompedString(
						$elm$parser$Parser$Advanced$chompUntil($dillonkearns$elm_markdown$Parser$Token$doubleQuote))),
				$elm$parser$Parser$Advanced$symbol($dillonkearns$elm_markdown$Parser$Token$doubleQuote)),
			$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$onlyWhitespaceTillNewline));
	return A2(
		$elm$parser$Parser$Advanced$inContext,
		'title',
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$Advanced$backtrackable(
					A2(
						$elm$parser$Parser$Advanced$keeper,
						A2(
							$elm$parser$Parser$Advanced$ignorer,
							$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
							$dillonkearns$elm_markdown$Whitespace$requiredWhitespace),
						$elm$parser$Parser$Advanced$oneOf(
							_List_fromArray(
								[
									inDoubleQuotes,
									inSingleQuotes,
									$elm$parser$Parser$Advanced$succeed($elm$core$Maybe$Nothing)
								])))),
					A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed($elm$core$Maybe$Nothing),
					$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$onlyWhitespaceTillNewline)
				])));
}();
var $dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$parser = A2(
	$elm$parser$Parser$Advanced$inContext,
	'link reference definition',
	A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed(
						F3(
							function (label, destination, title) {
								return _Utils_Tuple2(
									label,
									{fN: destination, j5: title});
							})),
					$dillonkearns$elm_markdown$Whitespace$upToThreeSpaces),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						A2(
							$elm$parser$Parser$Advanced$ignorer,
							$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$labelParser,
							$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$Whitespace$isSpaceOrTab)),
						$elm$parser$Parser$Advanced$oneOf(
							_List_fromArray(
								[
									$dillonkearns$elm_markdown$Whitespace$lineEnd,
									$elm$parser$Parser$Advanced$succeed(0)
								]))),
					$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$Whitespace$isSpaceOrTab))),
			$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$destinationParser),
		$dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$titleParser));
var $dillonkearns$elm_markdown$ThematicBreak$ThematicBreak = 0;
var $dillonkearns$elm_markdown$ThematicBreak$whitespace = $elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$Whitespace$isSpaceOrTab);
var $dillonkearns$elm_markdown$ThematicBreak$withChar = function (tchar) {
	var token = $dillonkearns$elm_markdown$Parser$Token$parseString(
		$elm$core$String$fromChar(tchar));
	return A2(
		$elm$parser$Parser$Advanced$ignorer,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						A2(
							$elm$parser$Parser$Advanced$ignorer,
							A2(
								$elm$parser$Parser$Advanced$ignorer,
								$elm$parser$Parser$Advanced$succeed(0),
								token),
							$dillonkearns$elm_markdown$ThematicBreak$whitespace),
						token),
					$dillonkearns$elm_markdown$ThematicBreak$whitespace),
				token),
			$elm$parser$Parser$Advanced$chompWhile(
				function (c) {
					return _Utils_eq(c, tchar) || $dillonkearns$elm_markdown$Whitespace$isSpaceOrTab(c);
				})),
		$dillonkearns$elm_markdown$Helpers$lineEndOrEnd);
};
var $dillonkearns$elm_markdown$ThematicBreak$parseThematicBreak = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			$dillonkearns$elm_markdown$ThematicBreak$withChar('-'),
			$dillonkearns$elm_markdown$ThematicBreak$withChar('*'),
			$dillonkearns$elm_markdown$ThematicBreak$withChar('_')
		]));
var $dillonkearns$elm_markdown$ThematicBreak$parser = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
						$dillonkearns$elm_markdown$Whitespace$space),
					$elm$parser$Parser$Advanced$oneOf(
						_List_fromArray(
							[
								$dillonkearns$elm_markdown$Whitespace$space,
								$elm$parser$Parser$Advanced$succeed(0)
							]))),
				$elm$parser$Parser$Advanced$oneOf(
					_List_fromArray(
						[
							$dillonkearns$elm_markdown$Whitespace$space,
							$elm$parser$Parser$Advanced$succeed(0)
						]))),
			$dillonkearns$elm_markdown$ThematicBreak$parseThematicBreak),
			$dillonkearns$elm_markdown$ThematicBreak$parseThematicBreak
		]));
var $dillonkearns$elm_markdown$Markdown$RawBlock$LevelOne = 0;
var $dillonkearns$elm_markdown$Markdown$RawBlock$LevelTwo = 1;
var $dillonkearns$elm_markdown$Markdown$RawBlock$SetextLine = F2(
	function (a, b) {
		return {$: 13, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Parser$Token$equals = A2(
	$elm$parser$Parser$Advanced$Token,
	'=',
	$elm$parser$Parser$Expecting('a `=`'));
var $dillonkearns$elm_markdown$Parser$Token$minus = A2(
	$elm$parser$Parser$Advanced$Token,
	'-',
	$elm$parser$Parser$Expecting('a `-`'));
var $dillonkearns$elm_markdown$Markdown$Parser$setextLineParser = function () {
	var setextLevel = F3(
		function (level, levelToken, levelChar) {
			return A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed(level),
					$elm$parser$Parser$Advanced$token(levelToken)),
				$elm$parser$Parser$Advanced$chompWhile(
					$elm$core$Basics$eq(levelChar)));
		});
	return A2(
		$elm$parser$Parser$Advanced$mapChompedString,
		F2(
			function (raw, level) {
				return A2($dillonkearns$elm_markdown$Markdown$RawBlock$SetextLine, level, raw);
			}),
		A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
				$dillonkearns$elm_markdown$Whitespace$upToThreeSpaces),
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$oneOf(
						_List_fromArray(
							[
								A3(setextLevel, 0, $dillonkearns$elm_markdown$Parser$Token$equals, '='),
								A3(setextLevel, 1, $dillonkearns$elm_markdown$Parser$Token$minus, '-')
							])),
					$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$Whitespace$isSpaceOrTab)),
				$dillonkearns$elm_markdown$Helpers$lineEndOrEnd)));
}();
var $dillonkearns$elm_markdown$Markdown$RawBlock$TableDelimiter = function (a) {
	return {$: 9, a: a};
};
var $dillonkearns$elm_markdown$Markdown$TableParser$chompSinglelineWhitespace = $elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$Whitespace$isSpaceOrTab);
var $dillonkearns$elm_markdown$Parser$Extra$maybeChomp = function (condition) {
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$Advanced$chompIf,
				condition,
				$elm$parser$Parser$Problem('Character not found')),
				$elm$parser$Parser$Advanced$succeed(0)
			]));
};
var $dillonkearns$elm_markdown$Markdown$TableParser$requirePipeIfNotFirst = function (columns) {
	return $elm$core$List$isEmpty(columns) ? $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				$dillonkearns$elm_markdown$Parser$Token$parseString('|'),
				$elm$parser$Parser$Advanced$succeed(0)
			])) : $dillonkearns$elm_markdown$Parser$Token$parseString('|');
};
var $dillonkearns$elm_markdown$Markdown$TableParser$delimiterRowHelp = function (revDelimiterColumns) {
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				$elm$parser$Parser$Advanced$backtrackable(
				A2(
					$elm$parser$Parser$Advanced$map,
					function (_v0) {
						return $elm$parser$Parser$Advanced$Done(revDelimiterColumns);
					},
					$dillonkearns$elm_markdown$Parser$Token$parseString('|\n'))),
				A2(
				$elm$parser$Parser$Advanced$map,
				function (_v1) {
					return $elm$parser$Parser$Advanced$Done(revDelimiterColumns);
				},
				$dillonkearns$elm_markdown$Parser$Token$parseString('\n')),
				A2(
				$elm$parser$Parser$Advanced$map,
				function (_v2) {
					return $elm$parser$Parser$Advanced$Done(revDelimiterColumns);
				},
				$elm$parser$Parser$Advanced$end(
					$elm$parser$Parser$Expecting('end'))),
				$elm$parser$Parser$Advanced$backtrackable(
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						$elm$parser$Parser$Advanced$succeed(
							$elm$parser$Parser$Advanced$Done(revDelimiterColumns)),
						$dillonkearns$elm_markdown$Parser$Token$parseString('|')),
					$elm$parser$Parser$Advanced$end(
						$elm$parser$Parser$Expecting('end')))),
				A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						$elm$parser$Parser$Advanced$succeed(
							function (column) {
								return $elm$parser$Parser$Advanced$Loop(
									A2($elm$core$List$cons, column, revDelimiterColumns));
							}),
						$dillonkearns$elm_markdown$Markdown$TableParser$requirePipeIfNotFirst(revDelimiterColumns)),
					$dillonkearns$elm_markdown$Markdown$TableParser$chompSinglelineWhitespace),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$getChompedString(
						A2(
							$elm$parser$Parser$Advanced$ignorer,
							A2(
								$elm$parser$Parser$Advanced$ignorer,
								A2(
									$elm$parser$Parser$Advanced$ignorer,
									$elm$parser$Parser$Advanced$succeed(0),
									$dillonkearns$elm_markdown$Parser$Extra$maybeChomp(
										function (c) {
											return c === ':';
										})),
								$dillonkearns$elm_markdown$Parser$Extra$chompOneOrMore(
									function (c) {
										return c === '-';
									})),
							$dillonkearns$elm_markdown$Parser$Extra$maybeChomp(
								function (c) {
									return c === ':';
								}))),
					$dillonkearns$elm_markdown$Markdown$TableParser$chompSinglelineWhitespace))
			]));
};
var $dillonkearns$elm_markdown$Markdown$Block$AlignCenter = 2;
var $dillonkearns$elm_markdown$Markdown$Block$AlignLeft = 0;
var $dillonkearns$elm_markdown$Markdown$Block$AlignRight = 1;
var $dillonkearns$elm_markdown$Markdown$TableParser$delimiterToAlignment = function (cell) {
	var _v0 = _Utils_Tuple2(
		A2($elm$core$String$startsWith, ':', cell),
		A2($elm$core$String$endsWith, ':', cell));
	if (_v0.a) {
		if (_v0.b) {
			return $elm$core$Maybe$Just(2);
		} else {
			return $elm$core$Maybe$Just(0);
		}
	} else {
		if (_v0.b) {
			return $elm$core$Maybe$Just(1);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	}
};
var $dillonkearns$elm_markdown$Markdown$TableParser$delimiterRowParser = A2(
	$elm$parser$Parser$Advanced$andThen,
	function (delimiterRow) {
		var trimmed = delimiterRow.a.hv;
		var headers = delimiterRow.b;
		return $elm$core$List$isEmpty(headers) ? $elm$parser$Parser$Advanced$problem(
			$elm$parser$Parser$Expecting('Must have at least one column in delimiter row.')) : ((($elm$core$List$length(headers) === 1) && (!(A2($elm$core$String$startsWith, '|', trimmed) && A2($elm$core$String$endsWith, '|', trimmed)))) ? $elm$parser$Parser$Advanced$problem(
			$elm$parser$Parser$Problem('Tables with a single column must have pipes at the start and end of the delimiter row to avoid ambiguity.')) : $elm$parser$Parser$Advanced$succeed(delimiterRow));
	},
	A2(
		$elm$parser$Parser$Advanced$mapChompedString,
		F2(
			function (delimiterText, revDelimiterColumns) {
				return A2(
					$dillonkearns$elm_markdown$Markdown$Table$TableDelimiterRow,
					{
						g0: delimiterText,
						hv: $elm$core$String$trim(delimiterText)
					},
					A2(
						$elm$core$List$map,
						$dillonkearns$elm_markdown$Markdown$TableParser$delimiterToAlignment,
						$elm$core$List$reverse(revDelimiterColumns)));
			}),
		A2($elm$parser$Parser$Advanced$loop, _List_Nil, $dillonkearns$elm_markdown$Markdown$TableParser$delimiterRowHelp)));
var $dillonkearns$elm_markdown$Markdown$Parser$tableDelimiterInOpenParagraph = A2($elm$parser$Parser$Advanced$map, $dillonkearns$elm_markdown$Markdown$RawBlock$TableDelimiter, $dillonkearns$elm_markdown$Markdown$TableParser$delimiterRowParser);
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $dillonkearns$elm_markdown$Markdown$TableParser$standardizeRowLength = F2(
	function (expectedLength, row) {
		var rowLength = $elm$core$List$length(row);
		var _v0 = A2($elm$core$Basics$compare, expectedLength, rowLength);
		switch (_v0) {
			case 0:
				return A2($elm$core$List$take, expectedLength, row);
			case 1:
				return row;
			default:
				return _Utils_ap(
					row,
					A2($elm$core$List$repeat, expectedLength - rowLength, ''));
		}
	});
var $dillonkearns$elm_markdown$Markdown$TableParser$bodyRowParser = function (expectedRowLength) {
	return A2(
		$elm$parser$Parser$Advanced$andThen,
		function (row) {
			return ($elm$core$List$isEmpty(row) || A2($elm$core$List$all, $elm$core$String$isEmpty, row)) ? $elm$parser$Parser$Advanced$problem(
				$elm$parser$Parser$Problem('A line must have at least one column')) : $elm$parser$Parser$Advanced$succeed(
				A2($dillonkearns$elm_markdown$Markdown$TableParser$standardizeRowLength, expectedRowLength, row));
		},
		$dillonkearns$elm_markdown$Markdown$TableParser$rowParser);
};
var $dillonkearns$elm_markdown$Markdown$Parser$tableRowIfTableStarted = function (_v0) {
	var headers = _v0.a;
	var body = _v0.b;
	return A2(
		$elm$parser$Parser$Advanced$map,
		function (row) {
			return $dillonkearns$elm_markdown$Markdown$RawBlock$Table(
				A2(
					$dillonkearns$elm_markdown$Markdown$Table$Table,
					headers,
					_Utils_ap(
						body,
						_List_fromArray(
							[row]))));
		},
		$dillonkearns$elm_markdown$Markdown$TableParser$bodyRowParser(
			$elm$core$List$length(headers)));
};
var $dillonkearns$elm_markdown$Markdown$Block$H1 = 0;
var $dillonkearns$elm_markdown$Markdown$Block$H2 = 1;
var $dillonkearns$elm_markdown$Markdown$Block$H3 = 2;
var $dillonkearns$elm_markdown$Markdown$Block$H4 = 3;
var $dillonkearns$elm_markdown$Markdown$Block$H5 = 4;
var $dillonkearns$elm_markdown$Markdown$Block$H6 = 5;
var $dillonkearns$elm_markdown$Markdown$Parser$toHeading = function (level) {
	switch (level) {
		case 1:
			return $elm$core$Result$Ok(0);
		case 2:
			return $elm$core$Result$Ok(1);
		case 3:
			return $elm$core$Result$Ok(2);
		case 4:
			return $elm$core$Result$Ok(3);
		case 5:
			return $elm$core$Result$Ok(4);
		case 6:
			return $elm$core$Result$Ok(5);
		default:
			return $elm$core$Result$Err(
				$elm$parser$Parser$Expecting(
					'A heading with 1 to 6 #\'s, but found ' + $elm$core$String$fromInt(level)));
	}
};
var $dillonkearns$elm_markdown$Markdown$ListItem$EmptyItem = {$: 2};
var $dillonkearns$elm_markdown$Markdown$ListItem$PlainItem = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$Markdown$ListItem$TaskItem = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$UnorderedList$getIntendedCodeItem = F4(
	function (markerStartPos, listMarker, markerEndPos, _v0) {
		var bodyStartPos = _v0.a;
		var item = _v0.b;
		var spaceNum = bodyStartPos - markerEndPos;
		if (spaceNum <= 4) {
			return _Utils_Tuple3(listMarker, bodyStartPos - markerStartPos, item);
		} else {
			var intendedCodeItem = function () {
				switch (item.$) {
					case 0:
						var completion = item.a;
						var string = item.b;
						return A2(
							$dillonkearns$elm_markdown$Markdown$ListItem$TaskItem,
							completion,
							_Utils_ap(
								A2($elm$core$String$repeat, spaceNum - 1, ' '),
								string));
					case 1:
						var string = item.a;
						return $dillonkearns$elm_markdown$Markdown$ListItem$PlainItem(
							_Utils_ap(
								A2($elm$core$String$repeat, spaceNum - 1, ' '),
								string));
					default:
						return $dillonkearns$elm_markdown$Markdown$ListItem$EmptyItem;
				}
			}();
			return _Utils_Tuple3(listMarker, (markerEndPos - markerStartPos) + 1, intendedCodeItem);
		}
	});
var $dillonkearns$elm_markdown$Markdown$UnorderedList$unorderedListEmptyItemParser = A2(
	$elm$parser$Parser$Advanced$keeper,
	$elm$parser$Parser$Advanced$succeed(
		function (bodyStartPos) {
			return _Utils_Tuple2(bodyStartPos, $dillonkearns$elm_markdown$Markdown$ListItem$EmptyItem);
		}),
	A2($elm$parser$Parser$Advanced$ignorer, $elm$parser$Parser$Advanced$getCol, $dillonkearns$elm_markdown$Helpers$lineEndOrEnd));
var $dillonkearns$elm_markdown$Markdown$ListItem$Complete = 1;
var $dillonkearns$elm_markdown$Markdown$ListItem$Incomplete = 0;
var $dillonkearns$elm_markdown$Markdown$ListItem$taskItemParser = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed(1),
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					'[x] ',
					$elm$parser$Parser$ExpectingSymbol('[x] ')))),
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed(1),
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					'[X] ',
					$elm$parser$Parser$ExpectingSymbol('[X] ')))),
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed(0),
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					'[ ] ',
					$elm$parser$Parser$ExpectingSymbol('[ ] '))))
		]));
var $dillonkearns$elm_markdown$Markdown$ListItem$parser = A2(
	$elm$parser$Parser$Advanced$keeper,
	$elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$Advanced$keeper,
				$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Markdown$ListItem$TaskItem),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$dillonkearns$elm_markdown$Markdown$ListItem$taskItemParser,
					$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$Whitespace$isSpaceOrTab))),
				$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Markdown$ListItem$PlainItem)
			])),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$getChompedString($dillonkearns$elm_markdown$Helpers$chompUntilLineEndOrEnd),
		$dillonkearns$elm_markdown$Helpers$lineEndOrEnd));
var $dillonkearns$elm_markdown$Markdown$UnorderedList$unorderedListItemBodyParser = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed(
				F2(
					function (bodyStartPos, item) {
						return _Utils_Tuple2(bodyStartPos, item);
					})),
			$dillonkearns$elm_markdown$Parser$Extra$chompOneOrMore($dillonkearns$elm_markdown$Whitespace$isSpaceOrTab)),
		$elm$parser$Parser$Advanced$getCol),
	$dillonkearns$elm_markdown$Markdown$ListItem$parser);
var $dillonkearns$elm_markdown$Markdown$UnorderedList$Asterisk = 2;
var $dillonkearns$elm_markdown$Markdown$UnorderedList$Minus = 0;
var $dillonkearns$elm_markdown$Markdown$UnorderedList$Plus = 1;
var $dillonkearns$elm_markdown$Markdown$UnorderedList$unorderedListMarkerParser = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed(0),
				A2($dillonkearns$elm_markdown$Parser$Extra$upTo, 3, $dillonkearns$elm_markdown$Whitespace$space)),
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					'-',
					$elm$parser$Parser$ExpectingSymbol('-')))),
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed(1),
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					'+',
					$elm$parser$Parser$ExpectingSymbol('+')))),
			A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed(2),
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					'*',
					$elm$parser$Parser$ExpectingSymbol('*'))))
		]));
var $dillonkearns$elm_markdown$Markdown$UnorderedList$parser = function (previousWasBody) {
	return A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$keeper,
					$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Markdown$UnorderedList$getIntendedCodeItem),
					$elm$parser$Parser$Advanced$getCol),
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$UnorderedList$unorderedListMarkerParser)),
			$elm$parser$Parser$Advanced$getCol),
		previousWasBody ? $dillonkearns$elm_markdown$Markdown$UnorderedList$unorderedListItemBodyParser : $elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[$dillonkearns$elm_markdown$Markdown$UnorderedList$unorderedListEmptyItemParser, $dillonkearns$elm_markdown$Markdown$UnorderedList$unorderedListItemBodyParser])));
};
var $dillonkearns$elm_markdown$Markdown$Parser$unorderedListBlock = function (previousWasBody) {
	var parseListItem = F3(
		function (listmarker, intended, unparsedListItem) {
			switch (unparsedListItem.$) {
				case 0:
					var completion = unparsedListItem.a;
					var body = unparsedListItem.b;
					return {
						hY: body,
						iZ: listmarker,
						p: $elm$core$Maybe$Just(
							function () {
								if (completion === 1) {
									return true;
								} else {
									return false;
								}
							}())
					};
				case 1:
					var body = unparsedListItem.a;
					return {hY: body, iZ: listmarker, p: $elm$core$Maybe$Nothing};
				default:
					return {hY: '', iZ: listmarker, p: $elm$core$Maybe$Nothing};
			}
		});
	return A2(
		$elm$parser$Parser$Advanced$map,
		function (_v0) {
			var listmarker = _v0.a;
			var intended = _v0.b;
			var unparsedListItem = _v0.c;
			return A4(
				$dillonkearns$elm_markdown$Markdown$RawBlock$UnorderedListBlock,
				true,
				intended,
				_List_Nil,
				A3(parseListItem, listmarker, intended, unparsedListItem));
		},
		$dillonkearns$elm_markdown$Markdown$UnorderedList$parser(previousWasBody));
};
var $dillonkearns$elm_markdown$Markdown$Parser$childToBlocks = F2(
	function (node, blocks) {
		switch (node.$) {
			case 0:
				var tag = node.a;
				var attributes = node.b;
				var children = node.c;
				var _v99 = $dillonkearns$elm_markdown$Markdown$Parser$nodesToBlocks(children);
				if (!_v99.$) {
					var childrenAsBlocks = _v99.a;
					var block = $dillonkearns$elm_markdown$Markdown$Block$HtmlBlock(
						A3($dillonkearns$elm_markdown$Markdown$Block$HtmlElement, tag, attributes, childrenAsBlocks));
					return $elm$core$Result$Ok(
						A2($elm$core$List$cons, block, blocks));
				} else {
					var err = _v99.a;
					return $elm$core$Result$Err(err);
				}
			case 1:
				var innerText = node.a;
				var _v100 = $dillonkearns$elm_markdown$Markdown$Parser$parse(innerText);
				if (!_v100.$) {
					var value = _v100.a;
					return $elm$core$Result$Ok(
						_Utils_ap(
							$elm$core$List$reverse(value),
							blocks));
				} else {
					var error = _v100.a;
					return $elm$core$Result$Err(
						$elm$parser$Parser$Expecting(
							A2(
								$elm$core$String$join,
								'\n',
								A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$deadEndToString, error))));
				}
			case 2:
				var string = node.a;
				return $elm$core$Result$Ok(
					A2(
						$elm$core$List$cons,
						$dillonkearns$elm_markdown$Markdown$Block$HtmlBlock(
							$dillonkearns$elm_markdown$Markdown$Block$HtmlComment(string)),
						blocks));
			case 3:
				var string = node.a;
				return $elm$core$Result$Ok(
					A2(
						$elm$core$List$cons,
						$dillonkearns$elm_markdown$Markdown$Block$HtmlBlock(
							$dillonkearns$elm_markdown$Markdown$Block$Cdata(string)),
						blocks));
			case 4:
				var string = node.a;
				return $elm$core$Result$Ok(
					A2(
						$elm$core$List$cons,
						$dillonkearns$elm_markdown$Markdown$Block$HtmlBlock(
							$dillonkearns$elm_markdown$Markdown$Block$ProcessingInstruction(string)),
						blocks));
			default:
				var declarationType = node.a;
				var content = node.b;
				return $elm$core$Result$Ok(
					A2(
						$elm$core$List$cons,
						$dillonkearns$elm_markdown$Markdown$Block$HtmlBlock(
							A2($dillonkearns$elm_markdown$Markdown$Block$HtmlDeclaration, declarationType, content)),
						blocks));
		}
	});
var $dillonkearns$elm_markdown$Markdown$Parser$completeBlocks = function (state) {
	var _v84 = state.b;
	_v84$5:
	while (true) {
		if (_v84.b) {
			switch (_v84.a.$) {
				case 11:
					var body2 = _v84.a.a;
					var rest = _v84.b;
					var _v85 = A2(
						$elm$parser$Parser$Advanced$run,
						$dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser(),
						body2);
					if (!_v85.$) {
						var value = _v85.a;
						return $elm$parser$Parser$Advanced$succeed(
							{
								a: _Utils_ap(state.a, value.a),
								b: A2(
									$elm$core$List$cons,
									$dillonkearns$elm_markdown$Markdown$RawBlock$ParsedBlockQuote(value.b),
									rest)
							});
					} else {
						var error = _v85.a;
						return $elm$parser$Parser$Advanced$problem(
							$elm$parser$Parser$Problem(
								$dillonkearns$elm_markdown$Markdown$Parser$deadEndsToString(error)));
					}
				case 3:
					var _v86 = _v84.a;
					var tight = _v86.a;
					var intended = _v86.b;
					var closeListItems = _v86.c;
					var openListItem = _v86.d;
					var rest = _v84.b;
					var _v87 = A2(
						$elm$parser$Parser$Advanced$run,
						$dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser(),
						openListItem.hY);
					if (!_v87.$) {
						var value = _v87.a;
						var tight2 = A2($elm$core$List$member, $dillonkearns$elm_markdown$Markdown$RawBlock$BlankLine, value.b) ? false : tight;
						return $elm$parser$Parser$Advanced$succeed(
							{
								a: _Utils_ap(state.a, value.a),
								b: A2(
									$elm$core$List$cons,
									A4(
										$dillonkearns$elm_markdown$Markdown$RawBlock$UnorderedListBlock,
										tight2,
										intended,
										A2(
											$elm$core$List$cons,
											{hY: value.b, p: openListItem.p},
											closeListItems),
										openListItem),
									rest)
							});
					} else {
						var e = _v87.a;
						return $elm$parser$Parser$Advanced$problem(
							$elm$parser$Parser$Problem(
								$dillonkearns$elm_markdown$Markdown$Parser$deadEndsToString(e)));
					}
				case 4:
					var _v92 = _v84.a;
					var tight = _v92.a;
					var intended = _v92.b;
					var marker = _v92.c;
					var order = _v92.d;
					var closeListItems = _v92.e;
					var openListItem = _v92.f;
					var rest = _v84.b;
					var _v93 = A2(
						$elm$parser$Parser$Advanced$run,
						$dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser(),
						openListItem);
					if (!_v93.$) {
						var value = _v93.a;
						var tight2 = A2($elm$core$List$member, $dillonkearns$elm_markdown$Markdown$RawBlock$BlankLine, value.b) ? false : tight;
						return $elm$parser$Parser$Advanced$succeed(
							{
								a: _Utils_ap(state.a, value.a),
								b: A2(
									$elm$core$List$cons,
									A6(
										$dillonkearns$elm_markdown$Markdown$RawBlock$OrderedListBlock,
										tight2,
										intended,
										marker,
										order,
										A2($elm$core$List$cons, value.b, closeListItems),
										openListItem),
									rest)
							});
					} else {
						var e = _v93.a;
						return $elm$parser$Parser$Advanced$problem(
							$elm$parser$Parser$Problem(
								$dillonkearns$elm_markdown$Markdown$Parser$deadEndsToString(e)));
					}
				case 10:
					if (_v84.b.b) {
						switch (_v84.b.a.$) {
							case 3:
								var _v88 = _v84.a;
								var _v89 = _v84.b;
								var _v90 = _v89.a;
								var tight = _v90.a;
								var intended = _v90.b;
								var closeListItems = _v90.c;
								var openListItem = _v90.d;
								var rest = _v89.b;
								var _v91 = A2(
									$elm$parser$Parser$Advanced$run,
									$dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser(),
									openListItem.hY);
								if (!_v91.$) {
									var value = _v91.a;
									var tight2 = A2($elm$core$List$member, $dillonkearns$elm_markdown$Markdown$RawBlock$BlankLine, value.b) ? false : tight;
									return $elm$parser$Parser$Advanced$succeed(
										{
											a: _Utils_ap(state.a, value.a),
											b: A2(
												$elm$core$List$cons,
												A4(
													$dillonkearns$elm_markdown$Markdown$RawBlock$UnorderedListBlock,
													tight2,
													intended,
													A2(
														$elm$core$List$cons,
														{hY: value.b, p: openListItem.p},
														closeListItems),
													openListItem),
												rest)
										});
								} else {
									var e = _v91.a;
									return $elm$parser$Parser$Advanced$problem(
										$elm$parser$Parser$Problem(
											$dillonkearns$elm_markdown$Markdown$Parser$deadEndsToString(e)));
								}
							case 4:
								var _v94 = _v84.a;
								var _v95 = _v84.b;
								var _v96 = _v95.a;
								var tight = _v96.a;
								var intended = _v96.b;
								var marker = _v96.c;
								var order = _v96.d;
								var closeListItems = _v96.e;
								var openListItem = _v96.f;
								var rest = _v95.b;
								var _v97 = A2(
									$elm$parser$Parser$Advanced$run,
									$dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser(),
									openListItem);
								if (!_v97.$) {
									var value = _v97.a;
									var tight2 = A2($elm$core$List$member, $dillonkearns$elm_markdown$Markdown$RawBlock$BlankLine, value.b) ? false : tight;
									return $elm$parser$Parser$Advanced$succeed(
										{
											a: _Utils_ap(state.a, value.a),
											b: A2(
												$elm$core$List$cons,
												A6(
													$dillonkearns$elm_markdown$Markdown$RawBlock$OrderedListBlock,
													tight2,
													intended,
													marker,
													order,
													A2($elm$core$List$cons, value.b, closeListItems),
													openListItem),
												rest)
										});
								} else {
									var e = _v97.a;
									return $elm$parser$Parser$Advanced$problem(
										$elm$parser$Parser$Problem(
											$dillonkearns$elm_markdown$Markdown$Parser$deadEndsToString(e)));
								}
							default:
								break _v84$5;
						}
					} else {
						break _v84$5;
					}
				default:
					break _v84$5;
			}
		} else {
			break _v84$5;
		}
	}
	return $elm$parser$Parser$Advanced$succeed(state);
};
var $dillonkearns$elm_markdown$Markdown$Parser$completeOrMergeBlocks = F2(
	function (state, newRawBlock) {
		var _v41 = _Utils_Tuple2(newRawBlock, state.b);
		_v41$13:
		while (true) {
			if (_v41.b.b) {
				switch (_v41.b.a.$) {
					case 5:
						if (_v41.a.$ === 5) {
							var block1 = _v41.a.a;
							var _v42 = _v41.b;
							var block2 = _v42.a.a;
							var rest = _v42.b;
							return $elm$parser$Parser$Advanced$succeed(
								{
									a: state.a,
									b: A2(
										$elm$core$List$cons,
										$dillonkearns$elm_markdown$Markdown$RawBlock$CodeBlock(
											{
												hY: A2($dillonkearns$elm_markdown$Markdown$Parser$joinStringsPreserveAll, block2.hY, block1.hY),
												d3: $elm$core$Maybe$Nothing
											}),
										rest)
								});
						} else {
							break _v41$13;
						}
					case 6:
						switch (_v41.a.$) {
							case 6:
								var block1 = _v41.a.a;
								var _v43 = _v41.b;
								var block2 = _v43.a.a;
								var rest = _v43.b;
								return $elm$parser$Parser$Advanced$succeed(
									{
										a: state.a,
										b: A2(
											$elm$core$List$cons,
											$dillonkearns$elm_markdown$Markdown$RawBlock$IndentedCodeBlock(
												A2($dillonkearns$elm_markdown$Markdown$Parser$joinStringsPreserveAll, block2, block1)),
											rest)
									});
							case 10:
								var _v44 = _v41.a;
								var _v45 = _v41.b;
								var block = _v45.a.a;
								var rest = _v45.b;
								return $elm$parser$Parser$Advanced$succeed(
									{
										a: state.a,
										b: A2(
											$elm$core$List$cons,
											$dillonkearns$elm_markdown$Markdown$RawBlock$IndentedCodeBlock(
												A2($dillonkearns$elm_markdown$Markdown$Parser$joinStringsPreserveAll, block, '\n')),
											rest)
									});
							default:
								break _v41$13;
						}
					case 11:
						var _v46 = _v41.b;
						var body2 = _v46.a.a;
						var rest = _v46.b;
						switch (newRawBlock.$) {
							case 11:
								var body1 = newRawBlock.a;
								return $elm$parser$Parser$Advanced$succeed(
									{
										a: state.a,
										b: A2(
											$elm$core$List$cons,
											$dillonkearns$elm_markdown$Markdown$RawBlock$BlockQuote(
												A2($dillonkearns$elm_markdown$Markdown$Parser$joinStringsPreserveAll, body2, body1)),
											rest)
									});
							case 1:
								var body1 = newRawBlock.a;
								return $elm$parser$Parser$Advanced$succeed(
									{
										a: state.a,
										b: A2(
											$elm$core$List$cons,
											$dillonkearns$elm_markdown$Markdown$RawBlock$BlockQuote(
												A3($dillonkearns$elm_markdown$Markdown$Parser$joinRawStringsWith, '\n', body2, body1)),
											rest)
									});
							default:
								var _v48 = A2(
									$elm$parser$Parser$Advanced$run,
									$dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser(),
									body2);
								if (!_v48.$) {
									var value = _v48.a;
									return $elm$parser$Parser$Advanced$succeed(
										{
											a: _Utils_ap(state.a, value.a),
											b: A2(
												$elm$core$List$cons,
												newRawBlock,
												A2(
													$elm$core$List$cons,
													$dillonkearns$elm_markdown$Markdown$RawBlock$ParsedBlockQuote(value.b),
													rest))
										});
								} else {
									var e = _v48.a;
									return $elm$parser$Parser$Advanced$problem(
										$elm$parser$Parser$Problem(
											$dillonkearns$elm_markdown$Markdown$Parser$deadEndsToString(e)));
								}
						}
					case 3:
						var _v49 = _v41.b;
						var _v50 = _v49.a;
						var tight = _v50.a;
						var intended1 = _v50.b;
						var closeListItems2 = _v50.c;
						var openListItem2 = _v50.d;
						var rest = _v49.b;
						switch (newRawBlock.$) {
							case 3:
								var intended2 = newRawBlock.b;
								var closeListItems1 = newRawBlock.c;
								var openListItem1 = newRawBlock.d;
								if (_Utils_eq(openListItem2.iZ, openListItem1.iZ)) {
									var _v52 = A2(
										$elm$parser$Parser$Advanced$run,
										$dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser(),
										openListItem2.hY);
									if (!_v52.$) {
										var value = _v52.a;
										return A2($elm$core$List$member, $dillonkearns$elm_markdown$Markdown$RawBlock$BlankLine, value.b) ? $elm$parser$Parser$Advanced$succeed(
											{
												a: _Utils_ap(state.a, value.a),
												b: A2(
													$elm$core$List$cons,
													A4(
														$dillonkearns$elm_markdown$Markdown$RawBlock$UnorderedListBlock,
														false,
														intended2,
														A2(
															$elm$core$List$cons,
															{hY: value.b, p: openListItem2.p},
															closeListItems2),
														openListItem1),
													rest)
											}) : $elm$parser$Parser$Advanced$succeed(
											{
												a: _Utils_ap(state.a, value.a),
												b: A2(
													$elm$core$List$cons,
													A4(
														$dillonkearns$elm_markdown$Markdown$RawBlock$UnorderedListBlock,
														tight,
														intended2,
														A2(
															$elm$core$List$cons,
															{hY: value.b, p: openListItem2.p},
															closeListItems2),
														openListItem1),
													rest)
											});
									} else {
										var e = _v52.a;
										return $elm$parser$Parser$Advanced$problem(
											$elm$parser$Parser$Problem(
												$dillonkearns$elm_markdown$Markdown$Parser$deadEndsToString(e)));
									}
								} else {
									var _v53 = A2(
										$elm$parser$Parser$Advanced$run,
										$dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser(),
										openListItem2.hY);
									if (!_v53.$) {
										var value = _v53.a;
										var tight2 = A2($elm$core$List$member, $dillonkearns$elm_markdown$Markdown$RawBlock$BlankLine, value.b) ? false : tight;
										return $elm$parser$Parser$Advanced$succeed(
											{
												a: _Utils_ap(state.a, value.a),
												b: A2(
													$elm$core$List$cons,
													newRawBlock,
													A2(
														$elm$core$List$cons,
														A4(
															$dillonkearns$elm_markdown$Markdown$RawBlock$UnorderedListBlock,
															tight2,
															intended1,
															A2(
																$elm$core$List$cons,
																{hY: value.b, p: openListItem2.p},
																closeListItems2),
															openListItem1),
														rest))
											});
									} else {
										var e = _v53.a;
										return $elm$parser$Parser$Advanced$problem(
											$elm$parser$Parser$Problem(
												$dillonkearns$elm_markdown$Markdown$Parser$deadEndsToString(e)));
									}
								}
							case 1:
								var body1 = newRawBlock.a;
								return $elm$parser$Parser$Advanced$succeed(
									{
										a: state.a,
										b: A2(
											$elm$core$List$cons,
											A4(
												$dillonkearns$elm_markdown$Markdown$RawBlock$UnorderedListBlock,
												tight,
												intended1,
												closeListItems2,
												_Utils_update(
													openListItem2,
													{
														hY: A3($dillonkearns$elm_markdown$Markdown$Parser$joinRawStringsWith, '\n', openListItem2.hY, body1)
													})),
											rest)
									});
							default:
								var _v54 = A2(
									$elm$parser$Parser$Advanced$run,
									$dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser(),
									openListItem2.hY);
								if (!_v54.$) {
									var value = _v54.a;
									var tight2 = A2($elm$core$List$member, $dillonkearns$elm_markdown$Markdown$RawBlock$BlankLine, value.b) ? false : tight;
									return $elm$parser$Parser$Advanced$succeed(
										{
											a: _Utils_ap(state.a, value.a),
											b: A2(
												$elm$core$List$cons,
												newRawBlock,
												A2(
													$elm$core$List$cons,
													A4(
														$dillonkearns$elm_markdown$Markdown$RawBlock$UnorderedListBlock,
														tight2,
														intended1,
														A2(
															$elm$core$List$cons,
															{hY: value.b, p: openListItem2.p},
															closeListItems2),
														openListItem2),
													rest))
										});
								} else {
									var e = _v54.a;
									return $elm$parser$Parser$Advanced$problem(
										$elm$parser$Parser$Problem(
											$dillonkearns$elm_markdown$Markdown$Parser$deadEndsToString(e)));
								}
						}
					case 4:
						var _v55 = _v41.b;
						var _v56 = _v55.a;
						var tight = _v56.a;
						var intended1 = _v56.b;
						var marker = _v56.c;
						var order = _v56.d;
						var closeListItems2 = _v56.e;
						var openListItem2 = _v56.f;
						var rest = _v55.b;
						switch (newRawBlock.$) {
							case 4:
								var intended2 = newRawBlock.b;
								var marker2 = newRawBlock.c;
								var closeListItems1 = newRawBlock.e;
								var openListItem1 = newRawBlock.f;
								if (_Utils_eq(marker, marker2)) {
									var _v58 = A2(
										$elm$parser$Parser$Advanced$run,
										$dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser(),
										openListItem2);
									if (!_v58.$) {
										var value = _v58.a;
										var tight2 = A2($elm$core$List$member, $dillonkearns$elm_markdown$Markdown$RawBlock$BlankLine, value.b) ? false : tight;
										return $elm$parser$Parser$Advanced$succeed(
											{
												a: _Utils_ap(state.a, value.a),
												b: A2(
													$elm$core$List$cons,
													A6(
														$dillonkearns$elm_markdown$Markdown$RawBlock$OrderedListBlock,
														tight2,
														intended2,
														marker,
														order,
														A2($elm$core$List$cons, value.b, closeListItems2),
														openListItem1),
													rest)
											});
									} else {
										var e = _v58.a;
										return $elm$parser$Parser$Advanced$problem(
											$elm$parser$Parser$Problem(
												$dillonkearns$elm_markdown$Markdown$Parser$deadEndsToString(e)));
									}
								} else {
									var _v59 = A2(
										$elm$parser$Parser$Advanced$run,
										$dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser(),
										openListItem2);
									if (!_v59.$) {
										var value = _v59.a;
										var tight2 = A2($elm$core$List$member, $dillonkearns$elm_markdown$Markdown$RawBlock$BlankLine, value.b) ? false : tight;
										return $elm$parser$Parser$Advanced$succeed(
											{
												a: _Utils_ap(state.a, value.a),
												b: A2(
													$elm$core$List$cons,
													newRawBlock,
													A2(
														$elm$core$List$cons,
														A6(
															$dillonkearns$elm_markdown$Markdown$RawBlock$OrderedListBlock,
															tight2,
															intended1,
															marker,
															order,
															A2($elm$core$List$cons, value.b, closeListItems2),
															openListItem2),
														rest))
											});
									} else {
										var e = _v59.a;
										return $elm$parser$Parser$Advanced$problem(
											$elm$parser$Parser$Problem(
												$dillonkearns$elm_markdown$Markdown$Parser$deadEndsToString(e)));
									}
								}
							case 1:
								var body1 = newRawBlock.a;
								return $elm$parser$Parser$Advanced$succeed(
									{
										a: state.a,
										b: A2(
											$elm$core$List$cons,
											A6($dillonkearns$elm_markdown$Markdown$RawBlock$OrderedListBlock, tight, intended1, marker, order, closeListItems2, openListItem2 + ('\n' + body1)),
											rest)
									});
							default:
								var _v60 = A2(
									$elm$parser$Parser$Advanced$run,
									$dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser(),
									openListItem2);
								if (!_v60.$) {
									var value = _v60.a;
									var tight2 = A2($elm$core$List$member, $dillonkearns$elm_markdown$Markdown$RawBlock$BlankLine, value.b) ? false : tight;
									return $elm$parser$Parser$Advanced$succeed(
										{
											a: _Utils_ap(state.a, value.a),
											b: A2(
												$elm$core$List$cons,
												newRawBlock,
												A2(
													$elm$core$List$cons,
													A6(
														$dillonkearns$elm_markdown$Markdown$RawBlock$OrderedListBlock,
														tight2,
														intended1,
														marker,
														order,
														A2($elm$core$List$cons, value.b, closeListItems2),
														openListItem2),
													rest))
										});
								} else {
									var e = _v60.a;
									return $elm$parser$Parser$Advanced$problem(
										$elm$parser$Parser$Problem(
											$dillonkearns$elm_markdown$Markdown$Parser$deadEndsToString(e)));
								}
						}
					case 1:
						switch (_v41.a.$) {
							case 1:
								var body1 = _v41.a.a;
								var _v61 = _v41.b;
								var body2 = _v61.a.a;
								var rest = _v61.b;
								return $elm$parser$Parser$Advanced$succeed(
									{
										a: state.a,
										b: A2(
											$elm$core$List$cons,
											$dillonkearns$elm_markdown$Markdown$RawBlock$OpenBlockOrParagraph(
												A3($dillonkearns$elm_markdown$Markdown$Parser$joinRawStringsWith, '\n', body2, body1)),
											rest)
									});
							case 13:
								if (!_v41.a.a) {
									var _v62 = _v41.a;
									var _v63 = _v62.a;
									var _v64 = _v41.b;
									var unparsedInlines = _v64.a.a;
									var rest = _v64.b;
									return $elm$parser$Parser$Advanced$succeed(
										{
											a: state.a,
											b: A2(
												$elm$core$List$cons,
												A2($dillonkearns$elm_markdown$Markdown$RawBlock$Heading, 1, unparsedInlines),
												rest)
										});
								} else {
									var _v65 = _v41.a;
									var _v66 = _v65.a;
									var _v67 = _v41.b;
									var unparsedInlines = _v67.a.a;
									var rest = _v67.b;
									return $elm$parser$Parser$Advanced$succeed(
										{
											a: state.a,
											b: A2(
												$elm$core$List$cons,
												A2($dillonkearns$elm_markdown$Markdown$RawBlock$Heading, 2, unparsedInlines),
												rest)
										});
								}
							case 9:
								var _v68 = _v41.a.a;
								var text = _v68.a;
								var alignments = _v68.b;
								var _v69 = _v41.b;
								var rawHeaders = _v69.a.a;
								var rest = _v69.b;
								var _v70 = A2(
									$dillonkearns$elm_markdown$Markdown$TableParser$parseHeader,
									A2($dillonkearns$elm_markdown$Markdown$Table$TableDelimiterRow, text, alignments),
									rawHeaders);
								if (!_v70.$) {
									var headers = _v70.a;
									return $elm$parser$Parser$Advanced$succeed(
										{
											a: state.a,
											b: A2(
												$elm$core$List$cons,
												$dillonkearns$elm_markdown$Markdown$RawBlock$Table(
													A2($dillonkearns$elm_markdown$Markdown$Table$Table, headers, _List_Nil)),
												rest)
										});
								} else {
									return $elm$parser$Parser$Advanced$succeed(
										{
											a: state.a,
											b: A2(
												$elm$core$List$cons,
												$dillonkearns$elm_markdown$Markdown$RawBlock$OpenBlockOrParagraph(
													A3($dillonkearns$elm_markdown$Markdown$Parser$joinRawStringsWith, '\n', rawHeaders, text.g0)),
												rest)
										});
								}
							default:
								break _v41$13;
						}
					case 8:
						if (_v41.a.$ === 8) {
							var updatedTable = _v41.a.a;
							var _v71 = _v41.b;
							var rest = _v71.b;
							return $elm$parser$Parser$Advanced$succeed(
								{
									a: state.a,
									b: A2(
										$elm$core$List$cons,
										$dillonkearns$elm_markdown$Markdown$RawBlock$Table(updatedTable),
										rest)
								});
						} else {
							break _v41$13;
						}
					case 10:
						if (_v41.b.b.b) {
							switch (_v41.b.b.a.$) {
								case 4:
									var _v72 = _v41.b;
									var _v73 = _v72.a;
									var _v74 = _v72.b;
									var _v75 = _v74.a;
									var tight = _v75.a;
									var intended1 = _v75.b;
									var marker = _v75.c;
									var order = _v75.d;
									var closeListItems2 = _v75.e;
									var openListItem2 = _v75.f;
									var rest = _v74.b;
									var _v76 = A2(
										$elm$parser$Parser$Advanced$run,
										$dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser(),
										openListItem2);
									if (!_v76.$) {
										var value = _v76.a;
										if (newRawBlock.$ === 4) {
											var intended2 = newRawBlock.b;
											var openListItem = newRawBlock.f;
											return $elm$parser$Parser$Advanced$succeed(
												{
													a: _Utils_ap(state.a, value.a),
													b: A2(
														$elm$core$List$cons,
														A6(
															$dillonkearns$elm_markdown$Markdown$RawBlock$OrderedListBlock,
															false,
															intended2,
															marker,
															order,
															A2($elm$core$List$cons, value.b, closeListItems2),
															openListItem),
														rest)
												});
										} else {
											return $elm$parser$Parser$Advanced$succeed(
												{
													a: _Utils_ap(state.a, value.a),
													b: A2(
														$elm$core$List$cons,
														newRawBlock,
														A2(
															$elm$core$List$cons,
															$dillonkearns$elm_markdown$Markdown$RawBlock$BlankLine,
															A2(
																$elm$core$List$cons,
																A6(
																	$dillonkearns$elm_markdown$Markdown$RawBlock$OrderedListBlock,
																	tight,
																	intended1,
																	marker,
																	order,
																	A2($elm$core$List$cons, value.b, closeListItems2),
																	openListItem2),
																rest)))
												});
										}
									} else {
										var e = _v76.a;
										return $elm$parser$Parser$Advanced$problem(
											$elm$parser$Parser$Problem(
												$dillonkearns$elm_markdown$Markdown$Parser$deadEndsToString(e)));
									}
								case 3:
									var _v78 = _v41.b;
									var _v79 = _v78.a;
									var _v80 = _v78.b;
									var _v81 = _v80.a;
									var tight = _v81.a;
									var intended1 = _v81.b;
									var closeListItems2 = _v81.c;
									var openListItem2 = _v81.d;
									var rest = _v80.b;
									var _v82 = A2(
										$elm$parser$Parser$Advanced$run,
										$dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser(),
										openListItem2.hY);
									if (!_v82.$) {
										var value = _v82.a;
										if (newRawBlock.$ === 3) {
											var openListItem = newRawBlock.d;
											return $elm$parser$Parser$Advanced$succeed(
												{
													a: _Utils_ap(state.a, value.a),
													b: A2(
														$elm$core$List$cons,
														A4(
															$dillonkearns$elm_markdown$Markdown$RawBlock$UnorderedListBlock,
															false,
															intended1,
															A2(
																$elm$core$List$cons,
																{hY: value.b, p: openListItem2.p},
																closeListItems2),
															openListItem),
														rest)
												});
										} else {
											return $elm$parser$Parser$Advanced$succeed(
												{
													a: _Utils_ap(state.a, value.a),
													b: A2(
														$elm$core$List$cons,
														newRawBlock,
														A2(
															$elm$core$List$cons,
															$dillonkearns$elm_markdown$Markdown$RawBlock$BlankLine,
															A2(
																$elm$core$List$cons,
																A4(
																	$dillonkearns$elm_markdown$Markdown$RawBlock$UnorderedListBlock,
																	tight,
																	intended1,
																	A2(
																		$elm$core$List$cons,
																		{hY: value.b, p: openListItem2.p},
																		closeListItems2),
																	openListItem2),
																rest)))
												});
										}
									} else {
										var e = _v82.a;
										return $elm$parser$Parser$Advanced$problem(
											$elm$parser$Parser$Problem(
												$dillonkearns$elm_markdown$Markdown$Parser$deadEndsToString(e)));
									}
								default:
									break _v41$13;
							}
						} else {
							break _v41$13;
						}
					default:
						break _v41$13;
				}
			} else {
				break _v41$13;
			}
		}
		return $elm$parser$Parser$Advanced$succeed(
			{
				a: state.a,
				b: A2($elm$core$List$cons, newRawBlock, state.b)
			});
	});
var $dillonkearns$elm_markdown$Markdown$Parser$inlineParseHelper = F2(
	function (referencesDict, _v36) {
		var unparsedInlines = _v36;
		var mappedReferencesDict = $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$mapSecond(
					function (_v37) {
						var destination = _v37.fN;
						var title = _v37.j5;
						return _Utils_Tuple2(destination, title);
					}),
				referencesDict));
		return A2(
			$elm$core$List$map,
			$dillonkearns$elm_markdown$Markdown$Parser$mapInline,
			A2($dillonkearns$elm_markdown$Markdown$InlineParser$parse, mappedReferencesDict, unparsedInlines));
	});
var $dillonkearns$elm_markdown$Markdown$Parser$mapInline = function (inline) {
	switch (inline.$) {
		case 0:
			var string = inline.a;
			return $dillonkearns$elm_markdown$Markdown$Block$Text(string);
		case 1:
			return $dillonkearns$elm_markdown$Markdown$Block$HardLineBreak;
		case 2:
			var string = inline.a;
			return $dillonkearns$elm_markdown$Markdown$Block$CodeSpan(string);
		case 3:
			var string = inline.a;
			var maybeString = inline.b;
			var inlines = inline.c;
			return A3(
				$dillonkearns$elm_markdown$Markdown$Block$Link,
				string,
				maybeString,
				A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$mapInline, inlines));
		case 4:
			var string = inline.a;
			var maybeString = inline.b;
			var inlines = inline.c;
			return A3(
				$dillonkearns$elm_markdown$Markdown$Block$Image,
				string,
				maybeString,
				A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$mapInline, inlines));
		case 5:
			var node = inline.a;
			return $dillonkearns$elm_markdown$Markdown$Block$HtmlInline(
				$dillonkearns$elm_markdown$Markdown$Parser$nodeToRawBlock(node));
		case 6:
			var level = inline.a;
			var inlines = inline.b;
			switch (level) {
				case 1:
					return $dillonkearns$elm_markdown$Markdown$Block$Emphasis(
						A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$mapInline, inlines));
				case 2:
					return $dillonkearns$elm_markdown$Markdown$Block$Strong(
						A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$mapInline, inlines));
				default:
					return $dillonkearns$elm_markdown$Markdown$Helpers$isEven(level) ? $dillonkearns$elm_markdown$Markdown$Block$Strong(
						_List_fromArray(
							[
								$dillonkearns$elm_markdown$Markdown$Parser$mapInline(
								A2($dillonkearns$elm_markdown$Markdown$Inline$Emphasis, level - 2, inlines))
							])) : $dillonkearns$elm_markdown$Markdown$Block$Emphasis(
						_List_fromArray(
							[
								$dillonkearns$elm_markdown$Markdown$Parser$mapInline(
								A2($dillonkearns$elm_markdown$Markdown$Inline$Emphasis, level - 1, inlines))
							]));
			}
		default:
			var inlines = inline.a;
			return $dillonkearns$elm_markdown$Markdown$Block$Strikethrough(
				A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$mapInline, inlines));
	}
};
var $dillonkearns$elm_markdown$Markdown$Parser$nodeToRawBlock = function (node) {
	switch (node.$) {
		case 1:
			var innerText = node.a;
			return $dillonkearns$elm_markdown$Markdown$Block$HtmlComment('TODO this never happens, but use types to drop this case.');
		case 0:
			var tag = node.a;
			var attributes = node.b;
			var children = node.c;
			var parseChild = function (child) {
				if (child.$ === 1) {
					var text = child.a;
					return $dillonkearns$elm_markdown$Markdown$Parser$textNodeToBlocks(text);
				} else {
					return _List_fromArray(
						[
							$dillonkearns$elm_markdown$Markdown$Block$HtmlBlock(
							$dillonkearns$elm_markdown$Markdown$Parser$nodeToRawBlock(child))
						]);
				}
			};
			return A3(
				$dillonkearns$elm_markdown$Markdown$Block$HtmlElement,
				tag,
				attributes,
				A2($elm$core$List$concatMap, parseChild, children));
		case 2:
			var string = node.a;
			return $dillonkearns$elm_markdown$Markdown$Block$HtmlComment(string);
		case 3:
			var string = node.a;
			return $dillonkearns$elm_markdown$Markdown$Block$Cdata(string);
		case 4:
			var string = node.a;
			return $dillonkearns$elm_markdown$Markdown$Block$ProcessingInstruction(string);
		default:
			var declarationType = node.a;
			var content = node.b;
			return A2($dillonkearns$elm_markdown$Markdown$Block$HtmlDeclaration, declarationType, content);
	}
};
var $dillonkearns$elm_markdown$Markdown$Parser$nodesToBlocks = function (children) {
	return A2($dillonkearns$elm_markdown$Markdown$Parser$nodesToBlocksHelp, children, _List_Nil);
};
var $dillonkearns$elm_markdown$Markdown$Parser$nodesToBlocksHelp = F2(
	function (remaining, soFar) {
		nodesToBlocksHelp:
		while (true) {
			if (remaining.b) {
				var node = remaining.a;
				var rest = remaining.b;
				var _v31 = A2($dillonkearns$elm_markdown$Markdown$Parser$childToBlocks, node, soFar);
				if (!_v31.$) {
					var newSoFar = _v31.a;
					var $temp$remaining = rest,
						$temp$soFar = newSoFar;
					remaining = $temp$remaining;
					soFar = $temp$soFar;
					continue nodesToBlocksHelp;
				} else {
					var e = _v31.a;
					return $elm$core$Result$Err(e);
				}
			} else {
				return $elm$core$Result$Ok(
					$elm$core$List$reverse(soFar));
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$Parser$parse = function (input) {
	var _v27 = A2(
		$elm$parser$Parser$Advanced$run,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser(),
			$dillonkearns$elm_markdown$Helpers$endOfFile),
		input);
	if (_v27.$ === 1) {
		var e = _v27.a;
		return $elm$core$Result$Err(e);
	} else {
		var v = _v27.a;
		var _v28 = $dillonkearns$elm_markdown$Markdown$Parser$parseAllInlines(v);
		if (_v28.$ === 1) {
			var e = _v28.a;
			return A2(
				$elm$parser$Parser$Advanced$run,
				$elm$parser$Parser$Advanced$problem(e),
				'');
		} else {
			var blocks = _v28.a;
			var isNotEmptyParagraph = function (block) {
				if ((block.$ === 5) && (!block.a.b)) {
					return false;
				} else {
					return true;
				}
			};
			return $elm$core$Result$Ok(
				A2($elm$core$List$filter, isNotEmptyParagraph, blocks));
		}
	}
};
var $dillonkearns$elm_markdown$Markdown$Parser$parseAllInlines = function (state) {
	return A3($dillonkearns$elm_markdown$Markdown$Parser$parseAllInlinesHelp, state, state.b, _List_Nil);
};
var $dillonkearns$elm_markdown$Markdown$Parser$parseAllInlinesHelp = F3(
	function (state, rawBlocks, parsedBlocks) {
		parseAllInlinesHelp:
		while (true) {
			if (rawBlocks.b) {
				var rawBlock = rawBlocks.a;
				var rest = rawBlocks.b;
				var _v26 = A2($dillonkearns$elm_markdown$Markdown$Parser$parseInlines, state.a, rawBlock);
				switch (_v26.$) {
					case 1:
						var newParsedBlock = _v26.a;
						var $temp$state = state,
							$temp$rawBlocks = rest,
							$temp$parsedBlocks = A2($elm$core$List$cons, newParsedBlock, parsedBlocks);
						state = $temp$state;
						rawBlocks = $temp$rawBlocks;
						parsedBlocks = $temp$parsedBlocks;
						continue parseAllInlinesHelp;
					case 0:
						var $temp$state = state,
							$temp$rawBlocks = rest,
							$temp$parsedBlocks = parsedBlocks;
						state = $temp$state;
						rawBlocks = $temp$rawBlocks;
						parsedBlocks = $temp$parsedBlocks;
						continue parseAllInlinesHelp;
					default:
						var e = _v26.a;
						return $elm$core$Result$Err(e);
				}
			} else {
				return $elm$core$Result$Ok(parsedBlocks);
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$Parser$parseHeaderInlines = F2(
	function (linkReferences, header) {
		return A2(
			$elm$core$List$map,
			function (_v24) {
				var label = _v24.bp;
				var alignment = _v24.cw;
				return A3(
					$dillonkearns$elm_markdown$Markdown$Parser$parseRawInline,
					linkReferences,
					function (parsedHeaderLabel) {
						return {cw: alignment, bp: parsedHeaderLabel};
					},
					label);
			},
			header);
	});
var $dillonkearns$elm_markdown$Markdown$Parser$parseInlines = F2(
	function (linkReferences, rawBlock) {
		switch (rawBlock.$) {
			case 0:
				var level = rawBlock.a;
				var unparsedInlines = rawBlock.b;
				var _v17 = $dillonkearns$elm_markdown$Markdown$Parser$toHeading(level);
				if (!_v17.$) {
					var parsedLevel = _v17.a;
					return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock(
						A2(
							$dillonkearns$elm_markdown$Markdown$Block$Heading,
							parsedLevel,
							A2($dillonkearns$elm_markdown$Markdown$Parser$inlineParseHelper, linkReferences, unparsedInlines)));
				} else {
					var e = _v17.a;
					return $dillonkearns$elm_markdown$Markdown$Parser$InlineProblem(e);
				}
			case 1:
				var unparsedInlines = rawBlock.a;
				return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock(
					$dillonkearns$elm_markdown$Markdown$Block$Paragraph(
						A2($dillonkearns$elm_markdown$Markdown$Parser$inlineParseHelper, linkReferences, unparsedInlines)));
			case 2:
				var html = rawBlock.a;
				return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock(
					$dillonkearns$elm_markdown$Markdown$Block$HtmlBlock(html));
			case 3:
				var tight = rawBlock.a;
				var intended = rawBlock.b;
				var unparsedItems = rawBlock.c;
				var parseItem = F2(
					function (rawBlockTask, rawBlocks) {
						var blocksTask = function () {
							if (!rawBlockTask.$) {
								if (!rawBlockTask.a) {
									return 1;
								} else {
									return 2;
								}
							} else {
								return 0;
							}
						}();
						var blocks = function () {
							var _v18 = $dillonkearns$elm_markdown$Markdown$Parser$parseAllInlines(
								{a: linkReferences, b: rawBlocks});
							if (!_v18.$) {
								var parsedBlocks = _v18.a;
								return parsedBlocks;
							} else {
								var e = _v18.a;
								return _List_Nil;
							}
						}();
						return A2($dillonkearns$elm_markdown$Markdown$Block$ListItem, blocksTask, blocks);
					});
				return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock(
					A2(
						$dillonkearns$elm_markdown$Markdown$Block$UnorderedList,
						$dillonkearns$elm_markdown$Markdown$Parser$isTightBoolToListDisplay(tight),
						$elm$core$List$reverse(
							A2(
								$elm$core$List$map,
								function (item) {
									return A2(parseItem, item.p, item.hY);
								},
								unparsedItems))));
			case 4:
				var tight = rawBlock.a;
				var startingIndex = rawBlock.d;
				var unparsedItems = rawBlock.e;
				var parseItem = function (rawBlocks) {
					var _v20 = $dillonkearns$elm_markdown$Markdown$Parser$parseAllInlines(
						{a: linkReferences, b: rawBlocks});
					if (!_v20.$) {
						var parsedBlocks = _v20.a;
						return parsedBlocks;
					} else {
						var e = _v20.a;
						return _List_Nil;
					}
				};
				return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock(
					A3(
						$dillonkearns$elm_markdown$Markdown$Block$OrderedList,
						$dillonkearns$elm_markdown$Markdown$Parser$isTightBoolToListDisplay(tight),
						startingIndex,
						$elm$core$List$reverse(
							A2($elm$core$List$map, parseItem, unparsedItems))));
			case 5:
				var codeBlock = rawBlock.a;
				return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock(
					$dillonkearns$elm_markdown$Markdown$Block$CodeBlock(codeBlock));
			case 7:
				return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock($dillonkearns$elm_markdown$Markdown$Block$ThematicBreak);
			case 10:
				return $dillonkearns$elm_markdown$Markdown$Parser$EmptyBlock;
			case 11:
				var rawBlocks = rawBlock.a;
				return $dillonkearns$elm_markdown$Markdown$Parser$EmptyBlock;
			case 12:
				var rawBlocks = rawBlock.a;
				var _v21 = $dillonkearns$elm_markdown$Markdown$Parser$parseAllInlines(
					{a: linkReferences, b: rawBlocks});
				if (!_v21.$) {
					var parsedBlocks = _v21.a;
					return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock(
						$dillonkearns$elm_markdown$Markdown$Block$BlockQuote(parsedBlocks));
				} else {
					var e = _v21.a;
					return $dillonkearns$elm_markdown$Markdown$Parser$InlineProblem(e);
				}
			case 6:
				var codeBlockBody = rawBlock.a;
				return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock(
					$dillonkearns$elm_markdown$Markdown$Block$CodeBlock(
						{hY: codeBlockBody, d3: $elm$core$Maybe$Nothing}));
			case 8:
				var _v22 = rawBlock.a;
				var header = _v22.a;
				var rows = _v22.b;
				return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock(
					A2(
						$dillonkearns$elm_markdown$Markdown$Block$Table,
						A2($dillonkearns$elm_markdown$Markdown$Parser$parseHeaderInlines, linkReferences, header),
						A2($dillonkearns$elm_markdown$Markdown$Parser$parseRowInlines, linkReferences, rows)));
			case 9:
				var _v23 = rawBlock.a;
				var text = _v23.a;
				return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock(
					$dillonkearns$elm_markdown$Markdown$Block$Paragraph(
						A2($dillonkearns$elm_markdown$Markdown$Parser$inlineParseHelper, linkReferences, text.g0)));
			default:
				var raw = rawBlock.b;
				return $dillonkearns$elm_markdown$Markdown$Parser$ParsedBlock(
					$dillonkearns$elm_markdown$Markdown$Block$Paragraph(
						A2($dillonkearns$elm_markdown$Markdown$Parser$inlineParseHelper, linkReferences, raw)));
		}
	});
var $dillonkearns$elm_markdown$Markdown$Parser$parseRawInline = F3(
	function (linkReferences, wrap, unparsedInlines) {
		return wrap(
			A2($dillonkearns$elm_markdown$Markdown$Parser$inlineParseHelper, linkReferences, unparsedInlines));
	});
var $dillonkearns$elm_markdown$Markdown$Parser$parseRowInlines = F2(
	function (linkReferences, rows) {
		return A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$elm$core$List$map,
					function (column) {
						return A3($dillonkearns$elm_markdown$Markdown$Parser$parseRawInline, linkReferences, $elm$core$Basics$identity, column);
					},
					row);
			},
			rows);
	});
var $dillonkearns$elm_markdown$Markdown$Parser$stepRawBlock = function (revStmts) {
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$Advanced$map,
				function (_v2) {
					return $elm$parser$Parser$Advanced$Done(revStmts);
				},
				$dillonkearns$elm_markdown$Helpers$endOfFile),
				A2(
				$elm$parser$Parser$Advanced$map,
				function (reference) {
					return $elm$parser$Parser$Advanced$Loop(
						A2($dillonkearns$elm_markdown$Markdown$Parser$addReference, revStmts, reference));
				},
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$LinkReferenceDefinition$parser)),
				function () {
				var _v3 = revStmts.b;
				_v3$6:
				while (true) {
					if (_v3.b) {
						switch (_v3.a.$) {
							case 1:
								return A2(
									$elm$parser$Parser$Advanced$map,
									function (block) {
										return $elm$parser$Parser$Advanced$Loop(block);
									},
									A2(
										$elm$parser$Parser$Advanced$andThen,
										$dillonkearns$elm_markdown$Markdown$Parser$completeOrMergeBlocks(revStmts),
										$dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockAfterOpenBlockOrParagraphParser()));
							case 8:
								var table = _v3.a.a;
								return A2(
									$elm$parser$Parser$Advanced$map,
									function (block) {
										return $elm$parser$Parser$Advanced$Loop(block);
									},
									A2(
										$elm$parser$Parser$Advanced$andThen,
										$dillonkearns$elm_markdown$Markdown$Parser$completeOrMergeBlocks(revStmts),
										$elm$parser$Parser$Advanced$oneOf(
											_List_fromArray(
												[
													$dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockNotAfterOpenBlockOrParagraphParser(),
													$dillonkearns$elm_markdown$Markdown$Parser$tableRowIfTableStarted(table)
												]))));
							case 3:
								var _v4 = _v3.a;
								var tight = _v4.a;
								var intended = _v4.b;
								var closeListItems = _v4.c;
								var openListItem = _v4.d;
								var rest = _v3.b;
								var completeOrMergeUnorderedListBlockBlankLine = F2(
									function (state, newString) {
										return _Utils_update(
											state,
											{
												b: A2(
													$elm$core$List$cons,
													$dillonkearns$elm_markdown$Markdown$RawBlock$BlankLine,
													A2(
														$elm$core$List$cons,
														A4(
															$dillonkearns$elm_markdown$Markdown$RawBlock$UnorderedListBlock,
															tight,
															intended,
															closeListItems,
															_Utils_update(
																openListItem,
																{
																	hY: A3($dillonkearns$elm_markdown$Markdown$Parser$joinRawStringsWith, '', openListItem.hY, newString)
																})),
														rest))
											});
									});
								var completeOrMergeUnorderedListBlock = F2(
									function (state, newString) {
										return _Utils_update(
											state,
											{
												b: A2(
													$elm$core$List$cons,
													A4(
														$dillonkearns$elm_markdown$Markdown$RawBlock$UnorderedListBlock,
														tight,
														intended,
														closeListItems,
														_Utils_update(
															openListItem,
															{
																hY: A3($dillonkearns$elm_markdown$Markdown$Parser$joinRawStringsWith, '\n', openListItem.hY, newString)
															})),
													rest)
											});
									});
								return $elm$parser$Parser$Advanced$oneOf(
									_List_fromArray(
										[
											A2(
											$elm$parser$Parser$Advanced$map,
											function (block) {
												return $elm$parser$Parser$Advanced$Loop(block);
											},
											A2(
												$elm$parser$Parser$Advanced$map,
												function (_v5) {
													return A2(completeOrMergeUnorderedListBlockBlankLine, revStmts, '\n');
												},
												$dillonkearns$elm_markdown$Markdown$Parser$blankLine)),
											A2(
											$elm$parser$Parser$Advanced$map,
											function (block) {
												return $elm$parser$Parser$Advanced$Loop(block);
											},
											A2(
												$elm$parser$Parser$Advanced$map,
												completeOrMergeUnorderedListBlock(revStmts),
												A2(
													$elm$parser$Parser$Advanced$keeper,
													A2(
														$elm$parser$Parser$Advanced$ignorer,
														$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
														$elm$parser$Parser$Advanced$symbol(
															A2(
																$elm$parser$Parser$Advanced$Token,
																A2($elm$core$String$repeat, intended, ' '),
																$elm$parser$Parser$ExpectingSymbol('Indentation')))),
													A2(
														$elm$parser$Parser$Advanced$ignorer,
														$elm$parser$Parser$Advanced$getChompedString($dillonkearns$elm_markdown$Helpers$chompUntilLineEndOrEnd),
														$dillonkearns$elm_markdown$Helpers$lineEndOrEnd)))),
											A2(
											$elm$parser$Parser$Advanced$map,
											function (block) {
												return $elm$parser$Parser$Advanced$Loop(block);
											},
											A2(
												$elm$parser$Parser$Advanced$andThen,
												$dillonkearns$elm_markdown$Markdown$Parser$completeOrMergeBlocks(revStmts),
												$dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockAfterList()))
										]));
							case 4:
								var _v10 = _v3.a;
								var tight = _v10.a;
								var intended = _v10.b;
								var marker = _v10.c;
								var order = _v10.d;
								var closeListItems = _v10.e;
								var openListItem = _v10.f;
								var rest = _v3.b;
								var completeOrMergeUnorderedListBlockBlankLine = F2(
									function (state, newString) {
										return _Utils_update(
											state,
											{
												b: A2(
													$elm$core$List$cons,
													$dillonkearns$elm_markdown$Markdown$RawBlock$BlankLine,
													A2(
														$elm$core$List$cons,
														A6($dillonkearns$elm_markdown$Markdown$RawBlock$OrderedListBlock, tight, intended, marker, order, closeListItems, openListItem + ('\n' + newString)),
														rest))
											});
									});
								var completeOrMergeUnorderedListBlock = F2(
									function (state, newString) {
										return _Utils_update(
											state,
											{
												b: A2(
													$elm$core$List$cons,
													A6($dillonkearns$elm_markdown$Markdown$RawBlock$OrderedListBlock, tight, intended, marker, order, closeListItems, openListItem + ('\n' + newString)),
													rest)
											});
									});
								return $elm$parser$Parser$Advanced$oneOf(
									_List_fromArray(
										[
											A2(
											$elm$parser$Parser$Advanced$map,
											function (block) {
												return $elm$parser$Parser$Advanced$Loop(block);
											},
											A2(
												$elm$parser$Parser$Advanced$map,
												function (_v11) {
													return A2(completeOrMergeUnorderedListBlockBlankLine, revStmts, '\n');
												},
												$dillonkearns$elm_markdown$Markdown$Parser$blankLine)),
											A2(
											$elm$parser$Parser$Advanced$map,
											function (block) {
												return $elm$parser$Parser$Advanced$Loop(block);
											},
											A2(
												$elm$parser$Parser$Advanced$map,
												completeOrMergeUnorderedListBlock(revStmts),
												A2(
													$elm$parser$Parser$Advanced$keeper,
													A2(
														$elm$parser$Parser$Advanced$ignorer,
														$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
														$elm$parser$Parser$Advanced$symbol(
															A2(
																$elm$parser$Parser$Advanced$Token,
																A2($elm$core$String$repeat, intended, ' '),
																$elm$parser$Parser$ExpectingSymbol('Indentation')))),
													A2(
														$elm$parser$Parser$Advanced$ignorer,
														$elm$parser$Parser$Advanced$getChompedString($dillonkearns$elm_markdown$Helpers$chompUntilLineEndOrEnd),
														$dillonkearns$elm_markdown$Helpers$lineEndOrEnd)))),
											A2(
											$elm$parser$Parser$Advanced$map,
											function (block) {
												return $elm$parser$Parser$Advanced$Loop(block);
											},
											A2(
												$elm$parser$Parser$Advanced$andThen,
												$dillonkearns$elm_markdown$Markdown$Parser$completeOrMergeBlocks(revStmts),
												$dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockAfterList()))
										]));
							case 10:
								if (_v3.b.b) {
									switch (_v3.b.a.$) {
										case 3:
											var _v6 = _v3.a;
											var _v7 = _v3.b;
											var _v8 = _v7.a;
											var tight = _v8.a;
											var intended = _v8.b;
											var closeListItems = _v8.c;
											var openListItem = _v8.d;
											var rest = _v7.b;
											var completeOrMergeUnorderedListBlockBlankLine = F2(
												function (state, newString) {
													return _Utils_update(
														state,
														{
															b: A2(
																$elm$core$List$cons,
																$dillonkearns$elm_markdown$Markdown$RawBlock$BlankLine,
																A2(
																	$elm$core$List$cons,
																	A4(
																		$dillonkearns$elm_markdown$Markdown$RawBlock$UnorderedListBlock,
																		tight,
																		intended,
																		closeListItems,
																		_Utils_update(
																			openListItem,
																			{
																				hY: A3($dillonkearns$elm_markdown$Markdown$Parser$joinRawStringsWith, '', openListItem.hY, newString)
																			})),
																	rest))
														});
												});
											var completeOrMergeUnorderedListBlock = F2(
												function (state, newString) {
													return _Utils_update(
														state,
														{
															b: A2(
																$elm$core$List$cons,
																A4(
																	$dillonkearns$elm_markdown$Markdown$RawBlock$UnorderedListBlock,
																	tight,
																	intended,
																	closeListItems,
																	_Utils_update(
																		openListItem,
																		{
																			hY: A3($dillonkearns$elm_markdown$Markdown$Parser$joinRawStringsWith, '\n', openListItem.hY, newString)
																		})),
																rest)
														});
												});
											return ($elm$core$String$trim(openListItem.hY) === '') ? A2(
												$elm$parser$Parser$Advanced$map,
												function (block) {
													return $elm$parser$Parser$Advanced$Loop(block);
												},
												A2(
													$elm$parser$Parser$Advanced$andThen,
													$dillonkearns$elm_markdown$Markdown$Parser$completeOrMergeBlocks(revStmts),
													$dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockNotAfterOpenBlockOrParagraphParser())) : $elm$parser$Parser$Advanced$oneOf(
												_List_fromArray(
													[
														A2(
														$elm$parser$Parser$Advanced$map,
														function (block) {
															return $elm$parser$Parser$Advanced$Loop(block);
														},
														A2(
															$elm$parser$Parser$Advanced$map,
															function (_v9) {
																return A2(completeOrMergeUnorderedListBlockBlankLine, revStmts, '\n');
															},
															$dillonkearns$elm_markdown$Markdown$Parser$blankLine)),
														A2(
														$elm$parser$Parser$Advanced$map,
														function (block) {
															return $elm$parser$Parser$Advanced$Loop(block);
														},
														A2(
															$elm$parser$Parser$Advanced$map,
															completeOrMergeUnorderedListBlock(revStmts),
															A2(
																$elm$parser$Parser$Advanced$keeper,
																A2(
																	$elm$parser$Parser$Advanced$ignorer,
																	$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
																	$elm$parser$Parser$Advanced$symbol(
																		A2(
																			$elm$parser$Parser$Advanced$Token,
																			A2($elm$core$String$repeat, intended, ' '),
																			$elm$parser$Parser$ExpectingSymbol('Indentation')))),
																A2(
																	$elm$parser$Parser$Advanced$ignorer,
																	$elm$parser$Parser$Advanced$getChompedString($dillonkearns$elm_markdown$Helpers$chompUntilLineEndOrEnd),
																	$dillonkearns$elm_markdown$Helpers$lineEndOrEnd)))),
														A2(
														$elm$parser$Parser$Advanced$map,
														function (block) {
															return $elm$parser$Parser$Advanced$Loop(block);
														},
														A2(
															$elm$parser$Parser$Advanced$andThen,
															$dillonkearns$elm_markdown$Markdown$Parser$completeOrMergeBlocks(revStmts),
															$dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockNotAfterOpenBlockOrParagraphParser()))
													]));
										case 4:
											var _v12 = _v3.a;
											var _v13 = _v3.b;
											var _v14 = _v13.a;
											var tight = _v14.a;
											var intended = _v14.b;
											var marker = _v14.c;
											var order = _v14.d;
											var closeListItems = _v14.e;
											var openListItem = _v14.f;
											var rest = _v13.b;
											var completeOrMergeUnorderedListBlockBlankLine = F2(
												function (state, newString) {
													return _Utils_update(
														state,
														{
															b: A2(
																$elm$core$List$cons,
																$dillonkearns$elm_markdown$Markdown$RawBlock$BlankLine,
																A2(
																	$elm$core$List$cons,
																	A6($dillonkearns$elm_markdown$Markdown$RawBlock$OrderedListBlock, tight, intended, marker, order, closeListItems, openListItem + ('\n' + newString)),
																	rest))
														});
												});
											var completeOrMergeUnorderedListBlock = F2(
												function (state, newString) {
													return _Utils_update(
														state,
														{
															b: A2(
																$elm$core$List$cons,
																A6($dillonkearns$elm_markdown$Markdown$RawBlock$OrderedListBlock, tight, intended, marker, order, closeListItems, openListItem + ('\n' + newString)),
																rest)
														});
												});
											return ($elm$core$String$trim(openListItem) === '') ? A2(
												$elm$parser$Parser$Advanced$map,
												function (block) {
													return $elm$parser$Parser$Advanced$Loop(block);
												},
												A2(
													$elm$parser$Parser$Advanced$andThen,
													$dillonkearns$elm_markdown$Markdown$Parser$completeOrMergeBlocks(revStmts),
													$dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockNotAfterOpenBlockOrParagraphParser())) : $elm$parser$Parser$Advanced$oneOf(
												_List_fromArray(
													[
														A2(
														$elm$parser$Parser$Advanced$map,
														function (block) {
															return $elm$parser$Parser$Advanced$Loop(block);
														},
														A2(
															$elm$parser$Parser$Advanced$map,
															function (_v15) {
																return A2(completeOrMergeUnorderedListBlockBlankLine, revStmts, '\n');
															},
															$dillonkearns$elm_markdown$Markdown$Parser$blankLine)),
														A2(
														$elm$parser$Parser$Advanced$map,
														function (block) {
															return $elm$parser$Parser$Advanced$Loop(block);
														},
														A2(
															$elm$parser$Parser$Advanced$map,
															completeOrMergeUnorderedListBlock(revStmts),
															A2(
																$elm$parser$Parser$Advanced$keeper,
																A2(
																	$elm$parser$Parser$Advanced$ignorer,
																	$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
																	$elm$parser$Parser$Advanced$symbol(
																		A2(
																			$elm$parser$Parser$Advanced$Token,
																			A2($elm$core$String$repeat, intended, ' '),
																			$elm$parser$Parser$ExpectingSymbol('Indentation')))),
																A2(
																	$elm$parser$Parser$Advanced$ignorer,
																	$elm$parser$Parser$Advanced$getChompedString($dillonkearns$elm_markdown$Helpers$chompUntilLineEndOrEnd),
																	$dillonkearns$elm_markdown$Helpers$lineEndOrEnd)))),
														A2(
														$elm$parser$Parser$Advanced$map,
														function (block) {
															return $elm$parser$Parser$Advanced$Loop(block);
														},
														A2(
															$elm$parser$Parser$Advanced$andThen,
															$dillonkearns$elm_markdown$Markdown$Parser$completeOrMergeBlocks(revStmts),
															$dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockNotAfterOpenBlockOrParagraphParser()))
													]));
										default:
											break _v3$6;
									}
								} else {
									break _v3$6;
								}
							default:
								break _v3$6;
						}
					} else {
						break _v3$6;
					}
				}
				return A2(
					$elm$parser$Parser$Advanced$map,
					function (block) {
						return $elm$parser$Parser$Advanced$Loop(block);
					},
					A2(
						$elm$parser$Parser$Advanced$andThen,
						$dillonkearns$elm_markdown$Markdown$Parser$completeOrMergeBlocks(revStmts),
						$dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockNotAfterOpenBlockOrParagraphParser()));
			}(),
				A2(
				$elm$parser$Parser$Advanced$map,
				function (block) {
					return $elm$parser$Parser$Advanced$Loop(block);
				},
				A2(
					$elm$parser$Parser$Advanced$andThen,
					$dillonkearns$elm_markdown$Markdown$Parser$completeOrMergeBlocks(revStmts),
					$dillonkearns$elm_markdown$Markdown$Parser$openBlockOrParagraphParser))
			]));
};
var $dillonkearns$elm_markdown$Markdown$Parser$textNodeToBlocks = function (textNodeValue) {
	return A2(
		$elm$core$Result$withDefault,
		_List_Nil,
		$dillonkearns$elm_markdown$Markdown$Parser$parse(textNodeValue));
};
var $dillonkearns$elm_markdown$Markdown$Parser$xmlNodeToHtmlNode = function (xmlNode) {
	switch (xmlNode.$) {
		case 1:
			var innerText = xmlNode.a;
			return $elm$parser$Parser$Advanced$succeed(
				$dillonkearns$elm_markdown$Markdown$RawBlock$OpenBlockOrParagraph(innerText));
		case 0:
			var tag = xmlNode.a;
			var attributes = xmlNode.b;
			var children = xmlNode.c;
			var _v1 = $dillonkearns$elm_markdown$Markdown$Parser$nodesToBlocks(children);
			if (!_v1.$) {
				var parsedChildren = _v1.a;
				return $elm$parser$Parser$Advanced$succeed(
					$dillonkearns$elm_markdown$Markdown$RawBlock$Html(
						A3($dillonkearns$elm_markdown$Markdown$Block$HtmlElement, tag, attributes, parsedChildren)));
			} else {
				var err = _v1.a;
				return $elm$parser$Parser$Advanced$problem(err);
			}
		case 2:
			var string = xmlNode.a;
			return $elm$parser$Parser$Advanced$succeed(
				$dillonkearns$elm_markdown$Markdown$RawBlock$Html(
					$dillonkearns$elm_markdown$Markdown$Block$HtmlComment(string)));
		case 3:
			var string = xmlNode.a;
			return $elm$parser$Parser$Advanced$succeed(
				$dillonkearns$elm_markdown$Markdown$RawBlock$Html(
					$dillonkearns$elm_markdown$Markdown$Block$Cdata(string)));
		case 4:
			var string = xmlNode.a;
			return $elm$parser$Parser$Advanced$succeed(
				$dillonkearns$elm_markdown$Markdown$RawBlock$Html(
					$dillonkearns$elm_markdown$Markdown$Block$ProcessingInstruction(string)));
		default:
			var declarationType = xmlNode.a;
			var content = xmlNode.b;
			return $elm$parser$Parser$Advanced$succeed(
				$dillonkearns$elm_markdown$Markdown$RawBlock$Html(
					A2($dillonkearns$elm_markdown$Markdown$Block$HtmlDeclaration, declarationType, content)));
	}
};
function $dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser() {
	return A2(
		$elm$parser$Parser$Advanced$andThen,
		$dillonkearns$elm_markdown$Markdown$Parser$completeBlocks,
		A2(
			$elm$parser$Parser$Advanced$loop,
			{a: _List_Nil, b: _List_Nil},
			$dillonkearns$elm_markdown$Markdown$Parser$stepRawBlock));
}
function $dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockNotAfterOpenBlockOrParagraphParser() {
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				$dillonkearns$elm_markdown$Markdown$Parser$parseAsParagraphInsteadOfHtmlBlock,
				$dillonkearns$elm_markdown$Markdown$Parser$blankLine,
				$dillonkearns$elm_markdown$Markdown$Parser$blockQuote,
				A2(
				$elm$parser$Parser$Advanced$map,
				$dillonkearns$elm_markdown$Markdown$RawBlock$CodeBlock,
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$CodeBlock$parser)),
				$dillonkearns$elm_markdown$Markdown$Parser$indentedCodeBlock,
				A2(
				$elm$parser$Parser$Advanced$map,
				function (_v40) {
					return $dillonkearns$elm_markdown$Markdown$RawBlock$ThematicBreak;
				},
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$ThematicBreak$parser)),
				$dillonkearns$elm_markdown$Markdown$Parser$unorderedListBlock(false),
				$dillonkearns$elm_markdown$Markdown$Parser$orderedListBlock(false),
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$Heading$parser),
				$dillonkearns$elm_markdown$Markdown$Parser$cyclic$htmlParser()
			]));
}
function $dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockAfterOpenBlockOrParagraphParser() {
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				$dillonkearns$elm_markdown$Markdown$Parser$parseAsParagraphInsteadOfHtmlBlock,
				$dillonkearns$elm_markdown$Markdown$Parser$blankLine,
				$dillonkearns$elm_markdown$Markdown$Parser$blockQuote,
				A2(
				$elm$parser$Parser$Advanced$map,
				$dillonkearns$elm_markdown$Markdown$RawBlock$CodeBlock,
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$CodeBlock$parser)),
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$Parser$setextLineParser),
				A2(
				$elm$parser$Parser$Advanced$map,
				function (_v39) {
					return $dillonkearns$elm_markdown$Markdown$RawBlock$ThematicBreak;
				},
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$ThematicBreak$parser)),
				$dillonkearns$elm_markdown$Markdown$Parser$unorderedListBlock(true),
				$dillonkearns$elm_markdown$Markdown$Parser$orderedListBlock(true),
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$Heading$parser),
				$dillonkearns$elm_markdown$Markdown$Parser$cyclic$htmlParser(),
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$Parser$tableDelimiterInOpenParagraph)
			]));
}
function $dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockAfterList() {
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				$dillonkearns$elm_markdown$Markdown$Parser$parseAsParagraphInsteadOfHtmlBlock,
				$dillonkearns$elm_markdown$Markdown$Parser$blankLine,
				$dillonkearns$elm_markdown$Markdown$Parser$blockQuote,
				A2(
				$elm$parser$Parser$Advanced$map,
				$dillonkearns$elm_markdown$Markdown$RawBlock$CodeBlock,
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$CodeBlock$parser)),
				A2(
				$elm$parser$Parser$Advanced$map,
				function (_v38) {
					return $dillonkearns$elm_markdown$Markdown$RawBlock$ThematicBreak;
				},
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$ThematicBreak$parser)),
				$dillonkearns$elm_markdown$Markdown$Parser$unorderedListBlock(false),
				$dillonkearns$elm_markdown$Markdown$Parser$orderedListBlock(false),
				$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$Heading$parser),
				$dillonkearns$elm_markdown$Markdown$Parser$cyclic$htmlParser()
			]));
}
function $dillonkearns$elm_markdown$Markdown$Parser$cyclic$htmlParser() {
	return A2($elm$parser$Parser$Advanced$andThen, $dillonkearns$elm_markdown$Markdown$Parser$xmlNodeToHtmlNode, $dillonkearns$elm_markdown$HtmlParser$html);
}
var $dillonkearns$elm_markdown$Markdown$Parser$rawBlockParser = $dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser();
$dillonkearns$elm_markdown$Markdown$Parser$cyclic$rawBlockParser = function () {
	return $dillonkearns$elm_markdown$Markdown$Parser$rawBlockParser;
};
var $dillonkearns$elm_markdown$Markdown$Parser$mergeableBlockNotAfterOpenBlockOrParagraphParser = $dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockNotAfterOpenBlockOrParagraphParser();
$dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockNotAfterOpenBlockOrParagraphParser = function () {
	return $dillonkearns$elm_markdown$Markdown$Parser$mergeableBlockNotAfterOpenBlockOrParagraphParser;
};
var $dillonkearns$elm_markdown$Markdown$Parser$mergeableBlockAfterOpenBlockOrParagraphParser = $dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockAfterOpenBlockOrParagraphParser();
$dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockAfterOpenBlockOrParagraphParser = function () {
	return $dillonkearns$elm_markdown$Markdown$Parser$mergeableBlockAfterOpenBlockOrParagraphParser;
};
var $dillonkearns$elm_markdown$Markdown$Parser$mergeableBlockAfterList = $dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockAfterList();
$dillonkearns$elm_markdown$Markdown$Parser$cyclic$mergeableBlockAfterList = function () {
	return $dillonkearns$elm_markdown$Markdown$Parser$mergeableBlockAfterList;
};
var $dillonkearns$elm_markdown$Markdown$Parser$htmlParser = $dillonkearns$elm_markdown$Markdown$Parser$cyclic$htmlParser();
$dillonkearns$elm_markdown$Markdown$Parser$cyclic$htmlParser = function () {
	return $dillonkearns$elm_markdown$Markdown$Parser$htmlParser;
};
var $elm$core$Result$map2 = F3(
	function (func, ra, rb) {
		if (ra.$ === 1) {
			var x = ra.a;
			return $elm$core$Result$Err(x);
		} else {
			var a = ra.a;
			if (rb.$ === 1) {
				var x = rb.a;
				return $elm$core$Result$Err(x);
			} else {
				var b = rb.a;
				return $elm$core$Result$Ok(
					A2(func, a, b));
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$combineResults = A2(
	$elm$core$List$foldr,
	$elm$core$Result$map2($elm$core$List$cons),
	$elm$core$Result$Ok(_List_Nil));
var $dillonkearns$elm_markdown$Markdown$Block$foldl = F3(
	function (_function, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var block = list.a;
				var remainingBlocks = list.b;
				switch (block.$) {
					case 0:
						var html = block.a;
						if (!html.$) {
							var children = html.c;
							var $temp$function = _function,
								$temp$acc = A2(_function, block, acc),
								$temp$list = _Utils_ap(children, remainingBlocks);
							_function = $temp$function;
							acc = $temp$acc;
							list = $temp$list;
							continue foldl;
						} else {
							var $temp$function = _function,
								$temp$acc = A2(_function, block, acc),
								$temp$list = remainingBlocks;
							_function = $temp$function;
							acc = $temp$acc;
							list = $temp$list;
							continue foldl;
						}
					case 1:
						var tight = block.a;
						var blocks = block.b;
						var childBlocks = A2(
							$elm$core$List$concatMap,
							function (_v3) {
								var children = _v3.b;
								return children;
							},
							blocks);
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = _Utils_ap(childBlocks, remainingBlocks);
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
					case 2:
						var _int = block.b;
						var blocks = block.c;
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = _Utils_ap(
							$elm$core$List$concat(blocks),
							remainingBlocks);
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
					case 3:
						var blocks = block.a;
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = _Utils_ap(blocks, remainingBlocks);
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
					case 4:
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = remainingBlocks;
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
					case 5:
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = remainingBlocks;
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
					case 6:
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = remainingBlocks;
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
					case 7:
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = remainingBlocks;
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
					default:
						var $temp$function = _function,
							$temp$acc = A2(_function, block, acc),
							$temp$list = remainingBlocks;
						_function = $temp$function;
						acc = $temp$acc;
						list = $temp$list;
						continue foldl;
				}
			}
		}
	});
var $dillonkearns$elm_markdown$Markdown$Block$extractInlineBlockText = function (block) {
	switch (block.$) {
		case 5:
			var inlines = block.a;
			return $dillonkearns$elm_markdown$Markdown$Block$extractInlineText(inlines);
		case 0:
			var html = block.a;
			if (!html.$) {
				var blocks = html.c;
				return A3(
					$dillonkearns$elm_markdown$Markdown$Block$foldl,
					F2(
						function (nestedBlock, soFar) {
							return _Utils_ap(
								soFar,
								$dillonkearns$elm_markdown$Markdown$Block$extractInlineBlockText(nestedBlock));
						}),
					'',
					blocks);
			} else {
				return '';
			}
		case 1:
			var tight = block.a;
			var items = block.b;
			return A2(
				$elm$core$String$join,
				'\n',
				A2(
					$elm$core$List$map,
					function (_v4) {
						var task = _v4.a;
						var blocks = _v4.b;
						return A2(
							$elm$core$String$join,
							'\n',
							A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Block$extractInlineBlockText, blocks));
					},
					items));
		case 2:
			var tight = block.a;
			var _int = block.b;
			var items = block.c;
			return A2(
				$elm$core$String$join,
				'\n',
				A2(
					$elm$core$List$map,
					function (blocks) {
						return A2(
							$elm$core$String$join,
							'\n',
							A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Block$extractInlineBlockText, blocks));
					},
					items));
		case 3:
			var blocks = block.a;
			return A2(
				$elm$core$String$join,
				'\n',
				A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Block$extractInlineBlockText, blocks));
		case 4:
			var headingLevel = block.a;
			var inlines = block.b;
			return $dillonkearns$elm_markdown$Markdown$Block$extractInlineText(inlines);
		case 6:
			var header = block.a;
			var rows = block.b;
			return A2(
				$elm$core$String$join,
				'\n',
				$elm$core$List$concat(
					_List_fromArray(
						[
							A2(
							$elm$core$List$map,
							$dillonkearns$elm_markdown$Markdown$Block$extractInlineText,
							A2(
								$elm$core$List$map,
								function ($) {
									return $.bp;
								},
								header)),
							$elm$core$List$concat(
							A2(
								$elm$core$List$map,
								$elm$core$List$map($dillonkearns$elm_markdown$Markdown$Block$extractInlineText),
								rows))
						])));
		case 7:
			var body = block.a.hY;
			return body;
		default:
			return '';
	}
};
var $dillonkearns$elm_markdown$Markdown$Block$extractInlineText = function (inlines) {
	return A3($elm$core$List$foldl, $dillonkearns$elm_markdown$Markdown$Block$extractTextHelp, '', inlines);
};
var $dillonkearns$elm_markdown$Markdown$Block$extractTextHelp = F2(
	function (inline, text) {
		switch (inline.$) {
			case 7:
				var str = inline.a;
				return _Utils_ap(text, str);
			case 8:
				return text + ' ';
			case 6:
				var str = inline.a;
				return _Utils_ap(text, str);
			case 1:
				var inlines = inline.c;
				return _Utils_ap(
					text,
					$dillonkearns$elm_markdown$Markdown$Block$extractInlineText(inlines));
			case 2:
				var inlines = inline.c;
				return _Utils_ap(
					text,
					$dillonkearns$elm_markdown$Markdown$Block$extractInlineText(inlines));
			case 0:
				var html = inline.a;
				if (!html.$) {
					var blocks = html.c;
					return A3(
						$dillonkearns$elm_markdown$Markdown$Block$foldl,
						F2(
							function (block, soFar) {
								return _Utils_ap(
									soFar,
									$dillonkearns$elm_markdown$Markdown$Block$extractInlineBlockText(block));
							}),
						text,
						blocks);
				} else {
					return text;
				}
			case 4:
				var inlines = inline.a;
				return _Utils_ap(
					text,
					$dillonkearns$elm_markdown$Markdown$Block$extractInlineText(inlines));
			case 3:
				var inlines = inline.a;
				return _Utils_ap(
					text,
					$dillonkearns$elm_markdown$Markdown$Block$extractInlineText(inlines));
			default:
				var inlines = inline.a;
				return _Utils_ap(
					text,
					$dillonkearns$elm_markdown$Markdown$Block$extractInlineText(inlines));
		}
	});
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$renderHtml = F5(
	function (tagName, attributes, children, _v0, renderedChildren) {
		var htmlRenderer = _v0;
		return A2(
			$elm$core$Result$andThen,
			function (okChildren) {
				return A2(
					$elm$core$Result$map,
					function (myRenderer) {
						return myRenderer(okChildren);
					},
					A3(htmlRenderer, tagName, attributes, children));
			},
			$dillonkearns$elm_markdown$Markdown$Renderer$combineResults(renderedChildren));
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$foldThing = F3(
	function (renderer, topLevelInline, soFar) {
		var _v12 = A2($dillonkearns$elm_markdown$Markdown$Renderer$renderSingleInline, renderer, topLevelInline);
		if (!_v12.$) {
			var inline = _v12.a;
			return A2($elm$core$List$cons, inline, soFar);
		} else {
			return soFar;
		}
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$renderHelper = F2(
	function (renderer, blocks) {
		return A2(
			$elm$core$List$filterMap,
			$dillonkearns$elm_markdown$Markdown$Renderer$renderHelperSingle(renderer),
			blocks);
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$renderHelperSingle = function (renderer) {
	return function (block) {
		switch (block.$) {
			case 4:
				var level = block.a;
				var content = block.b;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						function (children) {
							return renderer.iL(
								{
									ia: children,
									iY: level,
									jz: $dillonkearns$elm_markdown$Markdown$Block$extractInlineText(content)
								});
						},
						A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, content)));
			case 5:
				var content = block.a;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						renderer.gQ,
						A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, content)));
			case 0:
				var html = block.a;
				if (!html.$) {
					var tag = html.a;
					var attributes = html.b;
					var children = html.c;
					return $elm$core$Maybe$Just(
						A4($dillonkearns$elm_markdown$Markdown$Renderer$renderHtmlNode, renderer, tag, attributes, children));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			case 1:
				var tight = block.a;
				var items = block.b;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						function (listItems) {
							return renderer.fe(
								A2(
									$elm$core$List$map,
									function (_v7) {
										var task = _v7.a;
										var children = _v7.b;
										return A2(
											$dillonkearns$elm_markdown$Markdown$Block$ListItem,
											task,
											$elm$core$List$concat(children));
									},
									listItems));
						},
						$dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
							A2(
								$elm$core$List$map,
								function (_v4) {
									var task = _v4.a;
									var children = _v4.b;
									return A2(
										$elm$core$Result$map,
										$dillonkearns$elm_markdown$Markdown$Block$ListItem(task),
										$dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
											function (blocks) {
												return A2(
													$elm$core$List$filterMap,
													function (listItemBlock) {
														var _v5 = _Utils_Tuple2(tight, listItemBlock);
														if ((_v5.a === 1) && (_v5.b.$ === 5)) {
															var _v6 = _v5.a;
															var content = _v5.b.a;
															return $elm$core$Maybe$Just(
																A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, content));
														} else {
															return A2(
																$elm$core$Maybe$map,
																$elm$core$Result$map($elm$core$List$singleton),
																A2($dillonkearns$elm_markdown$Markdown$Renderer$renderHelperSingle, renderer, listItemBlock));
														}
													},
													blocks);
											}(children)));
								},
								items))));
			case 2:
				var tight = block.a;
				var startingIndex = block.b;
				var items = block.c;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						function (listItems) {
							return A2(
								renderer.en,
								startingIndex,
								A2(
									$elm$core$List$map,
									function (children) {
										return $elm$core$List$concat(children);
									},
									listItems));
						},
						$dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
							A2(
								$elm$core$List$map,
								function (itemsblocks) {
									return $dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
										function (blocks) {
											return A2(
												$elm$core$List$filterMap,
												function (listItemBlock) {
													var _v8 = _Utils_Tuple2(tight, listItemBlock);
													if ((_v8.a === 1) && (_v8.b.$ === 5)) {
														var _v9 = _v8.a;
														var content = _v8.b.a;
														return $elm$core$Maybe$Just(
															A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, content));
													} else {
														return A2(
															$elm$core$Maybe$map,
															$elm$core$Result$map($elm$core$List$singleton),
															A2($dillonkearns$elm_markdown$Markdown$Renderer$renderHelperSingle, renderer, listItemBlock));
													}
												},
												blocks);
										}(itemsblocks));
								},
								items))));
			case 7:
				var codeBlock = block.a;
				return $elm$core$Maybe$Just(
					$elm$core$Result$Ok(
						renderer.dp(codeBlock)));
			case 8:
				return $elm$core$Maybe$Just(
					$elm$core$Result$Ok(renderer.e4));
			case 3:
				var nestedBlocks = block.a;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						renderer.dm,
						$dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
							A2($dillonkearns$elm_markdown$Markdown$Renderer$renderHelper, renderer, nestedBlocks))));
			default:
				var header = block.a;
				var rows = block.b;
				var renderedHeaderCells = $dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
					A2(
						$elm$core$List$map,
						function (_v11) {
							var label = _v11.bp;
							var alignment = _v11.cw;
							return A2(
								$elm$core$Result$map,
								$elm$core$Tuple$pair(alignment),
								A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, label));
						},
						header));
				var renderedHeader = A2(
					$elm$core$Result$map,
					function (listListView) {
						return renderer.e$(
							$elm$core$List$singleton(
								renderer.c9(
									A2(
										$elm$core$List$map,
										function (_v10) {
											var maybeAlignment = _v10.a;
											var item = _v10.b;
											return A2(renderer.e0, maybeAlignment, item);
										},
										listListView))));
					},
					renderedHeaderCells);
				var renderedBody = function (r) {
					return $elm$core$List$isEmpty(r) ? _List_Nil : _List_fromArray(
						[
							renderer.eZ(r)
						]);
				};
				var alignmentForColumn = function (columnIndex) {
					return A2(
						$elm$core$Maybe$andThen,
						function ($) {
							return $.cw;
						},
						$elm$core$List$head(
							A2($elm$core$List$drop, columnIndex, header)));
				};
				var renderRow = function (cells) {
					return A2(
						$elm$core$Result$map,
						renderer.c9,
						A2(
							$elm$core$Result$map,
							$elm$core$List$indexedMap(
								F2(
									function (index, cell) {
										return A2(
											renderer.e_,
											alignmentForColumn(index),
											cell);
									})),
							$dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
								A2(
									$elm$core$List$map,
									$dillonkearns$elm_markdown$Markdown$Renderer$renderStyled(renderer),
									cells))));
				};
				var renderedRows = $dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
					A2($elm$core$List$map, renderRow, rows));
				return $elm$core$Maybe$Just(
					A3(
						$elm$core$Result$map2,
						F2(
							function (h, r) {
								return renderer.eY(
									A2(
										$elm$core$List$cons,
										h,
										renderedBody(r)));
							}),
						renderedHeader,
						renderedRows));
		}
	};
};
var $dillonkearns$elm_markdown$Markdown$Renderer$renderHtmlNode = F4(
	function (renderer, tag, attributes, children) {
		return A5(
			$dillonkearns$elm_markdown$Markdown$Renderer$renderHtml,
			tag,
			attributes,
			children,
			renderer.iO,
			A2($dillonkearns$elm_markdown$Markdown$Renderer$renderHelper, renderer, children));
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$renderSingleInline = F2(
	function (renderer, inline) {
		switch (inline.$) {
			case 4:
				var innerInlines = inline.a;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						renderer.eV,
						A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, innerInlines)));
			case 3:
				var innerInlines = inline.a;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						renderer.dN,
						A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, innerInlines)));
			case 5:
				var innerInlines = inline.a;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$map,
						renderer.eU,
						A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, innerInlines)));
			case 2:
				var src = inline.a;
				var title = inline.b;
				var children = inline.c;
				return $elm$core$Maybe$Just(
					$elm$core$Result$Ok(
						renderer.dV(
							{
								dh: $dillonkearns$elm_markdown$Markdown$Block$extractInlineText(children),
								jM: src,
								j5: title
							})));
			case 7:
				var string = inline.a;
				return $elm$core$Maybe$Just(
					$elm$core$Result$Ok(
						renderer.e2(string)));
			case 6:
				var string = inline.a;
				return $elm$core$Maybe$Just(
					$elm$core$Result$Ok(
						renderer.dq(string)));
			case 1:
				var destination = inline.a;
				var title = inline.b;
				var inlines = inline.c;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$Result$andThen,
						function (children) {
							return $elm$core$Result$Ok(
								A2(
									renderer.gq,
									{fN: destination, j5: title},
									children));
						},
						A2($dillonkearns$elm_markdown$Markdown$Renderer$renderStyled, renderer, inlines)));
			case 8:
				return $elm$core$Maybe$Just(
					$elm$core$Result$Ok(renderer.dS));
			default:
				var html = inline.a;
				if (!html.$) {
					var tag = html.a;
					var attributes = html.b;
					var children = html.c;
					return $elm$core$Maybe$Just(
						A4($dillonkearns$elm_markdown$Markdown$Renderer$renderHtmlNode, renderer, tag, attributes, children));
				} else {
					return $elm$core$Maybe$Nothing;
				}
		}
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$renderStyled = F2(
	function (renderer, styledStrings) {
		return $dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
			A3(
				$elm$core$List$foldr,
				$dillonkearns$elm_markdown$Markdown$Renderer$foldThing(renderer),
				_List_Nil,
				styledStrings));
	});
var $dillonkearns$elm_markdown$Markdown$Renderer$render = F2(
	function (renderer, ast) {
		return $dillonkearns$elm_markdown$Markdown$Renderer$combineResults(
			A2($dillonkearns$elm_markdown$Markdown$Renderer$renderHelper, renderer, ast));
	});
var $author$project$Governance$CommonViews$actionRow = F3(
	function (actionNumber, title, subtitles) {
		var renderer = _Utils_update(
			$dillonkearns$elm_markdown$Markdown$Renderer$defaultHtmlRenderer,
			{
				gq: F2(
					function (link, content) {
						return A2(
							$elm$html$Html$a,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__link'),
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('__blank')
									]),
								A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, link.fN)),
							content);
					}),
				gQ: $elm$html$Html$div(
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__action-row__text')
						]))
			});
		var markdownTitle = function () {
			var _v1 = A2(
				$elm$core$Result$andThen,
				function (ast) {
					return A2($dillonkearns$elm_markdown$Markdown$Renderer$render, renderer, ast);
				},
				A2(
					$elm$core$Result$mapError,
					$author$project$Governance$CommonViews$deadEndsToString,
					$dillonkearns$elm_markdown$Markdown$Parser$parse(title)));
			if (!_v1.$) {
				var rendered = _v1.a;
				return rendered;
			} else {
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__action-row__title')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(title)
							]))
					]);
			}
		}();
		var markdownSubtitles = A2(
			$elm$core$List$map,
			function (subtitle) {
				var _v0 = A2(
					$elm$core$Result$andThen,
					function (ast) {
						return A2($dillonkearns$elm_markdown$Markdown$Renderer$render, renderer, ast);
					},
					A2(
						$elm$core$Result$mapError,
						$author$project$Governance$CommonViews$deadEndsToString,
						$dillonkearns$elm_markdown$Markdown$Parser$parse(subtitle)));
				if (!_v0.$) {
					var rendered = _v0.a;
					return rendered;
				} else {
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__action-row__subtitle')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(subtitle)
								]))
						]);
				}
			},
			subtitles);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__action-row')
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__action-row__id-and-title-wrapper')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__action-row__number')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$elm$core$String$fromInt(actionNumber))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__action-row__title-wrapper')
								]),
							markdownTitle)
						])),
				A2(
					$elm$core$List$indexedMap,
					F2(
						function (index, markdownSubtitle) {
							return A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__action-row__id-and-title-wrapper')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__action-row__letter')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$Governance$CommonViews$getLetterFromIndex(index))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__action-row__subtitle-wrapper')
											]),
										markdownSubtitle)
									]));
						}),
					markdownSubtitles)));
	});
var $author$project$Governance$CommonViews$proposalDetailsParsedContent = F2(
	function (description, proposalActions) {
		var renderer = _Utils_update(
			$dillonkearns$elm_markdown$Markdown$Renderer$defaultHtmlRenderer,
			{
				iL: function (_v2) {
					var level = _v2.iY;
					var children = _v2.ia;
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__description__header')
							]),
						children);
				},
				gq: F2(
					function (link, content) {
						return A2(
							$elm$html$Html$a,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__link'),
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('__blank')
									]),
								A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, link.fN)),
							content);
					}),
				gQ: $elm$html$Html$div(
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__description__paragraph')
						]))
			});
		var _v0 = function () {
			var markdownDescription = function () {
				var _v1 = A2(
					$elm$core$Result$andThen,
					function (ast) {
						return A2($dillonkearns$elm_markdown$Markdown$Renderer$render, renderer, ast);
					},
					A2(
						$elm$core$Result$mapError,
						$author$project$Governance$CommonViews$deadEndsToString,
						$dillonkearns$elm_markdown$Markdown$Parser$parse(description)));
				if (!_v1.$) {
					var rendered = _v1.a;
					return rendered;
				} else {
					return A2(
						$elm$core$List$map,
						function (pg) {
							return A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__description__paragraph')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(pg)
									]));
						},
						A2($elm$core$String$split, '\n', description));
				}
			}();
			return {
				hL: A2(
					$elm$core$List$indexedMap,
					F2(
						function (index, action) {
							return A3($author$project$Governance$CommonViews$actionRow, index + 1, action.j5, action.jV);
						}),
					proposalActions),
				gR: markdownDescription
			};
		}();
		var paragraphs = _v0.gR;
		var actions = _v0.hL;
		return _Utils_Tuple2(paragraphs, actions);
	});
var $author$project$Governance$ProposalDetail$detailsPanel = F2(
	function (userLanguage, _v0) {
		var proposalDetailState = _v0.ab;
		var _v1 = function () {
			if (!proposalDetailState.$) {
				var proposalDetail = proposalDetailState.a;
				return A2($author$project$Governance$CommonViews$proposalDetailsParsedContent, proposalDetail.iu, proposalDetail.hL);
			} else {
				return _Utils_Tuple2(
					A2($elm$core$List$repeat, 1, $author$project$Governance$ProposalDetail$loadingParagraph),
					A2($elm$core$List$repeat, 3, $author$project$Governance$ProposalDetail$loadingActionRow));
			}
		}();
		var paragraphs = _v1.a;
		var actions = _v1.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-12 col-sm-8 details-panel')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__header')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h4,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											$author$project$Strings$Translations$details(userLanguage))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__actions')
								]),
							actions),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('details-panel__description')
								]),
							paragraphs)
						]))
				]));
	});
var $author$project$Strings$Translations$overview = function (lang) {
	switch (lang) {
		case 0:
			return 'Overview';
		case 1:
			return 'Visión general';
		case 2:
			return '总览';
		case 3:
			return 'Aperçu';
		default:
			return '개요';
	}
};
var $author$project$LandingSite$Page$getInPageBackTitleHref = F2(
	function (userLanguage, previousPage) {
		switch (previousPage.$) {
			case 4:
				return _Utils_Tuple2(
					$author$project$Strings$Translations$proposals(userLanguage),
					$author$project$LandingSite$Page$getHrefUrl($author$project$LandingSite$Page$GovernanceProposalOverview));
			case 3:
				var proposalId = previousPage.a;
				return _Utils_Tuple2(
					$author$project$Strings$Translations$details(userLanguage),
					$author$project$LandingSite$Page$getHrefUrl(
						$author$project$LandingSite$Page$GovernanceProposalDetail(proposalId)));
			default:
				return _Utils_Tuple2(
					$author$project$Strings$Translations$overview(userLanguage),
					$author$project$LandingSite$Page$getHrefUrl($author$project$LandingSite$Page$GovernanceHome));
		}
	});
var $author$project$Governance$CommonViews$loadingProposalContentViewHeader = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content__description')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content__description__title proposal__content__description__title--header--loading')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content__description__details proposal__content__description__details--loading')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content__description__details__tag proposal__content__description__details__tag--loading--large')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content__description__details__text')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal__content__description__details__text__time--loading proposal__content__description__details__text__time--loading--large')
										]),
									_List_Nil)
								]))
						]))
				]))
		]));
var $author$project$Governance$ProposalDetail$loadingProposerPane = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposer-pane')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('gov-profile-image gov-profile-image--loading')
				]),
			_List_Nil),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposer-pane__details')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposer-pane__details__display-name proposer-pane__details__display-name--loading')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposer-pane__details__address proposer-pane__details__address--loading')
						]),
					_List_Nil)
				]))
		]));
var $author$project$Governance$CommonViews$pageHeader = F4(
	function (backText, backUrl, headerView, maybeDetailsSection) {
		var detailsSection = function () {
			if (!maybeDetailsSection.$) {
				var detailSection_ = maybeDetailsSection.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('page-header__detail-section')
						]),
					_List_fromArray(
						[detailSection_]));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('page-header')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('page-header__main-section')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							A2(
								$elm$core$List$cons,
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('page-header__main-section__back'),
								A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 0, backUrl)),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('small page-header__main-section__back__text')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(backText)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('page-header__main-section__title')
								]),
							_List_fromArray(
								[headerView]))
						])),
					detailsSection
				]));
	});
var $author$project$Governance$ProposalDetail$placeholderLoadingProposal = function (targetProposalId) {
	return {
		fj: $torreyatcitty$the_best_decimal$Decimal$zero,
		hL: _List_Nil,
		fp: $torreyatcitty$the_best_decimal$Decimal$zero,
		iu: '',
		f_: $torreyatcitty$the_best_decimal$Decimal$zero,
		gb: targetProposalId,
		jv: {
			hO: ' ...',
			iv: $elm$core$Maybe$Just('...'),
			iP: $elm$core$Maybe$Nothing
		},
		hf: _List_Nil,
		j5: 'Loading...'
	};
};
var $author$project$CompoundComponents$Eth$Ethereum$TransactionHash = function (a) {
	return {$: 0, a: a};
};
var $author$project$CompoundComponents$Eth$Ethereum$etherscanUrl = F2(
	function (network, urlValue) {
		var _v0 = function () {
			switch (urlValue.$) {
				case 0:
					var suffix = urlValue.a;
					return _Utils_Tuple2('tx', suffix);
				case 1:
					var suffix = urlValue.a;
					return _Utils_Tuple2('token', suffix);
				default:
					var suffix = urlValue.a;
					return _Utils_Tuple2('address', suffix);
			}
		}();
		var linkType = _v0.a;
		var linkValue = _v0.b;
		switch (network) {
			case 1:
				return $elm$core$Maybe$Just('https://etherscan.io/' + (linkType + ('/' + linkValue)));
			case 4:
				return $elm$core$Maybe$Just('https://rinkeby.etherscan.io/' + (linkType + ('/' + linkValue)));
			case 7:
				return $elm$core$Maybe$Just('https://kovan.etherscan.io/' + (linkType + ('/' + linkValue)));
			case 3:
				return $elm$core$Maybe$Just('https://ropsten.etherscan.io/' + (linkType + ('/' + linkValue)));
			case 5:
				return $elm$core$Maybe$Just('https://goerli.etherscan.io/' + (linkType + ('/' + linkValue)));
			case 12:
				return $elm$core$Maybe$Just('https://polygonscan.com/' + (linkType + ('/' + linkValue)));
			case 13:
				return $elm$core$Maybe$Just('https://mumbai.polygonscan.com/' + (linkType + ('/' + linkValue)));
			case 14:
				return $elm$core$Maybe$Just('https://arbiscan.io/' + (linkType + ('/' + linkValue)));
			case 15:
				return $elm$core$Maybe$Just('https://goerli.arbiscan.io/' + (linkType + ('/' + linkValue)));
			case 16:
				return $elm$core$Maybe$Just('https://optimistic.etherscan.io/' + (linkType + ('/' + linkValue)));
			case 17:
				return $elm$core$Maybe$Just('https://goerli-optimism.etherscan.io/' + (linkType + ('/' + linkValue)));
			case 18:
				return $elm$core$Maybe$Just('https://basescan.org/' + (linkType + ('/' + linkValue)));
			case 19:
				return $elm$core$Maybe$Just('https://goerli.basescan.org/' + (linkType + ('/' + linkValue)));
			default:
				return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$CompoundComponents$Eth$Ethereum$etherscanLink = F3(
	function (maybeNetwork, value, attributes) {
		if (!maybeNetwork.$) {
			var network = maybeNetwork.a;
			var _v1 = A2($author$project$CompoundComponents$Eth$Ethereum$etherscanUrl, network, value);
			if (!_v1.$) {
				var etherscanLinkVal = _v1.a;
				return $elm$html$Html$a(
					A2(
						$elm$core$List$cons,
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank'),
						_Utils_ap(
							A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, etherscanLinkVal),
							attributes)));
			} else {
				return $elm$html$Html$a(attributes);
			}
		} else {
			return $elm$html$Html$a(attributes);
		}
	});
var $author$project$Strings$Translations$proposal_state_created = function (lang) {
	switch (lang) {
		case 0:
			return 'Created';
		case 1:
			return 'Creado';
		case 2:
			return '已建立';
		case 3:
			return 'Créé';
		default:
			return '생성됨';
	}
};
var $author$project$Strings$Translations$proposal_state_executed_on = F2(
	function (lang, cross_chain_network) {
		switch (lang) {
			case 0:
				return 'Executed on ' + (cross_chain_network + '');
			case 1:
				return 'Ejecutado en ' + (cross_chain_network + '');
			case 2:
				return '在 ' + (cross_chain_network + ' 上执行');
			case 3:
				return 'Exécuté sur ' + (cross_chain_network + '');
			default:
				return '' + (cross_chain_network + '에서 실행됨');
		}
	});
var $author$project$Strings$Translations$proposal_state_queued_on = F2(
	function (lang, cross_chain_network) {
		switch (lang) {
			case 0:
				return 'Queued on ' + (cross_chain_network + '');
			case 1:
				return 'En cola en ' + (cross_chain_network + '');
			case 2:
				return '在 ' + (cross_chain_network + ' 排队');
			case 3:
				return 'En file d\'attente sur ' + (cross_chain_network + '');
			default:
				return '' + (cross_chain_network + '에 대기 중');
		}
	});
var $author$project$Strings$Translations$proposal_state_succeeded = function (lang) {
	switch (lang) {
		case 0:
			return 'Succeeded';
		case 1:
			return 'Triunfó';
		case 2:
			return '成功';
		case 3:
			return 'Réussi';
		default:
			return '성공';
	}
};
var $ryannhg$date_format$DateFormat$AmPmLowercase = {$: 23};
var $ryannhg$date_format$DateFormat$amPmLowercase = $ryannhg$date_format$DateFormat$AmPmLowercase;
var $ryannhg$date_format$DateFormat$HourNumber = {$: 26};
var $ryannhg$date_format$DateFormat$hourNumber = $ryannhg$date_format$DateFormat$HourNumber;
var $ryannhg$date_format$DateFormat$MinuteFixed = {$: 31};
var $ryannhg$date_format$DateFormat$minuteFixed = $ryannhg$date_format$DateFormat$MinuteFixed;
var $author$project$Governance$ProposalDetail$stateTimeFormatter = function (timezone) {
	return A2(
		$ryannhg$date_format$DateFormat$format,
		_List_fromArray(
			[
				$ryannhg$date_format$DateFormat$monthNameFull,
				$ryannhg$date_format$DateFormat$text(' '),
				$ryannhg$date_format$DateFormat$dayOfMonthSuffix,
				$ryannhg$date_format$DateFormat$text(', '),
				$ryannhg$date_format$DateFormat$yearNumber,
				$ryannhg$date_format$DateFormat$text(' – '),
				$ryannhg$date_format$DateFormat$hourNumber,
				$ryannhg$date_format$DateFormat$text(':'),
				$ryannhg$date_format$DateFormat$minuteFixed,
				$ryannhg$date_format$DateFormat$amPmLowercase
			]),
		timezone);
};
var $author$project$Governance$ProposalDetail$historyStateRow = F5(
	function (userLanguage, timezone, network, maybeCurrentState, historyState) {
		var timestamp = A2($author$project$Governance$ProposalDetail$stateTimeFormatter, timezone, historyState.jP);
		var maybeState = A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.jQ;
			},
			maybeCurrentState);
		var _v0 = function () {
			var _v1 = _Utils_Tuple3(historyState.jQ, maybeState, historyState.$9);
			switch (_v1.a) {
				case 0:
					if ((!_v1.b.$) && (!_v1.b.a)) {
						var _v2 = _v1.a;
						var _v3 = _v1.b.a;
						return _Utils_Tuple2(
							$author$project$Strings$Translations$proposal_state_pending(userLanguage),
							'active');
					} else {
						var _v4 = _v1.a;
						return _Utils_Tuple2(
							$author$project$Strings$Translations$proposal_state_created(userLanguage),
							'inactive-succeeded');
					}
				case 1:
					if ((!_v1.b.$) && (_v1.b.a === 1)) {
						var _v5 = _v1.a;
						var _v6 = _v1.b.a;
						return _Utils_Tuple2(
							$author$project$Strings$Translations$proposal_state_active(userLanguage),
							'active');
					} else {
						var _v7 = _v1.a;
						return _Utils_Tuple2(
							$author$project$Strings$Translations$proposal_state_active(userLanguage),
							'inactive-succeeded');
					}
				case 2:
					if ((!_v1.b.$) && (_v1.b.a === 6)) {
						var _v8 = _v1.a;
						var _v9 = _v1.b.a;
						return _Utils_Tuple2(
							$author$project$Strings$Translations$proposal_state_succeeded(userLanguage),
							'inactive-succeeded');
					} else {
						var _v10 = _v1.a;
						return _Utils_Tuple2(
							$author$project$Strings$Translations$proposal_state_succeeded(userLanguage),
							'active');
					}
				case 3:
					var _v11 = _v1.a;
					return _Utils_Tuple2(
						$author$project$Strings$Translations$proposal_state_failed(userLanguage),
						'failed');
				case 4:
					if ((!_v1.b.$) && (_v1.b.a === 6)) {
						var _v12 = _v1.a;
						var _v13 = _v1.b.a;
						return _Utils_Tuple2(
							$author$project$Strings$Translations$proposal_state_queued(userLanguage),
							'inactive-succeeded');
					} else {
						if (!_v1.c.$) {
							var _v14 = _v1.a;
							var crossChainNetworkName = _v1.c.a;
							return _Utils_Tuple2(
								A2(
									$author$project$Strings$Translations$proposal_state_queued_on,
									userLanguage,
									$author$project$CompoundComponents$Eth$Network$networkName(crossChainNetworkName)),
								'active');
						} else {
							var _v16 = _v1.a;
							return _Utils_Tuple2(
								$author$project$Strings$Translations$proposal_state_queued(userLanguage),
								'active');
						}
					}
				case 5:
					if (!_v1.c.$) {
						var _v15 = _v1.a;
						var crossChainNetworkName = _v1.c.a;
						return _Utils_Tuple2(
							A2(
								$author$project$Strings$Translations$proposal_state_executed_on,
								userLanguage,
								$author$project$CompoundComponents$Eth$Network$networkName(crossChainNetworkName)),
							'active');
					} else {
						var _v17 = _v1.a;
						return _Utils_Tuple2(
							$author$project$Strings$Translations$proposal_state_executed(userLanguage),
							'active');
					}
				case 6:
					var _v18 = _v1.a;
					return _Utils_Tuple2(
						$author$project$Strings$Translations$proposal_state_canceled(userLanguage),
						'failed');
				default:
					var _v19 = _v1.a;
					return _Utils_Tuple2(
						$author$project$Strings$Translations$proposal_state_expired(userLanguage),
						'failed');
			}
		}();
		var stateName = _v0.a;
		var pointClass = _v0.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('history-panel__time-line__point history-panel__time-line__point--' + pointClass)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('history-panel__time-line__point__content')
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('history-panel__time-line__point__title')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(stateName)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('history-panel__time-line__point__timestamp')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(timestamp)
										]))
								])),
						function () {
							var _v20 = historyState.kk;
							if (!_v20.$) {
								var stateTrxHash = _v20.a;
								return _List_fromArray(
									[
										A4(
										$author$project$CompoundComponents$Eth$Ethereum$etherscanLink,
										$elm$core$Maybe$Just(
											A2($elm$core$Maybe$withDefault, network, historyState.$9)),
										$author$project$CompoundComponents$Eth$Ethereum$TransactionHash(stateTrxHash),
										_List_fromArray(
											[
												$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('external-link')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('line-icon line-icon--external-link line-icon--external-link--green')
													]),
												_List_Nil)
											]))
									]);
							} else {
								return _List_Nil;
							}
						}()))
				]));
	});
var $author$project$Governance$ProposalDetail$loadingHistoryStateRow = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('history-panel__time-line__point history-panel__time-line__point--loading')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('history-panel__time-line__point__content')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('history-panel__time-line__point__title history-panel__time-line__point__title--loading')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('history-panel__time-line__point__timestamp history-panel__time-line__point__timestamp--loading')
								]),
							_List_Nil)
						]))
				]))
		]));
var $author$project$Strings$Translations$proposal_history = function (lang) {
	switch (lang) {
		case 0:
			return 'Proposal History';
		case 1:
			return 'Historial de propuestas';
		case 2:
			return '提案历史';
		case 3:
			return 'Historique de la proposition';
		default:
			return '제안서 내역';
	}
};
var $author$project$Governance$ProposalDetail$proposalHistoryPanel = F4(
	function (userLanguage, timezone, network, _v0) {
		var proposalDetailState = _v0.ab;
		var statePoints = function () {
			if (!proposalDetailState.$) {
				var states = proposalDetailState.a.hf;
				var currentState = $elm$core$List$head(
					$elm$core$List$reverse(states));
				return A2(
					$elm$core$List$map,
					A4($author$project$Governance$ProposalDetail$historyStateRow, userLanguage, timezone, network, currentState),
					states);
			} else {
				return A2($elm$core$List$repeat, 2, $author$project$Governance$ProposalDetail$loadingHistoryStateRow);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-12 col-sm-4 history-panel')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__header')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h4,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											$author$project$Strings$Translations$proposal_history(userLanguage))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('history-panel__time-line ')
								]),
							statePoints)
						]))
				]));
	});
var $author$project$Governance$ProposalDetail$ForSelf = $elm$core$Basics$identity;
var $author$project$Governance$ProposalDetail$ImageError = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Utils$GovProfileImageHelper$Medium = 1;
var $author$project$Governance$ProposalDetail$proposerPane = F4(
	function (_v0, targetProposalId, network, _v1) {
		var proposalDetailState = _v1.ab;
		var proposerCompAccountState = _v1.c2;
		var hasProposerIconState = function () {
			if (!proposerCompAccountState.$) {
				var proposerCompAccount = proposerCompAccountState.a;
				return A2(
					$torreyatcitty$the_best_decimal$Decimal$gte,
					proposerCompAccount.kx,
					$author$project$Utils$GovernanceHelper$proposalThreshold(network));
			} else {
				return false;
			}
		}();
		var activeProposer = function () {
			if (!proposalDetailState.$) {
				var proposalDetail = proposalDetailState.a;
				return proposalDetail.jv;
			} else {
				return $author$project$Governance$ProposalDetail$placeholderLoadingProposal(targetProposalId).jv;
			}
		}();
		var friendlyDisplayName = A2(
			$elm$core$Maybe$withDefault,
			A3($author$project$CompoundComponents$Eth$Ethereum$shortenedAddressString, 4, 4, activeProposer.hO),
			activeProposer.iv);
		var profileImageComponent = A2(
			$elm$html$Html$map,
			function (profileImageMsg) {
				var profileElementId = profileImageMsg.a;
				var defaultImage = profileImageMsg.b;
				return A2($author$project$Governance$ProposalDetail$ImageError, profileElementId, defaultImage);
			},
			$author$project$Utils$GovProfileImageHelper$profileImage(
				{hO: activeProposer.hO, i1: activeProposer.iP, jJ: hasProposerIconState, a3: 1, kt: false}));
		return A2(
			$elm$html$Html$a,
			_Utils_ap(
				_List_fromArray(
					[
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposer-pane'),
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank')
					]),
				A2(
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href,
					2,
					$author$project$LandingSite$Page$getAccountProfileUrl(activeProposer.hO))),
			_List_fromArray(
				[
					profileImageComponent,
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposer-pane__details')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposer-pane__details__display-name')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(friendlyDisplayName)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposer-pane__details__address')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									A3($author$project$CompoundComponents$Eth$Ethereum$shortenedAddressString, 4, 4, activeProposer.hO))
								]))
						]))
				]));
	});
var $author$project$Governance$ProposalDetail$ShowVotesModal = function (a) {
	return {$: 4, a: a};
};
var $author$project$Strings$Translations$address = function (lang) {
	switch (lang) {
		case 0:
			return 'Address';
		case 1:
			return 'Habla a';
		case 2:
			return '地址';
		case 3:
			return 'Adresse';
		default:
			return '주소';
	}
};
var $author$project$Strings$Translations$against = function (lang) {
	switch (lang) {
		case 0:
			return 'Against';
		case 1:
			return 'En contra';
		case 2:
			return '反对';
		case 3:
			return 'Contre';
		default:
			return '반대';
	}
};
var $author$project$Strings$Translations$for = function (lang) {
	switch (lang) {
		case 0:
			return 'For';
		case 1:
			return 'Por';
		case 2:
			return '赞成';
		case 3:
			return 'Pour';
		default:
			return '찬성';
	}
};
var $author$project$Governance$ProposalDetail$loadingVoteRow = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('votes-panel__vote-row votes-panel__vote-row--loading')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('votes-panel__vote-row__account votes-panel__vote-row__account--loading')
				]),
			_List_Nil),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('votes-panel__vote-row__votes votes-panel__vote-row__votes--loading')
				]),
			_List_Nil)
		]));
var $author$project$Strings$Translations$view_all = function (lang) {
	switch (lang) {
		case 0:
			return 'View All';
		case 1:
			return 'Ver todo';
		case 2:
			return '查看全部';
		case 3:
			return 'Voir tout';
		default:
			return '모두 보기';
	}
};
var $author$project$Governance$ProposalDetail$loadingVotePanel = F2(
	function (userLanguage, isForVotes) {
		var headerText = isForVotes ? $author$project$Strings$Translations$for(userLanguage) : $author$project$Strings$Translations$against(userLanguage);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-12 col-sm-6 votes-panel')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__header votes-panel__header')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('votes-panel__header__title-bar')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$h4,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(headerText)
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('votes-panel__header__title-bar__percent--loading')
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('votes-panel__header__vote-bar')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__labels')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-6 text-left')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$label,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$Strings$Translations$address(userLanguage))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-6 text-right')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$label,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$Strings$Translations$votes(userLanguage))
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('votes-panel__vote-rows')
								]),
							A2($elm$core$List$repeat, 3, $author$project$Governance$ProposalDetail$loadingVoteRow)),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__footer panel__footer--disabled')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Strings$Translations$view_all(userLanguage))
								]))
						]))
				]));
	});
var $author$project$Governance$ProposalDetail$loadingVoteOverview = function (userLanguage) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('row')
			]),
		_List_fromArray(
			[
				A2($author$project$Governance$ProposalDetail$loadingVotePanel, userLanguage, true),
				A2($author$project$Governance$ProposalDetail$loadingVotePanel, userLanguage, false)
			]));
};
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $author$project$CompoundComponents$Utils$CompoundHtmlAttributes$onClickStopPropagation = function (msg) {
	return A2(
		$elm$html$Html$Events$custom,
		'click',
		$elm$json$Json$Decode$succeed(
			{i2: msg, jn: true, jT: true}));
};
var $elm$core$List$sortWith = _List_sortWith;
var $author$project$Strings$Translations$addresses = F2(
	function (lang, numberOfAddresses) {
		switch (lang) {
			case 0:
				return '' + (numberOfAddresses + ' Addresses');
			case 1:
				return '' + (numberOfAddresses + ' Direcciones');
			case 2:
				return '' + (numberOfAddresses + '个地址');
			case 3:
				return '' + (numberOfAddresses + ' Adresses');
			default:
				return '' + (numberOfAddresses + ' 주소');
		}
	});
var $author$project$Governance$ProposalDetail$emptyVoteRow = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('votes-panel__vote-row votes-panel__vote-row--empty')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('votes-panel__vote-row__account votes-panel__vote-row__account--empty')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('—')
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('votes-panel__vote-row__votes votes-panel__vote-row__votes--empty')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('—')
				]))
		]));
var $author$project$Governance$ProposalDetail$voteRow = F3(
	function (name, address, votes) {
		return A2(
			$elm$html$Html$a,
			_Utils_ap(
				_List_fromArray(
					[
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('votes-panel__vote-row'),
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank')
					]),
				A2(
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href,
					2,
					$author$project$LandingSite$Page$getAccountProfileUrl(address))),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('votes-panel__vote-row__account')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(name)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('votes-panel__vote-row__votes')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$CompoundComponents$Utils$NumberFormatter$formatTokenBalance(votes))
						]))
				]));
	});
var $author$project$Governance$ProposalDetail$voteTotalsAndRowsContent = F7(
	function (userLanguage, forModal, votes, votesPercentage, numberOfVoters, isForVotes, voters) {
		var allVotersRows = A2(
			$elm$core$List$map,
			function (voteReceipt) {
				var friendlyDisplayName = A2(
					$elm$core$Maybe$withDefault,
					A3($author$project$CompoundComponents$Eth$Ethereum$shortenedAddressString, 4, 4, voteReceipt.hz.hO),
					voteReceipt.hz.iv);
				return A3($author$project$Governance$ProposalDetail$voteRow, friendlyDisplayName, voteReceipt.hz.hO, voteReceipt.kx);
			},
			A2(
				$elm$core$List$sortWith,
				F2(
					function (a, b) {
						return A2(
							$torreyatcitty$the_best_decimal$Decimal$compare,
							function ($) {
								return $.kx;
							}(b),
							function ($) {
								return $.kx;
							}(a));
					}),
				voters));
		var votersRows = forModal ? allVotersRows : A2(
			$elm$core$List$take,
			3,
			_Utils_ap(
				allVotersRows,
				A2($elm$core$List$repeat, 3, $author$project$Governance$ProposalDetail$emptyVoteRow)));
		var addressLabel = (numberOfVoters === 1) ? $author$project$Strings$Translations$address(userLanguage) : A2(
			$author$project$Strings$Translations$addresses,
			userLanguage,
			$elm$core$String$fromInt(numberOfVoters));
		var votePanelHeaders = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__labels')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-6 text-left')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$label,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(addressLabel)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-6 text-right')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$label,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Strings$Translations$votes(userLanguage))
								]))
						]))
				]));
		var _v0 = isForVotes ? _Utils_Tuple2(
			$author$project$Strings$Translations$for(userLanguage),
			'--for') : _Utils_Tuple2(
			$author$project$Strings$Translations$against(userLanguage),
			'--against');
		var headerText = _v0.a;
		var barFillClass = _v0.b;
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__header votes-panel__header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('votes-panel__header__title-bar')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h4,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(headerText)
										])),
									A2(
									$elm$html$Html$h4,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(votes)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('votes-panel__header__vote-bar')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('votes-panel__header__vote-bar__fill votes-panel__header__vote-bar__fill' + barFillClass),
											A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$style, 'width', votesPercentage)
										]),
									_List_Nil)
								]))
						])),
					votePanelHeaders
				]),
			votersRows);
	});
var $author$project$Governance$ProposalDetail$votesSorter = F2(
	function (a, b) {
		return A2(
			$torreyatcitty$the_best_decimal$Decimal$compare,
			function ($) {
				return $.kx;
			}(b),
			function ($) {
				return $.kx;
			}(a));
	});
var $author$project$Governance$ProposalDetail$voteOverview = F2(
	function (userLanguage, _v0) {
		var proposalDetailState = _v0.ab;
		var forVoteReceiptsState = _v0.bi;
		var againstVoteReceiptsState = _v0.ba;
		var _v1 = _Utils_Tuple3(proposalDetailState, forVoteReceiptsState, againstVoteReceiptsState);
		if (((!_v1.a.$) && (!_v1.b.$)) && (!_v1.c.$)) {
			var proposalDetail = _v1.a.a;
			var forVoteReceipts = _v1.b.a;
			var againstVoteReceipts = _v1.c.a;
			var maybeCurrentState = $elm$core$List$head(
				$elm$core$List$reverse(proposalDetail.hf));
			var _v2 = function () {
				var _v3 = _Utils_Tuple2(
					A2(
						$elm$core$List$take,
						3,
						A2($elm$core$List$sortWith, $author$project$Governance$ProposalDetail$votesSorter, forVoteReceipts.aO)),
					($elm$core$List$length(forVoteReceipts.aO) >= 3) ? A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__footer'),
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$onClickStopPropagation(
								$author$project$Governance$ProposalDetail$ShowVotesModal(true))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Strings$Translations$view_all(userLanguage))
							])) : A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__footer panel__footer--disabled')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Strings$Translations$view_all(userLanguage))
							])));
				var forTop3 = _v3.a;
				var forVotesViewAll = _v3.b;
				var _v4 = _Utils_Tuple2(
					A2(
						$elm$core$List$take,
						3,
						A2($elm$core$List$sortWith, $author$project$Governance$ProposalDetail$votesSorter, againstVoteReceipts.aO)),
					($elm$core$List$length(againstVoteReceipts.aO) >= 3) ? A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__footer'),
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$onClickStopPropagation(
								$author$project$Governance$ProposalDetail$ShowVotesModal(false))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Strings$Translations$view_all(userLanguage))
							])) : A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__footer panel__footer--disabled')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Strings$Translations$view_all(userLanguage))
							])));
				var againstTop3 = _v4.a;
				var againstVotesViewAll = _v4.b;
				return {
					fn: _List_fromArray(
						[againstVotesViewAll]),
					fo: againstTop3,
					fY: _List_fromArray(
						[forVotesViewAll]),
					fZ: forTop3
				};
			}();
			var forVotesTop3 = _v2.fZ;
			var forVotesShowAll = _v2.fY;
			var againstVotesTop3 = _v2.fo;
			var againstVotesShowAll = _v2.fn;
			var _v5 = function () {
				var totalVotes = A2($torreyatcitty$the_best_decimal$Decimal$add, proposalDetail.f_, proposalDetail.fp);
				return {
					fm: $author$project$CompoundComponents$Utils$NumberFormatter$formatPercentage(
						A2(
							$elm$core$Maybe$withDefault,
							$torreyatcitty$the_best_decimal$Decimal$zero,
							A2($torreyatcitty$the_best_decimal$Decimal$fastdiv, proposalDetail.fp, totalVotes))),
					fX: $author$project$CompoundComponents$Utils$NumberFormatter$formatPercentage(
						A2(
							$elm$core$Maybe$withDefault,
							$torreyatcitty$the_best_decimal$Decimal$zero,
							A2($torreyatcitty$the_best_decimal$Decimal$fastdiv, proposalDetail.f_, totalVotes)))
				};
			}();
			var forVotesPercentage = _v5.fX;
			var againstVotesPercentage = _v5.fm;
			var votingOverviewPanels = A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('row')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-12 col-sm-6 votes-panel')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel')
									]),
								_Utils_ap(
									A7(
										$author$project$Governance$ProposalDetail$voteTotalsAndRowsContent,
										userLanguage,
										false,
										A3($author$project$CompoundComponents$Utils$NumberFormatter$formatToDecimalPlaces, 0, false, proposalDetail.f_),
										forVotesPercentage,
										forVoteReceipts.bJ,
										true,
										forVotesTop3),
									forVotesShowAll))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-12 col-sm-6 votes-panel')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel')
									]),
								_Utils_ap(
									A7(
										$author$project$Governance$ProposalDetail$voteTotalsAndRowsContent,
										userLanguage,
										false,
										A3($author$project$CompoundComponents$Utils$NumberFormatter$formatToDecimalPlaces, 0, false, proposalDetail.fp),
										againstVotesPercentage,
										againstVoteReceipts.bJ,
										false,
										againstVotesTop3),
									againstVotesShowAll))
							]))
					]));
			if (!maybeCurrentState.$) {
				var currentState = maybeCurrentState.a;
				return (!currentState.jQ) ? $elm$html$Html$text('') : votingOverviewPanels;
			} else {
				return votingOverviewPanels;
			}
		} else {
			return $author$project$Governance$ProposalDetail$loadingVoteOverview(userLanguage);
		}
	});
var $author$project$Governance$ProposalDetail$DismissVotesModal = {$: 5};
var $author$project$Strings$Translations$against_votes = function (lang) {
	switch (lang) {
		case 0:
			return 'Against Votes';
		case 1:
			return 'Contra los votos';
		case 2:
			return '反对投票';
		case 3:
			return 'Contre les votes';
		default:
			return '반대 표';
	}
};
var $elm$html$Html$button = _VirtualDom_node('button');
var $author$project$Strings$Translations$for_votes = function (lang) {
	switch (lang) {
		case 0:
			return 'For Votes';
		case 1:
			return 'Para los votos';
		case 2:
			return '投票';
		case 3:
			return 'Pour les votes';
		default:
			return '찬성 표';
	}
};
var $author$project$Governance$ProposalDetail$votersModalView = F2(
	function (userLanguage, _v0) {
		var shownVotesModal = _v0.ch;
		if (!shownVotesModal.$) {
			var showForVotes = shownVotesModal.a.eM;
			var proposalDetail = shownVotesModal.a.eC;
			var votersList = shownVotesModal.a.fh;
			var totalVotes = A2($torreyatcitty$the_best_decimal$Decimal$add, proposalDetail.f_, proposalDetail.fp);
			var _v2 = function () {
				if (showForVotes) {
					var percentage = $author$project$CompoundComponents$Utils$NumberFormatter$formatPercentage(
						A2(
							$elm$core$Maybe$withDefault,
							$torreyatcitty$the_best_decimal$Decimal$zero,
							A2($torreyatcitty$the_best_decimal$Decimal$fastdiv, proposalDetail.f_, totalVotes)));
					return _Utils_Tuple3(
						$author$project$Strings$Translations$for_votes(userLanguage) + (' – ' + percentage),
						percentage,
						A3($author$project$CompoundComponents$Utils$NumberFormatter$formatToDecimalPlaces, 0, false, proposalDetail.f_));
				} else {
					var percentage = $author$project$CompoundComponents$Utils$NumberFormatter$formatPercentage(
						A2(
							$elm$core$Maybe$withDefault,
							$torreyatcitty$the_best_decimal$Decimal$zero,
							A2($torreyatcitty$the_best_decimal$Decimal$fastdiv, proposalDetail.fp, totalVotes)));
					return _Utils_Tuple3(
						$author$project$Strings$Translations$against_votes(userLanguage) + (' – ' + percentage),
						percentage,
						A3($author$project$CompoundComponents$Utils$NumberFormatter$formatToDecimalPlaces, 0, false, proposalDetail.fp));
				}
			}();
			var headerText = _v2.a;
			var votesPercentage = _v2.b;
			var votes = _v2.c;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('modal')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('cover active'),
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$onClickStopPropagation($author$project$Governance$ProposalDetail$DismissVotesModal)
							]),
						_List_Nil),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('container')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('accent neutral')
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('legacy-panel')
									]),
								A2(
									$elm$core$List$cons,
									A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('header')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('title')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(headerText)
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('close-x')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$onClickStopPropagation($author$project$Governance$ProposalDetail$DismissVotesModal)
															]),
														_List_Nil)
													]))
											])),
									A7($author$project$Governance$ProposalDetail$voteTotalsAndRowsContent, userLanguage, true, votes, votesPercentage, votersList.bJ, showForVotes, votersList.aO)))
							]))
					]));
		} else {
			return $elm$html$Html$text('');
		}
	});
var $author$project$Governance$ProposalDetail$view = F7(
	function (userLanguage, network, backPage, targetProposalId, timezone, maybeCurrentTime, model) {
		var proposalDetailState = model.ab;
		var _v0 = function () {
			switch (proposalDetailState.$) {
				case 0:
					var proposalDetail = proposalDetailState.a;
					return _Utils_Tuple2(
						A5($author$project$Governance$CommonViews$proposalContentView, userLanguage, timezone, maybeCurrentTime, proposalDetail, true),
						$elm$core$Maybe$Just(
							A4($author$project$Governance$ProposalDetail$proposerPane, userLanguage, targetProposalId, network, model)));
				case 2:
					return _Utils_Tuple2(
						$author$project$Governance$CommonViews$loadingProposalContentViewHeader,
						$elm$core$Maybe$Just($author$project$Governance$ProposalDetail$loadingProposerPane));
				default:
					return _Utils_Tuple2(
						A5(
							$author$project$Governance$CommonViews$proposalContentView,
							userLanguage,
							timezone,
							maybeCurrentTime,
							$author$project$Governance$ProposalDetail$placeholderLoadingProposal(targetProposalId),
							true),
						$elm$core$Maybe$Just($author$project$Governance$ProposalDetail$loadingProposerPane));
			}
		}();
		var proposalHeader = _v0.a;
		var maybeProposerPane = _v0.b;
		var _v2 = A2($author$project$LandingSite$Page$getInPageBackTitleHref, userLanguage, backPage);
		var inPageHeaderTitle = _v2.a;
		var inPageHeaderHref = _v2.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('Proposal-Detail')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$section,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('hero')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('container')
								]),
							_List_fromArray(
								[
									A4($author$project$Governance$CommonViews$pageHeader, inPageHeaderTitle, inPageHeaderHref, proposalHeader, maybeProposerPane)
								]))
						])),
					A2(
					$elm$html$Html$section,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal-detail')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('content')
										]),
									_List_fromArray(
										[
											A2($author$project$Governance$ProposalDetail$voteOverview, userLanguage, model),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('row')
												]),
											_List_fromArray(
												[
													A2($author$project$Governance$ProposalDetail$detailsPanel, userLanguage, model),
													A4($author$project$Governance$ProposalDetail$proposalHistoryPanel, userLanguage, timezone, network, model)
												]))
										]))
								]))
						])),
					A2($author$project$Governance$ProposalDetail$votersModalView, userLanguage, model)
				]));
	});
var $author$project$Governance$ProposalOverview$ForSelf = $elm$core$Basics$identity;
var $author$project$Governance$ProposalOverview$PagerNext = {$: 2};
var $author$project$Governance$ProposalOverview$PagerPrevious = {$: 1};
var $author$project$Strings$Translations$all_proposals = function (lang) {
	switch (lang) {
		case 0:
			return 'All Proposals';
		case 1:
			return 'Todas las propuestas';
		case 2:
			return '所有提案';
		case 3:
			return 'Toutes les propositions';
		default:
			return '모든 제안';
	}
};
var $author$project$Strings$Translations$next = function (lang) {
	switch (lang) {
		case 0:
			return 'Next';
		case 1:
			return 'próximo';
		case 2:
			return '下一个';
		case 3:
			return 'Prochain';
		default:
			return '다음';
	}
};
var $author$project$Strings$Translations$prev = function (lang) {
	switch (lang) {
		case 0:
			return 'Prev';
		case 1:
			return 'Anterior';
		case 2:
			return '上一个';
		case 3:
			return 'Précédent';
		default:
			return '이전';
	}
};
var $author$project$Governance$ProposalOverview$allProposalsPane = F4(
	function (userLanguage, timezone, maybeCurrentTime, _v0) {
		var currentPageNumber = _v0.bd;
		var totalProposalsState = _v0.bI;
		var proposalsPageMap = _v0.by;
		var totalPages = function () {
			if (!totalProposalsState.$) {
				var totalProposals = totalProposalsState.a;
				return $elm$core$Basics$ceiling(totalProposals / $author$project$Governance$ProposalOverview$desiredPageSize);
			} else {
				return 0;
			}
		}();
		var proposalsForPage = function () {
			var _v1 = A2($elm$core$Dict$get, currentPageNumber, proposalsPageMap);
			if (!_v1.$) {
				var pageProposals = _v1.a;
				var proposalsToShow = A2(
					$elm$core$List$map,
					A3($author$project$Governance$CommonViews$proposalRow, userLanguage, timezone, maybeCurrentTime),
					pageProposals);
				return ($elm$core$List$length(proposalsToShow) > 0) ? proposalsToShow : _List_fromArray(
					[
						$author$project$Governance$CommonViews$noProposalsRow(userLanguage)
					]);
			} else {
				if (totalProposalsState.$ === 2) {
					return A2($elm$core$List$repeat, 10, $author$project$Governance$CommonViews$loadingProposalRow);
				} else {
					return _List_Nil;
				}
			}
		}();
		var pagerFooter = function () {
			if (totalPages > 1) {
				var prevButton = (currentPageNumber > 1) ? A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__pager__previous'),
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$onClickStopPropagation($author$project$Governance$ProposalOverview$PagerPrevious)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$label,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Strings$Translations$prev(userLanguage))
								]))
						])) : A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('')
						]));
				var pagerIndicator = A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__pager__indicator')
						]),
					A2(
						$elm$core$List$map,
						function (i) {
							var activeClass = _Utils_eq(currentPageNumber, i) ? ' panel__pager__indicator__text--active' : '';
							return A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__pager__indicator__text' + activeClass)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromInt(i))
									]));
						},
						A2($elm$core$List$range, 1, totalPages)));
				var nextButton = (_Utils_cmp(currentPageNumber, totalPages) < 0) ? A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__pager__next'),
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$onClickStopPropagation($author$project$Governance$ProposalOverview$PagerNext)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$label,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Strings$Translations$next(userLanguage))
								]))
						])) : A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('')
						]));
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__pager')
						]),
					_List_fromArray(
						[prevButton, pagerIndicator, nextButton]));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h4,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Strings$Translations$all_proposals(userLanguage))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposals')
						]),
					proposalsForPage),
					pagerFooter
				]));
	});
var $author$project$Strings$Translations$governance_proposals = function (lang) {
	switch (lang) {
		case 0:
			return 'Governance Proposals';
		case 1:
			return 'Propuestas de gobierno';
		case 2:
			return '治理提案';
		case 3:
			return 'Propositions de gouvernance';
		default:
			return '거버넌스 제안서';
	}
};
var $author$project$Governance$ProposalOverview$overviewHeader = function (userLanguage) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal-overview')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal-overview__header')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Strings$Translations$governance_proposals(userLanguage))
					]))
			]));
};
var $author$project$Governance$ProposalOverview$ActiveProposal = 0;
var $author$project$Governance$ProposalOverview$FailedProposal = 2;
var $author$project$Governance$ProposalOverview$PassedProposal = 1;
var $author$project$Governance$ProposalOverview$buildDataset = F3(
	function (activeCount, passedCount, failedCount) {
		var total = (activeCount + passedCount) + failedCount;
		return _List_fromArray(
			[
				{bK: activeCount / total},
				{bK: passedCount / total},
				{bK: failedCount / total}
			]);
	});
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{u: nodeList, q: nodeListSize, s: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $author$project$Charty$Color$defaultPalette = $elm$core$Array$fromList(
	_List_fromArray(
		['#9669ED', '#00D395', '#657786']));
var $elm$core$Array$length = function (_v0) {
	var len = _v0.a;
	return len;
};
var $author$project$Charty$Color$assignDefaults = function (dataset) {
	var colorCount = $elm$core$Array$length($author$project$Charty$Color$defaultPalette);
	var color = function (index) {
		var _v0 = A2(
			$elm$core$Array$get,
			A2($elm$core$Basics$modBy, colorCount, index),
			$author$project$Charty$Color$defaultPalette);
		if (_v0.$ === 1) {
			return '#00D395';
		} else {
			var x = _v0.a;
			return x;
		}
	};
	return A2(
		$elm$core$List$indexedMap,
		F2(
			function (i, series) {
				return _Utils_Tuple2(
					color(i),
					series);
			}),
		dataset);
};
var $author$project$Charty$PieChart$defaults = {
	fu: 'transparent',
	ds: $author$project$Charty$Color$assignDefaults,
	ed: $elm$core$Maybe$Just(8)
};
var $author$project$Governance$ProposalOverview$getOverviewStateForProposal = function (proposal) {
	var maybeCurrentState = $elm$core$List$head(
		$elm$core$List$reverse(proposal.hf));
	if (!maybeCurrentState.$) {
		var currentState = maybeCurrentState.a;
		var _v1 = currentState.jQ;
		switch (_v1) {
			case 0:
				return 0;
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 1;
			case 5:
				return 1;
			case 6:
				return 2;
			default:
				return 2;
		}
	} else {
		return 2;
	}
};
var $author$project$Charty$PieChart$accumulateStart = F2(
	function (start, slices) {
		if (!slices.b) {
			return _List_Nil;
		} else {
			var s = slices.a;
			var ss = slices.b;
			return A2(
				$elm$core$List$cons,
				_Utils_Tuple2(start, s),
				A2($author$project$Charty$PieChart$accumulateStart, start + s.cd, ss));
		}
	});
var $elm$core$Basics$cos = _Basics_cos;
var $elm$core$Basics$pi = _Basics_pi;
var $elm$core$Basics$sin = _Basics_sin;
var $author$project$Charty$PieChart$circumferencePoint = function (percentage) {
	var ang = (percentage * (2 * $elm$core$Basics$pi)) / 100;
	return _Utils_Tuple2(
		70 * (1 + $elm$core$Basics$sin(ang)),
		70 * (1 - $elm$core$Basics$cos(ang)));
};
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $elm$svg$Svg$Attributes$stroke = _VirtualDom_attribute('stroke');
var $author$project$Charty$PieChart$drawSlice = F3(
	function (config, start, slice) {
		var largeArc = (slice.cd <= 50) ? '0' : '1';
		var _v0 = $author$project$Charty$PieChart$circumferencePoint(
			start + A2($elm$core$Basics$min, 99.9999, slice.cd));
		var x2 = _v0.a;
		var y2 = _v0.b;
		var _v1 = $author$project$Charty$PieChart$circumferencePoint(start);
		var x1 = _v1.a;
		var y1 = _v1.b;
		var pathDefinition = A2(
			$elm$core$Basics$composeR,
			$elm$core$List$concat,
			$elm$core$String$join(' '))(
			_List_fromArray(
				[
					_List_fromArray(
					['M 70 70']),
					_List_fromArray(
					[
						'L ',
						$elm$core$String$fromFloat(x1),
						$elm$core$String$fromFloat(y1)
					]),
					_List_fromArray(
					['A 70 70 0', largeArc, '1']),
					_List_fromArray(
					[
						$elm$core$String$fromFloat(x2),
						$elm$core$String$fromFloat(y2)
					]),
					_List_fromArray(
					['Z'])
				]));
		return A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d(pathDefinition),
					$elm$svg$Svg$Attributes$stroke('transparent'),
					$elm$svg$Svg$Attributes$fill(slice.dr)
				]),
			_List_Nil);
	});
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $author$project$Charty$PieChart$uncurry = F2(
	function (f, _v0) {
		var a = _v0.a;
		var b = _v0.b;
		return A2(f, a, b);
	});
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $author$project$Charty$PieChart$drawSlices = F2(
	function (config, slices) {
		return A2(
			$elm$svg$Svg$svg,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$viewBox('0 0 140 140'),
					$elm$svg$Svg$Attributes$width('100%')
				]),
			A2(
				$elm$core$List$map,
				$author$project$Charty$PieChart$uncurry(
					$author$project$Charty$PieChart$drawSlice(config)),
				A2($author$project$Charty$PieChart$accumulateStart, 0, slices)));
	});
var $author$project$Charty$PieChart$sumValues = A2(
	$elm$core$List$foldr,
	F2(
		function (_v0, s) {
			var value = _v0.bK;
			return value + s;
		}),
	0);
var $author$project$Charty$PieChart$normalize = function (dataset) {
	var sum = $author$project$Charty$PieChart$sumValues(dataset);
	return A2(
		$elm$core$List$map,
		function (_v0) {
			var value = _v0.bK;
			return {bK: (100 * value) / sum};
		},
		dataset);
};
var $author$project$Charty$PieChart$truncate = F2(
	function (maxGroupCount, dataset) {
		if (maxGroupCount.$ === 1) {
			return dataset;
		} else {
			var n = maxGroupCount.a;
			var _v1 = A2($elm$core$List$drop, n - 1, dataset);
			if (!_v1.b) {
				return dataset;
			} else {
				if (!_v1.b.b) {
					return dataset;
				} else {
					var rest = _v1;
					return _Utils_ap(
						A2($elm$core$List$take, n - 1, dataset),
						_List_fromArray(
							[
								{
								bK: $author$project$Charty$PieChart$sumValues(rest)
							}
							]));
				}
			}
		}
	});
var $author$project$Charty$PieChart$preprocess = F2(
	function (config, dataset) {
		return A2(
			$elm$core$List$map,
			function (_v0) {
				var color = _v0.a;
				var value = _v0.b.bK;
				return {dr: color, cd: value};
			},
			config.ds(
				A2(
					$author$project$Charty$PieChart$truncate,
					config.ed,
					$author$project$Charty$PieChart$normalize(dataset))));
	});
var $author$project$Charty$PieChart$view = F2(
	function (config, dataset) {
		var slices = A2($author$project$Charty$PieChart$preprocess, config, dataset);
		return A2($author$project$Charty$PieChart$drawSlices, config, slices);
	});
var $author$project$Governance$ProposalOverview$proposalsPieChart = F2(
	function (userLanguage, _v0) {
		var totalProposalsState = _v0.bI;
		var proposalsPageMap = _v0.by;
		var totalProposalsText = function () {
			if (!totalProposalsState.$) {
				var total = totalProposalsState.a;
				return $elm$core$String$fromInt(total);
			} else {
				return '...';
			}
		}();
		var pieChart = function () {
			var _v1 = _Utils_Tuple2(
				totalProposalsState,
				A2($elm$core$Dict$get, 1, proposalsPageMap));
			if ((!_v1.a.$) && (!_v1.b.$)) {
				var totalProposals = _v1.a.a;
				var firstPageProposals = _v1.b.a;
				if (_Utils_cmp(totalProposals, $author$project$Governance$ProposalOverview$desiredPageSize) < 1) {
					var filterFunction = F2(
						function (desiredOverviewState, proposal) {
							return _Utils_eq(
								$author$project$Governance$ProposalOverview$getOverviewStateForProposal(proposal),
								desiredOverviewState) ? $elm$core$Maybe$Just(proposal) : $elm$core$Maybe$Nothing;
						});
					var passedProposals = $elm$core$List$length(
						A2(
							$elm$core$List$filterMap,
							filterFunction(1),
							firstPageProposals));
					var failedProposals = $elm$core$List$length(
						A2(
							$elm$core$List$filterMap,
							filterFunction(2),
							firstPageProposals));
					var activeProposals = $elm$core$List$length(
						A2(
							$elm$core$List$filterMap,
							filterFunction(0),
							firstPageProposals));
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposals-pie-chart')
							]),
						_List_fromArray(
							[
								A2(
								$author$project$Charty$PieChart$view,
								$author$project$Charty$PieChart$defaults,
								A3($author$project$Governance$ProposalOverview$buildDataset, activeProposals, passedProposals, failedProposals))
							]));
				} else {
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposals-pie-chart')
							]),
						_List_fromArray(
							[
								A2($author$project$Charty$PieChart$view, $author$project$Charty$PieChart$defaults, _List_Nil)
							]));
				}
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposals-pie-chart')
						]),
					_List_fromArray(
						[
							A2($author$project$Charty$PieChart$view, $author$project$Charty$PieChart$defaults, _List_Nil)
						]));
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposals-pie-view')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal-pie-wrapper')
						]),
					_List_fromArray(
						[
							pieChart,
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal-pie-description')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('headline')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(totalProposalsText)
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('proposal-pie-labels')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$label,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('active')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Strings$Translations$proposal_state_active(userLanguage))
								])),
							A2(
							$elm$html$Html$label,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('passed')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Strings$Translations$proposal_state_passed(userLanguage))
								])),
							A2(
							$elm$html$Html$label,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('failed')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Strings$Translations$proposal_state_failed(userLanguage))
								]))
						]))
				]));
	});
var $author$project$Governance$ProposalOverview$view = F4(
	function (userLanguage, timezone, maybeCurrentTime, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('ProposalOverview')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$section,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('hero')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('container')
								]),
							_List_fromArray(
								[
									A4(
									$author$project$Governance$CommonViews$pageHeader,
									$author$project$Strings$Translations$overview(userLanguage),
									$author$project$LandingSite$Page$getHrefUrl($author$project$LandingSite$Page$GovernanceHome),
									$author$project$Governance$ProposalOverview$overviewHeader(userLanguage),
									$elm$core$Maybe$Just(
										A2($author$project$Governance$ProposalOverview$proposalsPieChart, userLanguage, model)))
								]))
						])),
					A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('content')
										]),
									_List_fromArray(
										[
											A4($author$project$Governance$ProposalOverview$allProposalsPane, userLanguage, timezone, maybeCurrentTime, model)
										]))
								]))
						]))
				]));
	});
var $author$project$LandingSite$Comp$CopyMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$LandingSite$Comp$ForSelf = $elm$core$Basics$identity;
var $author$project$LandingSite$Comp$ToggleAccordianAtIndex = function (a) {
	return {$: 0, a: a};
};
var $author$project$CompoundComponents$Eth$Ethereum$TokenAddress = function (a) {
	return {$: 1, a: a};
};
var $author$project$LandingSite$Comp$aprToApy = function (apr) {
	var daysInYear = 365;
	var apy = A2(
		$elm$core$Basics$pow,
		1.0 + ($torreyatcitty$the_best_decimal$Decimal$toFloat(apr) / daysInYear),
		daysInYear) - 1;
	return A2(
		$elm$core$Maybe$withDefault,
		$torreyatcitty$the_best_decimal$Decimal$zero,
		$torreyatcitty$the_best_decimal$Decimal$fromFloat(apy));
};
var $author$project$Strings$Translations$apy = function (lang) {
	switch (lang) {
		case 0:
			return 'APY';
		case 1:
			return 'APY';
		case 2:
			return '亚太地区';
		case 3:
			return 'APY';
		default:
			return 'APY';
	}
};
var $author$project$Strings$Translations$borrow = function (lang) {
	switch (lang) {
		case 0:
			return 'Borrow';
		case 1:
			return 'Pedir prestado';
		case 2:
			return '借贷';
		case 3:
			return 'Emprunter';
		default:
			return '대출';
	}
};
var $author$project$Strings$Translations$comp_distribution = function (lang) {
	switch (lang) {
		case 0:
			return 'COMP Distribution';
		case 1:
			return 'Distribución COMP';
		case 2:
			return 'COMP分配';
		case 3:
			return 'Distribution COMP';
		default:
			return 'COMP 분배';
	}
};
var $author$project$Strings$Translations$daily_borrow = function (lang) {
	switch (lang) {
		case 0:
			return 'Daily Borrow';
		case 1:
			return 'Préstamo diario';
		case 2:
			return '每日借贷';
		case 3:
			return 'Emprunter quotidiennement';
		default:
			return '일일 대출';
	}
};
var $author$project$Strings$Translations$daily_distribution = function (lang) {
	switch (lang) {
		case 0:
			return 'Daily Distribution';
		case 1:
			return 'Distribución diaria';
		case 2:
			return '用户分布';
		case 3:
			return 'Distribution quotidienne';
		default:
			return '일일 분배 수량';
	}
};
var $author$project$Strings$Translations$daily_supply = function (lang) {
	switch (lang) {
		case 0:
			return 'Daily Supply';
		case 1:
			return 'Suministro diario';
		case 2:
			return '每日供应';
		case 3:
			return 'Approvisionnement quotidien';
		default:
			return '일일 공급';
	}
};
var $author$project$Strings$Translations$enabled_markets = function (lang) {
	switch (lang) {
		case 0:
			return 'Enabled Markets';
		case 1:
			return 'Mercados Habilitados';
		case 2:
			return '启用市场';
		case 3:
			return 'Marchés activés';
		default:
			return '활성화된 시장';
	}
};
var $author$project$LandingSite$Comp$loadingMarketRow = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('markets-panel__market-row markets-panel__market-row--loading')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-4')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('markets-panel__market-row__market')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('markets-panel__market-row__market__icon markets-panel__market-row__market__icon--loading ')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('markets-panel__market-row__market__text markets-panel__market-row__market__text--loading')
								]),
							_List_Nil)
						]))
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-2 mobile-hide')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('markets-panel__market-row__interest markets-panel__market-row__interest--loading')
						]),
					_List_Nil)
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-4 col-sm-2')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('markets-panel__market-row__comp markets-panel__market-row__comp--loading')
						]),
					_List_Nil)
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-2 mobile-hide')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('markets-panel__market-row__comp markets-panel__market-row__comp--loading')
						]),
					_List_Nil)
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-4 col-sm-2')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('markets-panel__market-row__total-comp markets-panel__market-row__total-comp--loading')
						]),
					_List_Nil)
				]))
		]));
var $author$project$LandingSite$Comp$mainnetCompAddress = '0xc00e94Cb662C3520282E6f5717214004A7f26888';
var $author$project$LandingSite$Comp$marketRow = F6(
	function (symbol, market, supplierCompApy, borrowerCompApy, supplierCompPerDay, borrowerCompPerDay) {
		var perDayText = function (total) {
			return A2($torreyatcitty$the_best_decimal$Decimal$eq, total, $torreyatcitty$the_best_decimal$Decimal$zero) ? A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('markets-panel__market-row__comp markets-panel__market-row__comp--empty')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('—')
					])) : $elm$html$Html$text(
				A3($author$project$CompoundComponents$Utils$NumberFormatter$formatToDecimalPlaces, 2, false, total));
		};
		var supplyComp = perDayText(supplierCompPerDay);
		var borrowComp = perDayText(borrowerCompPerDay);
		var apyText = function (apy) {
			return A2($torreyatcitty$the_best_decimal$Decimal$eq, apy, $torreyatcitty$the_best_decimal$Decimal$zero) ? A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('markets-panel__market-row__comp markets-panel__market-row__comp--empty')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('—')
					])) : $elm$html$Html$text(
				$author$project$CompoundComponents$Utils$NumberFormatter$formatPercentage(apy));
		};
		var borrowerApy = apyText(borrowerCompApy);
		var supplierApy = apyText(supplierCompApy);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('markets-panel__market-row')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-4')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('markets-panel__market-row__market')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('markets-panel__market-row__market__icon icon icon--' + symbol)
										]),
									_List_Nil),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('markets-panel__market-row__market__text')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(market)
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-4 col-sm-2 text-right')
						]),
					_List_fromArray(
						[supplyComp])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-4 col-sm-2 text-right')
						]),
					_List_fromArray(
						[borrowComp])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-2 text-right mobile-hide')
						]),
					_List_fromArray(
						[supplierApy])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-2 text-right mobile-hide')
						]),
					_List_fromArray(
						[borrowerApy]))
				]));
	});
var $author$project$Strings$Translations$market_distribution = function (lang) {
	switch (lang) {
		case 0:
			return 'Market Distribution';
		case 1:
			return 'Distribución del mercado';
		case 2:
			return '市场分布';
		case 3:
			return 'Distribution sur le marché';
		default:
			return '마켓 분배 현황';
	}
};
var $author$project$Strings$Translations$need_help = function (lang) {
	switch (lang) {
		case 0:
			return 'Need help?';
		case 1:
			return '¿Necesitas ayuda?';
		case 2:
			return '需要帮忙？';
		case 3:
			return 'Besoin d\'aide?';
		default:
			return '도움이 필요하세요?';
	}
};
var $author$project$Strings$Translations$need_help_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Frequently asked questions.';
		case 1:
			return 'Preguntas frecuentes.';
		case 2:
			return '经常问的问题。';
		case 3:
			return 'Questions fréquemment posées.';
		default:
			return '자주 묻는 질문.';
	}
};
var $author$project$Strings$Translations$network_does_not_have_comp = function (lang) {
	switch (lang) {
		case 0:
			return 'This network does not have a COMP token.';
		case 1:
			return 'Esta red no tiene un token COMP.';
		case 2:
			return '该网络没有COMP令牌。';
		case 3:
			return 'Ce réseau n\'a pas de jeton COMP.';
		default:
			return '이 네트워크에는 COMP 토큰이 없습니다.';
	}
};
var $author$project$LandingSite$Comp$noCompMarketRow = F2(
	function (symbol, market) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('markets-panel__market-row')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-4')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('markets-panel__market-row__market')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('markets-panel__market-row__market__icon icon ' + symbol)
										]),
									_List_Nil),
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('markets-panel__market-row__market__text')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(market)
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-4 col-sm-2 text-right')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('transactions-panel__transaction-row__time')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('markets-panel__market-row__comp markets-panel__market-row__comp--empty')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('—')
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-2 text-right mobile-hide')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('markets-panel__market-row__comp markets-panel__market-row__comp--empty')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('—')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-2 text-right mobile-hide')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('markets-panel__market-row__comp markets-panel__market-row__comp--empty')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('—')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-4 col-sm-2 text-right')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('markets-panel__market-row__comp markets-panel__market-row__comp--empty')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('—')
								]))
						]))
				]));
	});
var $author$project$Strings$Translations$remaining = function (lang) {
	switch (lang) {
		case 0:
			return 'Remaining';
		case 1:
			return 'Restante';
		case 2:
			return '未释放';
		case 3:
			return 'Restant';
		default:
			return '남은 수량';
	}
};
var $torreyatcitty$the_best_decimal$Decimal$negate = function (_v0) {
	var m = _v0.a;
	var e = _v0.b;
	return A2(
		$torreyatcitty$the_best_decimal$Decimal$Decimal,
		$cmditch$elm_bigint$BigInt$negate(m),
		e);
};
var $torreyatcitty$the_best_decimal$Decimal$sub = F2(
	function (a, b) {
		return A2(
			$torreyatcitty$the_best_decimal$Decimal$add,
			a,
			$torreyatcitty$the_best_decimal$Decimal$negate(b));
	});
var $author$project$Strings$Translations$supply = function (lang) {
	switch (lang) {
		case 0:
			return 'Supply';
		case 1:
			return 'Suministro';
		case 2:
			return '供应';
		case 3:
			return 'Approvisionnement';
		default:
			return '공급';
	}
};
var $author$project$Strings$Translations$total_distributed = function (lang) {
	switch (lang) {
		case 0:
			return 'Total Distributed';
		case 1:
			return 'Total distribuido';
		case 2:
			return '已释放总量';
		case 3:
			return 'Total distribué';
		default:
			return '총 분배 수량';
	}
};
var $author$project$V3Api$Decoders$v3MarketSymbol = function (market) {
	switch (market) {
		case 0:
			return 'USDC';
		case 1:
			return 'USDC.e';
		case 2:
			return 'USDbC';
		default:
			return 'ETH';
	}
};
var $author$project$V3Api$Decoders$v3SupportedChainFullNames = function (network) {
	switch (network) {
		case 1:
			return 'Ethereum';
		case 12:
			return 'Polygon';
		case 14:
			return 'Arbitrum';
		case 18:
			return 'Base';
		default:
			return 'Ethereum';
	}
};
var $author$project$LandingSite$Comp$view = F4(
	function (userLanguage, network, maybeLatestTokens, model) {
		var v3RewardSummaries = $elm$core$Dict$values(model.b0);
		var v3RewardSummariesFlatDeterministic = A2(
			$elm$core$List$sortBy,
			function (_v11) {
				var summary = _v11.c;
				return $torreyatcitty$the_best_decimal$Decimal$toFloat(summary.fx);
			},
			v3RewardSummaries);
		var v3SupplySpeedPerSecondAllMarkets = A3(
			$elm$core$List$foldl,
			$torreyatcitty$the_best_decimal$Decimal$add,
			$torreyatcitty$the_best_decimal$Decimal$zero,
			A2(
				$elm$core$List$map,
				function (_v10) {
					var summaryData = _v10.c;
					return summaryData.hj;
				},
				v3RewardSummaries));
		var v3MarketRewardsRows = A2(
			$elm$core$List$map,
			function (_v9) {
				var market = _v9.a;
				var marketNetwork = _v9.b;
				var rewardsSummary = _v9.c;
				var supplySpeedPerDay = A2(
					$torreyatcitty$the_best_decimal$Decimal$mul,
					$torreyatcitty$the_best_decimal$Decimal$fromInt((60 * 60) * 24),
					function ($) {
						return $.hj;
					}(rewardsSummary));
				var borrowSpeedPerDay = A2(
					$torreyatcitty$the_best_decimal$Decimal$mul,
					$torreyatcitty$the_best_decimal$Decimal$fromInt((60 * 60) * 24),
					function ($) {
						return $.fy;
					}(rewardsSummary));
				return A6(
					$author$project$LandingSite$Comp$marketRow,
					(market === 3) ? 'WETH' : $author$project$V3Api$Decoders$v3MarketSymbol(market),
					'Compound III ' + ($author$project$V3Api$Decoders$v3MarketSymbol(market) + (' (' + ($author$project$V3Api$Decoders$v3SupportedChainFullNames(marketNetwork) + ')'))),
					$author$project$LandingSite$Comp$aprToApy(
						function ($) {
							return $.jY;
						}(rewardsSummary)),
					$author$project$LandingSite$Comp$aprToApy(
						function ($) {
							return $.fx;
						}(rewardsSummary)),
					supplySpeedPerDay,
					borrowSpeedPerDay);
			},
			v3RewardSummariesFlatDeterministic);
		var v3BorrowSpeedPerSecondAllMarkets = A3(
			$elm$core$List$foldl,
			$torreyatcitty$the_best_decimal$Decimal$add,
			$torreyatcitty$the_best_decimal$Decimal$zero,
			A2(
				$elm$core$List$map,
				function (_v8) {
					var summaryData = _v8.c;
					return summaryData.fy;
				},
				v3RewardSummaries));
		var v3CompSpeedPerSecondAllMarkets = A2($torreyatcitty$the_best_decimal$Decimal$add, v3BorrowSpeedPerSecondAllMarkets, v3SupplySpeedPerSecondAllMarkets);
		var v3CompSpeedPerDayAllMarkets = A2(
			$torreyatcitty$the_best_decimal$Decimal$mul,
			$torreyatcitty$the_best_decimal$Decimal$fromInt((60 * 60) * 24),
			v3CompSpeedPerSecondAllMarkets);
		var latestTokens = function () {
			var _v5 = _Utils_Tuple2(maybeLatestTokens, model.b6);
			if ((!_v5.a.$) && (!_v5.b.$)) {
				var tokens = _v5.a.a;
				var compDistribution = _v5.b.a;
				var etherPrice = function () {
					var ethPerUsd = A2(
						$elm$core$Maybe$withDefault,
						$torreyatcitty$the_best_decimal$Decimal$zero,
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.fd;
							},
							$elm$core$List$head(
								A2(
									$elm$core$List$filter,
									function (token) {
										return token.kp === 'USDC';
									},
									tokens.h4))));
					return A2($torreyatcitty$the_best_decimal$Decimal$fastdiv, $torreyatcitty$the_best_decimal$Decimal$one, ethPerUsd);
				}();
				return ($elm$core$List$length(compDistribution.i_) > 0) ? A2(
					$elm$core$List$map,
					function (market) {
						var supplierDailyComp = market.jW;
						var maybeToken = $elm$core$List$head(
							A2(
								$elm$core$List$filter,
								function (token) {
									return _Utils_eq(token.ho, market.hO);
								},
								tokens.h4));
						var etherPriceOrZero = A2($elm$core$Maybe$withDefault, $torreyatcitty$the_best_decimal$Decimal$zero, etherPrice);
						var borrowerDailyComp = market.h0;
						var _v6 = function () {
							if (!maybeToken.$) {
								var token = maybeToken.a;
								return _Utils_Tuple2(
									A2(
										$elm$core$Maybe$withDefault,
										$torreyatcitty$the_best_decimal$Decimal$zero,
										A2(
											$torreyatcitty$the_best_decimal$Decimal$fastdiv,
											token.ii,
											$torreyatcitty$the_best_decimal$Decimal$fromInt(100))),
									A2(
										$elm$core$Maybe$withDefault,
										$torreyatcitty$the_best_decimal$Decimal$zero,
										A2(
											$torreyatcitty$the_best_decimal$Decimal$fastdiv,
											token.$8,
											$torreyatcitty$the_best_decimal$Decimal$fromInt(100))));
							} else {
								return _Utils_Tuple2($torreyatcitty$the_best_decimal$Decimal$zero, $torreyatcitty$the_best_decimal$Decimal$zero);
							}
						}();
						var supplierCompApy = _v6.a;
						var borrowerCompApy = _v6.b;
						return A6($author$project$LandingSite$Comp$marketRow, market.kp, market.ko, supplierCompApy, borrowerCompApy, supplierDailyComp, borrowerDailyComp);
					},
					A2(
						$elm$core$List$sortBy,
						function (market) {
							return market.kp;
						},
						A2(
							$elm$core$List$filter,
							function (market) {
								return A2($torreyatcitty$the_best_decimal$Decimal$gt, market.h0, $torreyatcitty$the_best_decimal$Decimal$zero) || A2($torreyatcitty$the_best_decimal$Decimal$gt, market.jW, $torreyatcitty$the_best_decimal$Decimal$zero);
							},
							A2(
								$elm$core$List$filter,
								function (market) {
									return market.kp !== 'ETH';
								},
								compDistribution.i_)))) : A2(
					$elm$core$List$map,
					function (market) {
						return A2($author$project$LandingSite$Comp$noCompMarketRow, market.kp, market.ko);
					},
					A2(
						$elm$core$List$sortBy,
						function (market) {
							return market.ko;
						},
						A2(
							$elm$core$List$filter,
							function (market) {
								return market.kp !== 'SAI';
							},
							tokens.h4)));
			} else {
				return A2($elm$core$List$repeat, 8, $author$project$LandingSite$Comp$loadingMarketRow);
			}
		}();
		var faqDisplayActive = function (index) {
			var faqState = A2($elm$core$Array$get, index, model.aU);
			var faqAccordianClass = function () {
				if ((!faqState.$) && faqState.a) {
					return ' active';
				} else {
					return '';
				}
			}();
			return faqAccordianClass;
		};
		var compAddressRow = function () {
			if (network === 1) {
				var address = $author$project$LandingSite$Comp$mainnetCompAddress;
				return _List_fromArray(
					[
						$elm$html$Html$text(address),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('line-icon line-icon--copy mobile-hide'),
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$onClickStopPropagation(
								$author$project$LandingSite$Comp$CopyMsg(address))
							]),
						_List_Nil),
						A4(
						$author$project$CompoundComponents$Eth$Ethereum$etherscanLink,
						$elm$core$Maybe$Just(network),
						$author$project$CompoundComponents$Eth$Ethereum$TokenAddress(address),
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('line-icon line-icon--external-link line-icon--external-link--gray'),
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank')
							]),
						_List_Nil)
					]);
			} else {
				return _List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Strings$Translations$network_does_not_have_comp(userLanguage))
					]);
			}
		}();
		var _v0 = function () {
			var _v1 = _Utils_Tuple2(model.cV, model.b6);
			if ((!_v1.a.$) && (!_v1.b.$)) {
				var governanceHistory = _v1.a.a;
				var compDistribution = _v1.b.a;
				var remainingComp = governanceHistory.ih;
				var remainingCompPercentage = A2(
					$elm$core$Maybe$withDefault,
					$torreyatcitty$the_best_decimal$Decimal$zero,
					A2($torreyatcitty$the_best_decimal$Decimal$fastdiv, remainingComp, $author$project$LandingSite$Comp$initialComp));
				return {
					dt: A3($author$project$CompoundComponents$Utils$NumberFormatter$formatToDecimalPlaces, 0, false, remainingComp),
					du: $author$project$CompoundComponents$Utils$NumberFormatter$formatPercentage(remainingCompPercentage),
					dH: A3(
						$author$project$CompoundComponents$Utils$NumberFormatter$formatToDecimalPlaces,
						0,
						false,
						A2($torreyatcitty$the_best_decimal$Decimal$add, compDistribution.ip, v3CompSpeedPerDayAllMarkets)),
					e8: A3(
						$author$project$CompoundComponents$Utils$NumberFormatter$formatToDecimalPlaces,
						0,
						false,
						A2($torreyatcitty$the_best_decimal$Decimal$sub, $author$project$LandingSite$Comp$initialComp, governanceHistory.ih))
				};
			} else {
				return {dt: '—', du: '0%', dH: '—', e8: '—'};
			}
		}();
		var dailyDistribution = _v0.dH;
		var totalDistributed = _v0.e8;
		var compRemaining = _v0.dt;
		var compRemainingPercentage = _v0.du;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('Comp')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$section,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('hero')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('row')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-12 col-sm-6')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('comp-header')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$img,
															_List_fromArray(
																[
																	$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('comp-header__img'),
																	$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$src('/images/comp-icn.svg')
																]),
															_List_Nil),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('comp-header__details')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('comp-header__details__display-name')
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text(
																			$author$project$Strings$Translations$comp_distribution(userLanguage))
																		])),
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('comp-header__details__address')
																		]),
																	compAddressRow)
																]))
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-0 col-sm-1')
												]),
											_List_Nil),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-12 col-sm-5')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('comp-dist-view')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('comp-dist-view__details')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('comp-dist-view__details__group')
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$div,
																			_List_fromArray(
																				[
																					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('comp-dist-view__details__group__label')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text(
																					$author$project$Strings$Translations$daily_distribution(userLanguage))
																				])),
																			A2(
																			$elm$html$Html$h4,
																			_List_fromArray(
																				[
																					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('comp-dist-view__details__group__value')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text(dailyDistribution)
																				]))
																		])),
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('comp-dist-view__details__group')
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$div,
																			_List_fromArray(
																				[
																					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('comp-dist-view__details__group__label')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text(
																					$author$project$Strings$Translations$total_distributed(userLanguage))
																				])),
																			A2(
																			$elm$html$Html$h4,
																			_List_fromArray(
																				[
																					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('comp-dist-view__details__group__value')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text(totalDistributed)
																				]))
																		])),
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('comp-dist-view__details__group')
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$div,
																			_List_fromArray(
																				[
																					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('comp-dist-view__details__group__label')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text(
																					$author$project$Strings$Translations$remaining(userLanguage))
																				])),
																			A2(
																			$elm$html$Html$h4,
																			_List_fromArray(
																				[
																					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('comp-dist-view__details__group__value')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text(compRemaining)
																				]))
																		]))
																])),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('comp-dist-view__bar')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('comp-dist-view__bar__fill'),
																			A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$style, 'width', compRemainingPercentage)
																		]),
																	_List_Nil)
																]))
														]))
												]))
										]))
								]))
						])),
					A2(
					$elm$html$Html$section,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('comp-details')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel comp-markets-panel')
										]),
									_Utils_ap(
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__header')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$h4,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text(
																$author$project$Strings$Translations$market_distribution(userLanguage))
															]))
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('panel__labels')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-4')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$label,
																_List_Nil,
																_List_fromArray(
																	[
																		$elm$html$Html$text(
																		$author$project$Strings$Translations$enabled_markets(userLanguage))
																	]))
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-4 col-sm-2 text-right comp-column')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$label,
																_List_Nil,
																_List_fromArray(
																	[
																		$elm$html$Html$text(
																		$author$project$Strings$Translations$daily_supply(userLanguage))
																	])),
																A2(
																$elm$html$Html$img,
																_List_fromArray(
																	[
																		$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$src('/images/comp-icn.svg')
																	]),
																_List_Nil)
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-4 col-sm-2 text-right comp-column')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$label,
																_List_Nil,
																_List_fromArray(
																	[
																		$elm$html$Html$text(
																		$author$project$Strings$Translations$daily_borrow(userLanguage))
																	])),
																A2(
																$elm$html$Html$img,
																_List_fromArray(
																	[
																		$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$src('/images/comp-icn.svg')
																	]),
																_List_Nil)
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-2 text-right mobile-hide comp-column')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$label,
																_List_Nil,
																_List_fromArray(
																	[
																		$elm$html$Html$text(
																		$author$project$Strings$Translations$supply(userLanguage))
																	])),
																A2(
																$elm$html$Html$img,
																_List_fromArray(
																	[
																		$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$src('/images/comp-icn.svg')
																	]),
																_List_Nil),
																A2(
																$elm$html$Html$label,
																_List_Nil,
																_List_fromArray(
																	[
																		$elm$html$Html$text(
																		$author$project$Strings$Translations$apy(userLanguage))
																	]))
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-2 text-right mobile-hide comp-column')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$label,
																_List_Nil,
																_List_fromArray(
																	[
																		$elm$html$Html$text(
																		$author$project$Strings$Translations$borrow(userLanguage))
																	])),
																A2(
																$elm$html$Html$img,
																_List_fromArray(
																	[
																		$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$src('/images/comp-icn.svg')
																	]),
																_List_Nil),
																A2(
																$elm$html$Html$label,
																_List_Nil,
																_List_fromArray(
																	[
																		$elm$html$Html$text(
																		$author$project$Strings$Translations$apy(userLanguage))
																	]))
															]))
													]))
											]),
										_Utils_ap(v3MarketRewardsRows, latestTokens))),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('row faq-section')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-3')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$label,
													_List_fromArray(
														[
															$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('big')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$need_help(userLanguage))
														])),
													A2(
													$elm$html$Html$h2,
													_List_fromArray(
														[
															$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('faq-header')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$need_help_description(userLanguage))
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-1')
												]),
											_List_Nil),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-12 col-sm-8 faqs')
												]),
											A2(
												$elm$core$List$cons,
												A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('line')
														]),
													_List_Nil),
												$elm$core$List$concat(
													A2(
														$elm$core$List$indexedMap,
														F2(
															function (index, _v2) {
																var header = _v2.a;
																var content = _v2.b;
																return _List_fromArray(
																	[
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('accordian__item')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$div,
																				_List_fromArray(
																					[
																						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('accordian__item__header'),
																						$elm$html$Html$Events$onClick(
																						$author$project$LandingSite$Comp$ToggleAccordianAtIndex(index))
																					]),
																				_List_fromArray(
																					[
																						A2(
																						$elm$html$Html$h3,
																						_List_fromArray(
																							[
																								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('accordian__item__text')
																							]),
																						_List_fromArray(
																							[
																								$elm$html$Html$text(header)
																							])),
																						A2(
																						$elm$html$Html$div,
																						_List_fromArray(
																							[
																								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class(
																								'accordian__plus' + faqDisplayActive(index))
																							]),
																						_List_fromArray(
																							[
																								A2($elm$html$Html$span, _List_Nil, _List_Nil),
																								A2($elm$html$Html$span, _List_Nil, _List_Nil)
																							]))
																					])),
																				A2(
																				$elm$html$Html$div,
																				_List_fromArray(
																					[
																						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class(
																						'accordian__item__description' + faqDisplayActive(index))
																					]),
																				content)
																			])),
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('line')
																			]),
																		_List_Nil)
																	]);
															}),
														A2($author$project$LandingSite$Comp$accordianData, userLanguage, dailyDistribution)))))
										]))
								]))
						]))
				]));
	});
var $author$project$Strings$Translations$audited_and_verified = function (lang) {
	switch (lang) {
		case 0:
			return 'Audited and Verified';
		case 1:
			return 'Auditado y verificado';
		case 2:
			return '审核和验证';
		case 3:
			return 'Audité et vérifié';
		default:
			return '감사 및 검증';
	}
};
var $author$project$Strings$Translations$audited_and_verified_description = function (lang) {
	switch (lang) {
		case 0:
			return 'The most secure protocol for money';
		case 1:
			return 'El protocolo más seguro para el dinero.';
		case 2:
			return '最安全的金钱协议';
		case 3:
			return 'Le protocole le plus sûr pour l\'argent';
		default:
			return '암호화폐를 위한 가장 안전한 프로토콜';
	}
};
var $author$project$Strings$Translations$bug_bounty = function (lang) {
	switch (lang) {
		case 0:
			return 'Bug Bounty';
		case 1:
			return 'Bug Bounty';
		case 2:
			return 'Bug Bounty';
		case 3:
			return 'Bug Bounty';
		default:
			return 'Bug Bounty';
	}
};
var $author$project$Strings$Translations$formal_verification = function (lang) {
	switch (lang) {
		case 0:
			return 'Formal Verification';
		case 1:
			return 'Formal Verification';
		case 2:
			return 'Formal Verification';
		case 3:
			return 'Formal Verification';
		default:
			return 'Formal Verification';
	}
};
var $author$project$Strings$Translations$market_risk_assessment = function (lang) {
	switch (lang) {
		case 0:
			return 'Market Risk Assessment';
		case 1:
			return 'Evaluación de riesgo de mercado';
		case 2:
			return '市场风险评估';
		case 3:
			return 'Évaluation des risques de marché';
		default:
			return '마켓 위험도 평가';
	}
};
var $author$project$LandingSite$Home$Icon = function (a) {
	return {$: 1, a: a};
};
var $author$project$LandingSite$Home$MarketIcon = function (a) {
	return {$: 0, a: a};
};
var $author$project$CompoundComponents$DisplayCurrency$UsdValue = function (a) {
	return {$: 1, a: a};
};
var $author$project$Utils$AppLinksHelper$appBaseUrl = 'https://app.compound.finance/';
var $author$project$Utils$AppLinksHelper$appDashboardUrl = F2(
	function (marketName, network) {
		var networkNameAdjusted = function () {
			switch (network) {
				case 14:
					return 'arb';
				case 18:
					return 'basemainnet';
				default:
					return $author$project$CompoundComponents$Eth$Network$networkName(network);
			}
		}();
		var marketKey = function () {
			var _v0 = _Utils_Tuple2(marketName, networkNameAdjusted);
			if ((_v0.a === 'USDCe') && (_v0.b === 'arb')) {
				return 'usdc.e-arb';
			} else {
				return $elm$core$String$toLower(marketName + ('-' + networkNameAdjusted));
			}
		}();
		return A2(
			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href,
			2,
			_Utils_ap(
				$author$project$Utils$AppLinksHelper$appBaseUrl,
				A2(
					$elm$url$Url$Builder$relative,
					_List_Nil,
					_List_fromArray(
						[
							A2($elm$url$Url$Builder$string, 'market', marketKey)
						]))));
	});
var $author$project$Utils$AppLinksHelper$appMarketsUrl = F2(
	function (marketName, network) {
		var networkNameAdjusted = function () {
			switch (network) {
				case 14:
					return 'arb';
				case 18:
					return 'basemainnet';
				default:
					return $author$project$CompoundComponents$Eth$Network$networkName(network);
			}
		}();
		var marketKey = function () {
			var _v0 = _Utils_Tuple2(marketName, networkNameAdjusted);
			if ((_v0.a === 'USDCe') && (_v0.b === 'arb')) {
				return 'usdc.e-arb';
			} else {
				return $elm$core$String$toLower(marketName + ('-' + networkNameAdjusted));
			}
		}();
		return A2(
			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href,
			2,
			_Utils_ap(
				$author$project$Utils$AppLinksHelper$appBaseUrl,
				A2(
					$elm$url$Url$Builder$relative,
					_List_fromArray(
						['markets']),
					_List_fromArray(
						[
							A2($elm$url$Url$Builder$string, 'market', marketKey)
						]))));
	});
var $author$project$LandingSite$Home$collateralUSDValue = F3(
	function (market, collateralValueInAsset, etherPrice) {
		return (market === 3) ? A2($torreyatcitty$the_best_decimal$Decimal$mul, etherPrice, collateralValueInAsset) : collateralValueInAsset;
	});
var $author$project$CompoundComponents$DisplayCurrency$calculateEuroValue = function (usdValue) {
	var _v0 = $torreyatcitty$the_best_decimal$Decimal$fromFloat(1.21);
	if (!_v0.$) {
		var euroDivisor = _v0.a;
		return A2($torreyatcitty$the_best_decimal$Decimal$fastdiv, usdValue, euroDivisor);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$CompoundComponents$DisplayCurrency$calculateGbpValue = function (usdValue) {
	var _v0 = $torreyatcitty$the_best_decimal$Decimal$fromFloat(1.41);
	if (!_v0.$) {
		var gbpDivisor = _v0.a;
		return A2($torreyatcitty$the_best_decimal$Decimal$fastdiv, usdValue, gbpDivisor);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$CompoundComponents$Utils$NumberFormatter$formatEth = function (decimalValue) {
	var _v0 = $author$project$CompoundComponents$Utils$NumberFormatter$getSignAndAbsValue(decimalValue);
	var signString = _v0.a;
	var absoluteDecimalValue = _v0.b;
	return signString + ('Ξ' + A3($author$project$CompoundComponents$Utils$NumberFormatter$formatToDecimalPlaces, $author$project$CompoundComponents$Utils$NumberFormatter$tokenDecimalPlaces, false, absoluteDecimalValue));
};
var $author$project$CompoundComponents$Utils$NumberFormatter$formatEuro = F2(
	function (shouldTruncate, decimalValue) {
		var roundedValue = A2($torreyatcitty$the_best_decimal$Decimal$round, -2, decimalValue);
		var numberOfDecimalPlaces = function () {
			if (shouldTruncate) {
				return 0;
			} else {
				return 2;
			}
		}();
		var _v0 = $author$project$CompoundComponents$Utils$NumberFormatter$getSignAndAbsValue(roundedValue);
		var signString = _v0.a;
		var absoluteRoundedValue = _v0.b;
		return signString + ('€' + A3($author$project$CompoundComponents$Utils$NumberFormatter$formatToDecimalPlaces, numberOfDecimalPlaces, false, absoluteRoundedValue));
	});
var $author$project$CompoundComponents$Utils$NumberFormatter$formatGbp = F2(
	function (shouldTruncate, decimalValue) {
		var roundedValue = A2($torreyatcitty$the_best_decimal$Decimal$round, -2, decimalValue);
		var numberOfDecimalPlaces = function () {
			if (shouldTruncate) {
				return 0;
			} else {
				return 2;
			}
		}();
		var _v0 = $author$project$CompoundComponents$Utils$NumberFormatter$getSignAndAbsValue(roundedValue);
		var signString = _v0.a;
		var absoluteRoundedValue = _v0.b;
		return signString + ('£' + A3($author$project$CompoundComponents$Utils$NumberFormatter$formatToDecimalPlaces, numberOfDecimalPlaces, false, absoluteRoundedValue));
	});
var $author$project$CompoundComponents$Utils$NumberFormatter$formatUsd = F2(
	function (shouldTruncate, decimalValue) {
		var roundedValue = A2($torreyatcitty$the_best_decimal$Decimal$round, -2, decimalValue);
		var numberOfDecimalPlaces = function () {
			if (shouldTruncate) {
				return 0;
			} else {
				return 2;
			}
		}();
		var _v0 = $author$project$CompoundComponents$Utils$NumberFormatter$getSignAndAbsValue(roundedValue);
		var signString = _v0.a;
		var absoluteRoundedValue = _v0.b;
		return signString + ('$' + A3($author$project$CompoundComponents$Utils$NumberFormatter$formatToDecimalPlaces, numberOfDecimalPlaces, false, absoluteRoundedValue));
	});
var $author$project$CompoundComponents$DisplayCurrency$getUsdAndEthValuesToDisplay = F2(
	function (maybeEtherPrice, valueToDisplay) {
		var _v0 = _Utils_Tuple2(valueToDisplay, maybeEtherPrice);
		if (!_v0.a.$) {
			if (!_v0.b.$) {
				var ethValue_ = _v0.a.a;
				var etherPrice = _v0.b.a;
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(
						A2($torreyatcitty$the_best_decimal$Decimal$mul, etherPrice, ethValue_)),
					$elm$core$Maybe$Just(ethValue_));
			} else {
				return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
			}
		} else {
			if (!_v0.b.$) {
				var usdValue_ = _v0.a.a;
				var etherPrice_ = _v0.b.a;
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(usdValue_),
					A2($torreyatcitty$the_best_decimal$Decimal$fastdiv, usdValue_, etherPrice_));
			} else {
				var usdValue_ = _v0.a.a;
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(usdValue_),
					$elm$core$Maybe$Nothing);
			}
		}
	});
var $author$project$CompoundComponents$DisplayCurrency$formatDisplayCurrency = F4(
	function (truncated, displayCurrency, maybeEtherPrice, valueToDisplay) {
		var _v0 = A2($author$project$CompoundComponents$DisplayCurrency$getUsdAndEthValuesToDisplay, maybeEtherPrice, valueToDisplay);
		var usdValue = _v0.a;
		var ethValue = _v0.b;
		switch (displayCurrency) {
			case 0:
				return A2($elm$core$Maybe$map, $author$project$CompoundComponents$Utils$NumberFormatter$formatEth, ethValue);
			case 1:
				return A2(
					$elm$core$Maybe$map,
					$author$project$CompoundComponents$Utils$NumberFormatter$formatUsd(truncated),
					usdValue);
			case 2:
				return A2(
					$elm$core$Maybe$map,
					$author$project$CompoundComponents$Utils$NumberFormatter$formatGbp(truncated),
					A2($elm$core$Maybe$andThen, $author$project$CompoundComponents$DisplayCurrency$calculateGbpValue, usdValue));
			default:
				return A2(
					$elm$core$Maybe$map,
					$author$project$CompoundComponents$Utils$NumberFormatter$formatEuro(truncated),
					A2($elm$core$Maybe$andThen, $author$project$CompoundComponents$DisplayCurrency$calculateEuroValue, usdValue));
		}
	});
var $author$project$CompoundComponents$Utils$NumberFormatter$formatEthToZeroDecimals = function (decimalValue) {
	var _v0 = $author$project$CompoundComponents$Utils$NumberFormatter$getSignAndAbsValue(decimalValue);
	var signString = _v0.a;
	var absoluteDecimalValue = _v0.b;
	return signString + ('Ξ' + A3($author$project$CompoundComponents$Utils$NumberFormatter$formatToDecimalPlaces, 0, false, absoluteDecimalValue));
};
var $author$project$CompoundComponents$Utils$NumberFormatter$formatEuroInThousandsOrMillions = function (decimalValue) {
	return A2($author$project$CompoundComponents$Utils$NumberFormatter$commonFormatInThousandsOrMillions, '€', decimalValue);
};
var $author$project$CompoundComponents$Utils$NumberFormatter$formatGbpInThousandsOrMillions = function (decimalValue) {
	return A2($author$project$CompoundComponents$Utils$NumberFormatter$commonFormatInThousandsOrMillions, '£', decimalValue);
};
var $author$project$CompoundComponents$Utils$NumberFormatter$formatUsdInThousandsOrMillions = function (decimalValue) {
	return A2($author$project$CompoundComponents$Utils$NumberFormatter$commonFormatInThousandsOrMillions, '$', decimalValue);
};
var $author$project$CompoundComponents$DisplayCurrency$formatMarketSize = F3(
	function (displayCurrency, maybeEtherPrice, valueToDisplay) {
		var _v0 = A2($author$project$CompoundComponents$DisplayCurrency$getUsdAndEthValuesToDisplay, maybeEtherPrice, valueToDisplay);
		var usdValue = _v0.a;
		var ethValue = _v0.b;
		switch (displayCurrency) {
			case 0:
				return A2($elm$core$Maybe$map, $author$project$CompoundComponents$Utils$NumberFormatter$formatEthToZeroDecimals, ethValue);
			case 1:
				return A2($elm$core$Maybe$map, $author$project$CompoundComponents$Utils$NumberFormatter$formatUsdInThousandsOrMillions, usdValue);
			case 2:
				return A2(
					$elm$core$Maybe$map,
					$author$project$CompoundComponents$Utils$NumberFormatter$formatGbpInThousandsOrMillions,
					A2($elm$core$Maybe$andThen, $author$project$CompoundComponents$DisplayCurrency$calculateGbpValue, usdValue));
			default:
				return A2(
					$elm$core$Maybe$map,
					$author$project$CompoundComponents$Utils$NumberFormatter$formatEuroInThousandsOrMillions,
					A2($elm$core$Maybe$andThen, $author$project$CompoundComponents$DisplayCurrency$calculateEuroValue, usdValue));
		}
	});
var $author$project$CompoundComponents$DisplayCurrency$getPlaceholderPrefCurrency = function (displayCurrency) {
	switch (displayCurrency) {
		case 0:
			return 'Ξ –';
		case 1:
			return '$ –';
		case 2:
			return '£ –';
		default:
			return '€ –';
	}
};
var $elm$core$String$append = _String_append;
var $author$project$LandingSite$Home$marketPanel = F7(
	function (marketName, network, _v0, iconType, _v1, _v2, dollarFormatter) {
		var appHref = _v0.cx;
		var marketHref = _v0.cU;
		var marketCollateral = _v1.a;
		var marketDebt = _v1.b;
		var totalCollateral = _v2.a;
		var totalDebt = _v2.b;
		var percentage = F2(
			function (market, total) {
				return function (percent) {
					return A2($elm$core$String$append, percent, '%');
				}(
					$torreyatcitty$the_best_decimal$Decimal$toString(
						A2(
							$torreyatcitty$the_best_decimal$Decimal$round,
							0,
							A2(
								$elm$core$Maybe$withDefault,
								$torreyatcitty$the_best_decimal$Decimal$zero,
								A2(
									$torreyatcitty$the_best_decimal$Decimal$fastdiv,
									A2(
										$torreyatcitty$the_best_decimal$Decimal$mul,
										$torreyatcitty$the_best_decimal$Decimal$fromInt(100),
										market),
									total)))));
			});
		var icon = function () {
			if (!iconType.$) {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('icon icon--' + 'V2')
						]),
					_List_Nil);
			} else {
				var asset = iconType.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('icon icon--' + asset)
						]),
					_List_Nil);
			}
		}();
		var _v3 = function () {
			switch (network) {
				case 1:
					return _Utils_Tuple2('ETH', 'Ethereum');
				case 12:
					return _Utils_Tuple2('MATIC', 'Polygon');
				case 14:
					return _Utils_Tuple2('ARBITRUM', 'Arbitrum');
				case 18:
					return _Utils_Tuple2('BASE', 'Base');
				default:
					return _Utils_Tuple2('ETH', 'Ethereum');
			}
		}();
		var chainIconKey = _v3.a;
		var chainFullName = _v3.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('market-panel')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('market-panel__info')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('market-panel__market')
								]),
							_List_fromArray(
								[
									icon,
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('icon icon--' + chainIconKey)
										]),
									_List_Nil),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(marketName),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('market-panel__market__network'),
													A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$style, 'margin', '0 0.25rem')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('| ' + chainFullName)
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('market-panel__bar-holder market-panel__bar-holder--collateral')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('bar')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('bar__fill'),
													A2(
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$style,
													'width',
													A2(percentage, marketCollateral, totalCollateral))
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('market-panel__bar-holder__text')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											dollarFormatter(
												$author$project$CompoundComponents$DisplayCurrency$UsdValue(marketCollateral)))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('market-panel__bar-holder market-panel__bar-holder--debt')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('bar')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('bar__fill'),
													A2(
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$style,
													'width',
													A2(percentage, marketDebt, totalDebt))
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('market-panel__bar-holder__text')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											dollarFormatter(
												$author$project$CompoundComponents$DisplayCurrency$UsdValue(marketDebt)))
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('market-panel__buttons')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							A2(
								$elm$core$List$cons,
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('market-panel__button'),
								appHref),
							_List_fromArray(
								[
									$elm$html$Html$text('App')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('market-panel__buttons__divider')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$a,
							A2(
								$elm$core$List$cons,
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('market-panel__button'),
								marketHref),
							_List_fromArray(
								[
									$elm$html$Html$text('Market')
								]))
						]))
				]));
	});
var $author$project$Utils$LandingPricesHelper$usdPerEth = function (tokens) {
	var _v0 = $elm$core$List$head(
		A2(
			$elm$core$List$filter,
			function (token) {
				return token.kp === 'ETH';
			},
			tokens));
	if (!_v0.$) {
		var eth = _v0.a;
		return eth.fd;
	} else {
		return $torreyatcitty$the_best_decimal$Decimal$one;
	}
};
var $author$project$V3Api$Decoders$v3MarketBaseSymbol = function (market) {
	switch (market) {
		case 0:
			return 'USDC';
		case 1:
			return 'USDCe';
		case 2:
			return 'USDbC';
		default:
			return 'WETH';
	}
};
var $author$project$CompoundComponents$Utils$DigitAnimatorHelper$valueFormattedStringToRandomDigits = function (totalValueFormattedString) {
	return A2(
		$elm$core$List$map,
		function (character) {
			var charAsString = $elm$core$String$fromChar(character);
			var _v0 = function () {
				var _v1 = $elm$core$String$toInt(charAsString);
				if (!_v1.$) {
					var digitAsInt = _v1.a;
					var digitsRange = A2(
						$elm$core$List$map,
						$elm$core$String$fromInt,
						$elm$core$List$reverse(
							A2($elm$core$List$range, 0, digitAsInt)));
					var digitClass = function () {
						switch (digitAsInt) {
							case 2:
								return ' digit-thousand';
							case 7:
								return ' digit-thousand';
							case 9:
								return ' digit-hundred';
							case 1:
								return ' digit-hundred';
							case 4:
								return ' digit-ten';
							case 5:
								return ' digit-ten';
							default:
								return ' digit-thousand';
						}
					}();
					return _Utils_Tuple3(
						'',
						digitClass,
						A2($elm$core$String$join, ' ', digitsRange));
				} else {
					return ((character === ',') || (character === '.')) ? _Utils_Tuple3(' comma', '', charAsString) : _Utils_Tuple3('', '', charAsString);
				}
			}();
			var digitExtraClass = _v0.a;
			var containerExtraClass = _v0.b;
			var textContent = _v0.c;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('digit' + digitExtraClass)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('digit-holder' + (containerExtraClass + ' active'))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(textContent)
							]))
					]));
		},
		$elm$core$String$toList(totalValueFormattedString));
};
var $author$project$LandingSite$Home$marketsHero = F3(
	function (userLanguage, latestTokens, v3MarketSummaries) {
		var v2TotalDebtUsd = A3(
			$elm$core$List$foldl,
			$torreyatcitty$the_best_decimal$Decimal$add,
			$torreyatcitty$the_best_decimal$Decimal$zero,
			A2(
				$elm$core$List$map,
				function ($) {
					return $.kc;
				},
				latestTokens));
		var v2TotalCollateralUsd = A3(
			$elm$core$List$foldl,
			$torreyatcitty$the_best_decimal$Decimal$add,
			$torreyatcitty$the_best_decimal$Decimal$zero,
			A2(
				$elm$core$List$map,
				function ($) {
					return $.ki;
				},
				latestTokens));
		var v2MarketPanelUrls = {cx: $author$project$Utils$AppLinksHelper$v2AppHref, cU: $author$project$Utils$AppLinksHelper$v2MarketsHref};
		var numberOfV2Markets = A2(
			$elm$core$Basics$max,
			0,
			$elm$core$List$length(latestTokens) - 1);
		var numberOfMarketsText = $elm$core$String$fromInt(
			1 + $elm$core$List$length(v3MarketSummaries));
		var marketsText = $elm$core$String$toLower(
			$author$project$Strings$Translations$markets(userLanguage));
		var marketTotalDollarFormatter = function (valueUsd) {
			return A2(
				$elm$core$Maybe$withDefault,
				$author$project$CompoundComponents$DisplayCurrency$getPlaceholderPrefCurrency(1),
				A4(
					$author$project$CompoundComponents$DisplayCurrency$formatDisplayCurrency,
					true,
					1,
					$elm$core$Maybe$Nothing,
					$author$project$CompoundComponents$DisplayCurrency$UsdValue(valueUsd)));
		};
		var totalValueDigitsView = function (totalMarketsValueUsd) {
			var totalValueFormattedString = marketTotalDollarFormatter(totalMarketsValueUsd);
			var digitsListView = $author$project$CompoundComponents$Utils$DigitAnimatorHelper$valueFormattedStringToRandomDigits(totalValueFormattedString);
			return A2($elm$html$Html$span, _List_Nil, digitsListView);
		};
		var marketPanelDollarFormatter = function (valueType) {
			return A2(
				$elm$core$Maybe$withDefault,
				$author$project$CompoundComponents$DisplayCurrency$getPlaceholderPrefCurrency(1),
				A3($author$project$CompoundComponents$DisplayCurrency$formatMarketSize, 1, $elm$core$Maybe$Nothing, valueType));
		};
		var etherPrice = $author$project$Utils$LandingPricesHelper$usdPerEth(latestTokens);
		var v3MarketSummariesFlatDeterministic = A2(
			$elm$core$List$sortBy,
			function (_v3) {
				var market = _v3.a;
				var summary = _v3.c;
				return $torreyatcitty$the_best_decimal$Decimal$toFloat(
					A3($author$project$LandingSite$Home$collateralUSDValue, market, summary.e7, etherPrice));
			},
			v3MarketSummaries);
		var v3TotalCollateralUsd = A3(
			$elm$core$List$foldl,
			$torreyatcitty$the_best_decimal$Decimal$add,
			$torreyatcitty$the_best_decimal$Decimal$zero,
			A2(
				$elm$core$List$map,
				function (_v2) {
					var market = _v2.a;
					var summaryData = _v2.c;
					return A3($author$project$LandingSite$Home$collateralUSDValue, market, summaryData.e7, etherPrice);
				},
				v3MarketSummaries));
		var totalCollateral = A2($torreyatcitty$the_best_decimal$Decimal$add, v2TotalCollateralUsd, v3TotalCollateralUsd);
		var v3TotalDebtUsd = A3(
			$elm$core$List$foldl,
			$torreyatcitty$the_best_decimal$Decimal$add,
			$torreyatcitty$the_best_decimal$Decimal$zero,
			A2(
				$elm$core$List$map,
				function (_v1) {
					var market = _v1.a;
					var summaryData = _v1.c;
					return A3($author$project$LandingSite$Home$collateralUSDValue, market, summaryData.hr, etherPrice);
				},
				v3MarketSummaries));
		var totalDebt = A2($torreyatcitty$the_best_decimal$Decimal$add, v2TotalDebtUsd, v3TotalDebtUsd);
		var v3Panels = A2(
			$elm$core$List$map,
			function (_v0) {
				var market = _v0.a;
				var network = _v0.b;
				var assetTotalSummary = _v0.c;
				var marketHref = A2(
					$author$project$Utils$AppLinksHelper$appMarketsUrl,
					$author$project$V3Api$Decoders$v3MarketBaseSymbol(market),
					network);
				var appHref = A2(
					$author$project$Utils$AppLinksHelper$appDashboardUrl,
					$author$project$V3Api$Decoders$v3MarketBaseSymbol(market),
					network);
				return A7(
					$author$project$LandingSite$Home$marketPanel,
					$author$project$V3Api$Decoders$v3MarketSymbol(market),
					network,
					{cx: appHref, cU: marketHref},
					$author$project$LandingSite$Home$Icon(
						(market === 3) ? 'WETH' : $author$project$V3Api$Decoders$v3MarketBaseSymbol(market)),
					_Utils_Tuple2(
						A3($author$project$LandingSite$Home$collateralUSDValue, market, assetTotalSummary.e7, etherPrice),
						A3($author$project$LandingSite$Home$collateralUSDValue, market, assetTotalSummary.hr, etherPrice)),
					_Utils_Tuple2(totalCollateral, totalDebt),
					marketPanelDollarFormatter);
			},
			v3MarketSummariesFlatDeterministic);
		var marketPanels = (!$elm$core$List$isEmpty(v3Panels)) ? _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('market-panels')
					]),
				_Utils_ap(
					_List_fromArray(
						[
							A7(
							$author$project$LandingSite$Home$marketPanel,
							'V2',
							1,
							v2MarketPanelUrls,
							$author$project$LandingSite$Home$MarketIcon(numberOfV2Markets),
							_Utils_Tuple2(v2TotalCollateralUsd, v2TotalDebtUsd),
							_Utils_Tuple2(totalCollateral, totalDebt),
							marketPanelDollarFormatter)
						]),
					$elm$core$List$reverse(v3Panels))),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('market-panels')
					]),
				_Utils_ap(
					_List_fromArray(
						[
							A7(
							$author$project$LandingSite$Home$marketPanel,
							'V2',
							1,
							v2MarketPanelUrls,
							$author$project$LandingSite$Home$MarketIcon(numberOfV2Markets),
							_Utils_Tuple2(v2TotalCollateralUsd, v2TotalDebtUsd),
							_Utils_Tuple2(totalCollateral, totalDebt),
							marketPanelDollarFormatter)
						]),
					$elm$core$List$reverse(v3Panels)))
			]) : _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('loading-market-panel')
					]),
				_List_Nil)
			]);
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('container-large header-line')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h1,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('totals totals--debt')
									]),
								_List_fromArray(
									[
										totalValueDigitsView(totalDebt)
									]))
							])),
						A2(
						$elm$html$Html$h1,
						_List_fromArray(
							[
								A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$style, 'margin-left', '0.5rem')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('of borrowing backed by')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('container-large header-line')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h1,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('totals')
									]),
								_List_fromArray(
									[
										totalValueDigitsView(totalCollateral)
									]))
							])),
						A2(
						$elm$html$Html$h1,
						_List_fromArray(
							[
								A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$style, 'margin-left', '0.5rem')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('of collateral across'),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('totals'),
										A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$style, 'margin', '0 0.5rem')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(numberOfMarketsText)
									])),
								$elm$html$Html$text(marketsText)
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('market-container')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('market-carousel')
							]),
						marketPanels),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('mask')
							]),
						_List_Nil)
					]))
			]);
	});
var $author$project$LandingSite$Home$Earn = 0;
var $author$project$LandingSite$Home$Manage = 1;
var $author$project$LandingSite$Home$SelectProjectCategory = function (a) {
	return {$: 2, a: a};
};
var $author$project$LandingSite$Home$Tools = 2;
var $author$project$Strings$Translations$anchorage = function (lang) {
	switch (lang) {
		case 0:
			return 'Anchorage';
		case 1:
			return 'Anchorage';
		case 2:
			return 'Anchorage';
		case 3:
			return 'Anchorage';
		default:
			return 'Anchorage';
	}
};
var $author$project$Strings$Translations$anchorage_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Safe crypto custody complete with trading, staking, and Compound governance.';
		case 1:
			return 'Custodia de cifrado segura completa con comercio, participación y gobierno compuesto.';
		case 2:
			return '安全的加密货币托管，包括交易，抵押和复合治理。';
		case 3:
			return 'Conservation sécurisée des cryptos avec négociation, jalonnement et gouvernance Compounde.';
		default:
			return '거래, 스테이킹, Compound 거버넌스를 지원하는 안전한 암호화폐 보관 서비스입니다.';
	}
};
var $author$project$Strings$Translations$ankr = function (lang) {
	switch (lang) {
		case 0:
			return 'Ankr';
		case 1:
			return 'Ankr';
		case 2:
			return 'Ankr';
		case 3:
			return 'Ankr';
		default:
			return 'Ankr';
	}
};
var $author$project$Strings$Translations$ankr_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Easily integrate Compound with a RESTful API.';
		case 1:
			return 'Integre fácilmente Compound con una API RESTful.';
		case 2:
			return '轻松将Compound与RESTful API集成。';
		case 3:
			return 'Intégrez facilement Compound avec une API RESTful.';
		default:
			return 'Compound를 RESTful API와 쉽게 통합할 수 있습니다.';
	}
};
var $author$project$Strings$Translations$argent = function (lang) {
	switch (lang) {
		case 0:
			return 'Argent';
		case 1:
			return 'Argent';
		case 2:
			return 'Argent';
		case 3:
			return 'Argent';
		default:
			return 'Argent';
	}
};
var $author$project$Strings$Translations$argent_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Easily earn interest & invest; securely store & send. No seed phrase. No gas.';
		case 1:
			return 'Obtenga fácilmente intereses e invierta; almacenar y enviar de forma segura. Sin frase simiente. No hay gasolina.';
		case 2:
			return '轻松赚取利息并进行投资；安全地存储和发送。没有种子词组。没气。';
		case 3:
			return 'Gagnez facilement des intérêts et investissez; stocker et envoyer en toute sécurité. Aucune phrase de départ. Pas de gaz.';
		default:
			return '간편한 이자 수익 & 투자; 안전한 보관 & 전송. 시드 구문과 가스 비용은 필요 없습니다.';
	}
};
var $author$project$Strings$Translations$binance = function (lang) {
	switch (lang) {
		case 0:
			return 'Binance';
		case 1:
			return 'Binance';
		case 2:
			return 'Binance';
		case 3:
			return 'Binance';
		default:
			return 'Binance';
	}
};
var $author$project$Strings$Translations$binance_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Leading crypto exchange, with DeFi staking powered by Compound.';
		case 1:
			return 'Intercambio de criptografía líder, con apuesta DeFi impulsada por Compound.';
		case 2:
			return '领先的加密货币交换，由Compound提供支持的DeFi股份交易。';
		case 3:
			return 'Premier échange crypto, avec le staking DeFi alimenté par Compound.';
		default:
			return '업계 최고 수준의 암호 화폐 거래소 중 하나이며 Compound를 활용한 DeFi 스테이킹 서비스를 제공합니다.';
	}
};
var $author$project$Strings$Translations$bitgo = function (lang) {
	switch (lang) {
		case 0:
			return 'Bitgo';
		case 1:
			return 'Bitgo';
		case 2:
			return 'Bitgo';
		case 3:
			return 'Bitgo';
		default:
			return 'Bitgo';
	}
};
var $author$project$Strings$Translations$bitgo_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Full-service crypto custodian, with support for both cTokens and COMP.';
		case 1:
			return 'Servicio completo de cripto custodio, con soporte para cTokens y COMP.';
		case 2:
			return '全方位服务的加密货币托管人，同时支持cToken和COMP。';
		case 3:
			return 'Dépositaire de cryptographie à service complet, avec prise en charge des cTokens et COMP.';
		default:
			return 'cToken과 COMP를 모두 지원하는 풀 서비스 암호화 관리자.';
	}
};
var $author$project$Strings$Translations$bprotocol = function (lang) {
	switch (lang) {
		case 0:
			return 'B.Protocol';
		case 1:
			return 'B.Protocol';
		case 2:
			return 'B.Protocol';
		case 3:
			return 'B.Protocol';
		default:
			return 'B.Protocol';
	}
};
var $author$project$Strings$Translations$bprotocol_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Import your Compound position for improved liquidation economics.';
		case 1:
			return 'Importe su posición compuesta para mejorar la economía de liquidación.';
		case 2:
			return '导入您的复合头寸，以提高清算经济性。';
		case 3:
			return 'Importez votre position composée pour une meilleure économie de liquidation.';
		default:
			return '청산 경제성을 개선하기 위해 컴파운드 포지션을 가져옵니다.';
	}
};
var $author$project$Strings$Translations$coinbase_custody = function (lang) {
	switch (lang) {
		case 0:
			return 'Coinbase Custody';
		case 1:
			return 'Coinbase Custody';
		case 2:
			return 'Coinbase Custody';
		case 3:
			return 'Coinbase Custody';
		default:
			return 'Coinbase Custody';
	}
};
var $author$project$Strings$Translations$coinbase_custody_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Secure custody for COMP & cTokens, and native support for Compound governance.';
		case 1:
			return 'Custodia segura para COMP y cTokens, y soporte nativo para la gobernanza compuesta.';
		case 2:
			return '对COMP和cToken的安全保管，以及对化合物治理的本地支持。';
		case 3:
			return 'Garde sécurisée pour COMP & cTokens et prise en charge native de la gouvernance de Compound.';
		default:
			return 'COMP & cTokens를 안전하게 보관할 수 있고, Compound 거버넌스를 지원합니다.';
	}
};
var $author$project$Strings$Translations$cointracker = function (lang) {
	switch (lang) {
		case 0:
			return 'Cointracker';
		case 1:
			return 'Cointracker';
		case 2:
			return 'Cointracker';
		case 3:
			return 'Cointracker';
		default:
			return 'Cointracker';
	}
};
var $author$project$Strings$Translations$cointracker_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Portfolio tracker and tax calculator tool, import your Compound activity.';
		case 1:
			return 'Herramienta de seguimiento de cartera y calculadora de impuestos, importe su actividad compuesta.';
		case 2:
			return '投资组合跟踪器和税收计算器工具，导入您的复合活动。';
		case 3:
			return 'Outil de suivi de portefeuille et de calculateur de taxes, importez votre activité Compounde.';
		default:
			return 'Compound 활동을 가져올 수 있으며, 포트폴리오 추적기 및 세금 계산기 도구를 제공합니다.';
	}
};
var $author$project$Strings$Translations$comp_vote = function (lang) {
	switch (lang) {
		case 0:
			return 'Comp.Vote';
		case 1:
			return 'Comp.Vote';
		case 2:
			return 'Comp.Vote';
		case 3:
			return 'Comp.Vote';
		default:
			return 'Comp.Vote';
	}
};
var $author$project$Strings$Translations$comp_vote_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Gas-free delegation and voting for COMP token-holders.';
		case 1:
			return 'Delegación libre de gas y votación para poseedores de tokens COMP.';
		case 2:
			return '无气体授权和COMP代币持有者投票。';
		case 3:
			return 'Délégation sans gaz et vote pour les détenteurs de jetons COMP.';
		default:
			return 'COMP 토큰 보유자를위한 가스없는 위임 및 투표.';
	}
};
var $author$project$Strings$Translations$compound_education = function (lang) {
	switch (lang) {
		case 0:
			return 'Compound Academy';
		case 1:
			return 'Compound Academy';
		case 2:
			return 'Compound Academy';
		case 3:
			return 'Compound Academy';
		default:
			return 'Compound Academy';
	}
};
var $author$project$Strings$Translations$compound_education_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Elevate your knowledge of Compound III with an intuitive and engaging education platform.';
		case 1:
			return 'Eleve su conocimiento de Compound III con una plataforma educativa intuitiva y atractiva.';
		case 2:
			return '通过直观且引人入胜的教育平台提升您对 Compound III 的了解。';
		case 3:
			return 'Améliorez vos connaissances sur le composé III avec une plate-forme éducative intuitive et attrayante.';
		default:
			return '직관적이고 매력적인 교육 플랫폼으로 Compound III에 대한 지식을 높이십시오.';
	}
};
var $author$project$Bootstrap$Carousel$Config = $elm$core$Basics$identity;
var $author$project$Bootstrap$Carousel$config = F2(
	function (toMsg, attributes) {
		return {ft: attributes, dz: false, d1: false, c7: _List_Nil, cn: toMsg};
	});
var $author$project$Bootstrap$Carousel$SlideInternal$Config = $elm$core$Basics$identity;
var $author$project$Bootstrap$Carousel$Slide$config = F2(
	function (attributes, content) {
		return {ft: attributes, fC: $elm$core$Maybe$Nothing, ij: content};
	});
var $author$project$Strings$Translations$crypto_dot_com = function (lang) {
	switch (lang) {
		case 0:
			return 'Crypto.com';
		case 1:
			return 'Crypto.com';
		case 2:
			return 'Crypto.com';
		case 3:
			return 'Crypto.com';
		default:
			return 'Crypto.com';
	}
};
var $author$project$Strings$Translations$crypto_dot_com_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Interact with Compound through a native integration in the Crypto.com DeFi wallet.';
		case 1:
			return 'Interactúe directamente con Compound a través de una integración nativa en la billetera Crypto.com DeFi.';
		case 2:
			return '通过 Crypto.com DeFi 钱包中的原生集成直接与 Compound 交互。';
		case 3:
			return 'Interagissez directement avec Compound via une intégration native dans le portefeuille Crypto.com DeFi.';
		default:
			return 'Crypto.com DeFi 지갑의 기본 통합을 통해 Compound와 직접 상호 작용합니다.';
	}
};
var $author$project$Bootstrap$Carousel$SlideInternal$Custom = function (a) {
	return {$: 1, a: a};
};
var $author$project$Bootstrap$Carousel$Slide$customContent = function (html) {
	return $author$project$Bootstrap$Carousel$SlideInternal$Custom(
		{iO: html});
};
var $author$project$Strings$Translations$defi_saver = function (lang) {
	switch (lang) {
		case 0:
			return 'DeFi Saver';
		case 1:
			return 'DeFi Saver';
		case 2:
			return 'DeFi Saver';
		case 3:
			return 'DeFi Saver';
		default:
			return 'DeFi Saver';
	}
};
var $author$project$Strings$Translations$defi_saver_description = function (lang) {
	switch (lang) {
		case 0:
			return 'An alternative Compound dashboard with automated portfolio management.';
		case 1:
			return 'Un panel compuesto alternativo con gestión de cartera automatizada.';
		case 2:
			return '具有自动投资组合管理的替代化合物仪表板。';
		case 3:
			return 'Un tableau de bord Compound alternatif avec gestion de portefeuille automatisée.';
		default:
			return '자동화된 포트폴리오 관리 기능이 있는 Compound 대시보드.';
	}
};
var $author$project$Strings$Translations$eidoo = function (lang) {
	switch (lang) {
		case 0:
			return 'Eidoo';
		case 1:
			return 'Eidoo';
		case 2:
			return 'Eidoo';
		case 3:
			return 'Eidoo';
		default:
			return 'Eidoo';
	}
};
var $author$project$Strings$Translations$eidoo_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Buy, sell, trade and earn in-app, or pay with Eidoo Card worldwide.';
		case 1:
			return 'Descubra DeFi a pedido. Compre, venda, negocie y gane en la aplicación, o pague con Eidoo Card en todo el mundo.';
		case 2:
			return '按需发现DeFi。在全球范围内使用Eidoo卡购买，出售，交易和赚取应用内收入或付款。';
		case 3:
			return 'Découvrez DeFi sur demande. Achetez, vendez, échangez et gagnez dans l\'application ou payez avec la carte Eidoo dans le monde entier.';
		default:
			return '전 세계에서 Eidoo 카드를 통해 구매, 판매, 거래, 결제, 앱 내 이자 수익 등의 서비스를 이용할 수 있습니다.';
	}
};
var $author$project$Strings$Translations$enzyme = function (lang) {
	switch (lang) {
		case 0:
			return 'Enzyme';
		case 1:
			return 'Enzyme';
		case 2:
			return 'Enzyme';
		case 3:
			return 'Enzyme';
		default:
			return 'Enzyme';
	}
};
var $author$project$Strings$Translations$enzyme_description = function (lang) {
	switch (lang) {
		case 0:
			return 'A fast & cost-effective way to build, scale and monetize investment strategies.';
		case 1:
			return 'Una forma rápida y rentable de crear, escalar y monetizar estrategias de inversión.';
		case 2:
			return '快速，经济高效地建立，扩展和货币化投资策略的方法。';
		case 3:
			return 'Un moyen rapide et économique de créer, de mettre à l\'échelle et de monétiser des stratégies d\'investissement.';
		default:
			return '투자 전략을 구축, 확장 및 수익 화하는 빠르고 비용 효율적인 방법입니다.';
	}
};
var $author$project$Strings$Translations$exodus = function (lang) {
	switch (lang) {
		case 0:
			return 'Exodus';
		case 1:
			return 'Exodus';
		case 2:
			return 'Exodus';
		case 3:
			return 'Exodus';
		default:
			return 'Exodus';
	}
};
var $author$project$Strings$Translations$exodus_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Secure, Manage, and Exchange on desktop, mobile and hardware wallets.';
		case 1:
			return 'Asegure, administre e intercambie en billeteras de escritorio, móviles y hardware.';
		case 2:
			return '在桌面，移动和硬件钱包上进行安全，管理和交换。';
		case 3:
			return 'Sécurisez, gérez et échangez sur les portefeuilles de bureau, mobiles et matériels.';
		default:
			return '데스크탑, 모바일, 하드웨어 지갑에서 보안, 관리, 거래 기능을 제공합니다.';
	}
};
var $author$project$Strings$Translations$fireblocks = function (lang) {
	switch (lang) {
		case 0:
			return 'Fireblocks';
		case 1:
			return 'Fireblocks';
		case 2:
			return 'Fireblocks';
		case 3:
			return 'Fireblocks';
		default:
			return 'Fireblocks';
	}
};
var $author$project$Strings$Translations$fireblocks_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Safely move assets between exchanges, wallets & Compound.';
		case 1:
			return 'Mueva activos de manera segura entre intercambios, billeteras y compuestos.';
		case 2:
			return '在交易所，钱包和化合物之间安全地转移资产.';
		case 3:
			return 'Déplacer en toute sécurité des actifs entre bourses, portefeuilles et Compounds.';
		default:
			return '거래소, 지갑 & Compound 사이에 자산을 안전하게 이동시킬 수 있습니다.';
	}
};
var $author$project$Strings$Translations$imToken = function (lang) {
	switch (lang) {
		case 0:
			return 'imToken';
		case 1:
			return 'imToken';
		case 2:
			return 'imToken';
		case 3:
			return 'imToken';
		default:
			return 'imToken';
	}
};
var $author$project$Strings$Translations$imToken_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Fully-featured crypto wallet mobile app, integrating Compound.';
		case 1:
			return 'Aplicación móvil de billetera criptográfica con todas las funciones, integrando Compuesto.';
		case 2:
			return '功能齐全的加密钱包移动应用程序，集成了Compound。';
		case 3:
			return 'Application mobile de portefeuille cryptographique complète, intégrant Compound.';
		default:
			return 'Compound가 통합된 다양한 기능을 제공하는 암호화폐 모바일 앱입니다.';
	}
};
var $author$project$Strings$Translations$instadapp = function (lang) {
	switch (lang) {
		case 0:
			return 'InstaDapp';
		case 1:
			return 'InstaDapp';
		case 2:
			return 'InstaDapp';
		case 3:
			return 'InstaDapp';
		default:
			return 'InstaDapp';
	}
};
var $author$project$Strings$Translations$instadapp_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Supply, borrow, and migrate MakerDAO CDPs into Compound.';
		case 1:
			return 'Suministre, pida prestado y migre los CDP de MakerDAO a Compound.';
		case 2:
			return '供应，借贷并将 MakerDAO CDPs 迁移到 Compound 中。';
		case 3:
			return 'Fournir, emprunter et migrer des CDP MakerDAO vers Compound.';
		default:
			return '공급과 대출에 참여하고, MakerDAO CDP를 Compound로 이전하세요.';
	}
};
var $author$project$Strings$Translations$ledger = function (lang) {
	switch (lang) {
		case 0:
			return 'Ledger';
		case 1:
			return 'Ledger';
		case 2:
			return 'Ledger';
		case 3:
			return 'Ledger';
		default:
			return 'Ledger';
	}
};
var $author$project$Strings$Translations$ledger_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Access Compound directly from the security of your Ledger hardware wallet.';
		case 1:
			return 'Acceda a Compuesto directamente desde la seguridad de su billetera de hardware Ledger.';
		case 2:
			return '直接从Ledger硬件钱包的安全性访问Compound。';
		case 3:
			return 'Accédez à Compound directement depuis la sécurité de votre portefeuille matériel Ledger.';
		default:
			return 'Ledger 하드웨어 지갑의 보안에서 직접 Compound에 액세스하십시오.';
	}
};
var $author$project$Strings$Translations$okx = function (lang) {
	switch (lang) {
		case 0:
			return 'OKX';
		case 1:
			return 'OKX';
		case 2:
			return 'OKX';
		case 3:
			return 'OKX';
		default:
			return 'OKX';
	}
};
var $author$project$Strings$Translations$okx_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Earn interest and borrow assets on one of the most popular crypto exchanges.';
		case 1:
			return 'Gana intereses y toma prestados activos en uno de los intercambios de cifrado más populares.';
		case 2:
			return '在最受欢迎的加密货币交易所之一上赚取利息并借入资产。';
		case 3:
			return 'Gagnez des intérêts et empruntez des actifs sur l\'un des échanges cryptographiques les plus populaires.';
		default:
			return '가장 인기있는 암호 화폐 거래소 중 하나인 okx에서 이자 수익 혜택과 함께 자산 대출 서비스를 이용해보세요.';
	}
};
var $author$project$Strings$Translations$pool_together = function (lang) {
	switch (lang) {
		case 0:
			return 'Pool Together';
		case 1:
			return 'Pool Together';
		case 2:
			return 'Pool Together';
		case 3:
			return 'Pool Together';
		default:
			return 'Pool Together';
	}
};
var $author$project$Strings$Translations$pool_together_description = function (lang) {
	switch (lang) {
		case 0:
			return 'A no-loss lottery utilizing the interest earned in Compound as the prize.';
		case 1:
			return 'Una lotería sin pérdidas que utiliza el interés ganado en Compound como premio.';
		case 2:
			return '以 Compound 利息为奖品的无损失彩票。';
		case 3:
			return 'Une loterie sans perte utilisant les intérêts gagnés dans le Compound comme prix.';
		default:
			return 'Compound에서 발생한 이자를 상금으로 제공하는 무손실 복권 서비스를 제공합니다.';
	}
};
var $author$project$Strings$Translations$product_showcase_earn = function (lang) {
	switch (lang) {
		case 0:
			return 'Earn';
		case 1:
			return 'Ganar';
		case 2:
			return '赚';
		case 3:
			return 'Gagner';
		default:
			return '이자 수익';
	}
};
var $author$project$Strings$Translations$product_showcase_institutions = function (lang) {
	switch (lang) {
		case 0:
			return 'Institutions';
		case 1:
			return 'Instituciones';
		case 2:
			return '机构';
		case 3:
			return 'Les institutions';
		default:
			return '기관';
	}
};
var $author$project$Strings$Translations$product_showcase_manage = function (lang) {
	switch (lang) {
		case 0:
			return 'Manage';
		case 1:
			return 'Gestionar';
		case 2:
			return '管理';
		case 3:
			return 'Gérer';
		default:
			return '관리';
	}
};
var $author$project$Strings$Translations$product_showcase_tools = function (lang) {
	switch (lang) {
		case 0:
			return 'Tools';
		case 1:
			return 'Herramientas';
		case 2:
			return '工具';
		case 3:
			return 'Outils';
		default:
			return '도구';
	}
};
var $author$project$LandingSite$Home$projectCard = F4(
	function (projectUrl, projectName, projectTitle, projectDescription) {
		return A2(
			$elm$html$Html$a,
			_Utils_ap(
				_List_fromArray(
					[
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-4 col-xs-12'),
						$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank')
					]),
				A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, projectUrl)),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('legacy-panel')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('icon icon--' + projectName)
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('project-card-header')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h4,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(projectTitle)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('project-card-description')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(projectDescription)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('project-card-try')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('small')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Try')
										])),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('arrow'),
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('arrow')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Bootstrap$Carousel$slides = F2(
	function (newSlides, _v0) {
		var settings = _v0;
		return _Utils_update(
			settings,
			{c7: newSlides});
	});
var $author$project$Strings$Translations$token_tax = function (lang) {
	switch (lang) {
		case 0:
			return 'Tokentax';
		case 1:
			return 'Tokentax';
		case 2:
			return 'Tokentax';
		case 3:
			return 'Tokentax';
		default:
			return 'Tokentax';
	}
};
var $author$project$Strings$Translations$token_tax_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Tax software and cryptocurrency accounting firm, to help with your Compound activity.';
		case 1:
			return 'Software de impuestos y firma de contabilidad de criptomonedas, para ayudarlo con su actividad de Compuestos.';
		case 2:
			return '税务软件和加密货币会计公司，以帮助您进行复合活动。';
		case 3:
			return 'Logiciel fiscal et cabinet de comptabilité en crypto-monnaie, pour vous aider dans votre activité Compound.';
		default:
			return 'Compound 활동에 도움이 되는 세금 소프트웨어와 암호화폐 회계 서비스를 제공합니다.';
	}
};
var $author$project$Bootstrap$Carousel$EndTransition = function (a) {
	return {$: 4, a: a};
};
var $author$project$Bootstrap$Carousel$SetHover = function (a) {
	return {$: 5, a: a};
};
var $author$project$Bootstrap$Carousel$controlNext = A2(
	$elm$html$Html$button,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('btn btn-link carousel-control-next'),
			A2($elm$html$Html$Attributes$attribute, 'role', 'button'),
			$elm$html$Html$Events$onClick(
			$author$project$Bootstrap$Carousel$StartTransition($author$project$Bootstrap$Carousel$Next))
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('carousel-control-next-icon'),
					A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true')
				]),
			_List_Nil),
			A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('sr-only')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Next')
				]))
		]));
var $author$project$Bootstrap$Carousel$Prev = {$: 1};
var $author$project$Bootstrap$Carousel$controlPrev = A2(
	$elm$html$Html$button,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('btn btn-link carousel-control-prev'),
			$elm$html$Html$Events$onClick(
			$author$project$Bootstrap$Carousel$StartTransition($author$project$Bootstrap$Carousel$Prev))
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('carousel-control-prev-icon'),
					A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true')
				]),
			_List_Nil),
			A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('sr-only')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Previous')
				]))
		]));
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$Keyed$node = $elm$virtual_dom$VirtualDom$keyedNode;
var $author$project$Bootstrap$Carousel$dirtyHack = function (size) {
	return A3(
		$elm$html$Html$Keyed$node,
		'div',
		_List_Nil,
		_List_fromArray(
			[
				_Utils_Tuple2(
				'dirtyHack',
				A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$Events$on,
							'load',
							$elm$json$Json$Decode$succeed(
								$author$project$Bootstrap$Carousel$EndTransition(size))),
							$elm$html$Html$Attributes$src('https://package.elm-lang.org/assets/favicon.ico'),
							A2($elm$html$Html$Attributes$style, 'display', 'none')
						]),
					_List_Nil))
			]));
};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $author$project$Bootstrap$Carousel$indicators = F2(
	function (size, activeIndex) {
		var item = function (n) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'active',
								_Utils_eq(n, activeIndex))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$Bootstrap$Carousel$StartTransition(
							$author$project$Bootstrap$Carousel$Number(n)))
					]),
				_List_Nil);
		};
		var items = A2(
			$elm$core$List$map,
			item,
			A2($elm$core$List$range, 0, size - 1));
		return A2(
			$elm$html$Html$ol,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('carousel-indicators')
				]),
			items);
	});
var $elm$html$Html$Events$onMouseEnter = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseenter',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$onMouseLeave = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseleave',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Bootstrap$Carousel$SlideInternal$addAttributes = F2(
	function (newAttributes, _v0) {
		var settings = _v0;
		return _Utils_update(
			settings,
			{
				ft: _Utils_ap(settings.ft, newAttributes)
			});
	});
var $author$project$Bootstrap$Carousel$transitionClassNames = F2(
	function (currentIndex, transition) {
		var base = 'carousel-item';
		var leftNext = {cK: base + '-left', cZ: base + '-next'};
		var rightPrev = {cK: base + '-right', cZ: base + '-prev'};
		switch (transition.$) {
			case 0:
				return leftNext;
			case 2:
				var n = transition.a;
				return (_Utils_cmp(n, currentIndex) > 0) ? leftNext : rightPrev;
			default:
				return rightPrev;
		}
	});
var $author$project$Bootstrap$Carousel$transitionClasses = F4(
	function (index, currentIndex, newIndex, tstage) {
		if (_Utils_eq(index, currentIndex)) {
			switch (tstage.$) {
				case 2:
					return _List_fromArray(
						[
							_Utils_Tuple2('active', true)
						]);
				case 0:
					var transition = tstage.a;
					return _List_fromArray(
						[
							_Utils_Tuple2('active', true)
						]);
				default:
					var transition = tstage.a;
					var _v1 = A2($author$project$Bootstrap$Carousel$transitionClassNames, currentIndex, transition);
					var directionalClassName = _v1.cK;
					return _List_fromArray(
						[
							_Utils_Tuple2('active', true),
							_Utils_Tuple2(directionalClassName, true)
						]);
			}
		} else {
			if (_Utils_eq(index, newIndex)) {
				switch (tstage.$) {
					case 2:
						return _List_Nil;
					case 0:
						var transition = tstage.a;
						return _List_fromArray(
							[
								_Utils_Tuple2(
								function ($) {
									return $.cZ;
								}(
									A2($author$project$Bootstrap$Carousel$transitionClassNames, currentIndex, transition)),
								true)
							]);
					default:
						var transition = tstage.a;
						var _v3 = A2($author$project$Bootstrap$Carousel$transitionClassNames, currentIndex, transition);
						var directionalClassName = _v3.cK;
						var orderClassName = _v3.cZ;
						return _List_fromArray(
							[
								_Utils_Tuple2(directionalClassName, true),
								_Utils_Tuple2(orderClassName, true)
							]);
				}
			} else {
				return _List_Nil;
			}
		}
	});
var $author$project$Bootstrap$Carousel$SlideInternal$view = function (_v0) {
	var attributes = _v0.ft;
	var content = _v0.ij;
	var caption = _v0.fC;
	var captionHtml = function () {
		if (caption.$ === 1) {
			return $elm$html$Html$text('');
		} else {
			var rec = caption.a;
			return A2(
				$elm$html$Html$div,
				_Utils_ap(
					rec.ft,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('carousel-caption d-none d-md-block')
						])),
				rec.ia);
		}
	}();
	return A2(
		$elm$html$Html$div,
		_Utils_ap(
			attributes,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('carousel-item')
				])),
		function () {
			if (!content.$) {
				var rec = content.a;
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_Utils_ap(
							rec.ft,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('d-block img-fluid'),
									$elm$html$Html$Attributes$src(rec.jM)
								])),
						_List_Nil),
						captionHtml
					]);
			} else {
				var html = content.a.iO;
				return _List_fromArray(
					[html, captionHtml]);
			}
		}());
};
var $author$project$Bootstrap$Carousel$viewSlide = F3(
	function (model, index, slide) {
		var tstage = model.a;
		var currentIndex = model.b.aS;
		var size = model.b.a3;
		var newIndex = A3($author$project$Bootstrap$Carousel$nextIndex, tstage, currentIndex, size);
		return $author$project$Bootstrap$Carousel$SlideInternal$view(
			A2(
				$author$project$Bootstrap$Carousel$SlideInternal$addAttributes,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						A4($author$project$Bootstrap$Carousel$transitionClasses, index, currentIndex, newIndex, tstage))
					]),
				slide));
	});
var $author$project$Bootstrap$Carousel$view = F2(
	function (model, _v0) {
		var tstage = model.a;
		var hovering = model.b.bY;
		var currentIndex = model.b.aS;
		var wrap = model.b.a9;
		var settings = _v0;
		var slidesHtml = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('carousel-inner'),
					A2($elm$html$Html$Attributes$attribute, 'role', 'listbox')
				]),
			A2(
				$elm$core$List$indexedMap,
				$author$project$Bootstrap$Carousel$viewSlide(model),
				settings.c7));
		var size = $elm$core$List$length(settings.c7);
		var indicatorsHtml = settings.d1 ? A2(
			$author$project$Bootstrap$Carousel$indicators,
			size,
			A3($author$project$Bootstrap$Carousel$nextIndex, tstage, currentIndex, size)) : $elm$html$Html$text('');
		var defaultCarouselAttributes = _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('carousel slide'),
					A2(
					$elm$html$Html$Events$on,
					'transitionend',
					$elm$json$Json$Decode$succeed(
						settings.cn(
							$author$project$Bootstrap$Carousel$EndTransition(size))))
				]),
			(hovering !== 2) ? _List_fromArray(
				[
					$elm$html$Html$Events$onMouseEnter(
					settings.cn(
						$author$project$Bootstrap$Carousel$SetHover(0))),
					$elm$html$Html$Events$onMouseLeave(
					settings.cn(
						$author$project$Bootstrap$Carousel$SetHover(1)))
				]) : _List_Nil);
		var controlsHtml = settings.dz ? ((wrap || ((!(!currentIndex)) && (!_Utils_eq(currentIndex, size - 1)))) ? _List_fromArray(
			[$author$project$Bootstrap$Carousel$controlPrev, $author$project$Bootstrap$Carousel$controlNext]) : ((!currentIndex) ? _List_fromArray(
			[$author$project$Bootstrap$Carousel$controlNext]) : _List_fromArray(
			[$author$project$Bootstrap$Carousel$controlPrev]))) : _List_Nil;
		return A2(
			$elm$html$Html$div,
			_Utils_ap(settings.ft, defaultCarouselAttributes),
			A2(
				$elm$core$List$cons,
				slidesHtml,
				A2(
					$elm$core$List$map,
					$elm$html$Html$map(settings.cn),
					_Utils_ap(
						_List_fromArray(
							[
								$author$project$Bootstrap$Carousel$dirtyHack(size),
								indicatorsHtml
							]),
						controlsHtml))));
	});
var $author$project$Strings$Translations$zapper = function (lang) {
	switch (lang) {
		case 0:
			return 'Zapper';
		case 1:
			return 'Zapper';
		case 2:
			return 'Zapper';
		case 3:
			return 'Zapper';
		default:
			return 'Zapper';
	}
};
var $author$project$Strings$Translations$zapper_description = function (lang) {
	switch (lang) {
		case 0:
			return 'A seamless interface to Compound alongside other DeFi applications.';
		case 1:
			return 'Una interfaz perfecta para Compound junto con otras aplicaciones DeFi.';
		case 2:
			return 'Compound 与其他 DeFi 应用程序的无缝接口。';
		case 3:
			return 'Une interface transparente vers Compound aux côtés d\'autres applications DeFi.';
		default:
			return '다른 DeFi 애플리케이션과 함께 Compound에 대한 원활한 인터페이스.';
	}
};
var $author$project$Strings$Translations$zerion = function (lang) {
	switch (lang) {
		case 0:
			return 'Zerion';
		case 1:
			return 'Zerion';
		case 2:
			return 'Zerion';
		case 3:
			return 'Zerion';
		default:
			return 'Zerion';
	}
};
var $author$project$Strings$Translations$zerion_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Save, borrow, and monitor Compound & Ethereum positions.';
		case 1:
			return 'Una interfaz intuitiva para protocolos basados en blockchain para finanzas descentralizadas.';
		case 2:
			return '基于区块链的分布式融资协议的直观界面。';
		case 3:
			return 'Une interface intuitive pour les protocoles basés sur la blockchain pour la finance décentralisée.';
		default:
			return '탈중앙형 금융을 위한 블록체인 기반 프로토콜에 직관적인 인터페이스를 제공합니다.';
	}
};
var $author$project$LandingSite$Home$projectsCarousel = F2(
	function (userLanguage, _v0) {
		var projectsCarouselState = _v0.aI;
		var selectedProjectCategory = _v0.c6;
		var tabOptionClass = function (category) {
			return _Utils_eq(category, selectedProjectCategory) ? 'tab-group__option tab-group__option--active tab-group__option--active--green' : 'tab-group__option';
		};
		var slideClass = function (category) {
			return _Utils_eq(category, selectedProjectCategory) ? 'projects__carousel__slide projects__carousel__slide--active' : 'projects__carousel__slide';
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('projects')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('tab-group projects__tab-group')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('tab-group__options')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$label,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class(
											tabOptionClass(3)),
											$elm$html$Html$Events$onClick(
											$author$project$LandingSite$Home$SelectProjectCategory(3))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											$author$project$Strings$Translations$product_showcase_institutions(userLanguage))
										])),
									A2(
									$elm$html$Html$label,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class(
											tabOptionClass(0)),
											$elm$html$Html$Events$onClick(
											$author$project$LandingSite$Home$SelectProjectCategory(0))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											$author$project$Strings$Translations$product_showcase_earn(userLanguage))
										])),
									A2(
									$elm$html$Html$label,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class(
											tabOptionClass(1)),
											$elm$html$Html$Events$onClick(
											$author$project$LandingSite$Home$SelectProjectCategory(1))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											$author$project$Strings$Translations$product_showcase_manage(userLanguage))
										])),
									A2(
									$elm$html$Html$label,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class(
											tabOptionClass(2)),
											$elm$html$Html$Events$onClick(
											$author$project$LandingSite$Home$SelectProjectCategory(2))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											$author$project$Strings$Translations$product_showcase_tools(userLanguage))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('tab-group__line')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-xs-12 projects__carousel')
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Bootstrap$Carousel$view,
									projectsCarouselState,
									A2(
										$author$project$Bootstrap$Carousel$slides,
										_List_fromArray(
											[
												A2(
												$author$project$Bootstrap$Carousel$Slide$config,
												_List_Nil,
												$author$project$Bootstrap$Carousel$Slide$customContent(
													A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class(
																slideClass(3))
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('row')
																	]),
																_List_fromArray(
																	[
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://custody.coinbase.com',
																		'coinbase-custody',
																		$author$project$Strings$Translations$coinbase_custody(userLanguage),
																		$author$project$Strings$Translations$coinbase_custody_description(userLanguage)),
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://anchorage.com',
																		'anchorage',
																		$author$project$Strings$Translations$anchorage(userLanguage),
																		$author$project$Strings$Translations$anchorage_description(userLanguage)),
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://www.fireblocks.com',
																		'fireblocks',
																		$author$project$Strings$Translations$fireblocks(userLanguage),
																		$author$project$Strings$Translations$fireblocks_description(userLanguage)),
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://www.bitgo.com/',
																		'bitgo',
																		$author$project$Strings$Translations$bitgo(userLanguage),
																		$author$project$Strings$Translations$bitgo_description(userLanguage)),
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://www.ledger.com/lend',
																		'ledger',
																		$author$project$Strings$Translations$ledger(userLanguage),
																		$author$project$Strings$Translations$ledger_description(userLanguage))
																	]))
															])))),
												A2(
												$author$project$Bootstrap$Carousel$Slide$config,
												_List_Nil,
												$author$project$Bootstrap$Carousel$Slide$customContent(
													A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class(
																slideClass(0))
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('row')
																	]),
																_List_fromArray(
																	[
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://www.argent.xyz/',
																		'argent',
																		$author$project$Strings$Translations$argent(userLanguage),
																		$author$project$Strings$Translations$argent_description(userLanguage)),
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://www.okx.com/web3/earn',
																		'okx',
																		$author$project$Strings$Translations$okx(userLanguage),
																		$author$project$Strings$Translations$okx_description(userLanguage)),
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://www.pooltogether.us',
																		'pool-together',
																		$author$project$Strings$Translations$pool_together(userLanguage),
																		$author$project$Strings$Translations$pool_together_description(userLanguage)),
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://www.binance.com/en/defi-staking',
																		'binance',
																		$author$project$Strings$Translations$binance(userLanguage),
																		$author$project$Strings$Translations$binance_description(userLanguage)),
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://crypto.com/us/defi-wallet',
																		'crypto-dot-com',
																		$author$project$Strings$Translations$crypto_dot_com(userLanguage),
																		$author$project$Strings$Translations$crypto_dot_com_description(userLanguage)),
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://token.im/',
																		'imToken',
																		$author$project$Strings$Translations$imToken(userLanguage),
																		$author$project$Strings$Translations$imToken_description(userLanguage)),
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://zapper.fi/save',
																		'zapper',
																		$author$project$Strings$Translations$zapper(userLanguage),
																		$author$project$Strings$Translations$zapper_description(userLanguage)),
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://www.exodus.io',
																		'exodus',
																		$author$project$Strings$Translations$exodus(userLanguage),
																		$author$project$Strings$Translations$exodus_description(userLanguage))
																	]))
															])))),
												A2(
												$author$project$Bootstrap$Carousel$Slide$config,
												_List_Nil,
												$author$project$Bootstrap$Carousel$Slide$customContent(
													A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class(
																slideClass(1))
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('row')
																	]),
																_List_fromArray(
																	[
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://defi.instadapp.io/compound',
																		'instadapp',
																		$author$project$Strings$Translations$instadapp(userLanguage),
																		$author$project$Strings$Translations$instadapp_description(userLanguage)),
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://comp.vote/',
																		'comp-vote',
																		$author$project$Strings$Translations$comp_vote(userLanguage),
																		$author$project$Strings$Translations$comp_vote_description(userLanguage)),
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://defisaver.com/compound/manage',
																		'defi-saver',
																		$author$project$Strings$Translations$defi_saver(userLanguage),
																		$author$project$Strings$Translations$defi_saver_description(userLanguage)),
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://zerion.io/en',
																		'zerion',
																		$author$project$Strings$Translations$zerion(userLanguage),
																		$author$project$Strings$Translations$zerion_description(userLanguage)),
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://app.ankr.com/apps/info?name=compound&repository=stable&type=chart&version=1.0.2',
																		'ankr',
																		$author$project$Strings$Translations$ankr(userLanguage),
																		$author$project$Strings$Translations$ankr_description(userLanguage)),
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://enzyme.finance/',
																		'enzyme',
																		$author$project$Strings$Translations$enzyme(userLanguage),
																		$author$project$Strings$Translations$enzyme_description(userLanguage)),
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://www.bprotocol.org/?from=compound',
																		'bprotocl',
																		$author$project$Strings$Translations$bprotocol(userLanguage),
																		$author$project$Strings$Translations$bprotocol_description(userLanguage)),
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://eidoo.io/',
																		'eidoo',
																		$author$project$Strings$Translations$eidoo(userLanguage),
																		$author$project$Strings$Translations$eidoo_description(userLanguage))
																	]))
															])))),
												A2(
												$author$project$Bootstrap$Carousel$Slide$config,
												_List_Nil,
												$author$project$Bootstrap$Carousel$Slide$customContent(
													A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class(
																slideClass(2))
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('row')
																	]),
																_List_fromArray(
																	[
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://tokentax.co',
																		'tokentax',
																		$author$project$Strings$Translations$token_tax(userLanguage),
																		$author$project$Strings$Translations$token_tax_description(userLanguage)),
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://www.cointracker.io',
																		'cointracker',
																		$author$project$Strings$Translations$cointracker(userLanguage),
																		$author$project$Strings$Translations$cointracker_description(userLanguage)),
																		A4(
																		$author$project$LandingSite$Home$projectCard,
																		'https://compound.education',
																		'compound-education',
																		$author$project$Strings$Translations$compound_education(userLanguage),
																		$author$project$Strings$Translations$compound_education_description(userLanguage))
																	]))
															]))))
											]),
										A2(
											$author$project$Bootstrap$Carousel$config,
											A2($elm$core$Basics$composeL, $elm$core$Basics$identity, $author$project$LandingSite$Home$ProjectsCarouselMsg),
											_List_Nil)))
								]))
						]))
				]));
	});
var $author$project$Strings$Translations$protocol_security = function (lang) {
	switch (lang) {
		case 0:
			return 'Protocol Security';
		case 1:
			return 'Protocolo de seguridad';
		case 2:
			return '协议安全';
		case 3:
			return 'Sécurité du protocole';
		default:
			return '프로토콜 보안';
	}
};
var $author$project$LandingSite$Home$securityCard = F2(
	function (contentList, maybeContentClass) {
		var contentClass = A2($elm$core$Maybe$withDefault, '', maybeContentClass);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('security-panel')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('security-panel__background')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('security-panel__content security-panel__content--' + contentClass)
								]),
							contentList)
						]))
				]));
	});
var $author$project$Strings$Translations$security_audit = function (lang) {
	switch (lang) {
		case 0:
			return 'Security Audit';
		case 1:
			return 'Auditoria de seguridad';
		case 2:
			return '安全审核';
		case 3:
			return 'Audit de sécurité';
		default:
			return '보안 감사';
	}
};
var $author$project$Strings$Translations$try_compound = function (lang) {
	switch (lang) {
		case 0:
			return 'Try Compound';
		case 1:
			return 'Probar Compound';
		case 2:
			return '体验 Compound';
		case 3:
			return 'Essayez Compound';
		default:
			return 'Compound 체험하기';
	}
};
var $author$project$Strings$Translations$try_compound_description = function (lang) {
	switch (lang) {
		case 0:
			return 'Community-built interfaces integrating the protocol';
		case 1:
			return 'Interfaces construidas en la comunidad que integran el protocolo.';
		case 2:
			return '社区构建的集成协议的接口';
		case 3:
			return 'Interfaces communautaires intégrant le protocole';
		default:
			return '프로토콜이 통합된 커뮤니티 기반 인터페이스';
	}
};
var $author$project$Utils$AppLinksHelper$v2BugBountyHref = A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://docs.compound.finance/v2/security/#bug-bounty-program');
var $author$project$Utils$AppLinksHelper$v2EconomicSecurityHref = A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://docs.compound.finance/v2/security/#economic-security');
var $author$project$Utils$AppLinksHelper$v2FormalVerificationHref = A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://docs.compound.finance/v2/security/#formal-verification');
var $author$project$Utils$AppLinksHelper$v2SecurityAuditsHref = A2($author$project$CompoundComponents$Utils$CompoundHtmlAttributes$href, 2, 'https://docs.compound.finance/v2/security/#audits');
var $author$project$LandingSite$Home$view = F5(
	function (userLanguage, network, maybeLatestTokens, v3MarketSummaries, model) {
		var latestTokens = function () {
			if (!maybeLatestTokens.$) {
				var tokens = maybeLatestTokens.a;
				return (network === 1) ? A2(
					$elm$core$List$filter,
					function (apiToken) {
						return (apiToken.kp !== 'SAI') && (apiToken.kp !== 'REP');
					},
					tokens.h4) : tokens.h4;
			} else {
				return _List_Nil;
			}
		}();
		var _v0 = function () {
			var _v1 = _Utils_Tuple2(
				$elm$core$List$isEmpty(latestTokens),
				$elm$core$List$isEmpty(v3MarketSummaries));
			if ((!_v1.a) && (!_v1.b)) {
				return _Utils_Tuple2(latestTokens, v3MarketSummaries);
			} else {
				return _Utils_Tuple2(_List_Nil, _List_Nil);
			}
		}();
		var latestV2Tokens = _v0.a;
		var v3MarketSummariesLoaded = _v0.b;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$section,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('landing-overview')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('container-full')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('dot-grid')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('row')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-12 text-left compound-data')
												]),
											A3($author$project$LandingSite$Home$marketsHero, userLanguage, latestV2Tokens, v3MarketSummariesLoaded))
										]))
								]))
						])),
					A2(
					$elm$html$Html$section,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('try-compound')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('container-large try-compound')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('row')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-5 text-left try-compound__header')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$label,
													_List_fromArray(
														[
															$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('label big')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$try_compound(userLanguage))
														])),
													A2(
													$elm$html$Html$h2,
													_List_fromArray(
														[
															$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('summary')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$try_compound_description(userLanguage))
														]))
												]))
										])),
									A2($author$project$LandingSite$Home$projectsCarousel, userLanguage, model),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('dot-grid-left')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('dot-grid-right')
										]),
									_List_Nil)
								]))
						])),
					A2(
					$elm$html$Html$section,
					_List_fromArray(
						[
							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('security-home')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('container-large')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('row')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-12 text-left')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$label,
													_List_fromArray(
														[
															$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('label big')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$audited_and_verified(userLanguage))
														])),
													A2(
													$elm$html$Html$h2,
													_List_fromArray(
														[
															$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('summary')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$audited_and_verified_description(userLanguage))
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('call-to-action')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$a,
															A2(
																$elm$core$List$cons,
																$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank'),
																$author$project$Utils$AppLinksHelper$v2SecurityHref),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$protocol_security(userLanguage))
																])),
															A2(
															$elm$html$Html$span,
															_List_fromArray(
																[
																	$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('arrow'),
																	$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('arrow')
																]),
															_List_Nil)
														]))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('audits')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('row')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$a,
													_Utils_ap(
														_List_fromArray(
															[
																$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-4 col-xs-12'),
																$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank')
															]),
														$author$project$Utils$AppLinksHelper$v2SecurityAuditsHref),
													_List_fromArray(
														[
															A2(
															$author$project$LandingSite$Home$securityCard,
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('security-panel__image security-panel__image--oz')
																		]),
																	_List_Nil),
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$security_audit(userLanguage))
																]),
															$elm$core$Maybe$Nothing)
														])),
													A2(
													$elm$html$Html$a,
													_Utils_ap(
														_List_fromArray(
															[
																$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-4 col-xs-12'),
																$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank')
															]),
														$author$project$Utils$AppLinksHelper$v2SecurityAuditsHref),
													_List_fromArray(
														[
															A2(
															$author$project$LandingSite$Home$securityCard,
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('security-panel__image security-panel__image--tob')
																		]),
																	_List_Nil),
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$security_audit(userLanguage))
																]),
															$elm$core$Maybe$Nothing)
														])),
													A2(
													$elm$html$Html$a,
													_Utils_ap(
														_List_fromArray(
															[
																$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-4 col-xs-12'),
																$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank')
															]),
														$author$project$Utils$AppLinksHelper$v2FormalVerificationHref),
													_List_fromArray(
														[
															A2(
															$author$project$LandingSite$Home$securityCard,
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('security-panel__image security-panel__image--certora')
																		]),
																	_List_Nil),
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$formal_verification(userLanguage))
																]),
															$elm$core$Maybe$Nothing)
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('row')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$a,
													_Utils_ap(
														_List_fromArray(
															[
																$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-4 col-xs-12'),
																$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank')
															]),
														$author$project$Utils$AppLinksHelper$v2EconomicSecurityHref),
													_List_fromArray(
														[
															A2(
															$author$project$LandingSite$Home$securityCard,
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('security-panel__image security-panel__image--gauntlet')
																		]),
																	_List_Nil),
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$market_risk_assessment(userLanguage))
																]),
															$elm$core$Maybe$Nothing)
														])),
													A2(
													$elm$html$Html$a,
													_Utils_ap(
														_List_fromArray(
															[
																$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('col-sm-8 col-xs-12'),
																$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$target('_blank')
															]),
														$author$project$Utils$AppLinksHelper$v2BugBountyHref),
													_List_fromArray(
														[
															A2(
															$author$project$LandingSite$Home$securityCard,
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('audits__bug-bounty')
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$div,
																			_List_fromArray(
																				[
																					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('audits__bug-bounty__image-section')
																				]),
																			_List_fromArray(
																				[
																					A2(
																					$elm$html$Html$div,
																					_List_fromArray(
																						[
																							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('security-panel__image security-panel__image--bug-bounty')
																						]),
																					_List_Nil)
																				])),
																			A2(
																			$elm$html$Html$div,
																			_List_fromArray(
																				[
																					$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('audits__bug-bounty__text-section')
																				]),
																			_List_fromArray(
																				[
																					A2(
																					$elm$html$Html$div,
																					_List_fromArray(
																						[
																							$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('audits__bug-bounty__text-section__bounty')
																						]),
																					_List_fromArray(
																						[
																							A2(
																							$elm$html$Html$p,
																							_List_Nil,
																							_List_fromArray(
																								[
																									$elm$html$Html$text('$')
																								])),
																							A2(
																							$elm$html$Html$h2,
																							_List_fromArray(
																								[
																									$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$class('audits__bug-bounty__text-section__bounty__value')
																								]),
																							_List_fromArray(
																								[
																									$elm$html$Html$text('150,000')
																								])),
																							A2(
																							$elm$html$Html$p,
																							_List_Nil,
																							_List_fromArray(
																								[
																									$elm$html$Html$text('00')
																								]))
																						])),
																					$elm$html$Html$text(
																					$author$project$Strings$Translations$bug_bounty(userLanguage))
																				]))
																		]))
																]),
															$elm$core$Maybe$Just('bug-bounty'))
														]))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$LandingSite$Main$viewPage = function (model) {
	var configs = model.dw;
	var latestTokens = model.b$;
	var latestV3Summaries = model.b0;
	var network = model.T;
	var page = model.aa;
	var pages = model.aF;
	var userLanguage = model.as;
	var v3MarketSummaries = $elm$core$Dict$values(latestV3Summaries);
	var backPage = A2(
		$elm$core$Maybe$withDefault,
		$author$project$LandingSite$Page$GovernanceHome,
		$elm$core$List$head(pages));
	var pageMainContent = function () {
		switch (page.$) {
			case 0:
				return A2(
					$elm$html$Html$map,
					$author$project$LandingSite$Main$landingHomeTranslator,
					A5($author$project$LandingSite$Home$view, userLanguage, network, latestTokens, v3MarketSummaries, model.ao));
			case 1:
				return A2(
					$elm$html$Html$map,
					$author$project$LandingSite$Main$landingCompTranslator,
					A4($author$project$LandingSite$Comp$view, userLanguage, network, latestTokens, model.aY));
			case 2:
				return A2(
					$elm$html$Html$map,
					$author$project$LandingSite$Main$governanceHomeTranslator,
					A5($author$project$Governance$Home$view, userLanguage, model.be, model.az, network, model.bj));
			case 3:
				var proposalId = page.a;
				return A2(
					$elm$html$Html$map,
					$author$project$LandingSite$Main$proposalDetailTranslator,
					A7($author$project$Governance$ProposalDetail$view, userLanguage, network, backPage, proposalId, model.be, model.az, model.aX));
			default:
				return A2(
					$elm$html$Html$map,
					$author$project$LandingSite$Main$proposalOverviewTranslator,
					A4($author$project$Governance$ProposalOverview$view, userLanguage, model.be, model.az, model.bk));
		}
	}();
	return pageMainContent;
};
var $author$project$LandingSite$Main$view = function (model) {
	var page = model.aa;
	var network = model.T;
	var userLanguage = model.as;
	var mobileMenuShowing = model.b7;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$author$project$CompoundComponents$Utils$CompoundHtmlAttributes$id('main')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$map,
				$author$project$LandingSite$Main$landingCommonTranslator,
				A3($author$project$LandingSite$CommonViews$pageHeader, userLanguage, page, mobileMenuShowing)),
				$author$project$LandingSite$Main$viewPage(model),
				A2(
				$elm$html$Html$map,
				$author$project$LandingSite$Main$landingCommonTranslator,
				A3($author$project$LandingSite$CommonViews$pageFooter, userLanguage, network, model.bq))
			]));
};
var $author$project$LandingSite$Main$main = $elm$browser$Browser$element(
	{iT: $author$project$LandingSite$Main$init, jU: $author$project$LandingSite$Main$subscriptions, ks: $author$project$LandingSite$Main$update, kv: $author$project$LandingSite$Main$view});
_Platform_export({'LandingSite':{'Main':{'init':$author$project$LandingSite$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (targetNetwork) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (protoDocJson) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (path) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (language) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (configurations) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (configAbiFiles) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (apiBaseUrlMap) {
															return $elm$json$Json$Decode$succeed(
																{M: apiBaseUrlMap, dv: configAbiFiles, dx: configurations, d3: language, eA: path, ce: protoDocJson, e1: targetNetwork});
														},
														A2($elm$json$Json$Decode$field, 'apiBaseUrlMap', $elm$json$Json$Decode$value));
												},
												A2($elm$json$Json$Decode$field, 'configAbiFiles', $elm$json$Json$Decode$value));
										},
										A2($elm$json$Json$Decode$field, 'configurations', $elm$json$Json$Decode$value));
								},
								A2($elm$json$Json$Decode$field, 'language', $elm$json$Json$Decode$string));
						},
						A2($elm$json$Json$Decode$field, 'path', $elm$json$Json$Decode$string));
				},
				A2($elm$json$Json$Decode$field, 'protoDocJson', $elm$json$Json$Decode$value));
		},
		A2($elm$json$Json$Decode$field, 'targetNetwork', $elm$json$Json$Decode$string)))(0)}}});}(this));